import React from "react";
interface CenterContainerProps {
  children: React.ReactNode;
}

const ResponsiveContainer = (props: CenterContainerProps) => {
  const { children } = props;
  return (
    <div className="max-w-[600px] mx-auto px-4 lg:max-w-[1024px] items-center lg:mt-12">
      {children}
    </div>
  );
};

export const ResponsiveContainerLargeSize = (props: CenterContainerProps) => {
  const { children } = props;
  return (
    <div className="max-w-[600px] mx-auto px-4 lg:px-8 lg:max-w-[2560px] items-center">
      {children}
    </div>
  );
};

export default ResponsiveContainer;
