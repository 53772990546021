import React from "react";
import ReactModal from "react-modal";
import clsx from "classnames";
import CloseIcon from "~assets/close.svg";

export interface ModalProps extends ReactModal.Props {
  title?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  hideCloseButton?: boolean;
  bodyClassName?: string;
}

export default function Modal(props: ModalProps) {
  const {
    title,
    className,
    contentClassName,
    onRequestClose,
    hideCloseButton,
    bodyClassName,
  } = props;
  return (
    <ReactModal
      {...props}
      className={clsx(className, "h-screen grid place-items-center")}
      style={{
        content: {
          outline: "none",
        },
      }}
    >
      <div
        className={clsx("relative", contentClassName || "w-11/12")}
        style={{ maxWidth: "550px" }}
      >
        {!hideCloseButton && (
          <button
            onClick={onRequestClose}
            className="absolute top-0 right-0 w-6 h-6 mt-4 mr-2 group"
          >
            <img
              className="opacity-30 group-hover:opacity-100"
              src={CloseIcon}
              width="22px"
              height="22px"
              alt="Close"
            />
          </button>
        )}
        {/* body */}
        <div className={`p-4 bg-white rounded-lg ${bodyClassName}`}>
          {title && <h2 className="text-lg font-bold">{title}</h2>}
          {props.children}
        </div>
      </div>
    </ReactModal>
  );
}
