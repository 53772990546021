import axios from "axios";
import { config } from "./global";
import { useWalletSelector } from "~context/namesky";
import { useQuery } from "@tanstack/react-query";
import { Near } from "near-api-js";

export const getAccountInfo = async (accountId: string): Promise<any> => {
  axios.get(`${config.WEB4_RPC_URL}/account/${accountId}`).then((res) => {
    console.log(res);
  });
};

export const accountExist = async (accountId: string): Promise<boolean> => {
  return axios
    .get(`${config.WEB4_RPC_URL}/account/${accountId}`)
    .then((res) => {
      console.log({ res });
      return true;
    })
    .catch((e) => {
      if (e.toString().includes("404")) {
        return false;
      }
      throw e;
    });
};

export const checkAccountExist = (near: Near, accountId: string) => {
  return near
    .account(accountId)
    .then((account) => {
      return account.state();
    })
    .then((state) => {
      return true;
    })
    .catch((e) => {
      e.toString().includes("does not exist");
      return false;
    });
};
