import { Amount, Units } from "namesky-sdk";
import { AccountId, U128 } from "~domain/near/types";
import { config } from "~domain/near/global";
import { BigNumber } from "bignumber.js";

export function assert(condition: boolean, message: string) {
  if (!condition) {
    throw message;
  }
}

export function addQueryParams(
  baseUrl: string,
  queryParams: {
    [name: string]: string;
  }
) {
  const url = new URL(baseUrl);
  for (const key in queryParams) {
    const param = queryParams[key];
    if (param) url.searchParams.set(key, param);
  }
  return url.toString();
}

export function numberWithCommas(x) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function transferToNumberWithCommas(x: U128, fixedCount?: number) {
  if (fixedCount === 0) {
    return numberWithCommas(Number(Amount.format(x, "NEAR")).toFixed(0));
  }
  if (fixedCount && fixedCount > 0 && fixedCount < 100) {
    return numberWithCommas(
      Number(Amount.format(x, "NEAR")).toFixed(fixedCount)
    );
  }
  return numberWithCommas(Number(Amount.format(x, "NEAR")));
}

/**
 * bubbling element in array
 * eg: array = [1,2,3], bubbling_index = 1, should return [2,1,3]
 * @param array
 * @param bubbling_index
 */
export function bubblingElementInArray(
  array: any[],
  bubbling_index: number
): any[] {
  if (array.length == 0) return array;
  // array.length should > 0 now
  assert(
    Number.isInteger(bubbling_index) &&
      bubbling_index >= 0 &&
      bubbling_index < array.length,
    `Array Index out of bounds.`
  );
  return [
    array[bubbling_index],
    ...array.slice(0, bubbling_index),
    ...array.slice(bubbling_index + 1, array.length),
  ];
}

export function convertToSearchName(input: AccountId): string {
  const name_split = input.split(".");
  if (name_split.length == 1) {
    return input;
  } else {
    return name_split.splice(0, name_split.length - 1).join("");
  }
}

export function helpCompleteAccountIdInput(input: AccountId): AccountId {
  if (input.includes(".")) {
    return input;
  }
  return input + (config.networkId === "testnet" ? ".testnet" : ".near");
}

function splitAccountInfo(str: string) {
  const parts = str.split(".");
  const firstPart = parts.slice(0, -1).join(".");
  const secondPart = parts[parts.length - 1];
  return [firstPart, secondPart];
}

export const generateNFTMediaSvg = (accountId = "") => {
  const [accountName, networkId] = splitAccountInfo(accountId);
  const svg = `
<svg viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
<text class="small" x="20" y="35">My coolll</text>
<rect width="800" height="800" rx="16" fill="url(#a)"/>
<g clip-path="url(#b)">
<path d="m156.18 98.554-14.632 21.724c-0.209 0.313-0.297 0.69-0.248 1.063 0.048 0.373 0.23 0.715 0.512 0.964s0.644 0.388 1.02 0.391c0.376 2e-3 0.74-0.131 1.025-0.377l14.403-12.493c0.085-0.075 0.189-0.124 0.301-0.142 0.112-0.017 0.227-1e-3 0.33 0.046s0.19 0.123 0.251 0.219c0.06 0.095 0.092 0.206 0.09 0.32v39.112c0 0.12-0.036 0.237-0.105 0.335s-0.166 0.172-0.279 0.213c-0.112 0.041-0.235 0.046-0.35 0.015-0.116-0.031-0.219-0.097-0.296-0.188l-43.536-52.116c-0.7-0.8269-1.572-1.4914-2.555-1.9472-0.983-0.4559-2.054-0.6921-3.138-0.6924h-1.521c-1.978 0-3.874 0.7856-5.273 2.1841-1.398 1.3984-2.184 3.2949-2.184 5.2729v55.086c0 1.978 0.786 3.874 2.184 5.273 1.399 1.398 3.295 2.184 5.273 2.184 1.275 0 2.529-0.326 3.642-0.948s2.048-1.519 2.716-2.605l14.632-21.725c0.209-0.313 0.297-0.69 0.248-1.063-0.048-0.373-0.23-0.715-0.512-0.964s-0.644-0.388-1.02-0.391c-0.376-2e-3 -0.74 0.131-1.025 0.377l-14.403 12.493c-0.085 0.075-0.189 0.124-0.301 0.142-0.112 0.017-0.227 1e-3 -0.33-0.046s-0.19-0.123-0.251-0.219c-0.06-0.095-0.092-0.207-0.091-0.32v-39.122c1e-3 -0.12 0.037-0.237 0.106-0.335s0.166-0.172 0.279-0.213c0.112-0.041 0.235-0.046 0.35-0.015 0.116 0.031 0.219 0.097 0.296 0.189l43.531 52.125c0.701 0.827 1.573 1.492 2.556 1.948 0.983 0.455 2.053 0.692 3.137 0.692h1.521c0.98 1e-3 1.95-0.192 2.855-0.566 0.906-0.375 1.728-0.924 2.421-1.616 0.693-0.693 1.243-1.515 1.618-2.42s0.568-1.875 0.568-2.855v-55.086c0-1.978-0.786-3.8745-2.184-5.2729-1.399-1.3985-3.295-2.1841-5.273-2.1841-1.275-3e-4 -2.529 0.3261-3.642 0.9482s-2.048 1.519-2.716 2.6053z" fill="#A01515"/>
</g>
<defs>
<linearGradient id="a" x2="775" y2="795" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFD2A9" offset="0"/>
<stop stop-color="#EADDFF" offset="1"/>
</linearGradient>
<clipPath id="b">
<rect transform="translate(100 95)" width="70" height="70" fill="#fff"/>
</clipPath>
</defs>
<text x="100" y="700" fill="#A01515" font-family="system-ui, sans-serif" font-size="70px" lengthAdjust="spacingAndGlyphs" textLength="600px" font-weight="500">accountName.networkId </text>
</svg>
`
    .replace("accountName", accountName)
    .replace("networkId", networkId);

  const svgBase64 = btoa(unescape(encodeURIComponent(svg)));
  const svgDataUri = `data:image/svg+xml;base64,${svgBase64}`;
  return svgDataUri;
};
