import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Copy, RefreshCw } from "react-feather";
import { KeyPair } from "near-api-js";
import { generateSeedPhrase } from "near-seed-phrase";
import { PrimaryButton, TextButton } from "~components/button/Button";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CenterContainer from "~components/layout/center-container";
import ReactLoading from "react-loading";
import { useLocalStorageState } from "ahooks";
import { useRegistryQuery } from "~domain/nnn/nft_contract/queries";
import PageTitle from "~components/text/page-title";
import { WebWalletIds, useWalletSelector } from "~context/namesky";
import { Gas, Token } from "namesky-sdk";

type MuteButtonProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const MuteButton: React.FC<MuteButtonProps> = (props) => {
  const { icon, children, className, onClick = () => {} } = props;
  return (
    <button
      onClick={onClick}
      className={`bg-gray-100 rounded-full h-[44px] flex justify-center items-center min-w-[124px] px-4 ${className}`}
    >
      <div className="flex items-center">
        {icon}
        <span className="font-bold text-blue-500 ml-[10px]">{children}</span>
      </div>
    </button>
  );
};

const ClaimPage = () => {
  // setp 0: generate secret phrase
  // step 1: ask user to confirm a specific word from the phrase
  const [step, setStep] = useState(0);
  const { id }: { id: string } = useParams();
  const [nft, setNft] = useState<Token>(null);
  const [isFetchingNFT, setIsFetchingNFT] = useState(true);

  const [isClaiming, setIsClaiming] = useState(false);
  const [copied, setCopied] = useState(false);
  const [passPhrase, setPassPhrase] = useState("");
  const [recoveryKeyPair, setRecoveryKeyPair] = useState();
  const [publicKey, setPublicKey] = useState<string>("");
  const [implicitAccountId, setImplicitAccountId] = useState("");
  const [wordIndex, setWordIndex] = useState(null);
  const history = useHistory();

  const [phraseWord, setPhraseWord] = useState("");
  const [phraseWordError, setPhraseWordError] = useState("");
  const [nameskyRecoveryPhrases, setNameskyRecoveryPhrases] =
    useLocalStorageState<{}>("nameskyRecoveryPhrases", {});
  const { namesky, selector, modal } = useWalletSelector();
  const isSignedIn = useMemo(() => {
    if (!selector) return undefined;
    return selector.isSignedIn();
  }, [selector]);

  const generateAndSetPhrase = () => {
    const { seedPhrase, secretKey } = generateSeedPhrase();
    const recoveryKeyPair = KeyPair.fromString(secretKey);
    const implicitAccountId = Buffer.from(
      recoveryKeyPair.getPublicKey().data
    ).toString("hex");

    setPublicKey(recoveryKeyPair.getPublicKey().toString());
    setPassPhrase(seedPhrase);
    setRecoveryKeyPair(recoveryKeyPair);
    setImplicitAccountId(implicitAccountId);
    setWordIndex(Math.floor(Math.random() * 12));
  };

  useEffect(() => {
    if (copied === true) {
      setTimeout(() => setCopied(false), 1000);
    }
  }, [copied]);

  useEffect(() => {
    generateAndSetPhrase();
  }, []);

  useEffect(() => {
    const fetchNFT = async () => {
      setIsFetchingNFT(true);
      if (!namesky) {
        return;
      }
      const nft_token = await namesky.coreContract.nftNameSkyToken({
        tokenId: id,
      });
      setNft(nft_token);
      setIsFetchingNFT(false);
    };
    fetchNFT();
  }, [id, namesky]);

  const { data: registerResult, isLoading: isFetchingRegister } =
    useRegistryQuery(id);

  if (isFetchingNFT || isFetchingRegister) {
    return (
      <CenterContainer>
        <div className="flex items-center justify-center p-4">
          <ReactLoading type="spin" color="#3B82F6" height={64} width={64} />
        </div>
      </CenterContainer>
    );
  }

  // If this NFT doesn't exist, redirect to details page to ask user to mint
  if (!nft && !registerResult) {
    history.push(`/detail/${id}`);
    return <></>;
  }

  const ownerId = nft?.owner_id || registerResult;

  // If the user is not signed in or signed with an account that is not this nft owner
  // ask the user to sign in
  if (!isSignedIn || selector.getActiveAccount()?.accountId !== ownerId) {
    return (
      <CenterContainer>
        <h1 className="text-2xl font-bold">Please sign in as {ownerId}</h1>
        <div className="mt-4" />
        <PrimaryButton
          onClick={() => {
            modal.show();
          }}
        >
          Connect to NEAR
        </PrimaryButton>
      </CenterContainer>
    );
  }

  const handleClaim = async () => {
    if (validatePhraseWord() === false) {
      return;
    }
    // TODO: should actually save the recovery phrase after the claiming successfully
    setNameskyRecoveryPhrases({
      ...nameskyRecoveryPhrases,
      [id]: passPhrase,
    });
    setIsClaiming(true);
    const callbackURL = `${window.location.origin}/account?redirect_source_action=redeem&redirect_source_id=${id}`;
    if (registerResult) {
      await namesky.coreContract
        .nftUnregister({
          registrantId: id,
          publicKey,
          callbackUrl: callbackURL,
        })
        .then((res: any) => {
          if (WebWalletIds.includes(selector.store.getState().selectedWalletId))
            return;
          location.assign(callbackURL);
        })
        .catch((e) => {
          location.reload();
        });
    } else {
      await namesky.coreContract
        .nftRedeem({
          tokenId: id,
          publicKey,
        })
        .then((res: any) => {
          if (WebWalletIds.includes(selector.store.getState().selectedWalletId))
            return;
          location.assign(callbackURL);
        })
        .catch((e) => {
          location.reload();
        });
    }
  };

  const validatePhraseWord = () => {
    if (!phraseWord || passPhrase.split(" ")[wordIndex] !== phraseWord) {
      setPhraseWordError("Incorrect word");
      return false;
    }
  };

  const title = (() => {
    if (step === 0)
      return (
        <>
          Take NEAR account back: <i className="underline">{id}</i>
        </>
      );
    if (step === 1) return `Confirm a word from the secure passphrase`;
  })();

  return (
    <div className="max-w-[600px] m-auto px-4 mt-[80px]">
      <PageTitle>{title}</PageTitle>
      <div className="mt-8" />
      {step === 0 && (
        <>
          <p className="mt-6 text-gray-500">
            Please make sure to write down this recovery phrase and keep it in a
            secure location, as it is the only way to access your account.
          </p>
          <div className="border-[1px] border-gray-100 p-4 mt-4 shadow-lg m-auto">
            <div className="grid grid-cols-3 gap-x-4 gap-y-4">
              {passPhrase.split(" ").map((w, idx) => {
                return (
                  <div key={idx} className="p-2 bg-blue-50">
                    <span className="text-blue-400 mr-[10px]">{idx + 1}</span>
                    <span className="text-blue-500">{w}</span>
                  </div>
                );
              })}
            </div>

            <div className="relative flex mt-8">
              <CopyToClipboard text={passPhrase} onCopy={() => setCopied(true)}>
                <div className="relative">
                  <MuteButton icon={<Copy size={20} color="#9CA3AF" />}>
                    Copy
                  </MuteButton>
                  <div
                    className={`absolute text-green-500 transition-all ${
                      copied ? "opacity-100" : "opacity-0"
                    }`}
                    style={{ top: "-25px", right: "30%" }}
                  >
                    Copied!
                  </div>
                </div>
              </CopyToClipboard>
              <div className="ml-4"></div>
              <MuteButton
                icon={<RefreshCw size={20} color="#9CA3AF" />}
                className="grow"
                onClick={generateAndSetPhrase}
              >
                Re-generate
              </MuteButton>
            </div>
          </div>

          <div className="mt-8" />
          <PrimaryButton
            loading={isClaiming}
            isFull
            size="large"
            onClick={() => {
              setStep(1);
            }}
          >
            Continue
          </PrimaryButton>
        </>
      )}
      {step === 1 && (
        <div>
          <p className="mt-2 text-gray-700">
            Please enter the word at position <b>{wordIndex + 1}</b> in your
            recovery phrase:
          </p>
          <div className="mt-4" />
          <input
            placeholder={`Word #${wordIndex + 1}`}
            type="text"
            className="w-[200px] h-[44px] rounded-[8px] border-[1px] border-gray-300 px-4"
            onChange={(e) => {
              let v = e.target.value.replace(/[^a-zA-Z]/g, "");
              v = v.toLowerCase();
              if (v !== phraseWord) {
                setPhraseWord(v);
                setPhraseWordError("");
              }
            }}
            onBlur={validatePhraseWord}
          ></input>
          <span className="block text-red-500">{phraseWordError}</span>
          <div className="mt-8" />
          <PrimaryButton
            loading={isClaiming}
            isFull
            size="large"
            onClick={handleClaim}
          >
            Verify and Claim
          </PrimaryButton>
          <TextButton
            className="w-full mt-4 font-bold text-gray-700"
            onClick={() => {
              setStep(0);
              setPhraseWord("");
              setPhraseWordError("");
            }}
          >
            Back
          </TextButton>
        </div>
      )}
    </div>
  );
};
export default ClaimPage;
