import React from 'react'
import { type Notification } from '../../../.graphclient'
import { Amount } from 'namesky-sdk'
import { Divider, Skeleton } from 'antd'
import './NotificationList.css'
import dayjs from 'dayjs'
import { config } from '~domain/near/global'
import { ExternalLink } from 'react-feather'
import { AccountLink, NftLink } from '~components/text/link-text'

interface NotificationListProps {
  notifications: Notification[]
  // todo how to compare with BigInt
  last_read_time: number
  loadMoreData: () => void
  hasMore: boolean
  isLoading: boolean
}

const NotificationList: React.FC<NotificationListProps> = ({
  notifications,
  last_read_time,
  loadMoreData,
  hasMore,
  isLoading
}) => {
  const footer = () => {
    if (isLoading) return <Skeleton avatar paragraph={{ rows: 1 }} active />
    if (hasMore) {
      return (
        <div className="load-more-text" onClick={loadMoreData}>
          Click to Load More
        </div>
      )
    }
    return <Divider plain>That is all, no more notifications!</Divider>
  }

  return (
    <div>
      {notifications.map((e) => renderNotificationItem(e, last_read_time))}
      <div className="mt-4">{footer()}</div>
    </div>
  )
}

export default NotificationList

const renderNotificationItem = (
  notification: Notification,
  last_read_time: number
) => {
  const transactionLink = (receipt_id: string): string =>
    `${config.explorerUrl}/?query=${receipt_id}`

  return (
    <div key={notification.id} className="notification-item">
      {notification.timestamp > last_read_time && (
        <span className={'notification-item-unread-dot'} />
      )}
      <div className="notification-item-header">
        <div className="notification-item-header-title">
          {renderTitleByNotification(notification)}
        </div>
        <div className="notification-item-header-other">
          <div className="notification-item-header-other-time">
            {dayjs(Math.round(notification.timestamp) / 1000000).fromNow()}
          </div>
          <div className="notification-item-header-other-explorer-link">
            <a
              className="mr-1"
              target="_blank"
              href={transactionLink(notification.receipt_id)} rel="noreferrer"
            >
              view in explorer
            </a>
            <ExternalLink color="#007bff" size={12} />
          </div>
        </div>
      </div>
      <div className="notification-item-body">
        <div className="notification-item-body-content">
          {renderContentByNotification(notification)}
        </div>
      </div>
    </div>
  )
}

const renderTitleByNotification = (notification: Notification) => {
  switch (notification.notification_type) {
    case 'nft_liked_notification':
      return <h1>Someone liked your NFT!</h1>
    case 'nft_watched_notification':
      return <h1>Someone watched your NFT!</h1>
    case 'offer_update_notification':
      return <h1>Offer Updated</h1>
    case 'receive_offer_notification':
      return <h1>Offer Received</h1>
    case 'offer_accepted_notification':
      return <h1>Offer Accepted</h1>
    case 'listing_sold_notification':
      return <h1>Listing Sold</h1>
    case 'receive_nft_notification':
      return <h1>NFT Received</h1>
    default:
      throw (
        'illegal notification.notification_type' +
        notification.notification_type
      )
  }
}

const renderContentByNotification = (notification: Notification) => {
  switch (notification.notification_type) {
    case 'nft_liked_notification':
      return (
        <p>
          Your NFT{' '}
          <NftLink
            nft_token_id={notification.nft_liked_notification.nft_token_id}
          />{' '}
          has been liked by{' '}
          <AccountLink
            account_id={notification.nft_liked_notification.account_id}
          />
          .
        </p>
      )
    case 'nft_watched_notification':
      return (
        <p>
          Your NFT{' '}
          <NftLink
            nft_token_id={notification.nft_watched_notification.nft_token_id}
          />{' '}
          has been watched by {notification.nft_watched_notification.account_id}
          .
        </p>
      )
    case 'offer_update_notification':
      return (
        <p>
          Your offer of{' '}
          <NftLink
            nft_token_id={
              notification.offer_update_notification.update_offering_action
                .nft_token_id
            }
          />{' '}
          from{' '}
          <AccountLink
            account_id={
              notification.offer_update_notification.update_offering_action
                .offer_creator
            }
          />{' '}
          has been updated from{' '}
          {Amount.format(
            notification.offer_update_notification.update_offering_action
              .old_price,
              'NEAR'
          )}
          Ⓝ to{' '}
          {Amount.format(
            notification.offer_update_notification.update_offering_action
              .new_price,'NEAR'
          )}
          Ⓝ.
        </p>
      )
    case 'receive_offer_notification':
      console.log('receive_offer_notification, notification: ', notification)
      return (
        <p>
          You have received an offer for your NFT{' '}
          <NftLink
            nft_token_id={
              notification.receive_offer_notification.create_offering_action
                .nft_token_id
            }
          />{' '}
          at price{' '}
          {Amount.format(
            notification.receive_offer_notification.create_offering_action.price,'NEAR'
          )}
          Ⓝ from{' '}
          <AccountLink
            account_id={
              notification.receive_offer_notification.create_offering_action
                .offer_creator
            }
          />
          .
        </p>
      )
    case 'offer_accepted_notification':
      return (
        <p>
          Your offer of{' '}
          <NftLink
            nft_token_id={
              notification.offer_accepted_notification.accept_offering_action
                .nft_token_id
            }
          />{' '}
          has been accepted at{' '}
          {Amount.format(
            notification.offer_accepted_notification.accept_offering_action
              .prepaid_balance,'NEAR'
          )}
          Ⓝ by{' '}
          <AccountLink
            account_id={
              notification.offer_accepted_notification.accept_offering_action
                .seller_id
            }
          />
          .
        </p>
      )
    case 'listing_sold_notification':
      return (
        <p>
          Your listing{' '}
          <NftLink
            nft_token_id={
              notification.listing_sold_notification.buy_listing_action
                .nft_token_id
            }
          />{' '}
          has been sold for{' '}
          {Amount.format(
            notification.listing_sold_notification.buy_listing_action
              .prepaid_balance,'NEAR'
          )}
          Ⓝ.
        </p>
      )
    case 'receive_nft_notification':
      return (
        <p>
          You have received a new NFT{' '}
          <NftLink
            nft_token_id={
              notification.receive_nft_notification.nft_transfer_action
                .token_id
            }
          />{' '}
          from{' '}
          <AccountLink
            account_id={
              notification.receive_nft_notification.nft_transfer_action
                .old_owner_id
            }
          />{' '}
          .
        </p>
      )
    default:
      throw (
        'illegal notification.notification_type' +
        notification.notification_type
      )
  }
}
