import React from "react";
import Modal, { ModalProps } from "./modal";
import { PrimaryButton } from "~components/button/Button";
import { useWalletSelector } from "~context/namesky";

interface RequestSignInModalProps extends ModalProps {
  text?: string;
}

export default function RequestSignInModal(props: RequestSignInModalProps) {
  const { modal } = useWalletSelector();
  return (
    <Modal title={props.title || ""} {...props}>
      <span className="inline-block mb-4 text-gray-900">{props.text}</span>
      <PrimaryButton isFull onClick={() => modal.show()}>
        Connect NEAR Wallet
      </PrimaryButton>
    </Modal>
  );
}
