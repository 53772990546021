import React from "react";
import Spacer from "~components/spacer";
import step1Icon from "~assets/hiw-1.png";
import step2Icon from "~assets/hiw-2.png";
import step3Icon from "~assets/hiw-3.png";

const Step = ({
  number,
  title,
  children,
  icon,
}: {
  number: number;
  title: string;
  children: React.ReactNode;
  icon: React.ReactNode;
}) => {
  return (
    <div className="relative bg-white shadow-lg p-6 border border-gray-300 rounded-[16px]">
      <div className="flex items-center justify-center font-bold text-lg text-white absolute -top-[20px] left-[calc(50%-20px)] w-[40px] h-[40px] rounded-full bg-blue-500">
        {number}
      </div>
      <div className="flex justify-center">{icon}</div>
      <Spacer h="24px" />
      <h3 className="text-xl font-bold text-gray-900">{title}</h3>
      <Spacer h="16px" />
      {children}
    </div>
  );
};

const HowItWorks = () => {
  return (
    <div>
      <h2 className="text-gray-900 font-bold text-3xl leading-[111%] text-center">
        How does NameSky work?
      </h2>
      <Spacer h="40px" />
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 gap-y-10">
        <Step
          number={1}
          title="Turn your NEAR account into an NFT"
          icon={<img width={170} src={step1Icon} alt="Step 1 - mint" />}
        >
          <div className="flex flex-col text-sm text-gray-500 gap-y-4">
            <p>You'll attach a NEAR account to a NameSky NFT upon minting.</p>
            <p>
              Think of the NFT like a envelope that has your NEAR account in it.
            </p>
            <p>
              All access keys will be deleted, so that only the owner of the NFT
              can access the NEAR account.
            </p>
          </div>
        </Step>
        <Step
          number={2}
          title="Transfer, Sell or Buy NFTs"
          icon={<img width={170} src={step2Icon} alt="Step 2 - trade" />}
        >
          <div className="flex flex-col text-sm text-gray-500 gap-y-4">
            <p>
              In our marketplace as well as Paras & Mintbase you’ll be able to
              sell, transfer or buy other NFTs.
            </p>
            <p>
              You’re not just buying a NameSky NFT, you’re buying the NEAR
              account and funds that are in that account.
            </p>
          </div>
        </Step>
        <Step
          number={3}
          title="Turn your NameSky NFT back into a NEAR account"
          icon={
            <img
              width={170}
              src={step3Icon}
              alt="Step 3 - redeem back account"
            />
          }
        >
          <div className="flex flex-col text-sm text-gray-500 gap-y-4">
            <p>
              If you have bought a NameSky NFT or just with your own minted NFT,
              head over to the profile page and take back the account.
            </p>
            <p>
              It will generate a new recovery phrase to grant you access to the
              NEAR account while the NFT will be burned.
            </p>
          </div>
        </Step>
      </div>
    </div>
  );
};

export default HowItWorks;
