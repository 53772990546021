import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import {
  getBuiltGraphSDK,
  GetNotificationsQuery,
  Notification,
} from "../../../.graphclient";

const { GetNotifications } = getBuiltGraphSDK();

export const useNotificationsQuery = ({
  user_id,
  limit = 50,
}: {
  user_id: string | undefined;
  limit: number | undefined;
}) => {
  return useInfiniteQuery<GetNotificationsQuery, Error>({
    enabled: !!user_id,
    queryKey: ["notifications", user_id],
    queryFn: async ({ pageParam }) => {
      pageParam = pageParam
        ? pageParam
        : { last_read_timestamp: (new Date().getTime() * 1000000).toString() };
      return await GetNotifications({
        receiver: user_id,
        user_id: user_id,
        limit: limit,
        ...pageParam,
      });
    },

    getNextPageParam: (lastPage: GetNotificationsQuery) => {
      return lastPage.notifications?.length < limit
        ? undefined
        : {
            last_read_timestamp:
              lastPage.notifications?.[lastPage.notifications.length - 1]
                ?.timestamp,
          };
    },
  });
};
