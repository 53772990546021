import React from "react";

interface CollapseProps {
  title: string;
  children?: React.ReactNode;
  maxHeight?: string;
}

const Collapse = (props: CollapseProps) => {
  const { children, title } = props;
  return (
    <div className="overflow-hidden border border-gray-200 rounded-lg">
      <div className="bg-white flex h-[60px] items-center px-4 border-b border-gray-200">
        <h3 className="font-semibold text-gray-900">{title}</h3>
      </div>
      <div
        style={{
          maxHeight: props.maxHeight,
          overflowY: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapse;
