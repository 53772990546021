import { Link } from "react-router-dom";
import React from "react";

export const AccountLink = ({
  account_id,
  className,
}: {
  account_id: string;
  className?: string;
}) => {
  return (
    <Link
      to={`/account/${account_id}`}
      className={`text-blue-500 ${className}`}
    >
      {account_id}
    </Link>
  );
};

export const NftLink = ({
  nft_token_id,
  className,
}: {
  nft_token_id: string;
  className?: string;
}) => {
  return (
    <Link to={`/${nft_token_id}`} className={`text-blue-500 ${className}`}>
      {nft_token_id}
    </Link>
  );
};
