import { getBuiltGraphSDK } from "~/../.graphclient";
import { useQuery } from "@tanstack/react-query";
import useTailwindBreakpoint from "~hooks/useTailwindBreakpoint";
import { ListItem } from "~components/listing/listing-list";
import React from "react";
import Skeleton from "react-loading-skeleton";

const { GetSales } = getBuiltGraphSDK();

export const RecentlySoldList = () => {
  const brkPnt = useTailwindBreakpoint();

  const { data, isLoading } = useQuery(["recently-sales"], async () => {
    return await GetSales({ order_by: "tx_time" });
  });

  const featuredTraddings = (() => {
    if (brkPnt === "sm") return data?.tradings?.slice(0, 4);
    if (brkPnt === "md") return data?.tradings?.slice(0, 8);
    if (brkPnt === "lg") return data?.tradings?.slice(0, 8);
    return data?.tradings?.slice(0, 9);
  })();

  return (
    <div>
      {isLoading ? (
        <Skeleton height={110} style={{ borderRadius: "16px", opacity: 0.5 }} />
      ) : (
        <div className="grid grid-cols-1 gap-x-2 gap-y-2 md:grid-cols-2 lg:grid-cols-3">
          {featuredTraddings?.map((sale) => {
            return (
              <ListItem
                key={sale.id}
                token_id={sale.nft_token_id}
                price={sale.payment_balance}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
