import React from "react";
import { PrimaryButton, TextButton } from "~components/button/Button";
import FAQSection from "./faq";
import ListingList from "~components/listing/listing-list";
import Spacer from "~components/spacer";
import HeroListing from "./hero-listing";
import HowItWorks from "./how-it-works";
import { MarketSummary } from "./market-summary";
import Entries from "./entries";
import TopOfferList from "./top-offer-list";
import darkblueBg from "~assets/darkblue.png";
import search from "~assets/search.svg";
import TopSaleList from "./top-sale-list";
import shopIcon from "~assets/shop.svg";
import { Gift, Zap } from "react-feather";
import { RecentlySoldList } from "./recently-saled-list";
import { TopMinterList } from "./top-minter-list";
import rocketWhite from "~assets/rocket-white.svg";

const HomePage = () => {
  return (
    <>
      <main className="max-w-[600px] mx-auto px-4 lg:max-w-[1024px] items-center  mt-12">
        <div>
          <div className="flex flex-col items-center lg:flex-row gap-y-6">
            <div className="flex flex-col items-center lg:items-start">
              <h1 className="text-gray-900 lg:max-w-[552px] font-bold leading-[111%] text-[32px] lg:text-[38px] text-center lg:text-left">
                Buy & Sell NEAR accounts as NFTs.
              </h1>
              <p className="mt-4 text-base text-center text-gray-500 lg:text-left lg:max-w-[520px]">
                NameSky is built with a mission to make trading NEAR account
                easy and secure.{" "}
                <a className="text-blue-500 cursor-pointer group" href="#how">
                  See how it works.
                </a>
              </p>
              <div className="flex flex-col items-center justify-between mt-6 md:flex-row gap-x-4 gap-y-4">
                <a href="/mint">
                  <div className="flex flex-col items-center gap-y-2">
                    <PrimaryButton
                      size="large"
                      className="w-[150px]"
                      prefixIcon={
                        <div className="flex gap-x-1 items-center">
                          <Zap size={22} />
                          {/*<img src={rocketWhite} width={22} />*/}
                        </div>
                      }
                    >
                      Mint
                    </PrimaryButton>
                    <p className="text-[12px] h-[0px] text-gray-500">
                      Get 40 SKY Reward!
                    </p>
                  </div>
                </a>
                <a href="/marketplace">
                  <TextButton className="font-semibold text-blue-500 mt-1">
                    <div className="gap-x-1 flex items-center text-[#2563EB]">
                      <img src={shopIcon} />
                      Explore marketplace
                    </div>
                  </TextButton>
                </a>
              </div>
            </div>

            <div className="flex-grow" />

            <HeroListing />
          </div>
        </div>
        {/* trading info */}
        <MarketSummary />
        {/* cool names */}

        <div className="mt-12">
          <h2 className="font-bold text-[32px] text-center">
            Recently Sold Accounts
          </h2>
          <Spacer h="16px" />
          <div className="z-[1]">
            <RecentlySoldList />
          </div>
        </div>

        <div className="mt-12">
          <h2 className="font-bold text-[32px] text-center">
            Recently Minted Accounts
          </h2>
          <Spacer h="16px" />
          <div className="z-[1]">
            <ListingList />
          </div>
        </div>

        <div className="mt-12">
          <h2 className="font-bold text-[32px] text-center">Top Offers</h2>
          <Spacer h="16px" />
          <div className="z-[1]">
            <TopOfferList />
          </div>
        </div>

        <div className="mt-12">
          <h2 className="font-bold text-[32px] text-center">Top Sales</h2>
          <Spacer h="16px" />
          <div className="z-[1]">
            <TopSaleList />
          </div>
        </div>

        <div className="mt-12">
          <h2 className="font-bold text-[32px] text-center">Top Minters</h2>
          <Spacer h="16px" />
          <div className="z-[1]">
            <TopMinterList />
          </div>
        </div>

        <a
          href="/marketplace"
          className="group flex p-6 mt-12 rounded-[16px] items-center bg-cover bg-center justify-between"
          style={{
            backgroundImage: `url(${darkblueBg})`,
          }}
        >
          <div className="text-xl font-bold text-white flex items-center gap-x-5">
            <img src={search} className="p-3 bg-white rounded-full" />
            Find your favorite NEAR Account Name
          </div>
          <PrimaryButton
            size="large"
            color="white"
            className="!text-blue-600 shrink-0 group-hover:scale-[110%]"
          >
            See All
          </PrimaryButton>
        </a>

        {/* how it works */}
        <div className="w-full pt-12" id="how">
          <HowItWorks />
        </div>

        {/* faq section */}
        <div id="faq" className="w-full pt-12">
          <FAQSection />
        </div>

        <div className="w-full pt-12">
          <Entries />
        </div>
      </main>
    </>
  );
};

export default HomePage;
