import React from "react";
import { useMarketSummaryQuery } from "~domain/nnn/queries";
import { transferToNumberWithCommas } from "~utils/util";

export const MarketSummary = ({}: {}) => {
  const { data: summary } = useMarketSummaryQuery();

  return (
    <div className="w-full px-6 mt-14 grid grid-cols-3 place-items-between">
      <div className="flex flex-col items-center">
        <span className="text-gray-900 font-bold text-[24px]">
          {summary?.nft_minted_sum ?? "-"}
        </span>
        <span className="text-gray-500 text-[16px] mt-2 block">Minted</span>
      </div>
      <div className="flex flex-col items-center">
        <span className="text-gray-900 font-bold text-[24px]">
          {summary?.listing_sum ?? "-"}
        </span>
        <span className="text-gray-500 text-[16px] mt-2 block">Listed</span>
      </div>
      <div className="flex flex-col items-center justify-center">
        <span className="text-gray-900 font-bold text-[24px] items-center flex gap-1">
          {!summary?.volume
            ? "-"
            : transferToNumberWithCommas(summary?.volume, 0)}
          <span className="text-[24px]">Ⓝ</span>
        </span>
        <span className="text-gray-500 text-[16px] mt-2 block">Volume</span>
      </div>
    </div>
  );
};
