import { useBoolean } from "ahooks";
import React from "react";
import { Info } from "react-feather";
import Modal from "~components/modal/modal";
import { config } from "~domain/near/global";
import CostsBreakdown from "./costs-breakdown";

const CostDisclosureText = () => {
  const [isModalOpen, { toggle }] = useBoolean(false);

  const serviceFee = config.MINT_SERVICE_FEE;
  const storageFee = config.MINT_STORAGE_FEE;
  const total = serviceFee + storageFee;

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggle}
        className="overflow-hidden max-w-[400px] m-auto"
        title={<span>{total} NEAR Breakdown</span>}
      >
        <div className="mt-4">
          <CostsBreakdown />
        </div>
      </Modal>
      <span className="cursor-pointer" onClick={toggle}>
        <span className="inline-flex items-center font-semibold">
          {total} NEAR{" "}
          <Info
            size={16}
            className="ml-[2px] inline-block text-gray-900 cursor-pointer"
          />
        </span>
      </span>
    </>
  );
};

export default CostDisclosureText;
