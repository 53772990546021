import React from "react";
import { Filter, FilterItem, FiltersState } from "./filters";
import { Tag } from "antd";
import { Delete, X } from "react-feather";

export default function FilterTags({
  filters,
  updateFilter,
  filtersState,
  onFilterChange,
}: {
  filters: Filter[];
  updateFilter: (filter_item: FilterItem) => void;
  filtersState: FiltersState;
  onFilterChange: (updates: any, reset?: boolean) => void;
}) {
  const selected_filters_item = filters.flatMap((filter) =>
    filter.filter_items
      .filter((filter_item) => filter_item.is_selected)
      .map((e) => {
        e.tagText = filter.tagText(e);
        return e;
      })
  );

  // DemoChen: filter tags managed by filtersState
  const activeFilterItems = filters
    .reduce((acc, filter) => {
      const filterItems = filter.filter_items;
      const activeItems = filterItems
        .filter((filterItem) => {
          if (!filterItem.checkIsActive && !filter.checkIsActive) {
            return false;
          }
          return (
            filterItem.checkIsActive?.(filtersState) ||
            filter.checkIsActive?.(filtersState, filterItem)
          );
        })
        .map((filterItem) => {
          return {
            ...filterItem,
            tagText: filter.tagText(filterItem),
          };
        });
      return [...acc, ...activeItems];
    }, [])
    .flat();

  const hasActiveFilters =
    selected_filters_item?.length > 0 || activeFilterItems?.length > 0;

  return (
    <div className="flex flex-wrap gap-y-2">
      {...selected_filters_item.map((filter_item, i) => {
        return (
          <Tag
            className="inline-flex items-center text-[16px] py-2 px-2 border-0 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-[8px] font-medium"
            key={i}
            id={i.toString()}
            closeIcon={<X size={16} style={{ display: "inline" }} />}
            closable
            onClose={(e) => {
              e.preventDefault();
              filter_item.is_selected = false;
              updateFilter(filter_item);
            }}
          >
            <span className="text-[14px]">{filter_item.tagText}</span>
          </Tag>
        );
      })}
      {...activeFilterItems.map((filter_item, i) => {
        return (
          <Tag
            className="inline-flex items-center text-[16px] py-2 px-2 border-0 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-[8px] font-medium"
            key={i}
            id={i.toString()}
            closeIcon={<X size={16} style={{ display: "inline" }} />}
            closable
            onClose={(e) => {
              e.preventDefault();
              const updates = filter_item.handleQueryParam(filtersState, false);
              onFilterChange(updates);
              // filter_item.is_selected = false
              // updateFilter(filter_item)
            }}
          >
            <span className="text-[14px]">{filter_item.tagText}</span>
          </Tag>
        );
      })}
      {hasActiveFilters && (
        <Tag
          className="hover:bg-red-300 cursor-pointer inline-flex items-center text-[16px] py-2 px-2 border-0 bg-gray-200  text-gray-700 rounded-[8px] font-medium"
          closeIcon={
            <Delete
              size={16}
              style={{ display: "inline", marginLeft: "4px" }}
            />
          }
          closable
          onClick={() => {
            selected_filters_item.forEach((item) => (item.is_selected = false));
            onFilterChange({}, true);
          }}
          onClose={(e) => {
            e.preventDefault();

            selected_filters_item.forEach((item) => (item.is_selected = false));
            onFilterChange({}, true);
          }}
        >
          <span className="text-[14px]">Delete all filters</span>
        </Tag>
      )}
    </div>
  );
}
