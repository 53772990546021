import { useQuery } from "@tanstack/react-query";
import { getBuiltGraphSDK } from "~/../.graphclient";
import React from "react";

import Skeleton from "react-loading-skeleton";
import { ListCountItem, ListItem } from "~components/listing/listing-list";
import useTailwindBreakpoint from "~hooks/useTailwindBreakpoint";

const { GetUsers } = getBuiltGraphSDK();

export const TopMinterList = () => {
  const brkPnt = useTailwindBreakpoint();
  const { data, isLoading } = useQuery(
    ["top-minter-by-mint_times"],
    async () => {
      return await GetUsers({
        first: 9,
        orderBy: "mint_times",
        search: "",
        orderDirection: "desc",
      });
    }
  );

  const featuredUsers = (() => {
    if (brkPnt === "sm") return data?.users?.slice(0, 4);
    if (brkPnt === "md") return data?.users?.slice(0, 8);
    if (brkPnt === "lg") return data?.users?.slice(0, 8);
    return data?.users?.slice(0, 9);
  })();

  return (
    <div>
      {isLoading ? (
        <Skeleton height={110} style={{ borderRadius: "16px", opacity: 0.5 }} />
      ) : (
        <div className="grid grid-cols-1 gap-x-2 gap-y-2 md:grid-cols-2 lg:grid-cols-3">
          {featuredUsers?.map((user) => {
            return (
              <ListCountItem
                key={user.id}
                user_id={user.account_id}
                count={user.mint_times}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TopMinterList;
