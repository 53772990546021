import React from "react";
import { useRegistryListQuery } from "~domain/nnn/nft_contract/queries";

import { PrimaryButton, SecondaryButton } from "~components/button/Button";
import { Link } from "react-router-dom";
import { config } from "~domain/near/global";
import { generateNFTMediaSvg } from "~utils/util";

type NFTListProps = {
  unfinishedIds: string[];
};

const UnfinishedList = (props: NFTListProps) => {
  const { unfinishedIds } = props;
  return (
    <div className="grid grid-cols-1 gap-y-2">
      {unfinishedIds.map((id) => {
        return (
          <div
            key={id}
            className="bg-white rounded-[4px] border-[1px] border-gray-50 py-3 px-4 flex justify-between items-center"
          >
            <div className="flex items-center">
              <img
                src={generateNFTMediaSvg(id)}
                className="mr-4 border border-gray-100 rounded-lg w-14"
              />
              <div className="flex flex-col font-bold text-gray-800">{id}</div>
            </div>
            <div className="flex flex-col gap-y-2">
              <Link
                to={`/mint?is_mint_import=true&account_id=${id}`}
                className="w-full"
              >
                <PrimaryButton className="w-full">Continue Mint</PrimaryButton>
              </Link>
              <Link to={`/redeem/${id}`} className="w-full">
                <SecondaryButton>Take back NEAR account</SecondaryButton>
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default function UnfinishedNFT({ account_id }: { account_id: string }) {
  const { data: unfinishedNfts = [] } = useRegistryListQuery(account_id);
  return (
    unfinishedNfts.length > 0 && (
      <section className="p-4 mt-0 mb-8 -m-4 bg-yellow-300 rounded-lg">
        <div className="flex flex-wrap flex-col">
          <h3 className="font-semibold text-gray-700">
            Mint in progress NFT’s
          </h3>
          <p className="text-sm text-gray-500">
            You have some mint-in-progress NFT's. You can continue minting OR
            take back NEAR accounts.{" "}
            <a
              className="text-sm text-blue-500"
              href={config.TELEGRAM_GROUP_URL}
              target="_blank"
            >
              Contact us for support.
            </a>
          </p>
        </div>
        <div className="mt-4" />
        <UnfinishedList unfinishedIds={unfinishedNfts} />
      </section>
    )
  );
}
