import React from "react";
import { Link } from "react-router-dom";
import darkblueBg from "~assets/darkblue.png";
import yellow from "~assets/yellow.png";
import { PrimaryButton } from "~components/button/Button";
import search from "~assets/search.svg";
import box from "~assets/box.svg";

export default function Entries() {
  return (
    <div className="flex flex-col text-center lg:flex-row gap-x-4 gap-y-4">
      <Link to="/marketplace" className="group">
        <div
          className="justify-center px-6 w-full h-[358px] flex flex-col items-center flex-grow p-2 rounded-[16px]"
          style={{ background: `url(${darkblueBg})` }}
        >
          <div className="flex flex-col items-center gap-y-4">
            <img src={search} className="p-3 bg-white rounded-full" />
            <p className="text-3xl font-bold text-white ">
              Find your favorite NEAR Account Name
            </p>
            <PrimaryButton
              size="large"
              color="white"
              className="!text-blue-600 group-hover:scale-[110%]"
            >
              Explorer marketplace
            </PrimaryButton>
          </div>
        </div>
      </Link>

      <Link to="/mint" className="group">
        <div
          className="justify-center px-6 w-full h-[358px] flex flex-col items-center flex-grow p-2 rounded-[16px]"
          style={{ background: `url(${yellow})` }}
        >
          <div className="flex flex-col items-center gap-y-4">
            <img src={box} className="p-3 bg-white rounded-full" />
            <p className="text-3xl font-bold text-white ">
              Turn your NEAR account into an NFT
            </p>
            <PrimaryButton
              size="large"
              color="white"
              className="!text-blue-600 group-hover:scale-[110%]"
            >
              Mint your NFT
            </PrimaryButton>
          </div>
        </div>
      </Link>
    </div>
  );
}
