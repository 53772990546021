import React from "react";

interface CheckboxProps {
  checked: boolean;
  children: React.ReactNode;
  onChange: (checked: boolean) => void;
}

const Checkbox = ({ children, checked, onChange }: CheckboxProps) => {
  return (
    <label className="text-[15px] flex text-gray-900 cursor-pointer leading-[130%]">
      <input
        checked={checked}
        type="checkbox"
        onChange={() => {
          onChange(!checked);
        }}
        className="w-[24px] h-[24px] rounded-[8px] text-blue-500 bg-white border-gray-300 focus:ring-blue-500"
      />
      <span className="block ml-2">{children}</span>
    </label>
  );
};

export default Checkbox;
