import {
  initNameSky,
  MultiSendWalletSelector,
  NameSky,
  NameSkySigner,
  setupMultiSendWalletSelector,
} from "namesky-sdk";
import type { WalletSelectorModal } from "@near-wallet-selector/modal-ui";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { setupModal } from "@near-wallet-selector/modal-ui";
import { setupMyNearWallet } from "@near-wallet-selector/my-near-wallet";
import { config } from "~domain/near/global";
import { map, distinctUntilChanged } from "rxjs";
import { AccountState } from "@near-wallet-selector/core";
import { setupMeteorWallet } from "@near-wallet-selector/meteor-wallet";
import { setupHereWallet } from "@near-wallet-selector/here-wallet";
import { setupBitteWallet } from "@near-wallet-selector/bitte-wallet";

import { Near } from "near-api-js";

interface WalletSelectorContextValue {
  modal: WalletSelectorModal;
  selector: MultiSendWalletSelector;
  namesky: NameSky;
  near: Near;
}

export const WebWalletIds = ["my-near-wallet", "bitte-wallet"];

const WalletSelectorContext =
  React.createContext<WalletSelectorContextValue | null>(null);

export const WalletSelectorContextProvider = ({
  children,
}: {
  children: any;
}) => {
  const [modal, setModal] = useState<WalletSelectorModal | null>(null);
  const [selector, setSelector] = useState<MultiSendWalletSelector | null>(
    null
  );
  const [namesky, setNameSky] = useState<NameSky | null>(null);
  const [near, setNear] = useState<Near | null>(null);
  const [accounts, setAccounts] = useState<Array<AccountState>>([]);

  const init = useCallback(async () => {
    const _selector = await setupMultiSendWalletSelector({
      network: {
        networkId: config.networkId,
        nodeUrl: config.nodeUrl,
        helperUrl: "",
        explorerUrl: "",
        indexerUrl: "",
      },
      debug: true,
      modules: [
        setupMyNearWallet(),
        setupMeteorWallet(),
        setupHereWallet(),
        setupBitteWallet({
          contractId: config.NAMESKY_USER_SETTING_CONTRACT_ID, // TODO: BitteWallet issue: didn't read contract id from wallet selector modal
        }),
      ],
    });

    const _namesky = await initNameSky({
      signer: NameSkySigner.fromWalletSelector(_selector),
      contracts: {
        coreContractId: config.NAMESKY_CORE_CONTRACT_ID,
        marketplaceContractId: config.NAMESKY_MARKET_CONTRACT_ID,
        userSettingContractId: config.NAMESKY_USER_SETTING_CONTRACT_ID,
      },
    });

    const _near = new Near({
      networkId: config.networkId,
      nodeUrl: config.nodeUrl,
    });

    const _modal = setupModal(_selector, {
      contractId: config.NAMESKY_USER_SETTING_CONTRACT_ID,
    });

    // TODO: Conflict with Bitte Wallet
    // if (
    //   _selector.isSignedIn() &&
    //   !(await _selector.isLoginAccessKeyAvailable())
    // ) {
    //   await (await _selector.wallet()).signOut();
    //   window.location.replace("/");
    // }

    setModal(_modal);
    setSelector(_selector);
    setNameSky(_namesky);
    setNear(_near);
  }, []);

  useEffect(() => {
    init().catch((err) => {
      console.error(err);
    });
  }, [init]);

  useEffect(() => {
    if (!selector) {
      return;
    }

    const subscription = selector.store.observable
      .pipe(
        map((state) => state.accounts),
        distinctUntilChanged()
      )
      .subscribe((nextAccounts) => {
        setAccounts(nextAccounts);
      });

    return () => subscription.unsubscribe();
  }, [namesky, selector, near]);

  return (
    <WalletSelectorContext.Provider
      value={{
        modal,
        selector,
        namesky,
        near,
      }}
    >
      {children}
    </WalletSelectorContext.Provider>
  );
};

export function useWalletSelector() {
  const context = useContext(WalletSelectorContext);

  if (!context) {
    throw new Error(
      "useWalletSelector must be used within a WalletSelectorContextProvider"
    );
  }

  return context;
}
