import React from "react";
import StepTitle from "./step-title";
import VerticalLine from "./vertical-line";

interface StepContentProps {
  activeStep: number;
  children?: React.ReactNode;
  title: string;
  step: number;
  totalStep?: number;
}

const StepContent = ({
  activeStep,
  title,
  children,
  step,
  totalStep,
}: StepContentProps) => {
  return (
    <div>
      <StepTitle stepNum={step} active={step <= activeStep}>
        {title}
      </StepTitle>
      <div className="flex py-2 my-2">
        {step <= activeStep && (
          <VerticalLine
            className={`mr-4 -my-2 ${totalStep === step && "mb-0"} ${
              totalStep === step && "invisible"
            }`}
            active={step < activeStep}
          />
        )}
        <div className="w-[340px]">{children}</div>
      </div>
    </div>
  );
};

export default StepContent;
