import getConfig from "~domain/near/config";

export const config = getConfig();

export const getWalletUrlByWalletId = (wallet_id: string): string => {
  switch (wallet_id) {
    case "near-wallet":
      return config.NEAR_WALLET_URL;
      break;
    case "my-near-wallet":
      return config.MYNEAR_WALLET_URL;
      break;
    default:
      throw "unexpect wallet id";
  }
};
