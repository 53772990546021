import React, { useEffect, useMemo, useRef, useState } from "react";
import { Heart } from "react-feather";
import ReactTooltip from "react-tooltip";
import AccountDetailBlock from "./account-detail-block";
import gradient3Img from "~assets/nft-background/gradient-3.png";
import Loading from "react-loading";
import { useWalletSelector } from "~context/namesky";
import { Token } from "namesky-sdk";
import { Maybe, Nft, User } from "../../../.graphclient";

interface TextFitComponentProps {
  text: string;
}

const TextFitComponent: React.FC<TextFitComponentProps> = ({ text }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);
  const [fontSize, setFontSize] = useState(100);

  useEffect(() => {
    const containerElement = containerRef.current;
    const textElement = textRef.current;

    const resizeTextToFit = () => {
      if (containerElement && textElement) {
        const containerWidth = containerElement.offsetWidth;
        const textWidth = textElement.offsetWidth;

        const targetWidth = containerWidth * 0.9;
        const newFontSize = (targetWidth / textWidth) * 100;
        setFontSize(newFontSize);
      }
    };

    const handleResize = () => {
      resizeTextToFit();
    };

    if (containerElement && textElement) {
      resizeTextToFit();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [text]);

  return (
    <div className="text-fit-container" ref={containerRef}>
      <span
        className="text-fit-content"
        ref={textRef}
        style={{ fontSize: `${fontSize}px` }}
      >
        {text}
      </span>
    </div>
  );
};

export default function NFTCard({
  token_id,
  contractNft,
  marketNft,
  isLoadingDetailPageData,
}: {
  token_id: string;
  contractNft: Token | null;
  marketNft: Maybe<
    Pick<Nft, "id" | "token_id" | "liked_sum"> & {
      like_by_users: Array<{ user: Pick<User, "account_id"> }>;
    }
  >;
  isLoadingDetailPageData: boolean;
}) {
  const [isToggleLike, setIsToggleLike] = React.useState(true);
  // const queryClient = useQueryClient();
  const { namesky, selector, modal } = useWalletSelector();
  const [likedSum, setLikedSum] = useState<number>(
    marketNft && marketNft.liked_sum ? marketNft.liked_sum : 0
  );
  const loginAccountId = selector.getActiveAccount()?.accountId;

  const [isLikedByLoginUser, setIsLikedByLoginUser] = useState<boolean>(
    !!loginAccountId &&
      !!marketNft &&
      marketNft.like_by_users.some(
        (like) => like.user.account_id === loginAccountId
      )
  );
  useEffect(() => {
    if (namesky) {
      if (marketNft && marketNft.like_by_users) {
        setLikedSum(marketNft.liked_sum);
        setIsLikedByLoginUser(
          marketNft.like_by_users.some(
            (like) => like.user.account_id === loginAccountId
          )
        );
      }
      setIsToggleLike(false);
    }
  }, [namesky, marketNft]);

  const ownerId = !contractNft?.owner_id ? token_id : contractNft?.owner_id;

  const isOwner = useMemo(() => {
    return loginAccountId && ownerId === loginAccountId;
  }, [loginAccountId]);

  const handleClickedLike = async () => {
    if (!selector.getActiveAccount()?.accountId) {
      modal.show();
      return;
    }
    if (isLikedByLoginUser) {
      setLikedSum(likedSum - 1);
      setIsLikedByLoginUser(false);
      await namesky.userSettingContract.unlike({ tokenId: token_id });
    } else {
      setLikedSum(likedSum + 1);
      setIsLikedByLoginUser(true);
      await namesky.userSettingContract.like({ tokenId: token_id });
    }
  };

  return (
    <div className="overflow-hidden bg-white rounded-lg shadow-lg border border-gray-200 ">
      {marketNft?.like_by_users && (
        <div
          className={`h-[36px] text-sm gap-x-1 ${
            isLikedByLoginUser ? "text-red-400" : "text-gray-500"
          } flex justify-end p-3 py-2 items-center`}
        >
          {isToggleLike || isLoadingDetailPageData ? (
            <>
              <Loading type="spin" color="#999" height={20} width={20} />
            </>
          ) : (
            <>
              {likedSum}{" "}
              <Heart
                data-tip
                size={16}
                className={`outline-none text-gray-500 cursor-pointer ${
                  isLikedByLoginUser ? "text-red-400" : ""
                }`}
                fill={isLikedByLoginUser ? "#F87171" : "none"}
                data-for="tooltip-heart"
                onClick={handleClickedLike}
              />
              <ReactTooltip
                id="tooltip-heart"
                effect="solid"
                place="top"
                key={`tooltip-heart-${isLikedByLoginUser}`}
              >
                <b>{isLikedByLoginUser ? "Unlike" : "Like"}</b>
              </ReactTooltip>
            </>
          )}
        </div>
      )}
      <div
        className="p-6 py-10 bg-cover bg-center"
        style={{
          backgroundColor: "#8C6B8E",
          backgroundImg: `url(${gradient3Img})`,
        }}
      >
        <h1
          className="text-center font-bold text-gradient1-fg text-white"
          style={{ containerType: "inline-size" }}
        >
          <TextFitComponent text={token_id} />
        </h1>
      </div>
      <div className="-mt-2">
        <AccountDetailBlock
          nft={contractNft}
          token_id={token_id}
          owner_id={ownerId}
          isOwner={isOwner}
        />
      </div>
    </div>
  );
}
