/**
 * v0 by Vercel.
 * @see https://v0.dev/t/QZLYUa0pWkU
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import React from "react";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { AvatarImage, AvatarFallback, Avatar } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import bgImg from "~assets/token-reward-background.svg";
import SiteHeader from "~components/layout/SiteHeader";
import Footer from "~pages/homepage/footer";
import { PrimaryButton } from "~components/button/Button";
import rocketWhite from "~assets/rocket-white.svg";

export default function Component() {
  return (
    <div
      className="bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${bgImg})`,
      }}
    >
      <div className="pt-8 pb-8">
        <SiteHeader />
      </div>
      <section className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-r">
        <div className="container grid gap-10 px-4 md:px-6 lg:grid-cols-2 lg:gap-20">
          <div className="space-y-4">
            <div className="inline-block rounded-lg bg-gray-100/50 px-3 py-1 text-sm dark:bg-gray-800/50">
              $SKY Token Reward Campaign
            </div>
            <h1 className="text-3xl font-bold tracking-tighter text-white sm:text-4xl md:text-5xl xl:text-6xl">
              Earn $SKY Tokens for Minting
            </h1>
            <p className="max-w-[600px] text-gray-200 md:text-xl">
              Every mint will grant you 100 $SKY tokens. Our goal is to
              distribute 10,000,000 $SKY tokens to the first 100,000 mints.
            </p>
            <div className="flex flex-col gap-2 min-[400px]:flex-row">
              <a href="/mint">
                <PrimaryButton
                  size="large"
                  className="w-[150px] bg-white text-[#6366F1] hover:from-[#6366F1]/90 hover:to-[#EC4899]/90"
                >
                  Mint
                </PrimaryButton>
              </a>
              <a
                className="inline-flex items-center justify-center rounded-md text-white px-4 py-2 text-sm font-medium"
                href="/#faq"
              >
                Learn More
              </a>
            </div>
          </div>
          <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-800 dark:bg-gray-950 space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-2xl font-bold">2.5%</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Progress (Mints 2772 of 100,000 target)
                </p>
              </div>
              <ActivityIcon className="h-8 w-8 text-gray-500 dark:text-gray-400" />
            </div>
            <Progress className="mt-4 bg-gray-200 dark:bg-gray-800" value={4} />
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-2xl font-bold">12,345</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  $SKY Tokens Earned
                </p>
              </div>
              <CoinsIcon className="h-8 w-8 text-gray-500 dark:text-gray-400" />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-2xl font-bold">2,000</h3>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  Mints
                </p>
              </div>
              <UsersIcon className="h-8 w-8 text-gray-500 dark:text-gray-400" />
            </div>
            <div className="bg-gradient-to-r from-[#6366F1] to-[#EC4899] rounded-lg p-4 text-white">
              <p className="font-medium">
                Explore the potential of the NEAR ecosystem and the emerging
                Near domain name marketplace with us. Your participation
                strengthens our community and positions you for future benefits.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="w-full py-12 md:py-24 lg:py-32">
        <div className="container grid gap-10 px-4 md:px-6">
          <div className="space-y-4 text-center">
            <div className="inline-block rounded-lg bg-gray-100/50 px-3 py-1 text-sm dark:bg-gray-800/50">
              Leaderboard
            </div>
            <h2 className="text-3xl font-bold tracking-tighter text-white sm:text-4xl md:text-5xl">
              Top Contributors
            </h2>
            <p className="max-w-[600px] mx-auto text-gray-200 md:text-xl">
              See who's leading the pack in earning tokens for the campaign.
            </p>
          </div>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-800 dark:bg-gray-950">
              <div className="flex items-center gap-4">
                <Avatar>
                  <AvatarImage
                    alt="@jaredpalmer"
                    src="/placeholder-avatar.jpg"
                  />
                  <AvatarFallback>JP</AvatarFallback>
                </Avatar>
                <div>
                  <p className="text-sm font-medium leading-none">
                    Jared Palmer
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    23,456 tokens earned
                  </p>
                </div>
                <Badge className="ml-auto" variant="secondary">
                  #1
                </Badge>
              </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-800 dark:bg-gray-950">
              <div className="flex items-center gap-4">
                <Avatar>
                  <AvatarImage alt="@shadcn" src="/placeholder-avatar.jpg" />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
                <div>
                  <p className="text-sm font-medium leading-none">Shu Ding</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    19,876 tokens earned
                  </p>
                </div>
                <Badge className="ml-auto" variant="secondary">
                  #2
                </Badge>
              </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-800 dark:bg-gray-950">
              <div className="flex items-center gap-4">
                <Avatar>
                  <AvatarImage alt="@maxleiter" src="/placeholder-avatar.jpg" />
                  <AvatarFallback>ML</AvatarFallback>
                </Avatar>
                <div>
                  <p className="text-sm font-medium leading-none">Max Leiter</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    15,432 tokens earned
                  </p>
                </div>
                <Badge className="ml-auto" variant="secondary">
                  #3
                </Badge>
              </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-800 dark:bg-gray-950">
              <div className="flex items-center gap-4">
                <Avatar>
                  <AvatarImage alt="@shuding_" src="/placeholder-avatar.jpg" />
                  <AvatarFallback>SD</AvatarFallback>
                </Avatar>
                <div>
                  <p className="text-sm font-medium leading-none">Shu Ding</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    12,345 tokens earned
                  </p>
                </div>
                <Badge className="ml-auto" variant="secondary">
                  #4
                </Badge>
              </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-800 dark:bg-gray-950">
              <div className="flex items-center gap-4">
                <Avatar>
                  <AvatarImage
                    alt="@jaredpalmer"
                    src="/placeholder-avatar.jpg"
                  />
                  <AvatarFallback>JP</AvatarFallback>
                </Avatar>
                <div>
                  <p className="text-sm font-medium leading-none">
                    Jared Palmer
                  </p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    10,987 tokens earned
                  </p>
                </div>
                <Badge className="ml-auto" variant="secondary">
                  #5
                </Badge>
              </div>
            </div>
            <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm dark:border-gray-800 dark:bg-gray-950">
              <div className="flex items-center gap-4">
                <Avatar>
                  <AvatarImage alt="@shadcn" src="/placeholder-avatar.jpg" />
                  <AvatarFallback>CN</AvatarFallback>
                </Avatar>
                <div>
                  <p className="text-sm font-medium leading-none">Shu Ding</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    8,765 tokens earned
                  </p>
                </div>
                <Badge className="ml-auto" variant="secondary">
                  #6
                </Badge>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="bg-white  m-auto px-4 mt-12 border-gray-300 border-t-[1px] py-[24px]">
        <Footer />
      </div>
    </div>
  );
}

function ActivityIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M22 12h-2.48a2 2 0 0 0-1.93 1.46l-2.35 8.36a.25.25 0 0 1-.48 0L9.24 2.18a.25.25 0 0 0-.48 0l-2.35 8.36A2 2 0 0 1 4.49 12H2" />
    </svg>
  );
}

function CoinsIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="8" cy="8" r="6" />
      <path d="M18.09 10.37A6 6 0 1 1 10.34 18" />
      <path d="M7 6h1v4" />
      <path d="m16.71 13.88.7.71-2.82 2.82" />
    </svg>
  );
}

function UsersIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
      <circle cx="9" cy="7" r="4" />
      <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
      <path d="M16 3.13a4 4 0 0 1 0 7.75" />
    </svg>
  );
}
