interface Config {
  networkId: string;
  nodeUrl: string;
  walletUrl: string;
  explorerUrl: string;
  NEAR_WALLET_URL: string;
  MYNEAR_WALLET_URL: string;

  NAMESKY_CORE_CONTRACT_ID: string;
  NAMESKY_USER_SETTING_CONTRACT_ID: string;
  NAMESKY_MARKET_CONTRACT_ID: string;
  NAMESKY_NFT_ROYALTY_PERCENT: number;
  NAMESKY_MARKETPLACE_FEE_PERCENT: number;
  NAMESKY_API_URL: string;
  NAMESKY_TWITTER_URL: string;
  NAMESKY_HERO_NFT: string;

  MINTBASE_MARKET_URL: string;
  REF_FINANCE_API_ENDPOINT: string;
  PARAS_MARKET_URL: string;

  WNEAR_TOKEN_ID: string;
  MINT_SERVICE_FEE: number;
  MINT_STORAGE_FEE: number;
  TELEGRAM_GROUP_URL: string;
  CORE_GITHUB_URL: string;
  GITHUB_NAMESKY_TEAM_URL: string;
  GRAPH_API_URL: string;
  WEB4_RPC_URL: string;
  SUFFIX: string;
}

export default function getConfig(env: string = process.env.NEAR_ENV): Config {
  switch (env) {
    case "testnet":
      return {
        networkId: "testnet",
        nodeUrl: "https://rpc.testnet.near.org",
        walletUrl: "https://wallet.testnet.near.org",
        explorerUrl: "https://testnet.nearblocks.io",
        NAMESKY_CORE_CONTRACT_ID: "core.namesky.testnet",
        NAMESKY_USER_SETTING_CONTRACT_ID: "login.namesky.testnet",
        NAMESKY_MARKET_CONTRACT_ID: "marketplace.namesky.testnet",
        NAMESKY_HERO_NFT: "namesky.testnet",
        PARAS_MARKET_URL: "https://marketplace-v2-testnet.paras.id",
        NEAR_WALLET_URL: "https://wallet.testnet.near.org",
        MYNEAR_WALLET_URL: "https://testnet.mynearwallet.com",
        REF_FINANCE_API_ENDPOINT: "https://testnet-indexer.ref-finance.com",
        MINTBASE_MARKET_URL: "https://testnet.mintbase.io",
        WNEAR_TOKEN_ID: "wrap.testnet",
        MINT_SERVICE_FEE: 0.2,
        MINT_STORAGE_FEE: 0.15,
        NAMESKY_NFT_ROYALTY_PERCENT: 2.5,
        NAMESKY_MARKETPLACE_FEE_PERCENT: 0,
        // TODO: update to https://api-testnet.namesky.app later and use http://127.0.0.1:3000 when do local development
        NAMESKY_API_URL: "https://api-testnet.namesky.app",
        TELEGRAM_GROUP_URL: "https://t.me/namesky_support",
        CORE_GITHUB_URL: "https://github.com/nameskyteam/namesky-core",
        GITHUB_NAMESKY_TEAM_URL: "https://github.com/nameskyteam",
        NAMESKY_TWITTER_URL: "https://twitter.com/NameSkyApp",
        GRAPH_API_URL:
          "https://gateway-arbitrum.network.thegraph.com/api/a72f540d5114d097f49f99d3d2cda08c/subgraphs/id/C5wLYxtXga6P4bnjRb92Nw4K7j4yUtmLZPHNGA7xUCtd",
        WEB4_RPC_URL: "https://rpc.web4.testnet.page",
        SUFFIX: ".testnet",
      };
    case "staging":
      return {
        networkId: "mainnet",
        nodeUrl: "https://rpc.mainnet.near.org",
        walletUrl: "https://wallet.near.org",
        explorerUrl: "https://nearblocks.io",
        NEAR_WALLET_URL: "https://wallet.near.org",
        MYNEAR_WALLET_URL: "https://app.mynearwallet.com",
        NAMESKY_CORE_CONTRACT_ID: "core.namesky-staging.near",
        NAMESKY_USER_SETTING_CONTRACT_ID: "login.namesky-staging.near",
        NAMESKY_NFT_ROYALTY_PERCENT: 2.5,
        NAMESKY_MARKETPLACE_FEE_PERCENT: 0,
        NAMESKY_MARKET_CONTRACT_ID: "marketplace.namesky-staging.near",
        NAMESKY_HERO_NFT: "namesky.near",
        PARAS_MARKET_URL: "https://paras.id",
        MINTBASE_MARKET_URL: "https://mintbase.io",
        WNEAR_TOKEN_ID: "wrap.near",
        MINT_SERVICE_FEE: 0.2,
        MINT_STORAGE_FEE: 0.15,
        REF_FINANCE_API_ENDPOINT: "https://mainnet-indexer.ref-finance.com",
        NAMESKY_API_URL: "https://api-staging.namesky.app",
        TELEGRAM_GROUP_URL: "https://t.me/namesky_support",
        CORE_GITHUB_URL: "https://github.com/nameskyteam/namesky-core",
        GITHUB_NAMESKY_TEAM_URL: "https://github.com/nameskyteam",
        NAMESKY_TWITTER_URL: "https://twitter.com/NameSkyApp",
        GRAPH_API_URL:
          "https://api.thegraph.com/subgraphs/name/hsxyl/namesky-testnet",
        WEB4_RPC_URL: "https://rpc.web4.testnet.page",
        SUFFIX: ".near",
      };
    case "production":
      return {
        networkId: "mainnet",
        nodeUrl:
          "https://near-mainnet.g.allthatnode.com/full/json_rpc/469e96f993db497ebd9c0ed82a872fee",
        walletUrl: "https://wallet.near.org",
        explorerUrl: "https://nearblocks.io",
        NEAR_WALLET_URL: "https://wallet.near.org",
        MYNEAR_WALLET_URL: "https://app.mynearwallet.com",
        NAMESKY_CORE_CONTRACT_ID: "core.namesky.near",
        NAMESKY_USER_SETTING_CONTRACT_ID: "login.namesky.near",
        NAMESKY_NFT_ROYALTY_PERCENT: 2.5,
        NAMESKY_MARKETPLACE_FEE_PERCENT: 0,
        NAMESKY_MARKET_CONTRACT_ID: "marketplace.namesky.near",
        NAMESKY_HERO_NFT: "namesky.near",
        PARAS_MARKET_URL: "https://paras.id",
        MINTBASE_MARKET_URL: "https://mintbase.io",
        WNEAR_TOKEN_ID: "wrap.near",
        MINT_SERVICE_FEE: 0.2,
        MINT_STORAGE_FEE: 0.15,
        REF_FINANCE_API_ENDPOINT: "https://mainnet-indexer.ref-finance.com",
        NAMESKY_API_URL: "https://api.namesky.app",
        TELEGRAM_GROUP_URL: "https://t.me/namesky_support",
        CORE_GITHUB_URL: "https://github.com/nameskyteam/namesky-core",
        GITHUB_NAMESKY_TEAM_URL: "https://github.com/nameskyteam",
        NAMESKY_TWITTER_URL: "https://twitter.com/NameSkyApp",
        GRAPH_API_URL:
          "https://gateway-arbitrum.network.thegraph.com/api/a72f540d5114d097f49f99d3d2cda08c/subgraphs/id/2wnxpRwCcyNy6z4hBZGfVsyALFQSfHo84UC8wSSceJcu",
        WEB4_RPC_URL: "https://rpc.web4.near.page",
        SUFFIX: ".near",
      };
    default:
      throw Error("Unconfigured environment");
  }
}
