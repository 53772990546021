import React from 'react'
import { useFormattedPriceText } from '~hooks/useNearFiatUSDText'
import nftBackgroundImg from '~assets/nft-background.png'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import { useQuery } from '@tanstack/react-query'
import { useWalletSelector } from '~context/namesky'
import { type OfferingView } from 'namesky-sdk/dist/core/types/data'
import { generateNFTMediaSvg } from '~utils/util'

const OfferItem = ({ offer }: { offer: OfferingView }) => {
  const { nft_token_id, price } = offer

  const {
    nearPriceFullText: offerNearPrice,
    fiatPriceFullText: offerFiatPrice
  } = useFormattedPriceText(price)

  return (
    <Link to={`/${nft_token_id}`}>
      <div className="hover:shadow-md bg-white rounded-[4px] border-[1px] border-gray-300 py-3 px-4 flex justify-between items-center">
        <div className="flex items-center">
          <img
            src={generateNFTMediaSvg(nft_token_id)}
            className="mr-4 border border-gray-100 rounded-lg w-14"
          />
          <div className="flex flex-col font-bold text-gray-800">
            {nft_token_id}
            <div className="text-sm font-[500] text-gray-500">
              <div className="text-blue-500">
                Your OFFER: {offerNearPrice} ({offerFiatPrice})
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default function Offers ({ account_id }: { account_id: string }) {
  const { namesky } = useWalletSelector()
  const { data: offers = [], isLoading } = useQuery(
    ['namesky_market_offers_made', account_id],
    async () =>
      await namesky.marketplaceContract.getOfferingViewsOf({
        accountId: account_id,
      }),
    { enabled: !!namesky && !!account_id }
  )
  return (
    <div className="flex flex-col gap-y-2">
      {isLoading && (
        <div>
          <Skeleton className="h-[80px]" />
          <Skeleton className="h-[80px]" />
          <Skeleton className="h-[80px]" />
        </div>
      )}
      {offers?.map((offer, idx) => (
        <OfferItem offer={offer} key={idx} />
      ))}
      {!isLoading && offers.length === 0 && (
        <div className="mt-4">
          <p className="text-gray-500">No offers found.</p>
        </div>
      )}
    </div>
  )
}
