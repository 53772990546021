import React, { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { PrimaryButton } from "~components/button/Button";
import Spacer from "~components/spacer";
import { config } from "~domain/near/global";
import TradingBlock from "./trading-block";
import OfferBlock from "./offers-block";
import ResponsiveContainer, {
  ResponsiveContainerLargeSize,
} from "~components/layout/responsive-container";
import Collapse from "~components/collapse";
import {
  useNFTQuery,
  useNFTVerifyQuery,
} from "~domain/nnn/nft_contract/queries";
import InvalidateAccount from "./invalidate-account";
import { MintSuccessModal } from "~pages/claim/mint-success-modal";
import AccountActivities from "~pages/account/account-activities";
import { Helmet } from "react-helmet";
import {
  DetailPageQueryQuery,
  getBuiltGraphSDK,
  Nft,
} from "../../../.graphclient";
import { useQuery } from "@tanstack/react-query";
import NFTCard from "./nft-card";
import { useWalletSelector } from "~context/namesky";
import { notification } from "antd";
import { useNFTOffersQuery } from "~domain/nnn/market/queries";

const { DetailPageQuery } = getBuiltGraphSDK();

const DetailPage = () => {
  const { id }: { id: string } = useParams();
  const history = useHistory();
  const location = useLocation();
  // if location contains uppercase letters, redirect to lowercase
  if (location.pathname !== location.pathname.toLowerCase()) {
    history.replace(location.pathname.toLowerCase());
  }

  const { namesky, selector } = useWalletSelector();
  const { data: nft, isLoading: isLoadingNft } = useNFTQuery(id);

  const {
    isSafe,
    isLoading: isVerifyingAccount,
    error: verifyError,
  } = useNFTVerifyQuery(id);
  const { data: detailPageData, isLoading: isLoadingDetailPageData } =
    useQuery<DetailPageQueryQuery>(
      ["detailPageData", id],
      () => {
        return DetailPageQuery({
          id: `${config.NAMESKY_CORE_CONTRACT_ID}:${id}`,
        });
      },
      {
        enabled: !!id,
      }
    );

  const { data: nameskyOffers = [], isLoading: isLoadingOffers } =
    useNFTOffersQuery(id);

  const hasOffer = useMemo(() => {
    if (namesky && selector && selector.getActiveAccount()?.accountId) {
      return !!nameskyOffers.find(
        (e) => e.buyer_id === selector.getActiveAccount()?.accountId
      );
    }
    return false;
  }, [namesky, nameskyOffers]);

  if (!namesky || !selector) {
    return <div />;
  }

  const loginAccountId = selector.getActiveAccount()?.accountId;
  const isOwner = loginAccountId && nft?.owner_id === loginAccountId;

  if (nft && !isSafe && !isVerifyingAccount && !verifyError) {
    return (
      <ResponsiveContainer>
        <div className="flex items-center justify-center h-full">
          <InvalidateAccount nft_token_id={id} />
        </div>
      </ResponsiveContainer>
    );
  }

  return (
    <div className="mt-12 lg:max-w-[1024px] lg:mx-auto">
      <ResponsiveContainerLargeSize>
        <Helmet>
          <title>{id} - Buy & Sell NEAR accounts as NFT's</title>
        </Helmet>
        <MintSuccessModal />
        {isLoadingNft ? (
          <div className="flex items-center justify-center h-full">
            <div className="text-2xl font-bold text-gray-900">Loading...</div>
          </div>
        ) : (
          <>
            <div className="flex flex-col lg:flex-row lg:gap-x-6">
              <div className="lg:w-[40%] lg:min-w-[400px] shrink-0">
                <NFTCard
                  contractNft={nft}
                  token_id={id}
                  marketNft={detailPageData?.nft}
                  isLoadingDetailPageData={isLoadingDetailPageData}
                />
              </div>
              <div className="flex flex-col flex-grow mt-4 lg:mt-0 gap-y-4 lg:w-[60%]">
                {nft === null && (
                  <div className="rounded-lg py-6 px-4 border border-gray-200 bg-[#fbfdff]">
                    <p className="text-gray-500">
                      Account <b>{id}</b> has not been minted, you can mint it
                      as an NFT if you own this account. Or you can make an
                      offer for it.
                    </p>
                    <Spacer h="16px" />
                    <PrimaryButton
                      isFull
                      size="large"
                      onClick={() => history.push("/mint")}
                    >
                      Mint
                    </PrimaryButton>
                    <div id="js-portal-offer" />
                  </div>
                )}

                <TradingBlock
                  signedAccountId={loginAccountId}
                  isOwner={isOwner}
                  token={nft}
                  token_id={id}
                  isVerifyingAccount={isVerifyingAccount}
                  isVerifyError={verifyError !== undefined}
                  hasOffer={hasOffer}
                />

                <div className="flex flex-col gap-y-4">
                  <OfferBlock
                    token_id={id}
                    token_owner_id={nft?.owner_id}
                    signedAccountId={loginAccountId}
                    nameskyOffers={nameskyOffers}
                    isLoadingOffers={isLoadingOffers}
                  />
                  <Collapse title="What am I buying">
                    <div className="flex flex-col bg-[#FBFDFF] p-4 text-gray-500 text-sm  gap-y-3">
                      <p>
                        When you buy a NameSky NFT you are not purchasing just
                        an NFT. Instead, you are buying access and ownership of
                        the attached NEAR account.
                      </p>
                      <p>
                        You will be able to take back the NEAR account & the
                        associated funds.
                      </p>
                      <p>
                        After that process, the NFT will be burned as it will
                        have served its purpose.
                      </p>
                    </div>
                  </Collapse>
                  <AccountActivities
                    token_id={id}
                    title="Item Activity"
                    hideTokenId
                    hideActionList={[
                      "nft_received_action",
                      "buy_listing_action",
                      "offering_accepted_action",
                      "nft_register_action",
                      "read_notification_action",
                      "like_nft_action",
                      "unlike_nft_action",
                      "watch_nft_action",
                      "unwatch_nft_action",
                    ]}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </ResponsiveContainerLargeSize>
    </div>
  );
};

export default DetailPage;
