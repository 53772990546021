import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Key, MoreHorizontal, Share2 as Share } from "react-feather";
import { config } from "~domain/near/global";
import parasLogo from "~assets/paras-logo.svg";
import { Link } from "react-router-dom";
import { getTokenParasLink } from "~domain/paras/services";

export default function NFTActionsDropdown({ token_id }: { token_id: string }) {
  const parasTokenLink = getTokenParasLink(token_id);
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="w-[48px] h-[48px] flex justify-center items-center text-gray-500 hover:text-gray-900">
          <MoreHorizontal size={28} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 bg-white shadow-lg origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              <Link to={`/redeem/${token_id}`}>
                <div className="hover:bg-gray-100">
                  <div className="shrink-0 flex items-center px-4 py-2 text-sm text-gray-900 h-[48px]">
                    <Key className="mr-2" />
                    <div className="font-bold whitespace-nowrap break-none">
                      Take back NEAR account
                    </div>
                  </div>
                </div>
              </Link>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
