import Axios from "axios";
import { config } from "~domain/near/global";

export const fetchOffers = async (token_id: string) => {
  const res = await Axios.get(
    `https://api-v3-marketplace-develop.paras.id/offers`,
    {
      params: { token_id },
    }
  );
  return res.data;
};

export const getTokenParasLink = (token_id: string) => {
  const { NAMESKY_CORE_CONTRACT_ID, PARAS_MARKET_URL } = config;
  return `${PARAS_MARKET_URL}/token/${NAMESKY_CORE_CONTRACT_ID}::${token_id}`;
};

export const getTokenMintbaseLink = (token_id: string) => {
  const { NAMESKY_CORE_CONTRACT_ID, MINTBASE_MARKET_URL } = config;
  return `${MINTBASE_MARKET_URL}/contract/${NAMESKY_CORE_CONTRACT_ID}/token/${token_id}`;
};
