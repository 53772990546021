import React, { useState } from "react";
import { CheckCircle, Shield } from "react-feather";
import { PrimaryButton } from "~components/button/Button";
import Modal from "~components/modal/modal";
import Skeleton from "react-loading-skeleton";

const SecurityText = (props: { loading: boolean; isVerifyError: boolean }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (props.isVerifyError) {
    return <div className="py-4 gap-y-1"></div>;
    return (
      <div
        className="flex flex-col px-5 py-2 mt-6 -mx-5 text-sm text-red-500 border-t border-gray-200 cursor-pointer bg-red-50 gap-y-1"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <div className="flex flex-wrap gap-y-2 gap-x-2">
          <div className="flex items-center gap-x-1">
            <Shield
              size={16}
              strokeWidth={2}
              className="text-red-500 shrink-0 mb-[1px]"
            />
            <span className="">Failed to NFT Verify</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        hideCloseButton
      >
        <div className="flex flex-col pb-2 mt-2 gap-y-2">
          <div className="text-base text-lg font-bold">
            This account consider safe for trading.
          </div>

          <div className="flex items-start mt-4 gap-x-2">
            <CheckCircle className="shrink-0 w-4 h-4 mt-[2px] text-green-500" />
            <div className="text-sm text-gray-500">
              Account code hash is correct.
            </div>
          </div>
          <div className="flex items-start gap-x-2">
            <CheckCircle className="shrink-0 w-4 h-4 mt-[2px] text-green-500" />
            <div className="text-sm text-gray-500">
              Account controller owner id is correct.
            </div>
          </div>
          <div className="flex items-start gap-x-2">
            <CheckCircle className="shrink-0 w-4 h-4 mt-[2px] text-green-500" />
            <div className="text-sm text-gray-500">Account state is clean.</div>
          </div>
          <div className="flex items-start gap-x-2">
            <CheckCircle className="w-4 h-4 mt-[2px] text-green-500" />
            <div className="text-sm text-gray-500">
              Account access keys are all deleted.
            </div>
          </div>
          <PrimaryButton
            size="large"
            className="mt-6"
            onClick={() => setIsModalOpen(false)}
          >
            Great
          </PrimaryButton>
        </div>
      </Modal>
      <div
        className="flex flex-col px-5 py-2 mt-6 -mx-5 text-sm text-green-500 border-t border-gray-200 cursor-pointer bg-green-50 gap-y-1"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        <div className="flex flex-wrap gap-y-2 gap-x-2">
          <div className="flex items-center gap-x-1">
            {props.loading ? (
              <Skeleton width={70} />
            ) : (
              <>
                <Shield
                  size={16}
                  strokeWidth={2}
                  className="text-green-500 shrink-0 mb-[1px]"
                />
                <span className="">Safe for trading</span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SecurityText;
