import { ActionType } from ".graphclient";
import dayjs from "dayjs";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Collapse from "~components/collapse";
import { useUserActivitiesQuery } from "~domain/nnn/queries";
import { useFormattedPriceText } from "~hooks/useNearFiatUSDText";
import { GetUserActionsQuery } from "~/../.graphclient";
import { TextButton } from "~components/button/Button";
import { ExternalLink } from "react-feather";
import { config } from "~domain/near/global";

const actionTypeNames: {
  [P in ActionType]?: string;
} = {
  // listing
  create_listing_action: "Listing",
  update_listing_action: "Update Listing",
  remove_listing_action: "Cancel Listing",
  buy_listing_action: "Buy",
  sold_listing_action: "Sold",

  // offer
  create_offering_action: "Offering",
  update_offering_action: "Update Offering",
  remove_offering_action: "Cancel Offering",
  accept_offering_action: "Accept Offer",
  offering_accepted_action: "Offer Accepted",

  // nft
  nft_mint_action: "Mint",
  nft_burn_action: "Burn",
  control_account_action: "Take Back Account",
  nft_received_action: "Received",
  nft_transfer_action: "Transfer",
};

const PriceComponent = ({
  action,
}: {
  action: GetUserActionsQuery["userActions"][0];
}) => {
  const {
    create_listing_action,
    update_listing_action,
    buy_listing_action,
    accept_offering_action,
    create_offering_action,
    update_offering_action,
  } = action;
  const price =
    create_listing_action?.price ||
    update_listing_action?.new_price ||
    buy_listing_action?.listing.price ||
    create_offering_action?.price ||
    update_offering_action?.new_price ||
    accept_offering_action?.payment_balance;

  if (!price) return null;
  const { fiatPriceFullText, nearPriceFullText } = useFormattedPriceText(price);
  return (
    <div className="flex flex-col items-end font-normal shrink-0">
      <span className="text-sm text-gray-800">{nearPriceFullText}</span>
      <span className="text-xs text-gray-500">{fiatPriceFullText}</span>
    </div>
  );
};

export const AccountLink = ({
  account_id,
  className,
}: {
  account_id: string;
  className?: string;
}) => {
  return (
    <Link
      to={`/account/${account_id}`}
      className={`text-blue-500 ${className}`}
    >
      {account_id}
    </Link>
  );
};

export const ActionsList = ({
  actions,
  hideTokenId,
}: {
  actions: GetUserActionsQuery["userActions"];
  hideTokenId?: boolean;
}) => {
  return (
    <>
      {actions?.map((item, idx) => {
        const {
          receipt_id,
          action_type,
          token_id,
          accept_offering_action,
          nft_transfer_action,
          buy_listing_action,
        } = item;

        const transactionLink = `${config.explorerUrl}/?query=${receipt_id}`;

        return (
          <div
            key={idx}
            className="group flex flex-row items-end justify-between p-4 border-b border-gray-200 last:border-b-0"
          >
            <div>
              <div className="inline-block text-xs bg-blue-100 py-[5px] font-semibold px-[10px] rounded-full text-blue-500">
                {actionTypeNames[item.action_type] || item.action_type}
              </div>

              {!hideTokenId ? (
                <div className="flex items-start mt-2 text-sm font-semibold text-gray-700">
                  <div className="flex-grow">
                    <Link to={`/${token_id}`} className="group-hover:underline">
                      {token_id}
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="mt-2" />
              )}

              <div className="flex flex-wrap items-center text-sm text-gray-500">
                {(item.action_type === "create_listing_action" ||
                  item.action_type === "update_listing_action" ||
                  item.action_type === "create_offering_action" ||
                  item.action_type === "update_offering_action" ||
                  item.action_type === "remove_offering_action" ||
                  item.action_type === "nft_mint_action" ||
                  item.action_type === "nft_burn_action" ||
                  item.action_type === "control_account_action" ||
                  item.action_type === "accept_offering_action" ||
                  item.action_type === "remove_listing_action") && (
                  <div className="mr-2">
                    <span>By</span> <AccountLink account_id={item.user_id} />
                  </div>
                )}
                {item.action_type === "offering_accepted_action" && (
                  <div className="mr-2">
                    <span>By</span>{" "}
                    <span className="text-blue-500">
                      {" "}
                      <AccountLink
                        account_id={accept_offering_action.seller_id}
                      />
                    </span>
                  </div>
                )}
                {(action_type === "nft_received_action" ||
                  action_type === "sold_listing_action" ||
                  action_type === "nft_transfer_action") && (
                  <div className="mr-2">
                    <span>From</span>{" "}
                    <span className="text-blue-500">
                      {" "}
                      <AccountLink
                        account_id={
                          nft_transfer_action?.old_owner_id ||
                          buy_listing_action?.listing?.seller_id
                        }
                      />{" "}
                    </span>
                    <span>to</span>{" "}
                    <span className="text-blue-500">
                      {" "}
                      <AccountLink
                        account_id={
                          nft_transfer_action?.new_owner_id ||
                          buy_listing_action?.buyer_id
                        }
                      />{" "}
                    </span>
                  </div>
                )}
                {item.action_type === "buy_listing_action" && (
                  <div className="mr-2 break-all">
                    <span>From</span>{" "}
                    <span className="text-blue-500">
                      <AccountLink
                        account_id={buy_listing_action.listing.seller_id}
                      />
                    </span>{" "}
                    <span>To</span>{" "}
                    <span className="text-blue-500">
                      {" "}
                      <AccountLink account_id={buy_listing_action.buyer_id} />
                    </span>
                  </div>
                )}
                <span>
                  {dayjs(Math.round(item.timestamp) / 1000000).fromNow()}
                </span>
                <a className="ml-1" target="_blank" href={transactionLink}>
                  <ExternalLink size={12} />
                </a>
              </div>
            </div>

            <PriceComponent action={item} />
          </div>
        );
      })}
    </>
  );
};

export default function AccountActivities({
  title,
  account_id = "",
  token_id = "",
  hideTokenId,
  hideActionList,
}: {
  title?: string;
  account_id?: string;
  token_id?: string;
  hideTokenId?: boolean;
  hideActionList?: ActionType[];
}) {
  const {
    data: userActions,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useUserActivitiesQuery({
    user_id: account_id,
    token_id,
  });
  const filteredActions = userActions?.filter((item) => {
    return !hideActionList.includes(item.action_type);
  });
  return (
    <Collapse title={title || "Activity"}>
      <div className="bg-[#FBFDFF] max-h-[540px] overflow-y-auto">
        <ActionsList actions={filteredActions} hideTokenId={hideTokenId} />
        {(isFetching || isFetchingNextPage) && (
          <div className="p-4">
            <Skeleton className="h-[80px]" />
            <Skeleton className="h-[80px]" />
          </div>
        )}
        {!isFetching && hasNextPage && (
          <TextButton
            onClick={() => fetchNextPage()}
            className="font-semibold text-blue-500 p-2 text-center w-full"
          >
            Load more
          </TextButton>
        )}
        {!isFetching && !hasNextPage && userActions?.length === 0 && (
          <div className="p-4 text-center text-gray-500">
            No activities found.
          </div>
        )}
      </div>
    </Collapse>
  );
}
