import { useRefNearTokenPriceQuery } from "~domain/ref/queries";
import { numberWithCommas } from "~utils/util";
import { Amount } from "namesky-sdk";
import { U128 } from "~domain/near/types";

export const useFormattedPriceText = (transferAmount?: U128) => {
  let fiatPriceNumberText = ""; // eg: "123.123"
  let fiatPriceFullText = ""; // eg: "$123.123" or "< $0.001"

  const readableAmount = transferAmount
    ? Amount.format(transferAmount, "NEAR")
    : "";
  const readableNumber = Number(readableAmount);
  const { data: nearTokenFiatValueUSD, isLoading } =
    useRefNearTokenPriceQuery();

  if (nearTokenFiatValueUSD) {
    const readableNumberInFiat = readableNumber * nearTokenFiatValueUSD;
    fiatPriceNumberText =
      readableNumberInFiat &&
      numberWithCommas(Number(readableNumberInFiat.toFixed(2)));
    fiatPriceFullText = `$${fiatPriceNumberText}`;
    if (fiatPriceNumberText === "0.00") {
      fiatPriceFullText = "< $0.01";
    }
  }

  return {
    isLoadingFiatPrice: isLoading,
    fiatPriceNumberText,
    fiatPriceFullText,
    nearPriceNumberText: numberWithCommas(readableNumber),
    nearPriceFullText: `${numberWithCommas(readableNumber)} Ⓝ`,
  };
};
