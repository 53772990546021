import React from "react";
import LogoFull from "~/components/icons/logo-full";
import { config } from "~domain/near/global";

export default function Footer() {
  return (
    <div className="flex md:flex-row flex-col gap-y-6 justify-between gap-x-4">
      <div className="flex text-[#666] flex-col justify-between max-w-[350px]">
        <LogoFull className="grayscale-100" size="70" />
        <span className="mt-2">
          The first marketplace to buy and sell NEAR accounts as NFT’s.
        </span>
      </div>
      <div className="flex gap-x-[100px]">
        <div className="gap-x-2">
          <p className="font-semibold text-gray-500">INFO</p>
          <div className="flex flex-col mt-2 gapy-y-2">
            <a href="/#faq" className="text-gray-500">
              FAQ
            </a>
            <a
              href={config.GITHUB_NAMESKY_TEAM_URL}
              className="text-gray-500"
              target="_blank"
            >
              <div className="flex items-center ">GitHub</div>
            </a>
            <a
              href="https://github.com/nameskyteam/assets/blob/113e1251c10692ca50023871449844b4d8cfcffd/NameSky%20Security%20Analysis%20by%20Pessimistic.pdf"
              className="text-gray-500"
              target="_blank"
            >
              <div className="flex items-center ">Audits</div>
            </a>
            <a
              href="https://testnet.namesky.app"
              className="text-gray-500"
              target="_blank"
            >
              <div className="flex items-center ">Testnet App</div>
            </a>
          </div>
        </div>
        <div className="gap-x-2">
          <p className="font-semibold text-gray-500">CONTACT</p>
          <div className="flex flex-col mt-2 gapy-y-2">
            <a
              href={config.TELEGRAM_GROUP_URL}
              className="text-gray-500"
              target="_blank"
            >
              <div className="flex items-center ">Telegram</div>
            </a>
            <a
              href={config.NAMESKY_TWITTER_URL}
              className="text-gray-500"
              target="_blank"
            >
              <div className="flex items-center">Twitter</div>
            </a>
            <a
              href="https://learnnear.club/bugeye-submit/?project=2"
              className="text-gray-500"
              target="_blank"
            >
              <div className="flex items-center">👀 Report a bug</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
