import React from "react";
import logoFull from "~assets/logo - with text.svg";

export default function LogoFull({
  size = "90",
  className,
}: {
  color?: string;
  size?: string;
  className?: string;
}) {
  return <img src={logoFull} width={size} className={className} />;
}
