import React from "react";
import Modal, { ModalProps } from "~components/modal/modal";
import NFTThumbnail from "~components/nft/thumbnail";
import Spacer from "~components/spacer";
import { Line } from "~pages/mint/mint-modal";
import { PrimaryButton } from "~components/button/Button";
import { config } from "~domain/near/global";
import { WebWalletIds, useWalletSelector } from "~context/namesky";
import { ListingView } from "namesky-sdk/dist/core/types/data";

interface Props extends ModalProps {
  listing?: ListingView;
}

const CancelListingModal = (props: Props) => {
  const { listing } = props;
  if (!listing) return null;
  const [loading, setLoading] = React.useState(false);
  const { namesky, selector } = useWalletSelector();

  const handleClickConfirm = async () => {
    setLoading(true);
    await namesky.marketplaceContract
      .removeListing({
        tokenId: listing.nft_token_id,
      })
      .then((res: any) => {
        if (WebWalletIds.includes(selector.store.getState().selectedWalletId))
          return;
        location.reload();
      })
      .catch((e) => {
        location.reload();
      });
  };

  return (
    <Modal {...props} title={<div className="text-center">Cancel listing</div>}>
      <div className="mx-[-16px] px-6">
        <div className="flex items-center mt-[40px]">
          <NFTThumbnail id={listing.nft_token_id} size={60} />
          <div className="flex flex-col items-start ml-[10px] leading-[100%]">
            <span className="text-sm font-semibold text-gray-500">NameSky</span>
            <span className="text-base font-semibold text-gray-900">
              {listing.nft_token_id}
            </span>
          </div>
        </div>
        <Line />
        <Spacer h="32px" />
        <p className="text-gray-900">
          This will cancel your listing. You will also be asked to confirm this
          transaction from your wallet.
        </p>
      </div>
      <Spacer h="32px" />
      <PrimaryButton
        size="large"
        isFull
        onClick={handleClickConfirm}
        loading={loading}
      >
        Continue
      </PrimaryButton>
    </Modal>
  );
};

export default CancelListingModal;
