import { useQuery } from "@tanstack/react-query";
import React from "react";
import Skeleton from "react-loading-skeleton";
import NFTList from "./nft-lists";
import { useWalletSelector } from "~context/namesky";

const NFTs = ({
  account_id,
  isMyAccount,
}: {
  account_id: string;
  isMyAccount: boolean;
}) => {
  const { namesky } = useWalletSelector();
  const { data: nfts = [], isLoading: isLoadingTokens } = useQuery(
    ["my_tokens", account_id],
    () =>
      namesky.coreContract.nftNameSkyTokensForOwner({
        accountId: account_id,
      }),
    { enabled: !!namesky }
  );
  return (
    <section className="mb-8">
      <NFTList nfts={nfts} isMyAccount={isMyAccount} />
      {isLoadingTokens && (
        <div>
          <Skeleton className="h-[80px]" />
          <Skeleton className="h-[80px]" />
          <Skeleton className="h-[80px]" />
        </div>
      )}
      {!isLoadingTokens && nfts.length === 0 && (
        <div className="mt-4">
          <p className="text-gray-500">
            No NFTs found.{" "}
            {isMyAccount && (
              <>
                <a href="/mint" className="text-blue-500">
                  Go mint one
                </a>{" "}
                or{" "}
                <a className="text-blue-500" href="/marketplace">
                  Buy from marketplace
                </a>
              </>
            )}
          </p>
        </div>
      )}
    </section>
  );
};

export default NFTs;
