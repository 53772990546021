import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Filters, {
  BetweenValue,
  ChangeFilterAction,
  Filter,
  FilterAction,
  FilterItem,
  FiltersState,
  SelectFilterAction,
  UnselectFilterAction,
} from "./filters";
import { useComplexNftsQuery, useNftsQuery } from "~domain/nnn/queries";
import NFTList from "./nft-list";
import { Nft_orderBy, OrderDirection } from "~/../.graphclient";
import useUrlQuery from "~hooks/useUrlQuery";
import { useHistory } from "react-router-dom";
import { ResponsiveContainerLargeSize } from "~components/layout/responsive-container";
import { Dropdown, MenuProps, Select, Space, Tag } from "antd";
import FilterTags from "./filter-tags";
import { ArrowDown, ChevronDown, Menu } from "react-feather";
import { Amount } from "namesky-sdk";
import useUrlState from "@ahooksjs/use-url-state";
import _ from "lodash";

const SortLabel = ({ children }: { children: ReactNode }) => (
  <div>
    <span className="text-gray-500">Sort:</span> {children}
  </div>
);

const MarketPage = () => {
  const history = useHistory();
  const observerElem = useRef(null);
  const [filtersState, setFiltersState] = useUrlState<FiltersState>(
    {},
    {
      parseOptions: {
        arrayFormat: "bracket",
      },
      stringifyOptions: {
        arrayFormat: "bracket",
      },
    }
  );

  const [search, setSearch] = useUrlState({
    search: "",
  });

  const [sort, setSort] = useUrlState<{
    sort: "mint_time|desc" | "price|asc" | "price|desc" | "listing_time|desc";
  }>({
    sort: "mint_time|desc",
  });

  const [filters, setFilters] = React.useState<Filter[]>(defaultFilters);

  const updateFilter = (filter_item: FilterItem) => {
    const filter_index = filters.findIndex(
      (e) => e.name === filter_item.filter_name
    );

    const filter_item_index = filters[filter_index].filter_items.findIndex(
      (e) => e.item_name === filter_item.item_name
    );
    filters[filter_index].filter_items[filter_item_index] = filter_item;
    setFilters([...filters]);
  };

  const orderOptions = [
    {
      value: "price|asc",
      label: <SortLabel>Price low to high</SortLabel>,
      optionLabel: "Price low to high",
    },
    {
      value: "price|desc",
      label: <SortLabel>Price high to low</SortLabel>,
      optionLabel: "Price high to low",
    },
    {
      value: "listing_time|desc",
      label: <SortLabel>Recently listed</SortLabel>,
      optionLabel: "Recently listed",
    },
    {
      value: "mint_time|desc",
      label: <SortLabel>Recently created</SortLabel>,
      optionLabel: "Recently created",
    },
  ];

  const handleOrderSelectorChange = (value: string) => {
    setSort({ sort: value });
  };

  const queryParam = useMemo(() => {
    let param: any = {};
    filters.forEach((filter) => {
      filter.filter_items.forEach((filter_item) => {
        param = filter_item.handleQueryParam(
          param,
          filter_item.is_selected,
          filter_item.value
        );
      });
    });

    let orderValues = sort.sort.split("|");
    param.orderBy = orderValues[0];
    param.orderDirection = orderValues[1];

    return param;
  }, [filters, sort]);

  const {
    data: nfts,
    isLoading: isLoadingNFTs,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useComplexNftsQuery({
    ...queryParam,
    ...filtersState,
    search: search.search,
  });

  // useEffect(() => {
  //   if (search === "") {
  //     history.push("/marketplace");
  //     return;
  //   }
  //   history.push("/marketplace/?search=" + search);
  // }, [search]);

  const handleObserver = useCallback(
    (entries) => {
      const [target] = entries;
      if (target.isIntersecting) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  useEffect(() => {
    const element = observerElem.current;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [fetchNextPage, hasNextPage, handleObserver]);

  const onFilterChange = (updates: {}, reset: boolean = false) => {
    if (reset) {
      setFiltersState((prev) => {
        const updates = Object.keys(prev).reduce((acc, key) => {
          acc[key] = undefined;
          return acc;
        }, {});
        return updates;
      });
      return;
    }
    setFiltersState((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  return (
    <ResponsiveContainerLargeSize>
      <h1 className="text-2xl font-bold mt-12">Account Marketplace</h1>
      <p className="mt-2 text-gray-800 max-w-[380px]">
        By purchasing an NFT you are buying access to the associated NEAR
        account & funds.
      </p>

      <div className="flex flex-col lg:flex-row">
        <div id="filter" className=" lg:w-[300px] shrink-0">
          <div className="mt-8">
            <Filters
              updateFilter={updateFilter}
              filtersState={filtersState}
              onFilterChange={onFilterChange}
              filters={filters}
            />
          </div>
        </div>

        <div className="flex-grow mt-8 lg:ml-8 min-w-[0px]">
          <div>
            <div className="flex flex-row items-center justify-between gap-x-3">
              <input
                style={{ width: "50%" }}
                type="text"
                placeholder="Search"
                className="h-[50px] rounded-full py-1 border-gray-300  px-4"
                value={search.search}
                onChange={(e) => setSearch({ search: e.target.value })}
              />

              <Select
                suffixIcon={<ChevronDown size={16} />}
                size="large"
                onChange={handleOrderSelectorChange}
                defaultValue="mint_time|desc"
                options={orderOptions}
                optionRender={(option) => (
                  <div className="text-[16px] text-gray-700">
                    {option.data.optionLabel}
                  </div>
                )}
              />
            </div>
            <div className="mt-4">
              <FilterTags
                updateFilter={updateFilter}
                filters={filters}
                filtersState={filtersState}
                onFilterChange={onFilterChange}
              />
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <NFTList
              nfts={nfts}
              isLoading={isLoadingNFTs || isFetchingNextPage}
              search={search.search}
            />
          </div>

          <span className="opacity-0" ref={observerElem}>
            Load More
          </span>
        </div>
      </div>
    </ResponsiveContainerLargeSize>
  );
};

const defaultFilters: Filter[] = [
  {
    name: "Format",
    type: "Multiple",
    input_type: "multiple",
    checkIsActive: (filtersState: FiltersState, filterItem: FilterItem) => {
      const values = filtersState[filterItem.key];
      if (!values) return false;
      const isActive =
        values === filterItem.value || values.indexOf(filterItem.value) !== -1;
      return isActive;
    },
    tagText: (filter_item: FilterItem): string => {
      return `${filter_item.filter_name}: ${filter_item.item_name}`;
    },
    filter_items: [
      {
        filter_name: "Format",
        key: "top_domain", // will be used in url
        type: "Multiple",
        item_name: ".near",
        value: "near",
        default_value: "near",
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          // since the values might be a string or an array, we need to convert it to an array
          let values: string[] = [filtersState["top_domain"] || []].flat();
          if (is_selected) {
            values = [...values, "near"];
          } else {
            _.remove(values, (value) => value === "near");
          }
          return { top_domain: values };
        },
      },
      {
        filter_name: "Format",
        key: "top_domain", // will be used in url
        type: "Multiple",
        item_name: ".tg",
        value: "tg",
        default_value: "tg",
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          // since the values might be a string or an array, we need to convert it to an array
          let values: string[] = [filtersState["top_domain"] || []].flat();
          if (is_selected) {
            values = [...values, "tg"];
          } else {
            _.remove(values, (value) => value === "tg");
          }
          return { top_domain: values };
        },
      },
    ],
  },
  {
    name: "Category",
    type: "Multiple",
    input_type: "multiple",
    checkIsActive: (filtersState: FiltersState, filterItem: FilterItem) => {
      const clubValue = filtersState[filterItem.key];
      if (!clubValue) return false;
      const isActive =
        clubValue === filterItem.value ||
        clubValue.indexOf(filterItem.value) !== -1;
      return isActive;
    },
    tagText: (filter_item: FilterItem): string => {
      return `${filter_item.filter_name}: ${filter_item.item_name}`;
    },
    filter_items: [
      {
        filter_name: "Category",
        key: "club", // will be used in url
        type: "Multiple",
        item_name: "999 Club",
        value: "club999",
        default_value: "club999",
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          // handle url for both ?club=club999 and ?club[]=club999
          let values: string[] = [filtersState["club"] || []].flat();
          if (is_selected) {
            values = [...values, "club999"];
          } else {
            _.remove(values, (value) => value === "club999");
          }
          return { club: values };
        },
      },
      {
        filter_name: "Category",
        key: "club", // will be used in url
        type: "Multiple",
        item_name: "10K Club",
        value: "club10k",
        default_value: "club10k",
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          let values: string[] = [filtersState["club"] || []].flat();
          if (is_selected) {
            values = [...values, "club10k"];
          } else {
            _.remove(values, (value) => value === "club10k");
          }
          return { club: values };
        },
      },
      {
        filter_name: "Category",
        key: "club", // will be used in url
        type: "Multiple",
        item_name: "100K Club",
        value: "club100k",
        default_value: "club100k",
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          let values: string[] = [filtersState["club"] || []].flat();
          if (is_selected) {
            values = [...values, "club100k"];
          } else {
            _.remove(values, (value) => value === "club100k");
          }
          return { club: values };
        },
      },
    ],
  },
  {
    name: "Character Set",
    type: "Multiple",
    input_type: "multiple",
    tagText: (filter_item: FilterItem): string => {
      return `${filter_item.filter_name}: ${filter_item.item_name}`;
    },
    checkIsActive: (filtersState: FiltersState, filterItem: FilterItem) => {
      const values = filtersState[filterItem.key];
      if (!values) return false;
      const isActive =
        values === filterItem.value || values.indexOf(filterItem.value) !== -1;
      return isActive;
    },
    filter_items: [
      {
        filter_name: "Character Set",
        item_name: "Letter",
        key: "character_set", // will be used in url
        type: "Multiple",
        value: "letter",
        default_value: "letter",
        is_selected: false,
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          // <del>since the values might be a string or an array, we need to convert it to an array</del>
          // After setting `arrayFormat: 'bracket'`, it's guarantee that character_set will be an array if value exists
          let values: string[] = filtersState["character_set"] || [];
          if (is_selected) {
            values = [...values, "letter"];
          } else {
            _.remove(values, (value) => value === "letter");
          }
          return { character_set: values };
        },
      },
      {
        filter_name: "Character Set",
        item_name: "Digit",
        key: "character_set", // will be used in url
        type: "Multiple",
        value: "digit",
        default_value: "digit",
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          // since the values might be a string or an array, we need to convert it to an array
          let values: string[] = filtersState["character_set"] || [];
          if (is_selected) {
            values = [...values, "digit"];
          } else {
            _.remove(values, (value) => value === "digit");
          }
          return { character_set: values };
        },
      },
      {
        filter_name: "Character Set",
        item_name: "Mixed",
        key: "character_set", // will be used in url
        type: "Multiple",
        value: "mixed",
        default_value: "mixed",
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          // since the values might be a string or an array, we need to convert it to an array
          let values: string[] = filtersState["character_set"] || [];
          if (is_selected) {
            values = [...values, "mixed"];
          } else {
            _.remove(values, (value) => value === "mixed");
          }
          return { character_set: values };
        },
      },
      {
        filter_name: "Character Set",
        item_name: "Allow separator",
        key: "contain_separator", // will be used in url
        type: "Single",
        value: "true", // TODO: need to change this to boolean true once useUrlState refactor is ready
        default_value: "separator",
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          if (is_selected) {
            return { contain_separator: "true" };
          } else {
            return { contain_separator: undefined };
          }
        },
      },
    ],
  },
  // {
  //   name: 'Price',
  //   key: 'price',
  //   type: 'Single',
  //   input_type: 'between',
  //   tagText: (filter_item: FilterItem): string => {
  //     const betweenValue = filter_item.value as BetweenValue;
  //     if (betweenValue.from.length === 0) {
  //       return `${filter_item.filter_name}: <= ${betweenValue.to}`
  //     }
  //
  //     if (betweenValue.to.length === 0) {
  //       return `${filter_item.filter_name}: >= ${betweenValue.from}`
  //     }
  //
  //     return `${filter_item.filter_name}: ${(filter_item.value as BetweenValue).from}-${(filter_item.value as BetweenValue).to}`
  //   },
  //   filter_items: [
  //     {
  //       filter_name: 'Price',
  //       type: 'Single',
  //       key: 'price',
  //       item_name: 'price',
  //       value: { from: '0', to: '' },
  //       default_value: { from: '0', to: '' },
  //       is_selected: false,
  //       handleQueryParam: (param, is_selected, value) => {
  //         if (!is_selected) return param
  //         let between_value = value as BetweenValue
  //         if (between_value.from) {
  //           param = { ...param, price_gte: Amount.parse(between_value.from, 'NEAR') }
  //         }
  //         if (between_value.to) {
  //           param = { ...param, price_lte: Amount.parse(between_value.to, 'NEAR') }
  //         }
  //         return param
  //       }
  //     }
  //   ],
  // },
  // {
  //   name: 'Name Length',
  //   type: 'Single',
  //   input_type: 'between',
  //   tagText: (filter_item: FilterItem): string => {
  //     const betweenValue = filter_item.value as BetweenValue;
  //     if (betweenValue.from.length === 0) {
  //       return `${filter_item.filter_name}: <= ${betweenValue.to}`
  //     }
  //
  //     if (betweenValue.to.length === 0) {
  //       return `${filter_item.filter_name}: >= ${betweenValue.from}`
  //     }
  //
  //     return `${filter_item.filter_name}: ${(filter_item.value as BetweenValue).from}-${(filter_item.value as BetweenValue).to}`
  //   },
  //   filter_items: [{
  //     filter_name: 'Name Length',
  //     key: 'name_length',
  //     type: 'Single',
  //     item_name: 'Length',
  //     value: { from: '0', to: '' },
  //     default_value: { from: '0', to: '' },
  //     is_selected: false,
  //     handleQueryParam: (param, is_selected, value) => {
  //       if (!is_selected) return param
  //       let between_value = value as BetweenValue
  //       if (between_value.from) {
  //         param = { ...param, search_name_len_gte: between_value.from }
  //       }
  //       if (between_value.to) {
  //         param = { ...param, search_name_len_lte: between_value.to }
  //       }
  //       return param
  //     }
  //   }],
  // },
  {
    name: "Name Level",
    hint: "",
    type: "Single",
    input_type: "single",
    tagText: (filter_item: FilterItem): string => {
      return `Name Level: ${filter_item.item_name}`;
    },
    checkIsActive: (filtersState: FiltersState, filterItem: FilterItem) => {
      const values = filtersState[filterItem.key];
      if (!values) return false;
      const isActive =
        values === filterItem.value || values.indexOf(filterItem.value) !== -1;
      return isActive;
    },
    filter_items: [
      {
        key: "name_level",
        type: "Single",
        filter_name: "Name Level",
        item_name: "Top Level",
        value: "1",
        default_value: 1,
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          return {
            name_level: is_selected ? "1" : undefined,
          };
        },
      },
      {
        key: "name_level",
        type: "Single",
        filter_name: "Name Level",
        item_name: "Second Level",
        value: "2",
        default_value: 2,
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          return {
            name_level: is_selected ? "2" : undefined,
          };
        },
      },
      {
        key: "name_level",
        type: "Single",
        filter_name: "Name Level",
        item_name: "Third level",
        value: "3",
        default_value: 3,
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          return {
            name_level: is_selected ? "3" : undefined,
          };
        },
      },
      {
        key: "name_level",
        type: "Single",
        filter_name: "Name Level",
        item_name: "All level",
        value: undefined,
        default_value: undefined,
        checkIsActive: (filtersState: FiltersState) => {
          const values = filtersState["name_level"];
          if (values === undefined) return false;
          const isActive = values === undefined;
          return isActive;
        },
        handleQueryParam: (
          filtersState: FiltersState,
          is_selected: boolean
        ) => {
          return {
            name_level: undefined,
          };
        },
      },
    ],
  },
];

export default MarketPage;
