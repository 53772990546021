import { useQuery } from "@tanstack/react-query";
import { config } from "~domain/near/global";

export function useRefNearTokenPriceQuery() {
  return useQuery(
    ["nearTokenFiatValueUSD"],
    async () => {
      const res = await fetch(
        config.REF_FINANCE_API_ENDPOINT +
          `/get-token-price?token_id=${config.WNEAR_TOKEN_ID}`
      );
      const data = await res.json();
      return data;
    },
    {
      staleTime: 1000 * 60 * 5,
      select: (data) => {
        try {
          return Number(data.price) || undefined;
        } catch (e) {
          return undefined;
        }
      },
    }
  );
}
