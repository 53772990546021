import React, { useEffect } from "react";
import Modal, { ModalProps } from "~components/modal/modal";
import NFTThumbnail from "~components/nft/thumbnail";
import Spacer from "~components/spacer";
import { Line } from "~pages/mint/mint-modal";
import nearIcon from "~assets/input-suffix-near.svg";
import { PrimaryButton, SecondaryButton } from "~components/button/Button";
import { config } from "~domain/near/global";
import { useFormattedPriceText } from "~hooks/useNearFiatUSDText";
import { useAccountBalanceQuery } from "~domain/nnn/queries";
import { useBoolean } from "ahooks";
import { WebWalletIds, useWalletSelector } from "~context/namesky";
import { Amount } from "namesky-sdk";
import { ListingView } from "namesky-sdk/dist/core/types/data";

interface Props extends ModalProps {
  token_id: string;
  listing?: ListingView;
}

const ListingModal = (props: Props) => {
  const { token_id, listing } = props;
  const [price, setPrice] = React.useState("");
  const [priceError, setPriceError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isShowConfirm, { setTrue: showConfirm, setFalse: hideConfirm }] =
    useBoolean(false);
  const { data: tokenAccountBalance } = useAccountBalanceQuery(token_id);

  const { namesky, selector } = useWalletSelector();

  useEffect(() => {
    setPrice(
      listing && listing.price ? Amount.format(listing.price, "NEAR") : ""
    );
  }, [listing, props.isOpen]);

  const handleClickConfirm = async (checkPrice: boolean) => {
    if (!price) {
      setPriceError("Price is required");
      return;
    }

    if (checkPrice && tokenAccountBalance) {
      if (Number(price) <= Number(tokenAccountBalance)) {
        showConfirm();
        return;
      }
    }

    setLoading(true);
    if (listing) {
      namesky.marketplaceContract
        .updateListing({
          tokenId: token_id,
          newPrice: Amount.parse(price, "NEAR"),
        })
        .then((res: any) => {
          if (WebWalletIds.includes(selector.store.getState().selectedWalletId))
            return;
          location.reload();
        })
        .catch((e) => {
          location.reload();
        });
    } else {
      namesky.marketplaceContract
        .createListing({
          tokenId: token_id,
          price: Amount.parse(price, "NEAR"),
        })
        .then((res: any) => {
          if (WebWalletIds.includes(selector.store.getState().selectedWalletId))
            return;
          location.reload();
        })
        .catch((e) => {
          location.reload();
        });
    }
  };

  const titleText = listing
    ? "Update the listing price"
    : "List account for sale";
  const confirmText = listing ? "Update listing" : "Confirm listing";

  const { fiatPriceFullText } = useFormattedPriceText(
    price ? Amount.parse(price, "NEAR") : ""
  );

  return (
    <Modal
      onAfterClose={() => {
        setPriceError("");
        setPrice("");
        setLoading(false);
      }}
      {...props}
      title={<div className="text-center">{titleText}</div>}
    >
      <Modal
        isOpen={isShowConfirm}
        onRequestClose={hideConfirm}
        title="Confirm listing"
        className="max-w-[440px] mx-auto"
      >
        <div className="mt-2 text-gray-500">
          Your listing price{" "}
          <span className="font-semibold text-gray-700">{price} NEAR</span> is
          less than the balance{" "}
          <span className="font-semibold text-gray-700">
            {Number(tokenAccountBalance).toFixed(4)} NEAR
          </span>{" "}
          in the <span className="font-semibold text-gray-700">{token_id}</span>{" "}
          account, which means if the item is sold at the listing price, you
          will be at a loss.
        </div>
        <div className="flex justify-end mt-4 gap-x-2">
          <SecondaryButton onClick={hideConfirm}>Cancel</SecondaryButton>
          <PrimaryButton
            className="bg-red-500"
            onClick={() => {
              hideConfirm();
              handleClickConfirm(false);
            }}
          >
            Yes, list at {price} NEAR
          </PrimaryButton>
        </div>
      </Modal>
      <div className="mx-[-16px] px-6">
        <div className="flex items-center mt-[40px]">
          <NFTThumbnail id={token_id} size={60} />
          <div className="flex flex-col items-start ml-[10px]">
            <span className="text-sm font-semibold text-gray-500">NameSky</span>
            <span className="text-base font-semibold text-gray-900">
              {token_id}
            </span>
            <span className="text-sm text-gray-500">
              Balance: {Number(tokenAccountBalance).toFixed(4)} NEAR
            </span>
          </div>
        </div>
        <Line />
        <Spacer h="24px" />
        <label>
          <p className="mb-2 font-semibold text-gray-900">Price</p>
          <div className="relative">
            <input
              placeholder="Amount"
              className="w-full h-[44px] rounded-[8px] border-[1px] border-gray-300 px-4 focus:border-gray-500 focus:outline-none "
              value={price}
              onChange={(e) => {
                if (e.target.value === "") setPrice("");
                if (!/^[0-9]+\.?\d*$/g.test(e.target.value)) return;
                setPriceError("");
                setPrice(e.target.value);
              }}
            />
            <img src={nearIcon} className="absolute right-[10px] top-[10px]" />
            <span className="text-sm text-gray-400 absolute right-[10px] bottom-[-24px]">
              {price && fiatPriceFullText}
            </span>
          </div>
          {priceError && <p className="mt-2 text-red-500">{priceError}</p>}
        </label>
        <Spacer h="24px" />
        <p className="mb-2 font-semibold text-gray-900">Fees</p>
        <div className="flex items-start justify-between">
          <div className="flex-grow">
            <p className="text-sm font-semibold text-gray-500">Royalty</p>
          </div>
          <span className="text-sm font-semibold text-gray-500">
            {config.NAMESKY_NFT_ROYALTY_PERCENT}%
          </span>
        </div>
        <div className="flex items-start justify-between">
          <div className="flex-grow">
            <p className="text-sm font-semibold text-gray-500">
              Marketplace Fee
            </p>
          </div>
          <span className="text-sm font-semibold text-gray-500">
            {config.NAMESKY_MARKETPLACE_FEE_PERCENT}%
          </span>
        </div>
      </div>
      <Spacer h="24px" />
      <PrimaryButton
        size="large"
        isFull
        onClick={() => handleClickConfirm(true)}
        loading={loading}
      >
        {confirmText}
      </PrimaryButton>
    </Modal>
  );
};

export default ListingModal;
