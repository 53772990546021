import React from "react";
import Skeleton from "react-loading-skeleton";
import { useNftsQuery } from "~domain/nnn/queries";
import useTailwindBreakpoint from "~hooks/useTailwindBreakpoint";
import { Link } from "react-router-dom";
import { transferToNumberWithCommas } from "~utils/util";
import { U128 } from "~domain/near/types";

export const ListCountItem = ({
  user_id,
  count,
}: {
  user_id: string;
  count: number;
}) => {
  return (
    <div
      className={` bg-gray-100 rounded-[16px] flex flex-col hover:-translate-y-1 transition duration-200 hover:shadow-lg`}
    >
      <Link
        to={`/account/${user_id}`}
        className="flex justify-between border items-center border-gray-300 rounded-[16px] p-3 bg-white "
      >
        <div id="account_name" className="break-words truncate">
          <span className="text-xl font-bold text-gray-700">
            {user_id.replace(/\.testnet|near$/, "")}
          </span>
          <span className="text-xl font-bold text-gray-700 break-normal opacity-30">
            {user_id.replace(/(.*)(\.testnet|near)$/, "$2")}
          </span>
        </div>
        <div className="font-semibold shrink-0 ml-4">{count}</div>
      </Link>
    </div>
  );
};

export const ListItem = ({
  token_id,
  price,
}: {
  token_id: string;
  price: U128;
}) => {
  return (
    <div
      className={` bg-gray-100 rounded-[16px] flex flex-col hover:-translate-y-1 transition duration-200 hover:shadow-lg`}
    >
      <Link
        to={`/${token_id}`}
        className="flex justify-between border items-center border-gray-300 rounded-[16px] p-3 bg-white "
      >
        <div id="account_name" className="break-words truncate">
          <span className="text-xl font-bold text-gray-700">
            {token_id.replace(/\.testnet|near|tg$/, "")}
          </span>
          <span className="text-xl font-bold text-gray-700 break-normal opacity-30">
            {token_id.replace(/(.*)(\.testnet|near|tg)$/, "$2")}
          </span>
        </div>
        <div className="font-semibold shrink-0 ml-4">
          {price && `${transferToNumberWithCommas(price)} Ⓝ`}
        </div>
      </Link>
    </div>
  );
};

const ListingList = () => {
  const brkPnt = useTailwindBreakpoint();
  const { data: nfts, isLoading } = useNftsQuery({
    search: "",
    orderBy: "mint_time",
    orderDirection: "desc",
    search_name_length: 64,
  });
  console.log({ nfts });
  if (nfts?.length === 0 && isLoading) {
    return (
      <div className="flex flex-col gap-y-3">
        {[...Array(5)].map((_, i) => (
          <Skeleton
            key={i}
            height={110}
            style={{ borderRadius: "16px", opacity: 0.5 }}
          />
        ))}
      </div>
    );
  }

  const featuredNfts = (() => {
    if (brkPnt === "sm") return nfts?.slice(0, 4);
    if (brkPnt === "md") return nfts?.slice(0, 8);
    if (brkPnt === "lg") return nfts?.slice(0, 8);
    return nfts?.slice(0, 9);
  })();

  return (
    <div>
      {isLoading ? (
        <Skeleton height={110} style={{ borderRadius: "16px", opacity: 0.5 }} />
      ) : (
        <div className="grid grid-cols-1 gap-x-2 gap-y-2 md:grid-cols-2 lg:grid-cols-3">
          {featuredNfts?.map((nft: { token_id: string; price: string }) => {
            return <ListItem key={nft.token_id} {...nft} />;
          })}
        </div>
      )}
    </div>
  );
};

export default ListingList;
