import { GetNftsQuery } from ".graphclient";
import React from "react";
import { PrimaryButton, SecondaryButton } from "~components/button/Button";
import { useFormattedPriceText } from "~hooks/useNearFiatUSDText";
import near16Icon from "~assets/near-16.svg";
import profile16Icon from "~assets/profile-16.svg";
import { useAccountBalanceQuery } from "~domain/nnn/queries";
import { Link, useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import notMintIcon from "~assets/not-mint.svg";
import { convertToSearchName, helpCompleteAccountIdInput } from "~utils/util";

const EmptyItem = ({ searchAccountId }: { searchAccountId: string }) => {
  const history = useHistory();
  const { data: tokenBalance, isLoading: isLoadingBalance } =
    useAccountBalanceQuery(searchAccountId);

  const splitSearchAccountId = searchAccountId.split(".");
  const hasSuffix = splitSearchAccountId.length > 1;
  const searchAccountIdWithoutSuffix = hasSuffix
    ? splitSearchAccountId.slice(0, splitSearchAccountId.length - 1).join(".")
    : searchAccountId;
  return (
    <div
      className={` bg-gray-100 rounded-[16px] flex flex-col hover:-translate-y-1 transition duration-200 hover:shadow-lg`}
    >
      <Link
        to={`/${searchAccountId}`}
        className="flex flex-col rounded-[16px] p-6"
      >
        <div id="account_name" className="break-words">
          <span className="text-2xl font-bold text-gray-700">
            {searchAccountIdWithoutSuffix}
          </span>
          {hasSuffix && (
            <span className="text-2xl font-bold text-gray-700">
              .{splitSearchAccountId[splitSearchAccountId.length - 1]}
            </span>
          )}
        </div>

        <div id="account_detail" className="mt-2">
          <div className="flex items-center gap-x-[6px] text-sm font-medium text-gray-500">
            <img src={near16Icon} alt="near" className="inline-block w-4 h-4" />
            {isLoadingBalance ? (
              <Skeleton width={40} />
            ) : (
              <span>{Number(tokenBalance).toFixed(2)}</span>
            )}
            <div className="flex items-center gap-x-1">NEAR Balance</div>
          </div>
          <div className=" flex items-center gap-x-[6px] text-sm font-medium text-gray-500">
            <img
              src={notMintIcon}
              alt="near"
              className="inline-block w-4 h-4"
            />
            <span className="truncate">Not minted</span>
          </div>
        </div>
      </Link>

      <div className="p-6 border-t">
        <SecondaryButton
          isFull
          size="large"
          onClick={() => history.push(`/${searchAccountId}`)}
        >
          Make an offer
        </SecondaryButton>
      </div>
    </div>
  );
};

const NFTItem = ({ nft }: { nft: GetNftsQuery["nfts"][0] }) => {
  const history = useHistory();
  const { listing } = nft;
  const { nearPriceFullText, fiatPriceFullText } = useFormattedPriceText(
    listing?.price
  );
  const { data: tokenBalance, isLoading: isLoadingBalance } =
    useAccountBalanceQuery(nft.token_id);
  const nftDetailLink = `/${nft.token_id}`;
  const handleClickBuy = () => {
    history.push(nftDetailLink);
  };
  const handleClickOffer = () => {
    history.push(nftDetailLink);
  };

  const is_sub = nft.token_id.includes(".");

  return (
    <div
      className={` bg-gray-100 rounded-[16px] flex flex-col hover:-translate-y-1 transition duration-200 hover:shadow-lg`}
    >
      <Link
        to={`/${nft.token_id}`}
        className="flex flex-col flex-grow border border-gray-300 rounded-[16px] p-6 bg-white"
      >
        <div id="account_name" className="break-words">
          <span className="text-2xl font-bold text-gray-700">
            {nft.prefix_domain}
          </span>
          {is_sub && (
            <span className="text-2xl font-bold text-gray-700 break-normal opacity-30">
              {"." + nft.top_domain}
            </span>
          )}
        </div>

        <div className="flex-grow"></div>

        <div id="account_detail" className="mt-2">
          <div className="flex items-center gap-x-[6px] text-sm font-medium text-gray-500">
            <img src={near16Icon} alt="near" className="inline-block w-4 h-4" />
            <div className="flex items-center gap-x-1">
              {isLoadingBalance ? (
                <Skeleton width={40} />
              ) : (
                <span>{Number(tokenBalance).toFixed(2)}</span>
              )}
              NEAR Balance
            </div>
          </div>
          <div className="flex items-center gap-x-[6px] text-sm font-medium text-gray-500">
            <img
              src={profile16Icon}
              alt="near"
              className="inline-block w-4 h-4"
            />
            <span className="truncate">Owned by {nft.owner_id}</span>
          </div>
        </div>
      </Link>

      <div className="p-6">
        {listing?.price && (
          <div className="flex justify-between items-center">
            <div className="flex flex-col gap-y-1">
              <span className="text-sm font-semibold text-gray-700">
                Listing Price
              </span>
              <span className="text-xl font-bold text-gray-700">
                {nearPriceFullText}{" "}
                <span className="text-base font-normal text-gray-500">
                  {fiatPriceFullText}
                </span>
              </span>
            </div>
            <PrimaryButton size="large" onClick={handleClickBuy}>
              BUY
            </PrimaryButton>
          </div>
        )}
        {!listing && (
          <SecondaryButton isFull size="large" onClick={handleClickOffer}>
            Make an offer
          </SecondaryButton>
        )}
      </div>
    </div>
  );
};

export default function NFTList({
  nfts = [],
  isLoading,
  className,
  search,
}: {
  nfts: GetNftsQuery["nfts"];
  isLoading: boolean;
  className?: string;
  search?: string;
}) {
  const searchAccountId = helpCompleteAccountIdInput(search);
  // const searchAccountId = convertToSearchName(search);

  const exactlyMatchedNFT = nfts.find(
    (nft) => nft.token_id === searchAccountId
  );

  return (
    <div
      className={`w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-none  gap-x-8 gap-y-4 ${className}`}
      style={{
        gridTemplateColumns: "repeat(auto-fill, minmax(270px, 1fr))",
      }}
    >
      <>
        {!isLoading && search && !exactlyMatchedNFT && (
          <EmptyItem searchAccountId={searchAccountId} />
        )}
        {nfts.map((nft) => {
          return <NFTItem nft={nft} key={nft.token_id} />;
        })}
      </>
      {isLoading && (
        <>
          <Skeleton height={200} className="rounded-[16px]" />
          <Skeleton height={200} className="rounded-[16px]" />
          <Skeleton height={200} className="rounded-[16px]" />
          <Skeleton height={200} className="rounded-[16px]" />
          <Skeleton height={200} className="rounded-[16px]" />
          <Skeleton height={200} className="rounded-[16px]" />
        </>
      )}
    </div>
  );
}
