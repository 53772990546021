import React from "react";
import { getBuiltGraphSDK } from "~/../.graphclient/index";
import { useQuery } from "@tanstack/react-query";
import NFTList from "~pages/market/nft-list";

const { GetLikedNfts } = getBuiltGraphSDK();

const LikedNFTList = ({ account_id }: { account_id: string }) => {
  const { data: likedNfts = {}, isLoading } = useQuery(
    ["likedNfts", account_id],
    () => GetLikedNfts({ user_id: account_id })
  );
  const nfts = likedNfts?.user?.like_nfts?.map(({ nft }) => ({ ...nft })) || [];
  return (
    <div className="grid grid-cols-1 gap-y-2">
      <NFTList nfts={nfts} isLoading={isLoading} search="" />
      {!isLoading && nfts.length === 0 && (
        <div className="mt-4">
          <p className="text-gray-500">No liked NFTs found.</p>
        </div>
      )}
    </div>
  );
};

export default LikedNFTList;
