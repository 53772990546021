import { useBoolean } from "ahooks";
import { Spin } from "antd";
import { Amount, MultiTransaction } from "namesky-sdk";
import { MultiSendWalletSelector, NameSky } from "namesky-sdk";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import { Gift, PlusCircle, RefreshCcw, XCircle } from "react-feather";
import { PrimaryButton } from "~components/button/Button";
import Checkbox from "~components/forms/checkbox";
import RequestSignInModal from "~components/modal/request-sign-in-modal";
import { useWalletSelector } from "~context/namesky";
import { config } from "~domain/near/global";
import { AccountId } from "~domain/near/types";
import { generateNFTMediaSvg } from "~utils/util";
import CostDisclosureText from "./cost-disclosure";
import FullPermissionsDisclosureText from "./full-permissions-disclosure";
import MintProgressModal from "./mint-modal";
import StepContent from "./step-content";
import AccountImportModal from "./wallets-modal";
import skyToken from "~assets/sky-token.svg";

const MintPage = () => {
  const { namesky, selector, near } = useWalletSelector();

  const [isOpenRequestSinInModal, setIsOpenRequestSinInModal] =
    useState<boolean>(false);

  const loginAccountId = useMemo(() => {
    if (!selector) {
      return undefined;
    }

    let activeAccountId = selector.getActiveAccount()?.accountId;
    setIsOpenRequestSinInModal(!activeAccountId);
    return activeAccountId;
  }, [selector]);

  const {
    account_id: accountIdInUrl,
    is_mint_import: isMintImport,
  }: { account_id?: string; is_mint_import?: string } = queryString.parse(
    window.location.search
  );

  const [importMintAccountId, setImportMintAccountId] = useState<
    string | undefined
  >(isMintImport ? accountIdInUrl : undefined);
  const cancelImportedMintAccount = () => setImportMintAccountId(undefined);
  const handleAccountImported = (accountId: AccountId): void => {
    setImportMintAccountId(accountId);
  };

  const [isCheckingBalance, setIsCheckingBalance] = useState<boolean>(true);
  const [currentBalance, setCurrentBalance] = useState<number | undefined>(
    undefined
  );
  const [requiredBalance, setRequiredBalance] = useState<number | undefined>(
    undefined
  );

  const [step, setStep] = useState<number>(1);

  const checkAccountBalance = () => {
    if (!namesky || !importMintAccountId || !near) {
      return;
    }

    if (loginAccountId === importMintAccountId) {
      return;
    }

    setIsCheckingBalance(true);
    Promise.all([
      near
        .account(importMintAccountId)
        .then((account) => account.state())
        .then((state) => state.amount),
      namesky.coreContract.nftGetMinterId({
        registrantId: importMintAccountId,
      }),
    ]).then(([balance, minter]) => {
      setIsCheckingBalance(false);
      const currentBalance = Number(Amount.format(balance, "NEAR"));
      const isRegister = !!minter;
      const requiredBalance = isRegister
        ? config.MINT_STORAGE_FEE
        : config.MINT_STORAGE_FEE + config.MINT_SERVICE_FEE;

      setCurrentBalance(currentBalance);
      setRequiredBalance(requiredBalance);

      if (currentBalance >= requiredBalance) {
        console.log({
          currentBalance,
          requiredBalance,
        });
        setStep(2);
      }
    });
  };

  useEffect(checkAccountBalance, [
    namesky,
    near,
    importMintAccountId,
    loginAccountId,
  ]);

  const [mintError, setMintError] = useState<string>("");
  const [isMinting, setIsMinting] = useState<boolean>(false);

  const [
    isMintProgressModalOpen,
    { setTrue: openMintProgressModal, setFalse: closeMintProgressModal },
  ] = useBoolean(false);

  const handleClickMint = async () => {
    setMintError("");
    openMintProgressModal();
  };

  const handleMintError = (error: string) => {
    closeMintProgressModal();
    setIsMinting(false);
    setMintError(error);
  };

  const [isReady, setIsReady] = useState<boolean>(false);

  return (
    <div className="mt-[80px] px-4 max-w-[600px] m-auto">
      <RequestSignInModal
        className="max-w-[400px] m-auto"
        text="Please sign in to mint."
        isOpen={isOpenRequestSinInModal}
        title={<span>Connect your wallet</span>}
        onRequestClose={() => {
          history.back();
        }}
      />
      <MintProgressModal
        isOpen={isMintProgressModalOpen}
        onRequestClose={closeMintProgressModal}
        mintAccountId={importMintAccountId}
        ownerAccountId={loginAccountId}
        onErrorMessage={handleMintError}
        setStartMinting={() => {
          setIsMinting(true);
        }}
      />
      <h2 className="text-4xl font-bold">Mint your NFT</h2>
      <div className="mt-2 flex items-center">
        <Gift className="mr-2 text-gray-700" size={24} />
        <div className="flex text-[18px] text-gray-700">
          Get 40 <img className="mr-1 ml-[1px]" width={"17px"} src={skyToken} />
          SKY Token reward
        </div>
      </div>
      <div className="mt-8">
        <StepContent
          step={1}
          activeStep={step}
          title="Import NEAR Account"
          totalStep={2}
        >
          <Step1Content
            {...{
              selector,
              importMintAccountId,
              loginAccountId: loginAccountId!,
              cancelImportedMintAccount,
              checkAccountBalance,
              isCheckingBalance,
              currentBalance,
              requiredBalance,
              handleAccountImported,
            }}
          />
        </StepContent>
        <StepContent
          title="Confirm Mint"
          step={2}
          activeStep={step}
          totalStep={2}
        >
          {step === 2 &&
            step2Content({
              isMinting,
              importMintAccountId,
              mintError,
              handleClickMint,
              isReady,
              setIsReady,
            })}
        </StepContent>
      </div>
    </div>
  );
};

export default MintPage;

const Step1Content = ({
  selector,
  importMintAccountId,
  loginAccountId,
  cancelImportedMintAccount,
  checkAccountBalance,
  isCheckingBalance,
  currentBalance,
  requiredBalance,
  handleAccountImported,
}: {
  selector: MultiSendWalletSelector;
  importMintAccountId: AccountId | undefined;
  loginAccountId: AccountId | undefined;
  cancelImportedMintAccount: () => void;
  checkAccountBalance: () => void;
  isCheckingBalance: boolean;
  currentBalance: number | undefined;
  requiredBalance: number | undefined;
  handleAccountImported: (accountId: AccountId) => void;
}) => {
  const [isWalletsModalShow, setIsWalletsModalShow] = useState(false);
  const isMintAccountSameAsLoginAccount =
    loginAccountId === importMintAccountId;
  const mintAccountSameAsLoginAccountErrorMessage = `You cannot mint the login account(${loginAccountId}), because it will be NFT owner, please import another account to be minted as NFT.`;
  const mintAccountBalanceCheckResult = (
    currentBalance: number,
    requiredBalance: number
  ) => {
    if (currentBalance >= requiredBalance) {
      return null;
    }

    const lackOfBalance =
      Math.ceil((requiredBalance - currentBalance) * 10000 + 1) / 10000;

    const sendNearToMintAccount = async () => {
      await selector.send(
        MultiTransaction.batch(importMintAccountId).transfer(
          Amount.parse(lackOfBalance, "NEAR")
        )
      );
    };

    const errorInfo =
      "Balance too low! \n" +
      `Account balance: ${currentBalance} NEAR \n` +
      `Required balance: ${requiredBalance} NEAR`;
    return (
      <div>
        <p className="mt-2 text-red-500 whitespace-pre">{errorInfo}</p>
        <br />
        <p
          onClick={sendNearToMintAccount}
          className="whitespace-nowrap gap-x-1 items-center font-medium text-blue-600 underline cursor-pointer inline-flex"
        >
          <PlusCircle size={17} />{" "}
          {`Transfer ${lackOfBalance} NEAR to ${importMintAccountId}`}
        </p>
        <p
          onClick={checkAccountBalance}
          className="gap-x-1 items-center font-medium text-blue-600 underline cursor-pointer inline-flex"
        >
          <RefreshCcw size={17} /> Refresh now
        </p>
      </div>
    );
  };

  const ImportMintAccountIdContent = () => {
    return (
      <div className="mb-[14px]">
        <div className="flex border items-center rounded-[16px] border-gray-300 bg-white p-4 w-full justify-between mb-4">
          <div className="flex items-center">
            <img
              src={generateNFTMediaSvg(importMintAccountId)}
              width={72}
              className="border border-gray-200 shadow-lg rounded-[8px]"
            />
            <div className="flex flex-col ml-4">
              <span className="text-xl font-bold text-gray-700 break-all">
                {importMintAccountId}
              </span>
              <span className="text-sm text-gray-500">
                Owner: {loginAccountId!}
              </span>
            </div>
          </div>
          <button
            onClick={cancelImportedMintAccount}
            className="p-2"
            data-place="top"
            data-effect="solid"
            data-padding="4px"
          >
            <XCircle className="w-6 h-6 text-blue-500" />
          </button>
        </div>
        {isMintAccountSameAsLoginAccount && (
          <p className="mt-2 text-red-500">
            {mintAccountSameAsLoginAccountErrorMessage}
          </p>
        )}
        {!isMintAccountSameAsLoginAccount &&
          (isCheckingBalance ? (
            <Spin tip={"Checking account balance ..."} />
          ) : (
            mintAccountBalanceCheckResult(currentBalance, requiredBalance)
          ))}
      </div>
    );
  };

  const NoImportMintAccountIdContent = ({
    onClickImport,
  }: {
    onClickImport: () => void;
  }) => {
    return (
      <div className="text-sm text-gray-700 max-w-[340px] flex flex-col gap-y-2 ">
        <p className="">
          You will be asked to give <FullPermissionsDisclosureText /> to mint
          your NEAR account as an NFT.
        </p>
        <p>
          Your access key will <b className="text-gray-600">never</b> be
          uploaded and will be deleted after minting.
        </p>
        <p className="">
          It's a requirement that there is <CostDisclosureText /> in the
          imported NEAR account balance for gas and storage fee.{" "}
        </p>
        <p>
          Have a question?{" "}
          <a className="text-blue-500 underline cursor-pointer" href="/#how">
            See how it works.
          </a>
        </p>
        <div className="mt-4">
          <PrimaryButton onClick={onClickImport}>
            Choose NEAR Account
          </PrimaryButton>
        </div>
      </div>
    );
  };
  return (
    <div>
      <AccountImportModal
        isOpen={isWalletsModalShow}
        onRequestClose={() => setIsWalletsModalShow(false)}
        handleAccountImported={handleAccountImported}
      />
      {importMintAccountId ? (
        <ImportMintAccountIdContent />
      ) : (
        <NoImportMintAccountIdContent
          onClickImport={() => {
            setIsWalletsModalShow(true);
          }}
        />
      )}
    </div>
  );
};

const step2Content = ({
  isMinting,
  importMintAccountId,
  mintError,
  handleClickMint,
  isReady,
  setIsReady,
}: {
  isMinting: boolean;
  importMintAccountId: AccountId;
  mintError: string;
  handleClickMint: () => void;
  isReady: boolean;
  setIsReady: (isReady: boolean) => void;
}) => {
  return (
    <div className="flex flex-col gap-y-2">
      <Checkbox checked={isReady} onChange={setIsReady}>
        I am ready to mint NEAR account{" "}
        <a
          target={"_blank"}
          className="italic font-semibold text-gray-500 underline"
          href={`${config.NEAR_WALLET_URL}/profile/${importMintAccountId}`}
          rel="noreferrer"
        >
          {importMintAccountId}
        </a>{" "}
        as an NFT.
      </Checkbox>
      <div className="mt-5">
        <PrimaryButton
          disabled={!isReady || isMinting}
          onClick={handleClickMint}
        >
          {mintError ? "Retry Mint" : "Complete Mint"}
        </PrimaryButton>
        {mintError && <p className="mt-2 text-red-500">{mintError}</p>}
      </div>
    </div>
  );
};
