import React, { useMemo } from "react";
import { PrimaryButton, SecondaryButton } from "~components/button/Button";
import { ClaimSuccessModal } from "~pages/claim/claim-success-modal";
import AccountActivities from "./account-activities";
import ResponsiveContainer from "~components/layout/responsive-container";
import { Tab } from "@headlessui/react";
import NFTs from "./nfts";
import { Redirect, useParams } from "react-router-dom";
import Avatar from "react-avatar";
import UnfinishedNFT from "./unfinished-list";
import ClaimedAccountList from "./claimed-account-list";
import Offers from "./offers";
import useUrlState from "@ahooksjs/use-url-state";
import LikedNFTList from "./liked/liked-list";
import { useWalletSelector } from "~context/namesky";
import { useNFTMintNumQuery } from "~domain/nnn/nft_contract/queries";
import skyToken from "~assets/sky-token.svg";
import { Button, Spin, Tooltip } from "antd";
import { Lock } from "react-feather";

const AccountPage = () => {
  const { account_id } = useParams<{ account_id: string }>();
  const { namesky, selector } = useWalletSelector();
  const { data: mintNum } = useNFTMintNumQuery(account_id);
  const isSignedIn = useMemo(() => {
    if (!selector) return undefined;
    return selector.isSignedIn();
  }, [selector]);
  const isMyAccount = useMemo(() => {
    if (!selector) return undefined;
    return isSignedIn && account_id === selector.getActiveAccount()?.accountId;
  }, [account_id, selector]);
  const [urlState, setUrlState] = useUrlState({ tab: "nfts" });

  if (isSignedIn && !account_id) {
    return (
      <Redirect to={"/account/" + selector.getActiveAccount()?.accountId} />
    );
  }

  const categories = [
    {
      key: "nfts",
      title: "NFTs",
    },
    ...(isMyAccount
      ? [
          {
            key: "redeemed",
            title: "Redeemed Accounts",
          },
          {
            key: "Liked",
            title: "Liked",
          },
        ]
      : []),
    {
      key: "offers",
      title: "Offers Made",
    },
    {
      key: "activities",
      title: "Activities",
    },
  ];

  const selectedTabIndex = categories.findIndex((c) => c.key === urlState?.tab);
  return (
    <ResponsiveContainer>
      <div className="mt-[40px]">
        {isMyAccount && <UnfinishedNFT account_id={account_id} />}
        <div className="py-5 flex justify-between items-center flex-wrap gap-y-4">
          <div className="flex items-center">
            <Avatar name={account_id} round size="60" textSizeRatio={2} />
            <div className="ml-2 flex flex-col items-left">
              <h1 className="text-2xl font-bold gap-x-3">{account_id}</h1>
              <div className="flex items-center md:text-lg text-gray-500">
                SKY Balance:{" "}
                {mintNum ? (
                  <div className="ml-2 flex">
                    {Number(mintNum) * 40}
                    <img className="ml-1" src={skyToken} width={"20px"} />
                  </div>
                ) : (
                  <Spin />
                )}
                <Tooltip title="The SKY Tokens acquired during the event can only be claimed after the event end.">
                  <Button
                    size="small"
                    className="ml-4"
                    disabled
                    onMouseOver={(e) => e.stopPropagation()}
                  >
                    <div className="flex items-center gap-2">
                      <Lock size={15} /> Claim
                    </div>
                  </Button>
                </Tooltip>
              </div>
            </div>
          </div>

          <div>
            {isSignedIn && (
              <SecondaryButton
                onClick={async () => {
                  await (await selector.wallet()).signOut();
                  window.location.replace("/");
                }}
              >
                Sign Out
              </SecondaryButton>
            )}
          </div>
        </div>
        <ClaimSuccessModal />
        <Tab.Group
          selectedIndex={selectedTabIndex}
          onChange={(index) => {
            setUrlState({ tab: categories[index].key });
          }}
        >
          <Tab.List className="flex p-1 rounded-lg overflow-x-auto">
            {categories.map((category) => {
              if (category.key === "redeemed" && !isMyAccount) {
                return null;
              }
              return (
                <Tab
                  key={category.key}
                  className={({ selected }) =>
                    `${
                      selected
                        ? "text-gray-900 border-gray-900"
                        : "text-gray-500"
                    }
                    whitespace-nowrap outline-none font-semibold w-full py-4 px-4 text-center border-b-2 text-sm cursor-pointer`
                  }
                >
                  {category.title}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="mt-8">
              <NFTs account_id={account_id} isMyAccount={isMyAccount} />
            </Tab.Panel>
            {isMyAccount && (
              <>
                <Tab.Panel className="mt-8">
                  <ClaimedAccountList nfts={[]} />
                </Tab.Panel>
                <Tab.Panel className="mt-8">
                  <LikedNFTList account_id={account_id} />
                </Tab.Panel>
              </>
            )}
            <Tab.Panel className="mt-8">
              <Offers account_id={account_id} />
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-8">
                <AccountActivities
                  account_id={account_id}
                  hideActionList={[
                    "nft_unregister_action",
                    "read_notification_action",
                    "like_nft_action",
                    "unlike_nft_action",
                    "watch_nft_action",
                    "unwatch_nft_action",
                  ]}
                />
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="flex flex-col mt-8 lg:flex-row lg:gap-x-6"></div>
    </ResponsiveContainer>
  );
};

export default AccountPage;
