import React, { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { History } from "history";
import LogoFull from "~components/icons/logo-full";
import SearchBar from "~components/search/search-bar";
import profileIcon from "~assets/profile.svg";
import shopIcon from "~assets/shop.svg";
import { ResponsiveContainerLargeSize } from "./responsive-container";
import useUrlState from "@ahooksjs/use-url-state";
import { useWalletSelector } from "~context/namesky";
import { Notification } from "../../../.graphclient";
import { Bell, User, Zap } from "react-feather";
import { Button, Divider, Drawer, Popover, Space } from "antd";
import { notification } from "antd";
import telegramLogoImg from "~assets/telegram-logo.png";
import rocketBlue from "~assets/rocket-blue.svg";
import skyToken from "~assets/sky-token.svg";

import { useNotificationsQuery } from "~components/notification/queries";
import NotificationHead from "~components/notification/NotificationHead";
import NotificationList from "~components/notification/NotificationList";

import "./SiteHeader.css";
import { config } from "~domain/near/global";
import { AccountId } from "~domain/near/types";
import { MultiSendWalletSelector, NameSky } from "namesky-sdk";
import { useNFTMintNumQuery } from "~domain/nnn/nft_contract/queries";
import { Near } from "near-api-js";

const ItemContainer = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div
      {...(onClick ? { onClick } : {})}
      // className="bg-white cursor-pointer shrink-0 min-w-[56px] px-3 h-[56px] rounded-[26px] border border-gray-300 flex justify-center items-center"
      className="bg-white cursor-pointer shrink-0 min-w-[42px] md:min-w-[56px] min-h-[42px] md:min-h-[56px] rounded-[26px] border border-gray-300 flex flex-col justify-center items-center"
    >
      {children}
    </div>
  );
};

function AccountEntry() {
  const { namesky, selector, modal } = useWalletSelector();
  const { data: mintNum } = useNFTMintNumQuery(
    selector?.getActiveAccount()?.accountId
  );

  if (!namesky || !selector) {
    return <div />;
  }

  const activeAccountId = selector.getActiveAccount()?.accountId;

  if (!activeAccountId) {
    return (
      <ItemContainer
        onClick={() => {
          modal.show();
        }}
      >
        <div className="flex items-center px-4 text-sm font-bold text-blue-600 gap-x-2">
          Connect Wallet
        </div>
      </ItemContainer>
    );
  }
  const [account, network] = activeAccountId.split(".");
  const niceAccountId = `${account.slice(0, 10)}...${network || ""}`;
  const accountName = account.length > 10 ? niceAccountId : activeAccountId;

  return (
    <Link to="/account">
      <ItemContainer>
        <div className="flex items-center gap-x-2 p-2">
          <img src={profileIcon} className="shrink-1 size-6" />
          <div className="hidden lg:flex flex-col justify-start items-start">
            <span className="text-blue-600 font-semibold">{accountName}</span>
            {false && mintNum && (
              <div className="flex items-center">
                <span className="hidden lg:inline-block text-gray-400 font-mono text-xs">
                  SKY Balance: {Number(mintNum) * 40}
                </span>
                <img src={skyToken} className="w-4 h-4 ml-1" />
              </div>
            )}
          </div>
        </div>
      </ItemContainer>
    </Link>
  );
}

const Header = () => {
  const [urlState, setUrlState] = useUrlState({ notifications: "false" });
  const { namesky, selector, near } = useWalletSelector();

  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    if (selector) {
      setIsSignedIn(selector.isSignedIn());
    }
  }, [selector]);

  const loginAccountId = useMemo(() => {
    if (!selector) return undefined;
    return selector.getActiveAccount()?.accountId;
  }, [selector]);

  const history = useHistory();

  useEffect(() => {
    if (namesky && selector && loginAccountId && near) {
      checkAccountActiveAndNotice(loginAccountId, selector);
      checkAccountNftMintUnfinishedAndNotice(
        loginAccountId,
        namesky,
        near,
        history
      );
    }
  }, [loginAccountId, namesky, selector]);

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useNotificationsQuery({ user_id: loginAccountId, limit: 50 });

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [lastReadTime, setLastReadTime] = useState<number>(0);

  const unreadNotificationCount = useMemo(() => {
    if (!notifications) {
      return 0;
    }
    return notifications.reduce(
      (unreadCount, notification) =>
        unreadCount + (notification["timestamp"] >= lastReadTime ? 1 : 0),
      0
    );
  }, [notifications, lastReadTime]);

  useEffect(() => {
    if (data && data.pages && data.pages.length > 0) {
      setNotifications(
        data.pages.flatMap((e) => [...e.notifications]) as Notification[]
      );
      setLastReadTime(
        (data.pages[0]?.user?.last_read_notification_time as number) ?? 0
      );
    }
  }, [data]);

  const markAllAsRead = () => {
    setLastReadTime(new Date().getTime() * 1000000);
    setUrlState({ notifications: false });
    namesky.userSettingContract.readNotificationAt({}).catch((e) => {
      notification.error({ message: "Mark All As Read Failed!" });
    });
    // fetchNextPage();
  };

  return (
    <ResponsiveContainerLargeSize>
      <div className="flex items-center justify-center w-full">
        <Link
          to="/"
          title="NameSky"
          className="flex items-center shrink-0 gap-x-[10px] w-[62px] md:w-[80px]"
        >
          <LogoFull size="100%" />
        </Link>
        <div className="flex-grow ml-6 mr-4">
          <div className="hidden md:block">
            <SearchBar />
          </div>
        </div>
        <div className="circleBox flex gap-x-2 md:gap-x-3">
          <Link to="/mint">
            <ItemContainer>
              <Zap className="text-blue-700" />
              {/*<img width={"20px"} className="" src={rocketBlue} />*/}
            </ItemContainer>
          </Link>
          <Link to="/marketplace">
            <ItemContainer>
              <img src={shopIcon} />
            </ItemContainer>
          </Link>
          {isSignedIn && (
            <span
              onClick={() => {
                setUrlState({ notifications: true });
              }}
            >
              <ItemContainer>
                <div className="relative">
                  <Bell className="text-blue-700" />
                  {unreadNotificationCount > 0 && (
                    <span className="unread-count-in-bell">
                      {unreadNotificationCount}
                    </span>
                  )}
                </div>
              </ItemContainer>
            </span>
          )}
          <AccountEntry />
        </div>
      </div>
      <Drawer
        size="large"
        closable={false}
        open={urlState.notifications === "true"}
        onClose={() => setUrlState({ notifications: "false" })}
      >
        <NotificationHead
          unreadCount={unreadNotificationCount}
          markAllAsRead={markAllAsRead}
        />
        <Divider />
        <NotificationList
          notifications={notifications}
          last_read_time={lastReadTime}
          loadMoreData={fetchNextPage}
          hasMore={hasNextPage}
          isLoading={isLoading || isFetching || isFetching}
        />
      </Drawer>
    </ResponsiveContainerLargeSize>
  );
};

export default Header;

async function checkAccountNftMintUnfinishedAndNotice(
  account_id: AccountId,
  namesky: NameSky,
  near: Near,
  history: History<unknown>
) {
  let controller_code_hash_list: string[] = (
    await namesky.coreContract.getControllerCodeViews()
  ).map((e) => e.code_hash);
  namesky.coreContract
    .nftRegistrantIdsOf({
      minterId: account_id,
    })
    .then(async (ids) => {
      for (let i = 0; i < ids.length; i++) {
        let id = ids[i];
        let key = `open${Date.now()}-${id}`;

        console.log("near", near);
        let account_code_hash = (await (await near.account(id)).state())
          .code_hash;
        let is_deploy_controller_code =
          controller_code_hash_list.includes(account_code_hash);

        notification.warning({
          key,
          duration: 0,
          message: `Your account ${id} haven't finished progress of minting NFT .\nYou can continue minting or take back this account. `,
          btn: (
            <div className="flex flex-col w-[300px]">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  history.push(`/mint?is_mint_import=true&account_id=${id}`);
                  notification.destroy(key);
                }}
              >
                Continue Mint
              </button>
              {is_deploy_controller_code && (
                <button
                  className={"text-blue-500 font-bold py-2 px-4 rounded mt-1"}
                  onClick={() => {
                    history.push(`/redeem/${id}`);
                    notification.destroy(key);
                  }}
                >
                  Take back NEAR account
                </button>
              )}
              <div className="border-t border-gray-300 my-2" />
              <div className="flex flex-row justify-center">
                <a
                  style={{ textDecoration: "underline" }}
                  href={config.TELEGRAM_GROUP_URL}
                  target="_blank"
                >
                  Ask for help in telegram!
                </a>
                <img
                  className="ml-2"
                  width="20px"
                  src={telegramLogoImg}
                  alt="Image"
                />
              </div>
            </div>
          ),
        });
      }
    });
}

function checkAccountActiveAndNotice(
  account_id: AccountId,
  selector: MultiSendWalletSelector
) {
  selector
    .isLoginAccessKeyAvailable({ accountId: account_id })
    .then((is_active) => {
      if (!is_active) {
        let key = `open${Date.now()}`;
        notification.warning({
          key: key,
          duration: 0,
          message:
            "Your authorized gas is almost running out. Please sign out and reauthorize to our App again.",
          btn: (
            <div>
              <button
                className={"bg-white-500 font-bold py-2 px-4 rounded"}
                onClick={() => notification.destroy(key)}
              >
                later
              </button>
              <button
                className={
                  "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                }
                onClick={async () => {
                  await (await selector.wallet()).signOut();
                  window.location.replace("/");
                }}
              >
                Sign Out
              </button>
              <div className="flex flex-row mt-4">
                <a
                  style={{ textDecoration: "underline" }}
                  href={config.TELEGRAM_GROUP_URL}
                  target="_blank"
                >
                  Ask for help in telegram!
                </a>
                <img
                  className="ml-2"
                  width="20px"
                  src={telegramLogoImg}
                  alt="Image"
                />
              </div>
            </div>
          ),
        });
      }
    });
}
