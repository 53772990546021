import React, { useRef } from "react";
import { Search } from "react-feather";
import { useClickAway } from "ahooks";
import Loading from "react-loading";
import { useNftsQuery, useUsersQuery } from "~domain/nnn/queries";
import { DebounceInput } from "react-debounce-input";
import shopIcon from "~assets/shop.svg";
import {
  helpCompleteAccountIdInput,
  transferToNumberWithCommas,
} from "~utils/util";
import Avatar from "react-avatar";

export default function SearchBar() {
  const [search, setSearch] = React.useState("");
  const [focus, setFocus] = React.useState(false);
  const refInput = useRef(null);
  const refBox = useRef(null);
  const handleSearch = (e: any) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleEnter = (event: any) => {
    if (event.key === "Enter" && search) {
      window.location.href = "/marketplace/?search=" + search;
    }
  };

  const {
    data: nfts,
    isLoading,
    fetchStatus,
  } = useNftsQuery({
    search,
    orderBy: "name_len",
    orderDirection: "asc",
    search_name_length: 64,
    enabled: !!search,
  });

  const { data: users } = useUsersQuery({
    search,
    enabled: !!search,
  });

  useClickAway(() => {
    setFocus(false);
  }, [refBox, refInput]);

  const show = focus && search.length > 0;
  const isFetching = isLoading && fetchStatus === "fetching";

  return (
    <div className="relative group">
      <Search
        size={16}
        className="absolute ml-3 text-gray-300 top-1/2 transform -translate-y-1/2"
        strokeWidth={3}
      />
      <div ref={refInput}>
        <DebounceInput
          debounceTimeout={300}
          value={search}
          onChange={handleSearch}
          onFocus={() => setFocus(true)}
          onKeyDown={handleEnter}
          className="h-[56px] w-full p-2 pl-8 placeholder-gray-500 bg-[rgba(255,255,255,0.7)] border border-2 border-gray-100 rounded-[36px] outline-none transition-all hover:border-gray-200 focus:border-gray-200 focus:bg-white hover:bg-white"
          placeholder="Search accounts"
        />
      </div>
      <div
        ref={refBox}
        className={`absolute z-10 w-full mt-2 bg-white rounded shadow-lg  divide-y divide-gray-100
          ${
            show
              ? "opacity-100 pointer-events-auto"
              : "opacity-0 pointer-events-none"
          }>
        `}
      >
        <ul
          className="py-1 pb-0 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownDefault"
        >
          {search.length > 0 && (
            <>
              {isFetching && (
                <li className="flex px-4 py-4 border-b border-gray-200">
                  <Loading type="bars" color="#666" height={30} width={30} />
                </li>
              )}

              <div className="px-4 py-2 text-gray-500 font-semibold border-b border-gray-200">
                ITEMS
              </div>
              <li>
                <a
                  href={`/${helpCompleteAccountIdInput(search)}`}
                  className="block px-4 py-4 border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                >
                  <span className="text-lg font-semibold text-gray-600">
                    {helpCompleteAccountIdInput(search)}
                  </span>
                </a>
              </li>
              {nfts?.slice(0, 4).map((nft) => (
                <li key={nft.id}>
                  <a
                    href={`/${nft.token_id}`}
                    className="flex flex-col  px-4 py-4 border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    <span className="text-lg font-semibold text-gray-600">
                      {nft.token_id}
                    </span>
                    {nft.listing && (
                      <span className="text-sm text-gray-500">
                        {transferToNumberWithCommas(nft.listing.price)} Ⓝ
                      </span>
                    )}
                  </a>
                </li>
              ))}
              {users?.length > 0 && (
                <>
                  <div className="px-4 py-2 text-gray-500 font-semibold border-b border-gray-200">
                    PROFILES
                  </div>
                  {users?.slice(0, 4).map(({ id }) => (
                    <li key={id}>
                      <a
                        href={`/account/${id}`}
                        className="flex items-center  px-4 py-4 border-b border-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600 gap-x-2"
                      >
                        <Avatar
                          round="4px"
                          name={id}
                          size="20"
                          textSizeRatio={2}
                        />
                        <span className="text-lg font-semibold text-gray-600">
                          {id}
                        </span>
                      </a>
                    </li>
                  ))}
                </>
              )}

              {nfts?.length > 0 && (
                <li>
                  <a
                    href={`/marketplace?search=${search}`}
                    className="text-base flex items-center gap-x-1 text-blue-500 block px-4 py-4 font-semibold text-gray-500 hover:bg-gray-100 dark:hover:bg-gray-600"
                  >
                    <img src={shopIcon} className="opacity-80" width={22} />
                    View all results for "{search}" in marketplace
                  </a>
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </div>
  );
}
