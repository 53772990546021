import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Flex } from "~components/layout/flex";
import Modal from "~components/modal/modal";
import getConfig from "~domain/near/config";
import { useQuery } from "~state/urls";
import hiw3 from "~assets/hiw-3.png";

export const ClaimSuccessModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [accountId, setAccountId] = React.useState("");
  const urlQuery = useQuery();
  const history = useHistory();
  const redirectSourceAction = urlQuery.get("redirect_source_action");
  const redirectSourceId = urlQuery.get("redirect_source_id");

  useEffect(() => {
    if (
      redirectSourceAction &&
      redirectSourceAction === "redeem" &&
      redirectSourceId
    ) {
      setIsOpen(true);
      setAccountId(redirectSourceId);
    }
  }, [urlQuery]);

  const closeModal = () => {
    setIsOpen(false);
    history.replace("/account");
  };

  return (
    <Modal
      isOpen={isOpen}
      title={<div className="text-center">Success!</div>}
      hideCloseButton
      className="max-w-[400px] m-auto"
    >
      <Flex column justifyCenter itemsCenter className="mt-8">
        <img src={hiw3} width="60%" />
        <p className="px-2 mt-6 text-center text-gray-700">
          You have successfully take back NEAR account <b>{redirectSourceId}</b>
          , the NFT has been burned as well. <br />
          <br />
          You can restore your account from NEAR wallet website with the
          recovery phrase.
        </p>
        <div className="mt-4" />
        <span
          className="text-sm font-bold text-blue-500 cursor-pointer"
          onClick={closeModal}
        >
          Back to Account Page
        </span>
      </Flex>
    </Modal>
  );
};
