import React from "react";
import { PrimaryButton } from "~components/button/Button";
import Modal, { ModalProps } from "~components/modal/modal";
import NFTThumbnail from "~components/nft/thumbnail";
import { config } from "~domain/near/global";
import axios from "axios";
import ProgressView from "./mint-progress-view";
import { useHistory } from "react-router-dom";
import CostsBreakdown from "./costs-breakdown";
import * as Sentry from "@sentry/react";
import { useWalletSelector } from "~context/namesky";

interface MintProgressModalProps extends ModalProps {
  onErrorMessage: (message: string) => void;
  mintAccountId: string;
  ownerAccountId: string;
  setStartMinting: () => void;
}

const MintProgressModal = (props: MintProgressModalProps) => {
  const history = useHistory();
  const { mintAccountId, ownerAccountId, onErrorMessage } = props;
  const [step, setStep] = React.useState<"confirm" | "progress" | "success">(
    "confirm"
  );
  const [mintProgress, setMintProgress] = React.useState<0 | 1 | 2>(0);
  const { namesky } = useWalletSelector();

  const handleClickConfirm = async () => {
    props.setStartMinting();
    setStep("progress");

    try {
      console.log("check nft is registered");
      const registerResult = await namesky.coreContract.nftGetMinterId({
        registrantId: mintAccountId,
      });

      console.log("registerResult", registerResult);

      if (!registerResult) {
        console.log("call nft register", registerResult);

        await namesky.register(mintAccountId);
        console.log("finish nft register", registerResult);
      } else {
        console.log("skip register");
      }

      setMintProgress(1);

      // const data = await namesky.coreContract.get_latest_controller_code({});
      // const buf = Buffer.from(data, "base64");

      console.log("getNftAccountSafety");
      let result = await namesky.getNftAccountSafety({
        accountId: mintAccountId,
      });
      console.log("getNftAccountSafety", result);

      await namesky.setupController(mintAccountId);

      setMintProgress(2);
      // const nft_token = await namesky.coreContract.nft_namesky_token({args: {token_id: mintAccountId}})

      await namesky.waitMintCompleted(mintAccountId);
      // console.log("nft_token", nft_token);
      // if(nft_token) {
      //   setStep("confirm");
      //   throw new Error("Mint failed");
      // }
      history.push(
        `/${mintAccountId}?redirect_source_action=mint&redirect_source_id=${mintAccountId}`
      );
      // const response = await axios.get<any>(
      //   `${config.NAMESKY_API_URL}/api/mint`,
      //   {
      //     params: { account_id: mintNearAccount.accountId },
      //   }
      // );
      // if (response?.data?.is_success !== true) {
      //   setStep("confirm");
      //   throw new Error(response?.data?.msg || "Mint failed");
      // }
      // history.push(
      //   `/${mintAccountId}?redirect_source_action=mint&redirect_source_id=${mintAccountId}`
      // );
    } catch (e) {
      console.log("error", e);
      Sentry.captureException(e);
      setStep("confirm");
      onErrorMessage(e.toString());
    }
  };

  let title = "Complete Mint";
  if (step === "success") {
    title = "Success!";
  }

  return (
    <Modal
      {...props}
      title={<div className="text-center">{title}</div>}
      bodyClassName="px-0"
      hideCloseButton={step !== "confirm"}
      shouldCloseOnEsc={false}
    >
      <div className="px-6">
        {step === "confirm" && (
          <>
            <div className="flex  mt-[40px] items-center">
              <NFTThumbnail id={mintAccountId} size={60} />
              <div className="flex flex-col items-start ml-[10px] justify-between h-full">
                <span className="text-sm font-semibold text-gray-500 leading-[20px]">
                  NameSky
                </span>
                <span className="text-base font-semibold text-gray-900 leading-[24px]">
                  {mintAccountId}
                </span>
                <span className="text-xs font-semibold text-gray-500 leading-[18px]">
                  Owner: {ownerAccountId}
                </span>
              </div>
            </div>
            <Line />
            <div className="px-4 py-8">
              <CostsBreakdown />
            </div>
            <PrimaryButton size="large" isFull onClick={handleClickConfirm}>
              Confirm mint
            </PrimaryButton>
          </>
        )}
        {step === "progress" && (
          <ProgressView
            progress={mintProgress}
            mintAccountId={mintAccountId}
            ownerId={ownerAccountId}
          />
        )}
      </div>
    </Modal>
  );
};

export default MintProgressModal;

export const Line = () => {
  return <div className="mt-6 border-t border-gray-200 mx-[-24px]"></div>;
};
