import React, { FC } from "react";
import clsx from "classnames";

interface IVerticalLine {
  className: string;
  active?: boolean;
}

const VerticalLine: FC<IVerticalLine> = ({ className, active }) => {
  return (
    <div className={clsx("min-w-[30px] grid place-items-center", className)}>
      <div
        className={clsx(
          "h-full w-0.5 rounded-full",
          !active ? "bg-blue-200" : "bg-blue-600"
        )}
      />
    </div>
  );
};

export default VerticalLine;
