import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SecondaryButton, TextButton } from "~components/button/Button";
import { Copy, Trash } from "react-feather";
import { Flex } from "~components/layout/flex";
import Modal from "~components/modal/modal";
import Spacer from "~components/spacer";
import { useBoolean, useLocalStorageState } from "ahooks";
import ClaimedAccountDropdown from "./claimed-accounts-dropdown";
import { Token } from "namesky-sdk";

interface Props {
  nfts: Token[];
}

const AccountItem = ({
  id,
  onClickShowRecoveryPhrase,
  onConfirmDeletePhrase,
}: {
  id: string;
  onClickShowRecoveryPhrase: (id: string) => void;
  onConfirmDeletePhrase: (id: string) => void;
}) => {
  const [isDeleteModalOpen, { toggle: toggleDeleteModal }] = useBoolean(false);
  return (
    <>
      <Modal
        isOpen={isDeleteModalOpen}
        onRequestClose={toggleDeleteModal}
        shouldCloseOnOverlayClick
        className="w-[420px] m-auto box-content"
      >
        <div>
          <p className="text-base font-semibold text-center text-gray-800">
            Are you sure you want to remove this passphrase?
          </p>
          <p className="max-w-[300px] m-auto text-sm font-semibold text-center text-gray-600 mt-[10px]">
            It will be removed from your device and can not be restored. If you
            forget this phrase you will no longer be able to access your NEAR
            account.
          </p>
        </div>
        <Spacer h="32px" />
        <div className="flex flex-col gap-y-2">
          <SecondaryButton
            isFull
            onClick={() => {
              onConfirmDeletePhrase(id);
              toggleDeleteModal();
            }}
          >
            <Flex itemsCenter className="text-red-500">
              <Trash size={20} className="" />
              <Spacer w="8px" />
              Remove Recovery Phrase
            </Flex>
          </SecondaryButton>
        </div>
      </Modal>
      <div className="bg-white rounded-[4px] border-[1px] border-gray-300 py-2 px-4 flex items-center justify-between">
        <div className="flex flex-col ">
          <p className="font-bold text-gray-900">{id}</p>
          {/*<MoreHorizontal className="cursor-pointer" /> */}
          <div>
            <TextButton
              className="text-sm"
              onClick={() => {
                onClickShowRecoveryPhrase(id);
              }}
            >
              <div className="font-semibold text-blue-500">
                Show recovery phrase
              </div>
            </TextButton>
          </div>
        </div>
        <ClaimedAccountDropdown
          onClickDeleteRecoveryPhrase={toggleDeleteModal}
        />
      </div>
    </>
  );
};

export default function ClaimedAccountList(props: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedAccountId, setSelectedAccountId] = React.useState("");

  const showRecoveryPhrase = (id: string) => {
    setSelectedAccountId(id);
    setIsOpen(true);
  };

  const [nameskyRecoveryPhrases, setNameskyRecoveryPhrases] =
    useLocalStorageState<{}>("nameskyRecoveryPhrases", {
      defaultValue: {},
    });

  const recoveryPhrase = nameskyRecoveryPhrases[selectedAccountId];
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied === true) {
      setTimeout(() => setCopied(false), 1000);
    }
  }, [copied]);

  const handleConfirmDeletePhrase = (id: string) => {
    const newNnnRecoveryPhrases = { ...nameskyRecoveryPhrases };
    delete newNnnRecoveryPhrases[id];
    setNameskyRecoveryPhrases(newNnnRecoveryPhrases);
  };

  return (
    <div>
      {Object.keys(nameskyRecoveryPhrases).length === 0 && (
        <section className="mb-8 max-h-[500px] overflow-auto">
          <span className="text-gray-500">
            You have not redeemed any accounts yet.
          </span>
        </section>
      )}

      {Object.keys(nameskyRecoveryPhrases).length > 0 && (
        <div className="grid grid-cols-1 gap-y-2">
          <Modal
            hideCloseButton
            isOpen={isOpen}
            onRequestClose={() => {
              setIsOpen(false);
            }}
            shouldCloseOnOverlayClick
            className="w-[420px] m-auto box-content"
          >
            <p className="mt-5">
              <div className="grid grid-cols-3 gap-y-2">
                {nameskyRecoveryPhrases[selectedAccountId]
                  ?.split(" ")
                  .map((word, index) => (
                    <div
                      key={index}
                      className="flex p-2 mr-2 text-sm text-blue-700 bg-blue-100 rounded-lg"
                    >
                      <span className="inline-block shrink-0">{index + 1}</span>
                      <span className="ml-[10px] break-all">{word}</span>
                    </div>
                  ))}
              </div>
              <Spacer h="24px" />
              <CopyToClipboard
                text={recoveryPhrase}
                onCopy={() => setCopied(true)}
              >
                <div className="relative">
                  <SecondaryButton isFull>
                    <Flex itemsCenter>
                      <Copy size={20} className="text-blue-500" />
                      <Spacer w="8px" />
                      Copy
                    </Flex>
                  </SecondaryButton>
                  <div
                    className={`text-[14px] absolute text-green-500 transition-all ${
                      copied ? "opacity-100" : "opacity-0"
                    }`}
                    style={{ top: "-20px", right: "40%" }}
                  >
                    Copied!
                  </div>
                </div>
              </CopyToClipboard>
              <p className="mt-4 mb-1 text-sm font-semibold text-center text-gray-600">
                Write down this recovery phrase immediately and store it in a
                safe place. Losing it will result in the loss of access to your
                account.
              </p>
            </p>
          </Modal>
          {Object.keys(nameskyRecoveryPhrases).map((id) => {
            if (props.nfts.find((nft) => nft.token_id === id)) {
              return null;
            }
            return (
              <AccountItem
                key={id}
                id={id}
                onClickShowRecoveryPhrase={showRecoveryPhrase}
                onConfirmDeletePhrase={handleConfirmDeletePhrase}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
