import React, { useEffect } from "react";
import Modal, { ModalProps } from "~components/modal/modal";
import NFTThumbnail from "~components/nft/thumbnail";
import Spacer from "~components/spacer";
import { Line } from "~pages/mint/mint-modal";
import nearIcon from "~assets/input-suffix-near.svg";
import { PrimaryButton } from "~components/button/Button";
import dayjs from "dayjs";
import { config } from "~domain/near/global";
import { WebWalletIds, useWalletSelector } from "~context/namesky";
import { Amount } from "namesky-sdk";
import { OfferingView } from "namesky-sdk/dist/core/types/data";
import { addQueryParams } from "~utils/util";

interface Props extends ModalProps {
  token_id: string;
  offering?: OfferingView;
}

const durationHoursMap = {
  1: "1 hour",
  3: "3 hours",
  6: "6 hours",
  24: "1 day",
  72: "3 days",
  168: "7 days",
  720: "30 days",
  2160: "90 days",
  4320: "180 days",
};

const OfferModal = (props: Props) => {
  const { token_id, offering } = props;
  const [price, setPrice] = React.useState("");
  const [priceError, setPriceError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [durationError, setDurationError] = React.useState("");
  const [durationType, setDurationType] = React.useState<"select" | "calendar">(
    "select"
  );
  const [calendarDate, setCalendarDate] = React.useState<Date>(
    dayjs().add(1, "hour").toDate()
  );
  const [selectedDuration, setSelectedDuration] = React.useState<number>(168);

  const { namesky, selector } = useWalletSelector();

  useEffect(() => {
    setPrice(offering ? Amount.format(offering.price, "NEAR") : "");
  }, [offering]);

  const handleClickConfirm = async () => {
    if (!price) {
      setPriceError("Price is required");
      return;
    }
    if (parseFloat(price) < 0.01) {
      setPriceError("Price must be greater than 0.01");
      return;
    }

    // let expiredAt: number; // unix timestamp in seconds
    // if (durationType === "select") {
    //   if (!selectedDuration) {
    //     setPriceError("Duration is required");
    //     return;
    //   }
    //   expiredAt = dayjs().add(selectedDuration, "hour").unix();
    // } else if (durationType === "calendar") {
    //   if (!calendarDate) {
    //     setPriceError("Duration is required");
    //     return;
    //   }
    //   expiredAt = dayjs(calendarDate).unix();
    // }

    // const debugMessage = `Offer\nAmount: ${price} \nExpires after: ${dayjs
    //   .unix(expiredAt)
    //   .toDate()}\nOffer is not implemented yet`;
    // console.log(debugMessage);
    // alert(debugMessage);

    setLoading(true);
    if (offering) {
      namesky.marketplaceContract
        .updateOffering({
          tokenId: token_id,
          newPrice: Amount.parse(price, "NEAR"),
        })
        .then((res: any) => {
          if (WebWalletIds.includes(selector.store.getState().selectedWalletId))
            return;
          location.reload();
        })
        .catch((e) => {
          location.reload();
        });
    } else {
      namesky.marketplaceContract
        .createOffering({
          tokenId: token_id,
          price: Amount.parse(price, "NEAR"),
        })
        .then((res: any) => {
          if (WebWalletIds.includes(selector.store.getState().selectedWalletId))
            return;
          location.reload();
        })
        .catch((e) => {
          location.reload();
        });
    }
  };

  const title = offering ? "Update Offer" : "Make Offer";
  const confirmText = offering ? "Update Offer" : "Confirm offer";

  return (
    <Modal
      onAfterClose={() => setLoading(false)}
      {...props}
      title={<div className="text-center">{title}</div>}
    >
      <div className="mx-[-16px] px-6">
        <div className="flex items-center mt-[40px]">
          <NFTThumbnail id={token_id} size={50} />
          <div className="flex flex-col items-start ml-[10px] leading-[100%]">
            <span className="text-xs font-semibold text-gray-500">NameSky</span>
            <span className="text-sm font-semibold text-gray-900">
              {token_id}
            </span>
          </div>
        </div>
        <Line />
        <Spacer h="24px" />
        <label>
          <p className="mb-2 font-semibold text-gray-900">Offer amount</p>
          <div className="relative">
            <input
              placeholder="Amount"
              className="w-full h-[44px] rounded-[8px] border-[1px] border-gray-300 px-4 focus:border-gray-500 focus:outline-none "
              value={price}
              onChange={(e) => {
                if (e.target.value === "") setPrice("");
                if (!/^[0-9]+\.?\d*$/g.test(e.target.value)) return;
                setPriceError("");
                setPrice(e.target.value);
              }}
            />
            <img src={nearIcon} className="absolute right-[10px] top-[10px]" />
          </div>
          {priceError && <p className="mt-2 text-red-500">{priceError}</p>}
        </label>
        <Spacer h="24px" />
      </div>
      <Spacer h="24px" />
      <PrimaryButton
        size="large"
        isFull
        onClick={handleClickConfirm}
        loading={loading}
      >
        {confirmText}
      </PrimaryButton>
    </Modal>
  );
};

export default OfferModal;
