import React from "react";
import { config } from "~domain/near/global";

const CostsBreakdown = ({
  minted_account_id,
}: {
  minted_account_id?: string;
}) => {
  const serviceFee = config.MINT_SERVICE_FEE;
  const storageFee = config.MINT_STORAGE_FEE;
  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="text-sm font-semibold text-gray-400">Item</span>
        <span className="text-sm font-semibold text-gray-400">Amount</span>
      </div>
      <div className="flex items-center justify-between mt-[12px]">
        <span className="text-sm font-semibold text-gray-500">Mint fee</span>
        <span className="text-sm font-semibold text-gray-500">
          {serviceFee} NEAR
        </span>
      </div>
      <div className="flex items-start justify-between mt-2">
        <p className="text-sm font-semibold text-gray-500 max-w-[70%]">
          Storage fee <br />
          <span className="text-sm font-normal text-gray-400">
            This {storageFee} NEAR will be kept in the minted NEAR account
            {minted_account_id && ` ${minted_account_id}`} and it is not the
            cost of the minting.
          </span>
          <br />
          <a
            className="font-normal text-sm text-blue-500 cursor-pointer"
            href="https://docs.near.org/concepts/storage/storage-staking#"
            target="_blank"
          >
            Learn more about NEAR storage
          </a>
        </p>
        <span className="text-sm font-semibold text-gray-500">
          {config.MINT_STORAGE_FEE} NEAR
        </span>
      </div>
    </div>
  );
};

export default CostsBreakdown;
