import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MoreHorizontal, Trash } from "react-feather";

export default function ClaimedAccountDropdown({
  onClickDeleteRecoveryPhrase,
}: {
  onClickDeleteRecoveryPhrase: () => void;
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="w-[48px] h-[48px] flex justify-center items-center text-gray-500 hover:text-gray-900">
          <MoreHorizontal size={28} />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 bg-white shadow-lg origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item onClick={onClickDeleteRecoveryPhrase}>
              <div className="cursor-pointer hover:bg-gray-100">
                <div className="flex items-center px-4 py-2 text-sm text-gray-900 whitespace-nowrap h-[48px]">
                  <Trash size={24} className="mr-2" />
                  <div className="font-bold dib">
                    Remove local recovery phrase
                  </div>
                </div>
              </div>
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
