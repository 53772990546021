import React from "react";
import NFTThumbnail from "~components/nft/thumbnail";
import { Line } from "./mint-modal";
import ReactLoading from "react-loading";
import { Check } from "react-feather";

interface MintProgressViewProps {
  progress: number;
  mintAccountId: string;
  ownerId: string;
}

const ProgressItem = (props: {
  title: string;
  progress: number;
  index: number;
  description?: string;
}) => {
  const { title, progress, index, description } = props;
  return (
    <div className="flex items-start">
      {progress === index && (
        <div className="relative ">
          <ReactLoading type="spin" color="#22C55E" width={36} height={36} />
          <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-sm font-bold">
            {index + 1}
          </div>
        </div>
      )}
      {progress < index && (
        <div className="flex items-center justify-center w-[36px] h-[36px] text-white bg-gray-300 rounded-full">
          <span className="text-sm font-bold">{index + 1}</span>
        </div>
      )}
      {progress > index && (
        <div className="flex items-center justify-center w-[36px] h-[36px] text-white bg-green-500 rounded-full">
          <Check className="absolute" size={16} strokeWidth={4} />
        </div>
      )}
      <div className="ml-3">
        <div className="flex h-[36px] items-center ">
          <span className="text-sm font-bold leading-5">{title}</span>
        </div>
        {progress === index && (
          <p className="text-sm text-gray-500 leading-5">{description}</p>
        )}
      </div>
    </div>
  );
};

const ProgressView = (props: MintProgressViewProps) => {
  const { progress, mintAccountId, ownerId } = props;
  return (
    <div>
      <div className="flex items-center mt-[40px]">
        <NFTThumbnail id={mintAccountId} size={60} />
        <div className="flex flex-col items-start ml-[10px] leading-[100%]">
          <span className="text-xs font-semibold text-gray-500">NameSky</span>
          <span className="text-sm font-semibold text-gray-900">
            {mintAccountId}
          </span>
          <span className="text-xs font-semibold text-gray-500">
            Owner: {ownerId}
          </span>
        </div>
      </div>
      <Line />
      <div className="mt-6 mb-4 grid grid-cols-1 gap-y-4">
        <ProgressItem
          title="Register"
          description={`Save ownership in contract, so ${ownerId} can take ${mintAccountId} back even if mint failed.`}
          progress={progress}
          index={0}
        />
        <ProgressItem
          title="Setup Controller"
          description={`Deploy controller wasm in ${mintAccountId} and Deleting all access keys of ${mintAccountId} to make sure only the controller contract have the permission to modify the account after minting.`}
          progress={progress}
          index={1}
        />
        <ProgressItem title="Confirm minting" progress={progress} index={2} />
      </div>
    </div>
  );
};

export default ProgressView;
