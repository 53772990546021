import React from "react";
import { Link } from "react-router-dom";
import {
  useNFTListingQuery,
  useNFTOffersQuery,
} from "~domain/nnn/market/queries";
import LoadingSkeleton from "react-loading-skeleton";
import { useFormattedPriceText } from "~hooks/useNearFiatUSDText";
import nftBackgroundImg from "~assets/nft-background.png";
import NFTActionsDropdown from "./nft-actions-dropdown";
import { NameSkyToken } from "namesky-sdk/dist/core/types/data";
import { generateNFTMediaSvg } from "~utils/util";

const NFTItem = ({
  nft,
  hideActionList,
}: {
  nft: NameSkyToken;
  hideActionList: boolean;
}) => {
  let { data: listingData, isLoading: isLoadingListing } = useNFTListingQuery(
    nft.token_id
  );

  if (listingData && listingData.seller_id !== nft.owner_id) listingData = null;

  const { nearPriceFullText, fiatPriceFullText, isLoadingFiatPrice } =
    useFormattedPriceText(listingData?.price);

  const { data: offers = [] } = useNFTOffersQuery(nft.token_id);

  const highestOffer = offers.reduce((prev, current) => {
    return Number(prev?.price) > Number(current?.price) ? prev : current;
  }, offers[0]);

  const {
    nearPriceFullText: offerNearPrice,
    fiatPriceFullText: offerFiatPrice,
  } = useFormattedPriceText(highestOffer?.price);

  const isLoading = isLoadingFiatPrice || isLoadingListing;

  console.log(nft);

  return (
    <Link to={`/${nft.token_id}`}>
      <div className="hover:shadow-md bg-white rounded-[4px] border-[1px] border-gray-300 py-3 px-4 flex justify-between items-center">
        <div className="flex items-center">
          <div
            style={{
              backgroundImage: `url(${generateNFTMediaSvg(nft.token_id)})`,
            }}
            className="mr-4 rounded-sm w-14 h-14 bg-cover"
          />
          <div className="flex flex-col font-bold text-gray-800">
            {nft.token_id}
            <div className="text-sm font-[500] text-gray-500">
              {isLoading ? (
                <LoadingSkeleton />
              ) : listingData?.price ? (
                <div>
                  LISTING PRICE: {nearPriceFullText} ({fiatPriceFullText})
                </div>
              ) : (
                <div className="">NOT LISTED</div>
              )}
              {highestOffer && (
                <div className="text-blue-500">
                  BEST OFFER: {offerNearPrice} ({offerFiatPrice})
                </div>
              )}
            </div>
          </div>
        </div>
        {/*<MoreHorizontal className="cursor-pointer" /> */}
        {!hideActionList && <NFTActionsDropdown token_id={nft.token_id} />}
      </div>
    </Link>
  );
};

type NFTListProps = {
  nfts: NameSkyToken[];
  isMyAccount: boolean;
};

const NFTList = (props: NFTListProps) => {
  const { nfts, isMyAccount } = props;
  return (
    <div className="grid grid-cols-1 gap-y-2">
      {nfts.map((nft) => {
        return (
          <NFTItem nft={nft} key={nft.token_id} hideActionList={!isMyAccount} />
        );
      })}
    </div>
  );
};

export default NFTList;
