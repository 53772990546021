import React from "react";

const InvalidateAccount = (props: { nft_token_id: string }) => {
  return (
    <div className="flex items-center justify-center h-full">
      <div className="text-2xl font-bold text-gray-900">
        Account {props.nft_token_id} is consider not safe for trading.
      </div>
    </div>
  );
};

export default InvalidateAccount;
