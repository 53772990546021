import React, { useMemo } from "react";
import "reflect-metadata";
import "es6-shim";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Modal from "react-modal";
import SiteHeader from "~components/layout/SiteHeader";
import HomePage from "~pages/homepage";
import MintPage from "~pages/mint/mint";
import RedeemPage from "~pages/claim/claim";
import DetailPage from "~pages/details";
import AccountPage from "~pages/account";
import MarketPage from "~pages/market";
import RewardPage from "~pages/token-reward";
import USPEPage from "~pages/2024-uspe";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import Footer from "~pages/homepage/footer";
import { WalletSelectorContextProvider } from "~context/namesky";
import { Button, notification } from "antd";
import { useLocalStorageState } from "ahooks";
import { isEmpty } from "lodash";
import { PrimaryButton } from "~components/button/Button";
// import  from "~assets/rocket.png"
import rocketBlue from "~assets/rocket-blue.svg";
import { Gift } from "react-feather";
import headshot from "~/assets/trump-harris-poll.png";

const Context = React.createContext({ name: "Default" });

Modal.defaultStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(17, 24, 39, 0.8)",
    backdropFilter: "blur(4px)",
    zIndex: 30,
  },
  content: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -65%)",
  },
};

Modal.setAppElement("#root");

function App() {
  const [api, contextHolder] = notification.useNotification();

  const isBetaUrl = window.location.href.includes("beta.namesky.app");
  const isProductionUrl = window.location.href.includes("https://namesky.app");

  const [nameskyRecoveryPhrases] = useLocalStorageState<{}>(
    "nameskyRecoveryPhrases",
    {
      defaultValue: {},
    }
  );

  if (isBetaUrl && isEmpty(nameskyRecoveryPhrases)) {
    // Get the current path from the URL
    var currentPath = window.location.pathname;
    // Construct the new URL with the current path
    var newUrl = "https://namesky.app" + currentPath;
    // Redirect to the new URL
    window.location.href = newUrl;
  }

  const contextValue = useMemo(() => ({ name: "Ant Design" }), []);
  return (
    <Context.Provider value={contextValue}>
      <WalletSelectorContextProvider>
        <QueryClientProvider
          client={
            new QueryClient({
              defaultOptions: {
                queries: {
                  refetchOnWindowFocus: false,
                },
              },
            })
          }
        >
          {contextHolder}
          <Router>
            <div className="hidden p-2 text-lg bg-yellow-500 text-[#1d4289] font-medium text-center">
              ⚠️ Scheduled Maintenance Notice: Due to The Graph's maintenance on
              November 6, 2023, at 08:00 UTC, , NameSky's searching and static
              indexing will be temporarily affected for up to 30-60 minutes.
              Core features like "Mint" and "Take back account" will remain
              operational. We apologize for any inconvenience.
            </div>
            <Switch>
              <Route path="/sky-reward" component={RewardPage} />
              <Route path="/2024-potus" component={USPEPage} />
              <Route>
                {false && (
                  <div className="flex flex-wrap flex-col sm:flex-row justify-center items-center text-center bg-blue-500 text-white py-2 px-4">
                    <img src={headshot} width={80} className="mb-2 mr-2" />
                    <p className="text-[18px]">
                      Predict the 2024 Presidential Election and Win 10 NEAR!
                    </p>
                    <a href="/2024-potus">
                      <PrimaryButton
                        size="normal"
                        color="white"
                        className="!text-blue-600 ml-4"
                        prefixIcon={
                          <div className="flex">
                            <Gift
                              size={20}
                              className="hidden sm:block mr-1"
                              color="#3182CE"
                            />
                            Vote & Win 10 NEAR!
                          </div>
                        }
                      ></PrimaryButton>
                    </a>
                  </div>
                )}
                <div className="pt-8 pb-8">
                  <SiteHeader />
                </div>
                <Switch>
                  <Route path="/mint" component={MintPage} />
                  <Route path="/redeem/:id" component={RedeemPage} />
                  <Route path="/marketplace" component={MarketPage} />
                  <Route path="/account/:account_id" component={AccountPage} />
                  <Route path="/account" component={AccountPage} />
                  <Route path="/detail/:id" component={DetailPage} />
                  <Route path="/:id" component={DetailPage} />
                  <Route path="/" component={HomePage} />
                </Switch>
                {/* footer */}
                <div className="max-w-[600px] lg:max-w-[1024px] m-auto px-4 mt-12 border-gray-300 border-t-[1px] py-[24px]">
                  <Footer />
                </div>
              </Route>
            </Switch>
          </Router>
        </QueryClientProvider>
      </WalletSelectorContextProvider>
    </Context.Provider>
  );
}

export default App;
