import { useQuery } from "@tanstack/react-query";
import { Token } from "namesky-sdk";
import { useWalletSelector } from "~context/namesky";
import { config } from "~domain/near/global";
import { useMemo } from "react";

export const useNFTMintNumQuery = (account_id: string | undefined) => {
  const { namesky } = useWalletSelector();
  return useQuery(
    ["nft_mint_num", account_id],
    () => namesky.coreContract.getMintNum({ accountId: account_id }),
    {
      enabled: !!namesky && !!account_id,
    }
  );
};

export const useNFTQuery = (token_id: string) => {
  const { namesky } = useWalletSelector();
  return useQuery(
    ["nft", token_id],
    () => namesky.coreContract.nftNameSkyToken({ tokenId: token_id }),
    {
      enabled: !!namesky,
    }
  );
};

export const useNFTVerifyQuery = (token_id: string) => {
  const { namesky } = useWalletSelector();

  const {
    data: safety,
    isLoading: isSafetyLoading,
    error,
  } = useQuery(
    ["nft_safety", token_id],
    () => namesky.getNftAccountSafety({ accountId: token_id }),
    {
      enabled: !!token_id && !!namesky,
    }
  );

  const isSafe =
    safety &&
    safety.isAccessKeysDeleted &&
    safety.isCodeHashCorrect &&
    safety.isControllerOwnerIdCorrect &&
    safety.isStateCleaned;

  return { isSafe, isLoading: isSafetyLoading, data: { safety }, error };
};

export const useRegistryListQuery = (owner: string) => {
  const { namesky } = useWalletSelector();
  return useQuery(
    ["registry_list", owner],
    () =>
      namesky.coreContract.nftRegistrantIdsOf({
        minterId: owner,
      }),
    { enabled: !!namesky }
  );
};

export const useRegistryQuery = (token_id: string) => {
  const { namesky } = useWalletSelector();

  return useQuery(
    ["registry", token_id],
    () =>
      namesky.coreContract.nftGetMinterId({
        registrantId: token_id,
      }),
    { enabled: !!namesky }
  );
};
