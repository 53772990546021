import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { Account } from "near-api-js";
import {
  getBuiltGraphSDK,
  GetMarketSummariesQuery,
  GetNftsQuery,
  Nft_orderBy,
  OrderDirection,
  GetUserActionsByUserIdQueryVariables,
  GetUserActionsByTokenIdQueryVariables,
  GetUserActionsByUserIdQuery,
  GetUserActionsByTokenIdQuery,
} from "~/../.graphclient";
import { useWalletSelector } from "~context/namesky";
import { Amount } from "namesky-sdk";
import { convertToSearchName } from "~utils/util";
import { createClient } from "urql";
import { cacheExchange, fetchExchange } from "@urql/core";
import { config } from "~domain/near/global";
import { render } from "mustache";

const APIURL = config.GRAPH_API_URL;

const client = createClient({
  url: APIURL,
  exchanges: [cacheExchange, fetchExchange],
});

const {
  GetUserActionsByUserId,
  GetUserActionsByTokenId,
  GetMarketSummaries,
  GetNfts,
  GetNftsOrderByPrice,
  GetUsers,
} = getBuiltGraphSDK();

export const useUserActivitiesQuery = (
  params:
    | GetUserActionsByTokenIdQueryVariables
    | GetUserActionsByUserIdQueryVariables
) => {
  const { user_id = "", token_id = "", last_timestamp } = params;

  const queryFn = user_id ? GetUserActionsByUserId : GetUserActionsByTokenId;

  return useInfiniteQuery({
    queryKey: ["user_actions", user_id, token_id, last_timestamp],
    queryFn: async ({ pageParam }) => {
      const res = await queryFn({
        user_id,
        token_id,
        last_timestamp,
        ...pageParam,
      });

      return res;
    },
    select: (data) => {
      return data.pages.reduce(
        (
          acc:
            | GetUserActionsByUserIdQuery["userActions"]
            | GetUserActionsByTokenIdQuery["userActions"],
          current
        ) => {
          return acc.concat(current.userActions);
        },
        []
      ) as any;
    },
    getNextPageParam: (
      lastPage: GetUserActionsByUserIdQuery | GetUserActionsByTokenIdQuery
    ) => {
      if (lastPage.userActions?.length < 10) return undefined;
      const lastItem =
        lastPage?.userActions?.[lastPage.userActions?.length - 1];
      if (lastItem) {
        return {
          last_timestamp: lastItem?.timestamp,
        };
      }
      return undefined;
    },
  });
};

export const useMarketSummaryQuery = () => {
  return useQuery(["marketSummary"], async () => await GetMarketSummaries(), {
    select: (data: GetMarketSummariesQuery) => {
      return data.marketSummary;
    },
  });
};

export const useAccountBalanceQuery = (account_id: string) => {
  const { near, selector } = useWalletSelector();
  const {
    data: account,
    isLoading: isLoadingAccount,
  }: { data: Account; error?: any; isLoading: boolean } = useQuery(
    ["nft_token_account", { account_id }],
    async () => near.account(account_id),
    { enabled: !!selector }
  );

  const { data: balanceData, isLoading: isLoadingBalance } = useQuery(
    ["nft_token_account_balance", account_id],
    () => account.getAccountBalance(),
    {
      enabled: !!selector && !!account,
    }
  );
  return {
    data: balanceData ? Amount.format(balanceData.total, "NEAR") : undefined,
    isLoading: isLoadingAccount || isLoadingBalance,
  };
};

export const useNftsQuery = ({
  search = "",
  orderBy,
  orderDirection,
  search_name_length,
  name_level = 64,
  enabled = true,
}: {
  search: string;
  orderBy: Nft_orderBy;
  orderDirection: OrderDirection;
  search_name_length: number;
  name_level?: number;
  enabled?: boolean;
}) => {
  let queryFn = GetNfts;
  if (orderBy === "price") {
    queryFn = GetNftsOrderByPrice;
  }

  search = convertToSearchName(search);

  return useInfiniteQuery({
    queryKey: [
      "nfts",
      search,
      orderBy,
      orderDirection,
      search_name_length,
      name_level,
    ],
    queryFn: async ({ pageParam }) => {
      const res = await queryFn({
        first: 10,
        search,
        orderBy,
        orderDirection,
        search_name_len: search_name_length,
        name_level: name_level,
        ...pageParam,
      });
      return res;
    },
    select: (data) => {
      return data.pages.reduce(
        (acc: GetNftsQuery["nfts"], current: GetNftsQuery) => {
          return acc.concat(current.nfts);
        },
        []
      ) as any;
    },
    getNextPageParam: (lastPage: GetNftsQuery, pages) => {
      if (lastPage.nfts?.length < 10) return undefined;
      const skip = pages.reduce((acc, current) => {
        return acc + current.nfts.length;
      }, 0);
      return { skip };
    },
    enabled,
  });
};

const nftQueryTemplate = `query{ nfts(
    orderBy: {{orderBy}}
    orderDirection: {{orderDirection}}
    first: {{first}}
    skip: {{skip}}
    where: {
      {{search_name_len_lte}}
      {{search_name_len_gte}}
      {{name_level}}
      {{{price_lte}}}
      {{{price_gte}}}
      is_burn: false
      contain_separator: {{contain_separator}}
      {{{search_name_contains_nocase}}}
      {{character_set_in}}
      {{club_in}}
      {{{top_domain_in}}}
      {{price_not_null}}
    }
  ) {
    id
    token_id
    owner_id
    price
    listing {
      id
      price
    }
    last_sale_price
    listing_time
    character_set
    club
    prefix_domain
    suffix_domain
    top_domain
    bottom_domain
  }
}
`;

export const useComplexNftsQuery = ({
  search,
  orderBy,
  orderDirection,
  search_name_len_lte,
  search_name_len_gte,
  character_set = [],
  contain_separator = false,
  club = [],
  price_lte,
  price_gte,
  name_level,
  enabled = true,
  top_domain = [],
}: {
  search?: string;
  orderBy: string;
  orderDirection: OrderDirection;
  search_name_len_lte?: number;
  search_name_len_gte?: number;
  character_set: string[];
  contain_separator: boolean;
  club: string[];
  price_lte?: string;
  price_gte?: string;
  name_level?: number;
  enabled?: boolean;
  top_domain?: [];
}) => {

  const nftQueryParam = {
    orderBy: orderBy,
    orderDirection: orderDirection,
    first: 10,
    skip: 0,
    search_name_len_lte: search_name_len_lte
      ? `search_name_len_lte: ${search_name_len_lte}`
      : "",
    search_name_len_gte: search_name_len_gte
      ? `search_name_len_gte: ${search_name_len_gte}`
      : "",
    search_name_contains_nocase: search
      ? `search_name_contains_nocase: "${convertToSearchName(search)}"`
      : undefined,
    character_set_in:
      character_set.length > 0
        ? `character_set_in: [${character_set}]`
        : undefined,
    contain_separator: contain_separator,
    // top_domain_in: top_domain?.length > 0 ? `top_domain_in: [${top_domain.join(",")}]` : undefined,
    // top_domain: top_domain ? `top_domain: ${top_domain}` : "",
    top_domain_in:
      top_domain?.length > 0
        ? `top_domain_in: [${top_domain.map((item) => `"${item}"`).join(",")}]`
        : undefined,
    club_in: club.length > 0 ? `club_in: [${club}]` : undefined,

    price_not_null:
      price_gte ||
      price_lte ||
      orderBy === "price" ||
      orderBy === "listing_time"
        ? "price_not: null"
        : undefined,
    price_lte: price_lte ? `price_lte: "${price_lte}"` : "",
    price_gte: price_gte ? `price_gte: "${price_gte}"` : "",
    name_level: name_level ? `name_level: ${name_level}` : "",
  };
  const nftQuery = async (pageParam: any) => {
    if (pageParam && pageParam.skip) {
      nftQueryParam.skip = pageParam.skip;
    }
    const queryStr = render(nftQueryTemplate, { ...nftQueryParam });
    return client.query(queryStr, {});
  };

  return useInfiniteQuery({
    queryKey: [
      "nfts",
      search,
      orderBy,
      orderDirection,
      search_name_len_gte,
      search_name_len_lte,
      character_set,
      contain_separator,
      club,
      price_lte,
      price_gte,
      name_level,
      top_domain,
    ],
    queryFn: async ({ pageParam }) => {
      const res = await nftQuery(pageParam);
      console.log("res", res);
      return res;
    },
    select: (data) => {
      return data.pages.reduce((acc: any, current: any) => {
        return acc.concat(current.data.nfts);
      }, []) as any;
    },
    getNextPageParam: (lastPage: any, pages) => {
      if (lastPage.data.nfts?.length < 10) return undefined;
      const skip = pages.reduce((acc, current) => {
        return acc + current.data.nfts.length;
      }, 0);
      return { skip };
    },
    enabled,
  });
};

export const useUsersQuery = ({
  search = "",
  enabled = true,
}: {
  search: string;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: ["users", search],
    queryFn: async () => {
      const res = await GetUsers({
        first: 10,
        skip: 0,
        search,
      });
      return res?.users;
    },
    enabled,
  });
};
