import ReactDOM from "react-dom";
import React from "react";
import App from "./App";
import Wrapper from "./components/wrapper";
import * as Sentry from "@sentry/react";
import "@near-wallet-selector/modal-ui/styles.css";

if (process.env.NODE_ENV === "production") {
  console.log = function () {};
  Sentry.init({
    dsn: "https://786396b97137413f9b6e151eb2173882@o4504257042710528.ingest.sentry.io/4504257046511616",
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  });
}

ReactDOM.render(
  <Wrapper>
    <App />
  </Wrapper>,
  document.querySelector("#root")
);

// new Worker('./worker.ts');
