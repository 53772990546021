import React from "react";
import SiteHeader from "~components/layout/SiteHeader";
import headshot from "~/assets/trump-harris-poll.png";
import headshot1 from "~/assets/trump-harris-poll-1.png";
import headshot2 from "~/assets/trump-harris-poll-2.png";
import headshot3 from "~/assets/trump-harris-poll-3.png";
import { useQuery } from "@tanstack/react-query";
import { config } from "~domain/near/global";
import voteImg from "~assets/vote.png";
import { ResponsiveContainerLargeSize } from "../../components/layout/responsive-container";

let trumpNFT: string,
  trumpNFTId: string,
  harrisNFT: string,
  harrisNFTId: string;

if (process.env.NEAR_ENV === "production") {
  trumpNFT = "https://www.namesky.app/trump4potus.near";
  trumpNFTId = "trump4potus.near";
  harrisNFT = "https://www.namesky.app/harris4potus.near";
  harrisNFTId = "harris4potus.near";
} else {
  trumpNFT = "https://testnet.namesky.app/migo.near";
  trumpNFTId = "migo.testnet";
  harrisNFT = "https://testnet.namesky.app/bc4412024.near";
  harrisNFTId = "bc4412024.testnet";
}

import {
  DetailPageQueryQuery,
  getBuiltGraphSDK,
  Nft,
} from "../../../.graphclient";
import Footer from "~pages/homepage/footer";
const { DetailPageQuery } = getBuiltGraphSDK();

export default function Component() {
  const { data: dataTrump, isLoading: isLoadingDataTrump } =
    useQuery<DetailPageQueryQuery>(["detailPageData", trumpNFTId], () => {
      return DetailPageQuery({
        id: `${config.NAMESKY_CORE_CONTRACT_ID}:${trumpNFTId}`,
      });
    });

  const { data: dataHarris, isLoading: isLoadingDataHarris } =
    useQuery<DetailPageQueryQuery>(["detailPageData", harrisNFTId], () => {
      return DetailPageQuery({
        id: `${config.NAMESKY_CORE_CONTRACT_ID}:${harrisNFTId}`,
      });
    });

  console.log({ dataTrump, dataHarris });
  const trumpVotes = dataTrump?.nft?.liked_sum || 0;
  const harrisVotes = dataHarris?.nft?.liked_sum || 0;

  const trumpPercentage = Math.round(
    (trumpVotes / (trumpVotes + harrisVotes)) * 100
  );
  const harrisPercentage = Math.round(
    (harrisVotes / (trumpVotes + harrisVotes)) * 100
  );
  // Hard code for testing UI
  // const trumpPercentage = 100;
  // const harrisPercentage = 100;

  const isLoading = isLoadingDataTrump || isLoadingDataHarris;
  const voters = [
    ...(dataTrump?.nft?.like_by_users ?? []),
    ...(dataHarris?.nft?.like_by_users ?? []),
  ].reduce((acc, user) => {
    if (
      !acc.some(
        (existingUser) => existingUser.user.account_id === user.user.account_id
      )
    ) {
      acc.push(user);
    }
    return acc;
  }, []);

  return (
    <div>
      <div className="pt-8 pb-8">
        <SiteHeader />
      </div>
      <ResponsiveContainerLargeSize>
        <div className="flex flex-col items-center w-full min-h-screen space-y-8 bg-gray-50 mb-8">
          <section className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-md">
            <div className="flex justify-center">
              <img src={headshot2} alt="Candidates" className="w-full" />
            </div>
            <div className="mt-6">
              <div className="flex items-center justify-between">
                <p className="font-semibold">
                  Donald Trump{" "}
                  <span className="text-gray-500 text-sm">
                    ({trumpVotes} votes)
                  </span>
                </p>
              </div>
              <div className="h-6 bg-gray-200 rounded-md overflow-hidden relative">
                <div
                  className="h-full bg-red-600 flex items-center justify-end transition-all duration-1000 ease-out"
                  style={{ width: isLoading ? "0%" : `${trumpPercentage}%` }}
                >
                  <p className="font-semibold text-white pr-2">
                    {!isLoading && `${trumpPercentage}%`}
                  </p>
                </div>
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <p className="text-sm font-semibold text-gray-600">
                      Loading...
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <p className="font-semibold">
                  Kamala Harris{" "}
                  <span className="text-gray-500 text-sm">
                    ({harrisVotes} votes)
                  </span>
                </p>
              </div>
              <div className="h-6 bg-gray-200 rounded-md overflow-hidden relative">
                <div
                  className="h-full bg-blue-600 flex items-center justify-end transition-all duration-1000 ease-out"
                  style={{ width: isLoading ? "0%" : `${harrisPercentage}%` }}
                >
                  <p className="font-semibold text-white pr-2">
                    {!isLoading && `${harrisPercentage}%`}
                  </p>
                </div>
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center">
                    <p className="text-sm font-semibold text-gray-600">
                      Loading...
                    </p>
                  </div>
                )}
              </div>
            </div>
            <h1 className="text-2xl font-bold mt-9">Vote & Win 10 NEAR!</h1>
            <p className="mt-2 text-gray-700">
              Predict the 2024 Presidential Election on NameSky. Winners
              announced on November 5, 2024.
            </p>
          </section>
          <section className="w-full max-w-3xl bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-bold">Join the Fun!</h2>
            <p className="mt-2 text-gray-700">
              This is a fun little voting game for the NEAR community. Cast your
              vote for your prediction of the final result, and those who guess
              correctly will have a chance to win 10 NEAR!
            </p>
            <div className="mt-6 space-y-4">
              <div className="flex items-start space-x-4">
                <div className="shrink-0 flex items-center justify-center w-8 h-8 text-white bg-red-600 rounded-full">
                  1
                </div>
                <div>
                  <h3 className="font-semibold">Cast Your Vote</h3>
                  <p className="text-gray-700 mb-2">
                    Predict the winner by voting for either Donald Trump or
                    Kamala Harris.
                  </p>
                  <p className="text-gray-700">
                    To vote, click on a candidate's NFT link and like it:
                  </p>
                  <ul className="list-disc list-inside mt-2">
                    <li>
                      <a
                        href={trumpNFT}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        Vote for Trump
                      </a>
                    </li>
                    <li>
                      <a
                        href={harrisNFT}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        Vote for Harris
                      </a>
                    </li>
                  </ul>
                  <div className="mt-4">
                    <img
                      src={voteImg}
                      alt="Vote Instruction"
                      className="w-full rounded-md max-w-[300px]"
                    />
                  </div>
                  <p className="text-gray-700 mt-2">
                    Tip: Like both NFTs to always have a chance to win!
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="shrink-0 flex items-center justify-center w-8 h-8 text-white bg-blue-600 rounded-full">
                  2
                </div>
                <div>
                  <h3 className="font-semibold">Wait for Results</h3>
                  <p className="text-gray-700">
                    Watch the voting status. Final result on{" "}
                    <b>November 5, 2024</b>.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <div className="shrink-0 flex items-center justify-center w-8 h-8 text-white bg-green-600 rounded-full">
                  3
                </div>
                <div>
                  <h3 className="font-semibold">Win Rewards!</h3>
                  <p className="text-gray-700 p-2 rounded">
                    If you guessed the final result correctly, we will{" "}
                    <b>pick 3 people and give each 10 NEAR</b> as a reward!
                  </p>
                  <div className="mt-4 bg-gray-100 p-2 rounded">
                    <p className="text-gray-700 mt-2">
                      These are the voters who will have a chance to win:
                    </p>
                    <div className="flex-wrap flex p-2 gap-2">
                      {voters.map((voter, index) => (
                        <div
                          key={index}
                          className="text-gray-700 bg-blue-100 py-1 px-2 rounded-lg"
                        >
                          {voter?.user?.account_id}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="w-full max-w-3xl rounded-lg">
            <Footer />
          </section>
        </div>
      </ResponsiveContainerLargeSize>
    </div>
  );
}
