import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TextButton } from "~components/button/Button";
import { Flex } from "~components/layout/flex";
import Modal from "~components/modal/modal";
import NFTThumbnail from "~components/nft/thumbnail";
import { useQuery } from "~state/urls";

export const MintSuccessModal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [accountId, setAccountId] = React.useState("");
  const urlQuery = useQuery();
  const history = useHistory();
  const redirectSourceAction = urlQuery.get("redirect_source_action");
  const redirectSourceId = urlQuery.get("redirect_source_id");

  useEffect(() => {
    if (
      redirectSourceAction &&
      redirectSourceAction === "mint" &&
      redirectSourceId
    ) {
      setIsOpen(true);
      setAccountId(redirectSourceId);
    }
  }, [urlQuery]);

  const closeModal = () => {
    setIsOpen(false);
    history.replace(`${redirectSourceId}`);
  };

  return (
    <Modal
      isOpen={isOpen}
      title={<div className="text-center">Success!</div>}
      hideCloseButton
      className="max-w-[400px] m-auto"
    >
      <Flex column justifyCenter itemsCenter className="mt-8">
        <NFTThumbnail id={accountId} size={170} />
        <p className="px-2 mt-6 text-center text-gray-700">
          Congrats, you have minted successfully.
        </p>
        <div className="mt-4" />
        <TextButton
          onClick={closeModal}
          className="text-sm font-bold text-blue-500"
        >
          View NFT
        </TextButton>
      </Flex>
    </Modal>
  );
};
