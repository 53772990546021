import React from "react";
import { generateNFTMediaSvg } from "~utils/util";
import nftCover from "../../assets/nft-background.png";

const NFTThumbnail = ({
  id,
  size = 240,
  className = "",
}: {
  id: string;
  size?: number;
  className?: string;
}) => {
  return (
    <div className={`relative shadow-md ${className}`}>
      <img src={generateNFTMediaSvg(id)} alt="nft cover" width={size} />
    </div>
  );
};

export default NFTThumbnail;
