import React from "react";
import { config } from "~domain/near/global";

const Instruction = () => {
  return (
    <div>
      <p className="text-gray-700 text-base leading-5">
        The full access key on your local machine enables you to mint the NEAR
        account as an NFT.
      </p>
      <p className="mt-2 text-gray-700 leading-5">
        It will be deleted after minting.
      </p>
    </div>
  );
};

const CostsBreakdown = ({
  minted_account_id,
}: {
  minted_account_id?: string;
}) => {
  const serviceFee = config.MINT_SERVICE_FEE;
  const storageFee = config.MINT_STORAGE_FEE;
  return (
    <div>
      <Instruction />
    </div>
  );
};

export default CostsBreakdown;
