import { useQuery } from "@tanstack/react-query";
import { config } from "~domain/near/global";
import { useWalletSelector } from "~context/namesky";

export const useNFTListingQuery = (nft_token_id: string | undefined) => {
  const { namesky } = useWalletSelector();
  return useQuery(
    ["listing", nft_token_id],
    () =>
      namesky.marketplaceContract.getListingView({
        tokenId: nft_token_id,
      }),
    { enabled: !!namesky && !!nft_token_id }
  );
};

export const useNFTOffersQuery = (nft_token_id: string | undefined) => {
  const { namesky } = useWalletSelector();
  return useQuery(
    ["offers", nft_token_id],
    () =>
      namesky.marketplaceContract.getNftOfferingViewsOf({
        tokenId: nft_token_id,
      }),
    { enabled: !!namesky && !!nft_token_id }
  );
};
