import { useQuery } from "@tanstack/react-query";
import { Offering, getBuiltGraphSDK } from "~/../.graphclient";
import React from "react";

import Skeleton from "react-loading-skeleton";
import { ListItem } from "~components/listing/listing-list";
import useTailwindBreakpoint from "~hooks/useTailwindBreakpoint";

const { GetOffers } = getBuiltGraphSDK();

const TopOfferList = () => {
  const brkPnt = useTailwindBreakpoint();
  const { data, isLoading } = useQuery(["top-offers"], async () => {
    return await GetOffers({ first: 100 });
  });

  let tokens = new Set();
  let uniqueBuyerTopOffers: Pick<
    Offering,
    "id" | "token_id" | "buyer_id" | "price"
  >[] = [];
  data?.offerings?.forEach((e) => {
    if (!tokens.has(e.token_id)) {
      tokens.add(e.token_id);
      uniqueBuyerTopOffers.push(e);
    }
  });

  const featuredOfferings = (() => {
    if (brkPnt === "sm") return uniqueBuyerTopOffers.slice(0, 4);
    if (brkPnt === "md") return uniqueBuyerTopOffers.slice(0, 8);
    if (brkPnt === "lg") return uniqueBuyerTopOffers.slice(0, 8);
    return uniqueBuyerTopOffers.slice(0, 9);
  })();

  return (
    <div>
      {isLoading ? (
        <Skeleton height={110} style={{ borderRadius: "16px", opacity: 0.5 }} />
      ) : (
        <div className="grid grid-cols-1 gap-x-2 gap-y-2 md:grid-cols-2 lg:grid-cols-3">
          {featuredOfferings?.map((offer) => {
            return (
              <ListItem
                key={offer.id}
                token_id={offer.token_id}
                price={offer.price}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TopOfferList;
