import React, { FC } from "react";
import clsx from "classnames";

interface StepTitleProps {
  stepNum: number;
  children: string;
  className?: string;
  active?: boolean;
}
const StepTitle: FC<StepTitleProps> = ({ stepNum, children, active }) => {
  return (
    <div className={clsx("flex items-center", { "opacity-30": !active })}>
      <div
        className="
        pt-[2px]
        flex
        items-center
        justify-center
        text-white
        font-semibold
        w-[30px] h-[30px] rounded-full bg-blue-500"
      >
        {stepNum}
      </div>
      <h2 className="text-[16px] font-semibold text-gray-900 ml-4">
        {children}
      </h2>
    </div>
  );
};

export default StepTitle;
