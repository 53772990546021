import React from "react";
interface CenterContainerProps {
  children: React.ReactNode;
}

const CenterContainer = (props: CenterContainerProps) => {
  const { children } = props;
  return <div className="px-4 max-w-[600px] m-auto">{children}</div>;
};

export default CenterContainer;
