import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Collapse from "~components/collapse";
import { PrimaryButton, SecondaryButton } from "~components/button/Button";
import { useNFTOffersQuery } from "~domain/nnn/market/queries";
import OfferModal from "./offer-modal";
import { useBoolean } from "ahooks";
import { AccountLink } from "~pages/account/account-activities";
import { WebWalletIds, useWalletSelector } from "~context/namesky";
import { OfferingView } from "namesky-sdk/dist/core/types/data";
import { Amount } from "namesky-sdk";
import { bubblingElementInArray } from "~utils/util";
dayjs.extend(relativeTime);
interface Props {
  token_id: string;
  token_owner_id?: string;
  signedAccountId?: string;
  nameskyOffers?: OfferingView[];
  isLoadingOffers?: boolean;
}

const OfferBlock = ({
  token_id,
  signedAccountId,
  token_owner_id,
  nameskyOffers,
  isLoadingOffers,
}: Props) => {
  const [underEditOffer, setUnderEditOffer] = React.useState<OfferingView>();
  const [
    isOfferModalOpen,
    { setTrue: openOfferModal, setFalse: closeOfferModal },
  ] = useBoolean(false);

  const { namesky, selector } = useWalletSelector();

  const handleClickAccept = async (offering: OfferingView) => {
    await namesky.marketplaceContract.acceptOffering({
      tokenId: offering.nft_token_id,
      buyerId: offering.buyer_id,
    });
  };

  const handleClickCancel = async (offering: OfferingView) => {
    namesky.marketplaceContract
      .removeOffering({
        tokenId: offering.nft_token_id,
      })
      .then((res: any) => {
        if (WebWalletIds.includes(selector.store.getState().selectedWalletId))
          return;
        location.reload();
      })
      .catch((e) => {
        location.reload();
      });
  };

  const handleClickUpdate = (offering: OfferingView) => {
    setUnderEditOffer(offering);
    openOfferModal();
  };

  console.log("nameskyOffers", nameskyOffers.length);
  if (isLoadingOffers || (!isLoadingOffers && nameskyOffers.length === 0)) {
    return <></>;
  }

  let selfOfferIndex = nameskyOffers.findIndex(
    (e) => e.buyer_id === signedAccountId
  );
  if (selfOfferIndex != -1) {
    console.log("offers before bubbling", nameskyOffers);
    nameskyOffers = bubblingElementInArray(nameskyOffers, selfOfferIndex);
    console.log("offers after bubbling", nameskyOffers);
  }

  return (
    <Collapse maxHeight="300px" title="Offers">
      <div className="bg-[#FBFDFF]">
        <OfferModal
          offering={underEditOffer}
          token_id={token_id}
          isOpen={isOfferModalOpen}
          onRequestClose={closeOfferModal}
        />
        {(nameskyOffers || [])?.map((item, idx) => (
          <div
            className="flex items-center justify-between p-4 border-b border-gray-200 last:border-b-0"
            key={idx}
          >
            <div>
              <div className="pr-[10px] rounded-full text-gray-500">
                Offer Price:{" "}
                <b className="text-gray-900">
                  {Amount.format(item.price, "NEAR")} Ⓝ
                </b>
              </div>
              <div className="flex items-center mt-2 text-sm text-gray-500">
                <div>
                  <span>By</span>
                  <span className="text-blue-500">
                    {" "}
                    {item.buyer_id === signedAccountId ? (
                      "You"
                    ) : (
                      <AccountLink account_id={item.buyer_id} />
                    )}
                  </span>
                </div>
                {/*<span className="ml-2">
                    {dayjs(item?.issued_at).fromNow()}
                  </span>*/}
              </div>
            </div>
            {signedAccountId && (
              <div className="flex flex-col gap-y-2">
                {signedAccountId === token_owner_id && (
                  <PrimaryButton onClick={() => handleClickAccept(item)}>
                    Accept offer
                  </PrimaryButton>
                )}
                {signedAccountId === item.buyer_id && (
                  <>
                    <PrimaryButton onClick={() => handleClickUpdate(item)}>
                      Update offer
                    </PrimaryButton>
                    <SecondaryButton onClick={() => handleClickCancel(item)}>
                      Cancel offer
                    </SecondaryButton>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </Collapse>
  );
};

export default OfferBlock;
