import React, { Fragment, useEffect, useState } from "react";
import {
  PrimaryButton,
  SecondaryButton,
  TextButton,
} from "~components/button/Button";
import { useBoolean } from "ahooks";
import ListingModal from "./listing-modal";
import PurchaseModal from "./purchase-modal";
import { config } from "~domain/near/global";
import CancelListingModal from "./cancel-listing-modal";
import RequestSignInModal from "~components/modal/request-sign-in-modal";
import OfferModal from "./offer-modal";
import SecurityText from "./account-security-text";
import {
  useNFTListingQuery,
  useNFTOffersQuery,
} from "~domain/nnn/market/queries";
import { useFormattedPriceText } from "~hooks/useNearFiatUSDText";
import { Key } from "react-feather";
import ReactDOM from "react-dom";
import { useWalletSelector } from "~context/namesky";
import { Gas, Token } from "namesky-sdk";
import { Tooltip } from "antd";
import { Approval, NameSkyToken } from "namesky-sdk/dist/core/types/data";

const TradingBlock = (props: {
  isOwner: boolean;
  token: Token;
  isVerifyingAccount: boolean;
  isVerifyError: boolean;
  signedAccountId: string;
  token_id: string;
  hasOffer: boolean;
}) => {
  const { isOwner, token, signedAccountId, token_id, hasOffer } = props;
  const { namesky, selector } = useWalletSelector();
  const [
    isListingModalOpen,
    { setTrue: openListingModal, setFalse: closeListingModal },
  ] = useBoolean(false);

  const [isBuyModalOpen, { setTrue: openBuyModal, setFalse: closeBuyModal }] =
    useBoolean(false);

  const [
    isRequestSigninModalOpen,
    { setTrue: openRequestSigninModal, setFalse: closeRequestSigninModal },
  ] = useBoolean(false);

  const [
    isCancelModalOpen,
    { setTrue: openCancelModal, setFalse: closeCancelModal },
  ] = useBoolean(false);

  const [
    isOfferModalOpen,
    { setTrue: openOfferModal, setFalse: closeOfferModal },
  ] = useBoolean(false);

  let { data: listing, isLoading: isLoadingListing } = useNFTListingQuery(
    token?.token_id
  );

  const { data: offers = [] } = useNFTOffersQuery(token_id);

  const highestOffer = offers.reduce((prev, current) => {
    return Number(prev.price) > Number(current.price) ? prev : current;
  }, offers[0]);

  const handleClickAccept = async () => {
    await namesky.marketplaceContract.acceptOffering({
      tokenId: highestOffer.nft_token_id,
      buyerId: highestOffer.buyer_id,
    });
  };
  const { fiatPriceFullText, nearPriceFullText } = useFormattedPriceText(
    listing?.price
  );
  const {
    fiatPriceFullText: highestOfferFiatPriceText,
    nearPriceFullText: highestOfferNearText,
  } = useFormattedPriceText(highestOffer?.price.toString());

  // if checking, checkApproval= undefined
  const [checkApproval, setCheckApproval] = useState<boolean | undefined>(
    undefined
  );
  useEffect(() => {
    if (!namesky && token) {
      return;
    }
    Promise.all([
      namesky.coreContract.nftNameSkyToken({ tokenId: token_id }),
      namesky.marketplaceContract.getNftApproval({ tokenId: token_id }),
    ]).then(([resultFromCore, resultFromMarket]) => {
      let approvalIdInCore = (resultFromCore as NameSkyToken)
        ?.approved_account_ids[namesky.marketplaceContract.contractId];
      let approvalIdInMarket = (resultFromMarket as Approval)?.approval_id;
      setCheckApproval(approvalIdInCore === approvalIdInMarket);
      console.log({ approvalIdInCore, approvalIdInMarket });
    });
  }, [namesky]);

  if (token && (isLoadingListing || checkApproval === undefined))
    return <div />;

  let showListing = !!(listing?.seller_id === token?.owner_id && checkApproval);

  const offerButton = () => {
    return (
      <SecondaryButton
        disabled={hasOffer}
        isFull
        size="large"
        onClick={() => {
          if (!selector.isSignedIn()) {
            openRequestSigninModal();
            return;
          }
          openOfferModal();
        }}
      >
        Make offer
      </SecondaryButton>
    );
  };

  const offerButtonByHasOffer = () => {
    return hasOffer ? (
      <Tooltip
        title={"You have already made an offer for this NFT."}
        placement={"rightBottom"}
      >
        <div style={{ display: "inline-block", padding: 5 }}>
          {offerButton()}
        </div>
      </Tooltip>
    ) : (
      offerButton()
    );
  };

  return (
    <>
      <RequestSignInModal
        title="Sign in to continue"
        isOpen={isRequestSigninModalOpen}
        onRequestClose={closeRequestSigninModal}
      />
      <OfferModal
        token_id={token_id}
        isOpen={isOfferModalOpen}
        onRequestClose={closeOfferModal}
      />
      {/* always display offers */}
      {highestOffer && (
        <div className="overflow-hidden rounded-lg border border-gray-200 bg-[#fbfdff] px-5 py-6">
          <div className="text-sm text-gray-500">Best Offer:</div>
          <div className="mt-1 text-2xl font-bold">
            {highestOfferNearText}
            <span className="ml-1 text-sm font-normal text-gray-500">
              {highestOfferFiatPriceText}
            </span>
          </div>
          {isOwner && (
            <div className="flex flex-col mt-4 gap-y-3">
              <PrimaryButton isFull size="large" onClick={handleClickAccept}>
                Accept offer
              </PrimaryButton>
            </div>
          )}
        </div>
      )}

      {/* content only displayed with token existing (minted) */}
      {token ? (
        <div className="overflow-hidden rounded-lg border border-gray-200 bg-[#fbfdff] px-5 py-6 pb-0">
          <ListingModal
            token_id={token_id}
            isOpen={isListingModalOpen}
            onRequestClose={closeListingModal}
            listing={showListing ? listing : undefined}
          />
          <PurchaseModal
            listing={listing}
            isOpen={isBuyModalOpen}
            onRequestClose={closeBuyModal}
          />
          <CancelListingModal
            listing={listing}
            isOpen={isCancelModalOpen}
            onRequestClose={closeCancelModal}
          />

          {/* price display */}
          {/* Diplay listing price first, if no listing price, display offer price */}
          {showListing && (
            <div>
              <div className="text-sm text-gray-500">Current Price:</div>
              <div className="mt-1 text-2xl font-bold">
                {nearPriceFullText}
                <span className="ml-1 text-sm font-normal text-gray-500">
                  {fiatPriceFullText}
                </span>
              </div>
            </div>
          )}
          {/* end of price display */}

          {/* buttons */}
          <div className="flex flex-col mt-4 gap-y-3">
            {!showListing && isOwner && (
              <PrimaryButton isFull size="large" onClick={openListingModal}>
                Sell
              </PrimaryButton>
            )}
            {showListing && isOwner && (
              <>
                <SecondaryButton isFull size="large" onClick={openListingModal}>
                  Update price
                </SecondaryButton>
                <SecondaryButton isFull size="large" onClick={openCancelModal}>
                  Cancel listing
                </SecondaryButton>
              </>
            )}
            {showListing && !isOwner && (
              <PrimaryButton
                isFull
                size="large"
                onMouseLeave={() => {
                  console.log("pri mouse leave");
                }}
                onClick={() => {
                  if (!selector.isSignedIn()) {
                    openRequestSigninModal();
                    return;
                  }
                  openBuyModal();
                }}
              >
                Buy
              </PrimaryButton>
            )}

            {!isOwner && offerButtonByHasOffer()}
            {isOwner && !showListing && (
              <a href={`/redeem/${token?.token_id}`}>
                <SecondaryButton isFull size="large">
                  Take back NEAR account
                </SecondaryButton>
              </a>
            )}
            {isOwner && showListing && (
              <a href={`/redeem/${token?.token_id}`}>
                <div className="flex items-center justify-center py-1 text-blue-500">
                  <Key className="mr-1" size={20} />
                  <TextButton className="py-2 ">
                    Take back NEAR account
                  </TextButton>
                </div>
              </a>
            )}
          </div>
          {/* end of buttons */}

          <SecurityText
            loading={props.isVerifyingAccount}
            isVerifyError={props.isVerifyError}
          />
        </div>
      ) : (
        <>
          {!isOwner &&
            document.getElementById("js-portal-offer") &&
            ReactDOM.createPortal(
              <SecondaryButton
                className="mt-2"
                isFull
                size="large"
                onClick={() => {
                  if (!selector.isSignedIn()) {
                    openRequestSigninModal();
                    return;
                  }
                  if (highestOffer?.buyer_id === signedAccountId) {
                    alert("You have already made an offer for this NFT");
                    return;
                  }
                  openOfferModal();
                }}
              >
                Make offer
              </SecondaryButton>,
              document.getElementById("js-portal-offer")
            )}
        </>
      )}
    </>
  );
};

export default TradingBlock;
