// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import GraphqlHandler from "@graphql-mesh/graphql"
import BareMerger from "@graphql-mesh/merger-bare";
import { printWithCache } from '@graphql-mesh/utils';
import { createMeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import type { NameskyBetatestContext } from './sources/namesky-betatest/types';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInt: any;
  Bytes: any;
  Int8: any;
  Timestamp: any;
};

export type AcceptOfferingAction = {
  id: Scalars['ID'];
  buyer_id: Scalars['String'];
  seller_id: Scalars['String'];
  prepaid_balance: Scalars['BigInt'];
  payment_balance: Scalars['BigInt'];
  payout_balance: Scalars['BigInt'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
  trading_id: Scalars['String'];
  trading: Trading;
  offering_id: Scalars['String'];
  offering: Offering;
};

export type AcceptOfferingAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  buyer_id?: InputMaybe<Scalars['String']>;
  buyer_id_not?: InputMaybe<Scalars['String']>;
  buyer_id_gt?: InputMaybe<Scalars['String']>;
  buyer_id_lt?: InputMaybe<Scalars['String']>;
  buyer_id_gte?: InputMaybe<Scalars['String']>;
  buyer_id_lte?: InputMaybe<Scalars['String']>;
  buyer_id_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_id_contains?: InputMaybe<Scalars['String']>;
  buyer_id_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_contains?: InputMaybe<Scalars['String']>;
  buyer_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_starts_with?: InputMaybe<Scalars['String']>;
  buyer_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_starts_with?: InputMaybe<Scalars['String']>;
  buyer_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_ends_with?: InputMaybe<Scalars['String']>;
  buyer_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_ends_with?: InputMaybe<Scalars['String']>;
  buyer_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id?: InputMaybe<Scalars['String']>;
  seller_id_not?: InputMaybe<Scalars['String']>;
  seller_id_gt?: InputMaybe<Scalars['String']>;
  seller_id_lt?: InputMaybe<Scalars['String']>;
  seller_id_gte?: InputMaybe<Scalars['String']>;
  seller_id_lte?: InputMaybe<Scalars['String']>;
  seller_id_in?: InputMaybe<Array<Scalars['String']>>;
  seller_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  seller_id_contains?: InputMaybe<Scalars['String']>;
  seller_id_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_contains?: InputMaybe<Scalars['String']>;
  seller_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_id_starts_with?: InputMaybe<Scalars['String']>;
  seller_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_starts_with?: InputMaybe<Scalars['String']>;
  seller_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_ends_with?: InputMaybe<Scalars['String']>;
  seller_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_ends_with?: InputMaybe<Scalars['String']>;
  seller_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  prepaid_balance?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_not?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_gt?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_lt?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_gte?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_lte?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  prepaid_balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payment_balance?: InputMaybe<Scalars['BigInt']>;
  payment_balance_not?: InputMaybe<Scalars['BigInt']>;
  payment_balance_gt?: InputMaybe<Scalars['BigInt']>;
  payment_balance_lt?: InputMaybe<Scalars['BigInt']>;
  payment_balance_gte?: InputMaybe<Scalars['BigInt']>;
  payment_balance_lte?: InputMaybe<Scalars['BigInt']>;
  payment_balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payment_balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payout_balance?: InputMaybe<Scalars['BigInt']>;
  payout_balance_not?: InputMaybe<Scalars['BigInt']>;
  payout_balance_gt?: InputMaybe<Scalars['BigInt']>;
  payout_balance_lt?: InputMaybe<Scalars['BigInt']>;
  payout_balance_gte?: InputMaybe<Scalars['BigInt']>;
  payout_balance_lte?: InputMaybe<Scalars['BigInt']>;
  payout_balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payout_balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading_id?: InputMaybe<Scalars['String']>;
  trading_id_not?: InputMaybe<Scalars['String']>;
  trading_id_gt?: InputMaybe<Scalars['String']>;
  trading_id_lt?: InputMaybe<Scalars['String']>;
  trading_id_gte?: InputMaybe<Scalars['String']>;
  trading_id_lte?: InputMaybe<Scalars['String']>;
  trading_id_in?: InputMaybe<Array<Scalars['String']>>;
  trading_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  trading_id_contains?: InputMaybe<Scalars['String']>;
  trading_id_contains_nocase?: InputMaybe<Scalars['String']>;
  trading_id_not_contains?: InputMaybe<Scalars['String']>;
  trading_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  trading_id_starts_with?: InputMaybe<Scalars['String']>;
  trading_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  trading_id_not_starts_with?: InputMaybe<Scalars['String']>;
  trading_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  trading_id_ends_with?: InputMaybe<Scalars['String']>;
  trading_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading_id_not_ends_with?: InputMaybe<Scalars['String']>;
  trading_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading?: InputMaybe<Scalars['String']>;
  trading_not?: InputMaybe<Scalars['String']>;
  trading_gt?: InputMaybe<Scalars['String']>;
  trading_lt?: InputMaybe<Scalars['String']>;
  trading_gte?: InputMaybe<Scalars['String']>;
  trading_lte?: InputMaybe<Scalars['String']>;
  trading_in?: InputMaybe<Array<Scalars['String']>>;
  trading_not_in?: InputMaybe<Array<Scalars['String']>>;
  trading_contains?: InputMaybe<Scalars['String']>;
  trading_contains_nocase?: InputMaybe<Scalars['String']>;
  trading_not_contains?: InputMaybe<Scalars['String']>;
  trading_not_contains_nocase?: InputMaybe<Scalars['String']>;
  trading_starts_with?: InputMaybe<Scalars['String']>;
  trading_starts_with_nocase?: InputMaybe<Scalars['String']>;
  trading_not_starts_with?: InputMaybe<Scalars['String']>;
  trading_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  trading_ends_with?: InputMaybe<Scalars['String']>;
  trading_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading_not_ends_with?: InputMaybe<Scalars['String']>;
  trading_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading_?: InputMaybe<Trading_filter>;
  offering_id?: InputMaybe<Scalars['String']>;
  offering_id_not?: InputMaybe<Scalars['String']>;
  offering_id_gt?: InputMaybe<Scalars['String']>;
  offering_id_lt?: InputMaybe<Scalars['String']>;
  offering_id_gte?: InputMaybe<Scalars['String']>;
  offering_id_lte?: InputMaybe<Scalars['String']>;
  offering_id_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_contains?: InputMaybe<Scalars['String']>;
  offering_id_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_contains?: InputMaybe<Scalars['String']>;
  offering_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering?: InputMaybe<Scalars['String']>;
  offering_not?: InputMaybe<Scalars['String']>;
  offering_gt?: InputMaybe<Scalars['String']>;
  offering_lt?: InputMaybe<Scalars['String']>;
  offering_gte?: InputMaybe<Scalars['String']>;
  offering_lte?: InputMaybe<Scalars['String']>;
  offering_in?: InputMaybe<Array<Scalars['String']>>;
  offering_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_contains?: InputMaybe<Scalars['String']>;
  offering_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_not_contains?: InputMaybe<Scalars['String']>;
  offering_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_starts_with?: InputMaybe<Scalars['String']>;
  offering_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_ends_with?: InputMaybe<Scalars['String']>;
  offering_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_?: InputMaybe<Offering_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<AcceptOfferingAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<AcceptOfferingAction_filter>>>;
};

export type AcceptOfferingAction_orderBy =
  | 'id'
  | 'buyer_id'
  | 'seller_id'
  | 'prepaid_balance'
  | 'payment_balance'
  | 'payout_balance'
  | 'nft_contract_id'
  | 'nft_token_id'
  | 'trading_id'
  | 'trading'
  | 'trading__id'
  | 'trading__trading_type'
  | 'trading__offering_id'
  | 'trading__is_simple_offering'
  | 'trading__listing_id'
  | 'trading__nft_contract_id'
  | 'trading__nft_token_id'
  | 'trading__nft_approval_id'
  | 'trading__seller_id'
  | 'trading__buyer_id'
  | 'trading__prepaid_balance'
  | 'trading__payment_balance'
  | 'trading__payout_balance'
  | 'trading__transfer_detail'
  | 'trading__tx_time'
  | 'offering_id'
  | 'offering'
  | 'offering__id'
  | 'offering__buyer_id'
  | 'offering__contract_id'
  | 'offering__token_id'
  | 'offering__price'
  | 'offering__create_time'
  | 'offering__update_time'
  | 'offering__is_simple_offering'
  | 'offering__is_remove'
  | 'offering__expire_time';

export type ActionType =
  | 'create_listing_action'
  | 'update_listing_action'
  | 'remove_listing_action'
  | 'create_offering_action'
  | 'update_offering_action'
  | 'remove_offering_action'
  | 'buy_listing_action'
  | 'sold_listing_action'
  | 'accept_offering_action'
  | 'offering_accepted_action'
  | 'nft_mint_action'
  | 'nft_transfer_action'
  | 'nft_received_action'
  | 'nft_burn_action'
  | 'nft_register_action'
  | 'nft_unregister_action'
  | 'control_account_action'
  | 'like_nft_action'
  | 'unlike_nft_action'
  | 'watch_nft_action'
  | 'unwatch_nft_action'
  | 'read_notification_action';

export type Aggregation_interval =
  | 'hour'
  | 'day';

export type BlockChangedFilter = {
  number_gte: Scalars['Int'];
};

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']>;
  number?: InputMaybe<Scalars['Int']>;
  number_gte?: InputMaybe<Scalars['Int']>;
};

export type BuyListingAction = {
  id: Scalars['ID'];
  buyer_id: Scalars['String'];
  seller_id: Scalars['String'];
  prepaid_balance: Scalars['BigInt'];
  payment_balance: Scalars['BigInt'];
  payout_balance: Scalars['BigInt'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
  trading_id: Scalars['String'];
  trading: Trading;
  listing_id: Scalars['String'];
  listing: Listing;
};

export type BuyListingAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  buyer_id?: InputMaybe<Scalars['String']>;
  buyer_id_not?: InputMaybe<Scalars['String']>;
  buyer_id_gt?: InputMaybe<Scalars['String']>;
  buyer_id_lt?: InputMaybe<Scalars['String']>;
  buyer_id_gte?: InputMaybe<Scalars['String']>;
  buyer_id_lte?: InputMaybe<Scalars['String']>;
  buyer_id_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_id_contains?: InputMaybe<Scalars['String']>;
  buyer_id_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_contains?: InputMaybe<Scalars['String']>;
  buyer_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_starts_with?: InputMaybe<Scalars['String']>;
  buyer_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_starts_with?: InputMaybe<Scalars['String']>;
  buyer_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_ends_with?: InputMaybe<Scalars['String']>;
  buyer_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_ends_with?: InputMaybe<Scalars['String']>;
  buyer_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id?: InputMaybe<Scalars['String']>;
  seller_id_not?: InputMaybe<Scalars['String']>;
  seller_id_gt?: InputMaybe<Scalars['String']>;
  seller_id_lt?: InputMaybe<Scalars['String']>;
  seller_id_gte?: InputMaybe<Scalars['String']>;
  seller_id_lte?: InputMaybe<Scalars['String']>;
  seller_id_in?: InputMaybe<Array<Scalars['String']>>;
  seller_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  seller_id_contains?: InputMaybe<Scalars['String']>;
  seller_id_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_contains?: InputMaybe<Scalars['String']>;
  seller_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_id_starts_with?: InputMaybe<Scalars['String']>;
  seller_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_starts_with?: InputMaybe<Scalars['String']>;
  seller_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_ends_with?: InputMaybe<Scalars['String']>;
  seller_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_ends_with?: InputMaybe<Scalars['String']>;
  seller_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  prepaid_balance?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_not?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_gt?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_lt?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_gte?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_lte?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  prepaid_balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payment_balance?: InputMaybe<Scalars['BigInt']>;
  payment_balance_not?: InputMaybe<Scalars['BigInt']>;
  payment_balance_gt?: InputMaybe<Scalars['BigInt']>;
  payment_balance_lt?: InputMaybe<Scalars['BigInt']>;
  payment_balance_gte?: InputMaybe<Scalars['BigInt']>;
  payment_balance_lte?: InputMaybe<Scalars['BigInt']>;
  payment_balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payment_balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payout_balance?: InputMaybe<Scalars['BigInt']>;
  payout_balance_not?: InputMaybe<Scalars['BigInt']>;
  payout_balance_gt?: InputMaybe<Scalars['BigInt']>;
  payout_balance_lt?: InputMaybe<Scalars['BigInt']>;
  payout_balance_gte?: InputMaybe<Scalars['BigInt']>;
  payout_balance_lte?: InputMaybe<Scalars['BigInt']>;
  payout_balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payout_balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading_id?: InputMaybe<Scalars['String']>;
  trading_id_not?: InputMaybe<Scalars['String']>;
  trading_id_gt?: InputMaybe<Scalars['String']>;
  trading_id_lt?: InputMaybe<Scalars['String']>;
  trading_id_gte?: InputMaybe<Scalars['String']>;
  trading_id_lte?: InputMaybe<Scalars['String']>;
  trading_id_in?: InputMaybe<Array<Scalars['String']>>;
  trading_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  trading_id_contains?: InputMaybe<Scalars['String']>;
  trading_id_contains_nocase?: InputMaybe<Scalars['String']>;
  trading_id_not_contains?: InputMaybe<Scalars['String']>;
  trading_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  trading_id_starts_with?: InputMaybe<Scalars['String']>;
  trading_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  trading_id_not_starts_with?: InputMaybe<Scalars['String']>;
  trading_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  trading_id_ends_with?: InputMaybe<Scalars['String']>;
  trading_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading_id_not_ends_with?: InputMaybe<Scalars['String']>;
  trading_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading?: InputMaybe<Scalars['String']>;
  trading_not?: InputMaybe<Scalars['String']>;
  trading_gt?: InputMaybe<Scalars['String']>;
  trading_lt?: InputMaybe<Scalars['String']>;
  trading_gte?: InputMaybe<Scalars['String']>;
  trading_lte?: InputMaybe<Scalars['String']>;
  trading_in?: InputMaybe<Array<Scalars['String']>>;
  trading_not_in?: InputMaybe<Array<Scalars['String']>>;
  trading_contains?: InputMaybe<Scalars['String']>;
  trading_contains_nocase?: InputMaybe<Scalars['String']>;
  trading_not_contains?: InputMaybe<Scalars['String']>;
  trading_not_contains_nocase?: InputMaybe<Scalars['String']>;
  trading_starts_with?: InputMaybe<Scalars['String']>;
  trading_starts_with_nocase?: InputMaybe<Scalars['String']>;
  trading_not_starts_with?: InputMaybe<Scalars['String']>;
  trading_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  trading_ends_with?: InputMaybe<Scalars['String']>;
  trading_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading_not_ends_with?: InputMaybe<Scalars['String']>;
  trading_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  trading_?: InputMaybe<Trading_filter>;
  listing_id?: InputMaybe<Scalars['String']>;
  listing_id_not?: InputMaybe<Scalars['String']>;
  listing_id_gt?: InputMaybe<Scalars['String']>;
  listing_id_lt?: InputMaybe<Scalars['String']>;
  listing_id_gte?: InputMaybe<Scalars['String']>;
  listing_id_lte?: InputMaybe<Scalars['String']>;
  listing_id_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_contains?: InputMaybe<Scalars['String']>;
  listing_id_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_contains?: InputMaybe<Scalars['String']>;
  listing_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Scalars['String']>;
  listing_not?: InputMaybe<Scalars['String']>;
  listing_gt?: InputMaybe<Scalars['String']>;
  listing_lt?: InputMaybe<Scalars['String']>;
  listing_gte?: InputMaybe<Scalars['String']>;
  listing_lte?: InputMaybe<Scalars['String']>;
  listing_in?: InputMaybe<Array<Scalars['String']>>;
  listing_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_contains?: InputMaybe<Scalars['String']>;
  listing_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_not_contains?: InputMaybe<Scalars['String']>;
  listing_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_starts_with?: InputMaybe<Scalars['String']>;
  listing_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_ends_with?: InputMaybe<Scalars['String']>;
  listing_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_?: InputMaybe<Listing_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<BuyListingAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<BuyListingAction_filter>>>;
};

export type BuyListingAction_orderBy =
  | 'id'
  | 'buyer_id'
  | 'seller_id'
  | 'prepaid_balance'
  | 'payment_balance'
  | 'payout_balance'
  | 'nft_contract_id'
  | 'nft_token_id'
  | 'trading_id'
  | 'trading'
  | 'trading__id'
  | 'trading__trading_type'
  | 'trading__offering_id'
  | 'trading__is_simple_offering'
  | 'trading__listing_id'
  | 'trading__nft_contract_id'
  | 'trading__nft_token_id'
  | 'trading__nft_approval_id'
  | 'trading__seller_id'
  | 'trading__buyer_id'
  | 'trading__prepaid_balance'
  | 'trading__payment_balance'
  | 'trading__payout_balance'
  | 'trading__transfer_detail'
  | 'trading__tx_time'
  | 'listing_id'
  | 'listing'
  | 'listing__id'
  | 'listing__seller_id'
  | 'listing__contract_id'
  | 'listing__token_id'
  | 'listing__price'
  | 'listing__create_time'
  | 'listing__update_time'
  | 'listing__is_remove';

export type CharacterSet =
  | 'letter'
  | 'digit'
  | 'mixed';

export type ControlAccountAction = {
  id: Scalars['ID'];
  account_id: Scalars['String'];
  public_key: Scalars['String'];
};

export type ControlAccountAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  account_id?: InputMaybe<Scalars['String']>;
  account_id_not?: InputMaybe<Scalars['String']>;
  account_id_gt?: InputMaybe<Scalars['String']>;
  account_id_lt?: InputMaybe<Scalars['String']>;
  account_id_gte?: InputMaybe<Scalars['String']>;
  account_id_lte?: InputMaybe<Scalars['String']>;
  account_id_in?: InputMaybe<Array<Scalars['String']>>;
  account_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_id_contains?: InputMaybe<Scalars['String']>;
  account_id_contains_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_contains?: InputMaybe<Scalars['String']>;
  account_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_id_starts_with?: InputMaybe<Scalars['String']>;
  account_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_starts_with?: InputMaybe<Scalars['String']>;
  account_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_ends_with?: InputMaybe<Scalars['String']>;
  account_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_ends_with?: InputMaybe<Scalars['String']>;
  account_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
  public_key_not?: InputMaybe<Scalars['String']>;
  public_key_gt?: InputMaybe<Scalars['String']>;
  public_key_lt?: InputMaybe<Scalars['String']>;
  public_key_gte?: InputMaybe<Scalars['String']>;
  public_key_lte?: InputMaybe<Scalars['String']>;
  public_key_in?: InputMaybe<Array<Scalars['String']>>;
  public_key_not_in?: InputMaybe<Array<Scalars['String']>>;
  public_key_contains?: InputMaybe<Scalars['String']>;
  public_key_contains_nocase?: InputMaybe<Scalars['String']>;
  public_key_not_contains?: InputMaybe<Scalars['String']>;
  public_key_not_contains_nocase?: InputMaybe<Scalars['String']>;
  public_key_starts_with?: InputMaybe<Scalars['String']>;
  public_key_starts_with_nocase?: InputMaybe<Scalars['String']>;
  public_key_not_starts_with?: InputMaybe<Scalars['String']>;
  public_key_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  public_key_ends_with?: InputMaybe<Scalars['String']>;
  public_key_ends_with_nocase?: InputMaybe<Scalars['String']>;
  public_key_not_ends_with?: InputMaybe<Scalars['String']>;
  public_key_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ControlAccountAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ControlAccountAction_filter>>>;
};

export type ControlAccountAction_orderBy =
  | 'id'
  | 'account_id'
  | 'public_key';

export type CreateListingAction = {
  id: Scalars['ID'];
  price: Scalars['BigInt'];
  listing_id: Scalars['String'];
  listing: Listing;
};

export type CreateListingAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  listing_id?: InputMaybe<Scalars['String']>;
  listing_id_not?: InputMaybe<Scalars['String']>;
  listing_id_gt?: InputMaybe<Scalars['String']>;
  listing_id_lt?: InputMaybe<Scalars['String']>;
  listing_id_gte?: InputMaybe<Scalars['String']>;
  listing_id_lte?: InputMaybe<Scalars['String']>;
  listing_id_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_contains?: InputMaybe<Scalars['String']>;
  listing_id_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_contains?: InputMaybe<Scalars['String']>;
  listing_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Scalars['String']>;
  listing_not?: InputMaybe<Scalars['String']>;
  listing_gt?: InputMaybe<Scalars['String']>;
  listing_lt?: InputMaybe<Scalars['String']>;
  listing_gte?: InputMaybe<Scalars['String']>;
  listing_lte?: InputMaybe<Scalars['String']>;
  listing_in?: InputMaybe<Array<Scalars['String']>>;
  listing_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_contains?: InputMaybe<Scalars['String']>;
  listing_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_not_contains?: InputMaybe<Scalars['String']>;
  listing_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_starts_with?: InputMaybe<Scalars['String']>;
  listing_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_ends_with?: InputMaybe<Scalars['String']>;
  listing_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_?: InputMaybe<Listing_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CreateListingAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<CreateListingAction_filter>>>;
};

export type CreateListingAction_orderBy =
  | 'id'
  | 'price'
  | 'listing_id'
  | 'listing'
  | 'listing__id'
  | 'listing__seller_id'
  | 'listing__contract_id'
  | 'listing__token_id'
  | 'listing__price'
  | 'listing__create_time'
  | 'listing__update_time'
  | 'listing__is_remove';

export type CreateOfferingAction = {
  id: Scalars['ID'];
  offer_creator: Scalars['String'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
  price: Scalars['BigInt'];
  offering_id: Scalars['String'];
  offering: Offering;
};

export type CreateOfferingAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  offer_creator?: InputMaybe<Scalars['String']>;
  offer_creator_not?: InputMaybe<Scalars['String']>;
  offer_creator_gt?: InputMaybe<Scalars['String']>;
  offer_creator_lt?: InputMaybe<Scalars['String']>;
  offer_creator_gte?: InputMaybe<Scalars['String']>;
  offer_creator_lte?: InputMaybe<Scalars['String']>;
  offer_creator_in?: InputMaybe<Array<Scalars['String']>>;
  offer_creator_not_in?: InputMaybe<Array<Scalars['String']>>;
  offer_creator_contains?: InputMaybe<Scalars['String']>;
  offer_creator_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_not_contains?: InputMaybe<Scalars['String']>;
  offer_creator_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_starts_with?: InputMaybe<Scalars['String']>;
  offer_creator_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_not_starts_with?: InputMaybe<Scalars['String']>;
  offer_creator_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_ends_with?: InputMaybe<Scalars['String']>;
  offer_creator_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_not_ends_with?: InputMaybe<Scalars['String']>;
  offer_creator_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  offering_id?: InputMaybe<Scalars['String']>;
  offering_id_not?: InputMaybe<Scalars['String']>;
  offering_id_gt?: InputMaybe<Scalars['String']>;
  offering_id_lt?: InputMaybe<Scalars['String']>;
  offering_id_gte?: InputMaybe<Scalars['String']>;
  offering_id_lte?: InputMaybe<Scalars['String']>;
  offering_id_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_contains?: InputMaybe<Scalars['String']>;
  offering_id_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_contains?: InputMaybe<Scalars['String']>;
  offering_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering?: InputMaybe<Scalars['String']>;
  offering_not?: InputMaybe<Scalars['String']>;
  offering_gt?: InputMaybe<Scalars['String']>;
  offering_lt?: InputMaybe<Scalars['String']>;
  offering_gte?: InputMaybe<Scalars['String']>;
  offering_lte?: InputMaybe<Scalars['String']>;
  offering_in?: InputMaybe<Array<Scalars['String']>>;
  offering_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_contains?: InputMaybe<Scalars['String']>;
  offering_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_not_contains?: InputMaybe<Scalars['String']>;
  offering_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_starts_with?: InputMaybe<Scalars['String']>;
  offering_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_ends_with?: InputMaybe<Scalars['String']>;
  offering_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_?: InputMaybe<Offering_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<CreateOfferingAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<CreateOfferingAction_filter>>>;
};

export type CreateOfferingAction_orderBy =
  | 'id'
  | 'offer_creator'
  | 'nft_contract_id'
  | 'nft_token_id'
  | 'price'
  | 'offering_id'
  | 'offering'
  | 'offering__id'
  | 'offering__buyer_id'
  | 'offering__contract_id'
  | 'offering__token_id'
  | 'offering__price'
  | 'offering__create_time'
  | 'offering__update_time'
  | 'offering__is_simple_offering'
  | 'offering__is_remove'
  | 'offering__expire_time';

export type LikeNftAction = {
  id: Scalars['ID'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
};

export type LikeNftAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<LikeNftAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<LikeNftAction_filter>>>;
};

export type LikeNftAction_orderBy =
  | 'id'
  | 'nft_contract_id'
  | 'nft_token_id';

export type Listing = {
  id: Scalars['ID'];
  seller_id: Scalars['String'];
  contract_id: Scalars['String'];
  token_id: Scalars['String'];
  price: Scalars['BigInt'];
  create_time: Scalars['BigInt'];
  update_time: Scalars['BigInt'];
  is_remove: Scalars['Boolean'];
};

export type ListingSoldNotification = {
  id: Scalars['ID'];
  buy_listing_action: BuyListingAction;
};

export type ListingSoldNotification_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  buy_listing_action?: InputMaybe<Scalars['String']>;
  buy_listing_action_not?: InputMaybe<Scalars['String']>;
  buy_listing_action_gt?: InputMaybe<Scalars['String']>;
  buy_listing_action_lt?: InputMaybe<Scalars['String']>;
  buy_listing_action_gte?: InputMaybe<Scalars['String']>;
  buy_listing_action_lte?: InputMaybe<Scalars['String']>;
  buy_listing_action_in?: InputMaybe<Array<Scalars['String']>>;
  buy_listing_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  buy_listing_action_contains?: InputMaybe<Scalars['String']>;
  buy_listing_action_contains_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_contains?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_starts_with?: InputMaybe<Scalars['String']>;
  buy_listing_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_starts_with?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_ends_with?: InputMaybe<Scalars['String']>;
  buy_listing_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_ends_with?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_?: InputMaybe<BuyListingAction_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ListingSoldNotification_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ListingSoldNotification_filter>>>;
};

export type ListingSoldNotification_orderBy =
  | 'id'
  | 'buy_listing_action'
  | 'buy_listing_action__id'
  | 'buy_listing_action__buyer_id'
  | 'buy_listing_action__seller_id'
  | 'buy_listing_action__prepaid_balance'
  | 'buy_listing_action__payment_balance'
  | 'buy_listing_action__payout_balance'
  | 'buy_listing_action__nft_contract_id'
  | 'buy_listing_action__nft_token_id'
  | 'buy_listing_action__trading_id'
  | 'buy_listing_action__listing_id';

export type Listing_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  seller_id?: InputMaybe<Scalars['String']>;
  seller_id_not?: InputMaybe<Scalars['String']>;
  seller_id_gt?: InputMaybe<Scalars['String']>;
  seller_id_lt?: InputMaybe<Scalars['String']>;
  seller_id_gte?: InputMaybe<Scalars['String']>;
  seller_id_lte?: InputMaybe<Scalars['String']>;
  seller_id_in?: InputMaybe<Array<Scalars['String']>>;
  seller_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  seller_id_contains?: InputMaybe<Scalars['String']>;
  seller_id_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_contains?: InputMaybe<Scalars['String']>;
  seller_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_id_starts_with?: InputMaybe<Scalars['String']>;
  seller_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_starts_with?: InputMaybe<Scalars['String']>;
  seller_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_ends_with?: InputMaybe<Scalars['String']>;
  seller_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_ends_with?: InputMaybe<Scalars['String']>;
  seller_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['String']>;
  contract_id_not?: InputMaybe<Scalars['String']>;
  contract_id_gt?: InputMaybe<Scalars['String']>;
  contract_id_lt?: InputMaybe<Scalars['String']>;
  contract_id_gte?: InputMaybe<Scalars['String']>;
  contract_id_lte?: InputMaybe<Scalars['String']>;
  contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_contains?: InputMaybe<Scalars['String']>;
  contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_contains?: InputMaybe<Scalars['String']>;
  contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  token_id_not?: InputMaybe<Scalars['String']>;
  token_id_gt?: InputMaybe<Scalars['String']>;
  token_id_lt?: InputMaybe<Scalars['String']>;
  token_id_gte?: InputMaybe<Scalars['String']>;
  token_id_lte?: InputMaybe<Scalars['String']>;
  token_id_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_contains?: InputMaybe<Scalars['String']>;
  token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_contains?: InputMaybe<Scalars['String']>;
  token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_starts_with?: InputMaybe<Scalars['String']>;
  token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_ends_with?: InputMaybe<Scalars['String']>;
  token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  create_time?: InputMaybe<Scalars['BigInt']>;
  create_time_not?: InputMaybe<Scalars['BigInt']>;
  create_time_gt?: InputMaybe<Scalars['BigInt']>;
  create_time_lt?: InputMaybe<Scalars['BigInt']>;
  create_time_gte?: InputMaybe<Scalars['BigInt']>;
  create_time_lte?: InputMaybe<Scalars['BigInt']>;
  create_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  create_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  update_time?: InputMaybe<Scalars['BigInt']>;
  update_time_not?: InputMaybe<Scalars['BigInt']>;
  update_time_gt?: InputMaybe<Scalars['BigInt']>;
  update_time_lt?: InputMaybe<Scalars['BigInt']>;
  update_time_gte?: InputMaybe<Scalars['BigInt']>;
  update_time_lte?: InputMaybe<Scalars['BigInt']>;
  update_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  update_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  is_remove?: InputMaybe<Scalars['Boolean']>;
  is_remove_not?: InputMaybe<Scalars['Boolean']>;
  is_remove_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_remove_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Listing_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Listing_filter>>>;
};

export type Listing_orderBy =
  | 'id'
  | 'seller_id'
  | 'contract_id'
  | 'token_id'
  | 'price'
  | 'create_time'
  | 'update_time'
  | 'is_remove';

export type MarketSummary = {
  id: Scalars['ID'];
  volume: Scalars['BigInt'];
  trading_sum: Scalars['BigInt'];
  listing_sum: Scalars['BigInt'];
  nft_minted_sum: Scalars['BigInt'];
};

export type MarketSummary_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  volume?: InputMaybe<Scalars['BigInt']>;
  volume_not?: InputMaybe<Scalars['BigInt']>;
  volume_gt?: InputMaybe<Scalars['BigInt']>;
  volume_lt?: InputMaybe<Scalars['BigInt']>;
  volume_gte?: InputMaybe<Scalars['BigInt']>;
  volume_lte?: InputMaybe<Scalars['BigInt']>;
  volume_in?: InputMaybe<Array<Scalars['BigInt']>>;
  volume_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  trading_sum?: InputMaybe<Scalars['BigInt']>;
  trading_sum_not?: InputMaybe<Scalars['BigInt']>;
  trading_sum_gt?: InputMaybe<Scalars['BigInt']>;
  trading_sum_lt?: InputMaybe<Scalars['BigInt']>;
  trading_sum_gte?: InputMaybe<Scalars['BigInt']>;
  trading_sum_lte?: InputMaybe<Scalars['BigInt']>;
  trading_sum_in?: InputMaybe<Array<Scalars['BigInt']>>;
  trading_sum_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  listing_sum?: InputMaybe<Scalars['BigInt']>;
  listing_sum_not?: InputMaybe<Scalars['BigInt']>;
  listing_sum_gt?: InputMaybe<Scalars['BigInt']>;
  listing_sum_lt?: InputMaybe<Scalars['BigInt']>;
  listing_sum_gte?: InputMaybe<Scalars['BigInt']>;
  listing_sum_lte?: InputMaybe<Scalars['BigInt']>;
  listing_sum_in?: InputMaybe<Array<Scalars['BigInt']>>;
  listing_sum_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  nft_minted_sum?: InputMaybe<Scalars['BigInt']>;
  nft_minted_sum_not?: InputMaybe<Scalars['BigInt']>;
  nft_minted_sum_gt?: InputMaybe<Scalars['BigInt']>;
  nft_minted_sum_lt?: InputMaybe<Scalars['BigInt']>;
  nft_minted_sum_gte?: InputMaybe<Scalars['BigInt']>;
  nft_minted_sum_lte?: InputMaybe<Scalars['BigInt']>;
  nft_minted_sum_in?: InputMaybe<Array<Scalars['BigInt']>>;
  nft_minted_sum_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<MarketSummary_filter>>>;
  or?: InputMaybe<Array<InputMaybe<MarketSummary_filter>>>;
};

export type MarketSummary_orderBy =
  | 'id'
  | 'volume'
  | 'trading_sum'
  | 'listing_sum'
  | 'nft_minted_sum';

export type Nft = {
  id: Scalars['ID'];
  contract_id: Scalars['String'];
  token_id: Scalars['String'];
  owner_id?: Maybe<Scalars['String']>;
  is_burn: Scalars['Boolean'];
  metadata?: Maybe<TokenMetadata>;
  search_name: Scalars['String'];
  search_name_len: Scalars['Int'];
  mint_time?: Maybe<Scalars['BigInt']>;
  name_len: Scalars['Int'];
  name_level: Scalars['Int'];
  nft_approve_id_for_market_contract?: Maybe<Scalars['Int']>;
  nft_approve_id_in_market_contract?: Maybe<Scalars['Int']>;
  is_approve_id_effective_in_market_contract: Scalars['Boolean'];
  price?: Maybe<Scalars['BigInt']>;
  listing?: Maybe<Listing>;
  listing_time?: Maybe<Scalars['BigInt']>;
  last_sale_price?: Maybe<Scalars['BigInt']>;
  liked_sum: Scalars['Int'];
  watched_sum: Scalars['Int'];
  like_by_users: Array<UserLikeNft>;
  watch_by_users: Array<UserWatchNft>;
  character_set: CharacterSet;
  contain_separator: Scalars['Boolean'];
  club?: Maybe<NftClub>;
  offer_count?: Maybe<Scalars['Int']>;
  offers?: Maybe<Array<Offering>>;
  top_domain: Scalars['String'];
  bottom_domain: Scalars['String'];
  suffix_domain: Scalars['String'];
  prefix_domain: Scalars['String'];
};


export type Nftlike_by_usersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserLikeNft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserLikeNft_filter>;
};


export type Nftwatch_by_usersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserWatchNft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserWatchNft_filter>;
};


export type NftoffersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Offering_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Offering_filter>;
};

export type NftBurnAction = {
  id: Scalars['ID'];
  token_id: Scalars['String'];
  authorized_id?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
};

export type NftBurnAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  token_id?: InputMaybe<Scalars['String']>;
  token_id_not?: InputMaybe<Scalars['String']>;
  token_id_gt?: InputMaybe<Scalars['String']>;
  token_id_lt?: InputMaybe<Scalars['String']>;
  token_id_gte?: InputMaybe<Scalars['String']>;
  token_id_lte?: InputMaybe<Scalars['String']>;
  token_id_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_contains?: InputMaybe<Scalars['String']>;
  token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_contains?: InputMaybe<Scalars['String']>;
  token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_starts_with?: InputMaybe<Scalars['String']>;
  token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_ends_with?: InputMaybe<Scalars['String']>;
  token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  authorized_id?: InputMaybe<Scalars['String']>;
  authorized_id_not?: InputMaybe<Scalars['String']>;
  authorized_id_gt?: InputMaybe<Scalars['String']>;
  authorized_id_lt?: InputMaybe<Scalars['String']>;
  authorized_id_gte?: InputMaybe<Scalars['String']>;
  authorized_id_lte?: InputMaybe<Scalars['String']>;
  authorized_id_in?: InputMaybe<Array<Scalars['String']>>;
  authorized_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  authorized_id_contains?: InputMaybe<Scalars['String']>;
  authorized_id_contains_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_not_contains?: InputMaybe<Scalars['String']>;
  authorized_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_starts_with?: InputMaybe<Scalars['String']>;
  authorized_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_not_starts_with?: InputMaybe<Scalars['String']>;
  authorized_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_ends_with?: InputMaybe<Scalars['String']>;
  authorized_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_not_ends_with?: InputMaybe<Scalars['String']>;
  authorized_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  memo_not?: InputMaybe<Scalars['String']>;
  memo_gt?: InputMaybe<Scalars['String']>;
  memo_lt?: InputMaybe<Scalars['String']>;
  memo_gte?: InputMaybe<Scalars['String']>;
  memo_lte?: InputMaybe<Scalars['String']>;
  memo_in?: InputMaybe<Array<Scalars['String']>>;
  memo_not_in?: InputMaybe<Array<Scalars['String']>>;
  memo_contains?: InputMaybe<Scalars['String']>;
  memo_contains_nocase?: InputMaybe<Scalars['String']>;
  memo_not_contains?: InputMaybe<Scalars['String']>;
  memo_not_contains_nocase?: InputMaybe<Scalars['String']>;
  memo_starts_with?: InputMaybe<Scalars['String']>;
  memo_starts_with_nocase?: InputMaybe<Scalars['String']>;
  memo_not_starts_with?: InputMaybe<Scalars['String']>;
  memo_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  memo_ends_with?: InputMaybe<Scalars['String']>;
  memo_ends_with_nocase?: InputMaybe<Scalars['String']>;
  memo_not_ends_with?: InputMaybe<Scalars['String']>;
  memo_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NftBurnAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NftBurnAction_filter>>>;
};

export type NftBurnAction_orderBy =
  | 'id'
  | 'token_id'
  | 'authorized_id'
  | 'memo';

export type NftClub =
  | 'club999'
  | 'club10k'
  | 'club100k';

export type NftLikedNotification = {
  id: Scalars['ID'];
  account_id: Scalars['String'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
  nft: Nft;
};

export type NftLikedNotification_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  account_id?: InputMaybe<Scalars['String']>;
  account_id_not?: InputMaybe<Scalars['String']>;
  account_id_gt?: InputMaybe<Scalars['String']>;
  account_id_lt?: InputMaybe<Scalars['String']>;
  account_id_gte?: InputMaybe<Scalars['String']>;
  account_id_lte?: InputMaybe<Scalars['String']>;
  account_id_in?: InputMaybe<Array<Scalars['String']>>;
  account_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_id_contains?: InputMaybe<Scalars['String']>;
  account_id_contains_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_contains?: InputMaybe<Scalars['String']>;
  account_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_id_starts_with?: InputMaybe<Scalars['String']>;
  account_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_starts_with?: InputMaybe<Scalars['String']>;
  account_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_ends_with?: InputMaybe<Scalars['String']>;
  account_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_ends_with?: InputMaybe<Scalars['String']>;
  account_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft?: InputMaybe<Scalars['String']>;
  nft_not?: InputMaybe<Scalars['String']>;
  nft_gt?: InputMaybe<Scalars['String']>;
  nft_lt?: InputMaybe<Scalars['String']>;
  nft_gte?: InputMaybe<Scalars['String']>;
  nft_lte?: InputMaybe<Scalars['String']>;
  nft_in?: InputMaybe<Array<Scalars['String']>>;
  nft_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contains?: InputMaybe<Scalars['String']>;
  nft_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_not_contains?: InputMaybe<Scalars['String']>;
  nft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_starts_with?: InputMaybe<Scalars['String']>;
  nft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_ends_with?: InputMaybe<Scalars['String']>;
  nft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_?: InputMaybe<Nft_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NftLikedNotification_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NftLikedNotification_filter>>>;
};

export type NftLikedNotification_orderBy =
  | 'id'
  | 'account_id'
  | 'nft_contract_id'
  | 'nft_token_id'
  | 'nft'
  | 'nft__id'
  | 'nft__contract_id'
  | 'nft__token_id'
  | 'nft__owner_id'
  | 'nft__is_burn'
  | 'nft__search_name'
  | 'nft__search_name_len'
  | 'nft__mint_time'
  | 'nft__name_len'
  | 'nft__name_level'
  | 'nft__nft_approve_id_for_market_contract'
  | 'nft__nft_approve_id_in_market_contract'
  | 'nft__is_approve_id_effective_in_market_contract'
  | 'nft__price'
  | 'nft__listing_time'
  | 'nft__last_sale_price'
  | 'nft__liked_sum'
  | 'nft__watched_sum'
  | 'nft__character_set'
  | 'nft__contain_separator'
  | 'nft__club'
  | 'nft__offer_count'
  | 'nft__top_domain'
  | 'nft__bottom_domain'
  | 'nft__suffix_domain'
  | 'nft__prefix_domain';

export type NftMintAction = {
  id: Scalars['ID'];
  owner_id: Scalars['String'];
  contract_id: Scalars['String'];
  token_id: Scalars['String'];
};

export type NftMintAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  owner_id?: InputMaybe<Scalars['String']>;
  owner_id_not?: InputMaybe<Scalars['String']>;
  owner_id_gt?: InputMaybe<Scalars['String']>;
  owner_id_lt?: InputMaybe<Scalars['String']>;
  owner_id_gte?: InputMaybe<Scalars['String']>;
  owner_id_lte?: InputMaybe<Scalars['String']>;
  owner_id_in?: InputMaybe<Array<Scalars['String']>>;
  owner_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_id_contains?: InputMaybe<Scalars['String']>;
  owner_id_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_id_not_contains?: InputMaybe<Scalars['String']>;
  owner_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_id_starts_with?: InputMaybe<Scalars['String']>;
  owner_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_id_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_id_ends_with?: InputMaybe<Scalars['String']>;
  owner_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_id_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['String']>;
  contract_id_not?: InputMaybe<Scalars['String']>;
  contract_id_gt?: InputMaybe<Scalars['String']>;
  contract_id_lt?: InputMaybe<Scalars['String']>;
  contract_id_gte?: InputMaybe<Scalars['String']>;
  contract_id_lte?: InputMaybe<Scalars['String']>;
  contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_contains?: InputMaybe<Scalars['String']>;
  contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_contains?: InputMaybe<Scalars['String']>;
  contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  token_id_not?: InputMaybe<Scalars['String']>;
  token_id_gt?: InputMaybe<Scalars['String']>;
  token_id_lt?: InputMaybe<Scalars['String']>;
  token_id_gte?: InputMaybe<Scalars['String']>;
  token_id_lte?: InputMaybe<Scalars['String']>;
  token_id_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_contains?: InputMaybe<Scalars['String']>;
  token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_contains?: InputMaybe<Scalars['String']>;
  token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_starts_with?: InputMaybe<Scalars['String']>;
  token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_ends_with?: InputMaybe<Scalars['String']>;
  token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NftMintAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NftMintAction_filter>>>;
};

export type NftMintAction_orderBy =
  | 'id'
  | 'owner_id'
  | 'contract_id'
  | 'token_id';

export type NftRegisterAction = {
  id: Scalars['ID'];
  registrant_id: Scalars['String'];
  minter_id: Scalars['String'];
};

export type NftRegisterAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  registrant_id?: InputMaybe<Scalars['String']>;
  registrant_id_not?: InputMaybe<Scalars['String']>;
  registrant_id_gt?: InputMaybe<Scalars['String']>;
  registrant_id_lt?: InputMaybe<Scalars['String']>;
  registrant_id_gte?: InputMaybe<Scalars['String']>;
  registrant_id_lte?: InputMaybe<Scalars['String']>;
  registrant_id_in?: InputMaybe<Array<Scalars['String']>>;
  registrant_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  registrant_id_contains?: InputMaybe<Scalars['String']>;
  registrant_id_contains_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_not_contains?: InputMaybe<Scalars['String']>;
  registrant_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_starts_with?: InputMaybe<Scalars['String']>;
  registrant_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_not_starts_with?: InputMaybe<Scalars['String']>;
  registrant_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_ends_with?: InputMaybe<Scalars['String']>;
  registrant_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_not_ends_with?: InputMaybe<Scalars['String']>;
  registrant_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  minter_id?: InputMaybe<Scalars['String']>;
  minter_id_not?: InputMaybe<Scalars['String']>;
  minter_id_gt?: InputMaybe<Scalars['String']>;
  minter_id_lt?: InputMaybe<Scalars['String']>;
  minter_id_gte?: InputMaybe<Scalars['String']>;
  minter_id_lte?: InputMaybe<Scalars['String']>;
  minter_id_in?: InputMaybe<Array<Scalars['String']>>;
  minter_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  minter_id_contains?: InputMaybe<Scalars['String']>;
  minter_id_contains_nocase?: InputMaybe<Scalars['String']>;
  minter_id_not_contains?: InputMaybe<Scalars['String']>;
  minter_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  minter_id_starts_with?: InputMaybe<Scalars['String']>;
  minter_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  minter_id_not_starts_with?: InputMaybe<Scalars['String']>;
  minter_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  minter_id_ends_with?: InputMaybe<Scalars['String']>;
  minter_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  minter_id_not_ends_with?: InputMaybe<Scalars['String']>;
  minter_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NftRegisterAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NftRegisterAction_filter>>>;
};

export type NftRegisterAction_orderBy =
  | 'id'
  | 'registrant_id'
  | 'minter_id';

export type NftSummary = {
  id: Scalars['ID'];
  mint_count: Scalars['BigInt'];
  burn_count: Scalars['BigInt'];
};

export type NftSummary_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  mint_count?: InputMaybe<Scalars['BigInt']>;
  mint_count_not?: InputMaybe<Scalars['BigInt']>;
  mint_count_gt?: InputMaybe<Scalars['BigInt']>;
  mint_count_lt?: InputMaybe<Scalars['BigInt']>;
  mint_count_gte?: InputMaybe<Scalars['BigInt']>;
  mint_count_lte?: InputMaybe<Scalars['BigInt']>;
  mint_count_in?: InputMaybe<Array<Scalars['BigInt']>>;
  mint_count_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  burn_count?: InputMaybe<Scalars['BigInt']>;
  burn_count_not?: InputMaybe<Scalars['BigInt']>;
  burn_count_gt?: InputMaybe<Scalars['BigInt']>;
  burn_count_lt?: InputMaybe<Scalars['BigInt']>;
  burn_count_gte?: InputMaybe<Scalars['BigInt']>;
  burn_count_lte?: InputMaybe<Scalars['BigInt']>;
  burn_count_in?: InputMaybe<Array<Scalars['BigInt']>>;
  burn_count_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NftSummary_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NftSummary_filter>>>;
};

export type NftSummary_orderBy =
  | 'id'
  | 'mint_count'
  | 'burn_count';

export type NftTransferAction = {
  id: Scalars['ID'];
  old_owner_id: Scalars['String'];
  new_owner_id: Scalars['String'];
  token_id: Scalars['String'];
  authorized_id?: Maybe<Scalars['String']>;
  memo?: Maybe<Scalars['String']>;
};

export type NftTransferAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  old_owner_id?: InputMaybe<Scalars['String']>;
  old_owner_id_not?: InputMaybe<Scalars['String']>;
  old_owner_id_gt?: InputMaybe<Scalars['String']>;
  old_owner_id_lt?: InputMaybe<Scalars['String']>;
  old_owner_id_gte?: InputMaybe<Scalars['String']>;
  old_owner_id_lte?: InputMaybe<Scalars['String']>;
  old_owner_id_in?: InputMaybe<Array<Scalars['String']>>;
  old_owner_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  old_owner_id_contains?: InputMaybe<Scalars['String']>;
  old_owner_id_contains_nocase?: InputMaybe<Scalars['String']>;
  old_owner_id_not_contains?: InputMaybe<Scalars['String']>;
  old_owner_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  old_owner_id_starts_with?: InputMaybe<Scalars['String']>;
  old_owner_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  old_owner_id_not_starts_with?: InputMaybe<Scalars['String']>;
  old_owner_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  old_owner_id_ends_with?: InputMaybe<Scalars['String']>;
  old_owner_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  old_owner_id_not_ends_with?: InputMaybe<Scalars['String']>;
  old_owner_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  new_owner_id?: InputMaybe<Scalars['String']>;
  new_owner_id_not?: InputMaybe<Scalars['String']>;
  new_owner_id_gt?: InputMaybe<Scalars['String']>;
  new_owner_id_lt?: InputMaybe<Scalars['String']>;
  new_owner_id_gte?: InputMaybe<Scalars['String']>;
  new_owner_id_lte?: InputMaybe<Scalars['String']>;
  new_owner_id_in?: InputMaybe<Array<Scalars['String']>>;
  new_owner_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  new_owner_id_contains?: InputMaybe<Scalars['String']>;
  new_owner_id_contains_nocase?: InputMaybe<Scalars['String']>;
  new_owner_id_not_contains?: InputMaybe<Scalars['String']>;
  new_owner_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  new_owner_id_starts_with?: InputMaybe<Scalars['String']>;
  new_owner_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  new_owner_id_not_starts_with?: InputMaybe<Scalars['String']>;
  new_owner_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  new_owner_id_ends_with?: InputMaybe<Scalars['String']>;
  new_owner_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  new_owner_id_not_ends_with?: InputMaybe<Scalars['String']>;
  new_owner_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  token_id_not?: InputMaybe<Scalars['String']>;
  token_id_gt?: InputMaybe<Scalars['String']>;
  token_id_lt?: InputMaybe<Scalars['String']>;
  token_id_gte?: InputMaybe<Scalars['String']>;
  token_id_lte?: InputMaybe<Scalars['String']>;
  token_id_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_contains?: InputMaybe<Scalars['String']>;
  token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_contains?: InputMaybe<Scalars['String']>;
  token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_starts_with?: InputMaybe<Scalars['String']>;
  token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_ends_with?: InputMaybe<Scalars['String']>;
  token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  authorized_id?: InputMaybe<Scalars['String']>;
  authorized_id_not?: InputMaybe<Scalars['String']>;
  authorized_id_gt?: InputMaybe<Scalars['String']>;
  authorized_id_lt?: InputMaybe<Scalars['String']>;
  authorized_id_gte?: InputMaybe<Scalars['String']>;
  authorized_id_lte?: InputMaybe<Scalars['String']>;
  authorized_id_in?: InputMaybe<Array<Scalars['String']>>;
  authorized_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  authorized_id_contains?: InputMaybe<Scalars['String']>;
  authorized_id_contains_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_not_contains?: InputMaybe<Scalars['String']>;
  authorized_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_starts_with?: InputMaybe<Scalars['String']>;
  authorized_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_not_starts_with?: InputMaybe<Scalars['String']>;
  authorized_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_ends_with?: InputMaybe<Scalars['String']>;
  authorized_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  authorized_id_not_ends_with?: InputMaybe<Scalars['String']>;
  authorized_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  memo?: InputMaybe<Scalars['String']>;
  memo_not?: InputMaybe<Scalars['String']>;
  memo_gt?: InputMaybe<Scalars['String']>;
  memo_lt?: InputMaybe<Scalars['String']>;
  memo_gte?: InputMaybe<Scalars['String']>;
  memo_lte?: InputMaybe<Scalars['String']>;
  memo_in?: InputMaybe<Array<Scalars['String']>>;
  memo_not_in?: InputMaybe<Array<Scalars['String']>>;
  memo_contains?: InputMaybe<Scalars['String']>;
  memo_contains_nocase?: InputMaybe<Scalars['String']>;
  memo_not_contains?: InputMaybe<Scalars['String']>;
  memo_not_contains_nocase?: InputMaybe<Scalars['String']>;
  memo_starts_with?: InputMaybe<Scalars['String']>;
  memo_starts_with_nocase?: InputMaybe<Scalars['String']>;
  memo_not_starts_with?: InputMaybe<Scalars['String']>;
  memo_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  memo_ends_with?: InputMaybe<Scalars['String']>;
  memo_ends_with_nocase?: InputMaybe<Scalars['String']>;
  memo_not_ends_with?: InputMaybe<Scalars['String']>;
  memo_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NftTransferAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NftTransferAction_filter>>>;
};

export type NftTransferAction_orderBy =
  | 'id'
  | 'old_owner_id'
  | 'new_owner_id'
  | 'token_id'
  | 'authorized_id'
  | 'memo';

export type NftUnregisterAction = {
  id: Scalars['ID'];
  registrant_id: Scalars['String'];
  public_key: Scalars['String'];
  is_force: Scalars['Boolean'];
  is_control_success: Scalars['Boolean'];
};

export type NftUnregisterAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  registrant_id?: InputMaybe<Scalars['String']>;
  registrant_id_not?: InputMaybe<Scalars['String']>;
  registrant_id_gt?: InputMaybe<Scalars['String']>;
  registrant_id_lt?: InputMaybe<Scalars['String']>;
  registrant_id_gte?: InputMaybe<Scalars['String']>;
  registrant_id_lte?: InputMaybe<Scalars['String']>;
  registrant_id_in?: InputMaybe<Array<Scalars['String']>>;
  registrant_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  registrant_id_contains?: InputMaybe<Scalars['String']>;
  registrant_id_contains_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_not_contains?: InputMaybe<Scalars['String']>;
  registrant_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_starts_with?: InputMaybe<Scalars['String']>;
  registrant_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_not_starts_with?: InputMaybe<Scalars['String']>;
  registrant_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_ends_with?: InputMaybe<Scalars['String']>;
  registrant_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  registrant_id_not_ends_with?: InputMaybe<Scalars['String']>;
  registrant_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  public_key?: InputMaybe<Scalars['String']>;
  public_key_not?: InputMaybe<Scalars['String']>;
  public_key_gt?: InputMaybe<Scalars['String']>;
  public_key_lt?: InputMaybe<Scalars['String']>;
  public_key_gte?: InputMaybe<Scalars['String']>;
  public_key_lte?: InputMaybe<Scalars['String']>;
  public_key_in?: InputMaybe<Array<Scalars['String']>>;
  public_key_not_in?: InputMaybe<Array<Scalars['String']>>;
  public_key_contains?: InputMaybe<Scalars['String']>;
  public_key_contains_nocase?: InputMaybe<Scalars['String']>;
  public_key_not_contains?: InputMaybe<Scalars['String']>;
  public_key_not_contains_nocase?: InputMaybe<Scalars['String']>;
  public_key_starts_with?: InputMaybe<Scalars['String']>;
  public_key_starts_with_nocase?: InputMaybe<Scalars['String']>;
  public_key_not_starts_with?: InputMaybe<Scalars['String']>;
  public_key_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  public_key_ends_with?: InputMaybe<Scalars['String']>;
  public_key_ends_with_nocase?: InputMaybe<Scalars['String']>;
  public_key_not_ends_with?: InputMaybe<Scalars['String']>;
  public_key_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  is_force?: InputMaybe<Scalars['Boolean']>;
  is_force_not?: InputMaybe<Scalars['Boolean']>;
  is_force_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_force_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_control_success?: InputMaybe<Scalars['Boolean']>;
  is_control_success_not?: InputMaybe<Scalars['Boolean']>;
  is_control_success_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_control_success_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NftUnregisterAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NftUnregisterAction_filter>>>;
};

export type NftUnregisterAction_orderBy =
  | 'id'
  | 'registrant_id'
  | 'public_key'
  | 'is_force'
  | 'is_control_success';

export type NftWatchedNotification = {
  id: Scalars['ID'];
  account_id: Scalars['String'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
  nft: Nft;
};

export type NftWatchedNotification_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  account_id?: InputMaybe<Scalars['String']>;
  account_id_not?: InputMaybe<Scalars['String']>;
  account_id_gt?: InputMaybe<Scalars['String']>;
  account_id_lt?: InputMaybe<Scalars['String']>;
  account_id_gte?: InputMaybe<Scalars['String']>;
  account_id_lte?: InputMaybe<Scalars['String']>;
  account_id_in?: InputMaybe<Array<Scalars['String']>>;
  account_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_id_contains?: InputMaybe<Scalars['String']>;
  account_id_contains_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_contains?: InputMaybe<Scalars['String']>;
  account_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_id_starts_with?: InputMaybe<Scalars['String']>;
  account_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_starts_with?: InputMaybe<Scalars['String']>;
  account_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_ends_with?: InputMaybe<Scalars['String']>;
  account_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_ends_with?: InputMaybe<Scalars['String']>;
  account_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft?: InputMaybe<Scalars['String']>;
  nft_not?: InputMaybe<Scalars['String']>;
  nft_gt?: InputMaybe<Scalars['String']>;
  nft_lt?: InputMaybe<Scalars['String']>;
  nft_gte?: InputMaybe<Scalars['String']>;
  nft_lte?: InputMaybe<Scalars['String']>;
  nft_in?: InputMaybe<Array<Scalars['String']>>;
  nft_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contains?: InputMaybe<Scalars['String']>;
  nft_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_not_contains?: InputMaybe<Scalars['String']>;
  nft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_starts_with?: InputMaybe<Scalars['String']>;
  nft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_ends_with?: InputMaybe<Scalars['String']>;
  nft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_?: InputMaybe<Nft_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<NftWatchedNotification_filter>>>;
  or?: InputMaybe<Array<InputMaybe<NftWatchedNotification_filter>>>;
};

export type NftWatchedNotification_orderBy =
  | 'id'
  | 'account_id'
  | 'nft_contract_id'
  | 'nft_token_id'
  | 'nft'
  | 'nft__id'
  | 'nft__contract_id'
  | 'nft__token_id'
  | 'nft__owner_id'
  | 'nft__is_burn'
  | 'nft__search_name'
  | 'nft__search_name_len'
  | 'nft__mint_time'
  | 'nft__name_len'
  | 'nft__name_level'
  | 'nft__nft_approve_id_for_market_contract'
  | 'nft__nft_approve_id_in_market_contract'
  | 'nft__is_approve_id_effective_in_market_contract'
  | 'nft__price'
  | 'nft__listing_time'
  | 'nft__last_sale_price'
  | 'nft__liked_sum'
  | 'nft__watched_sum'
  | 'nft__character_set'
  | 'nft__contain_separator'
  | 'nft__club'
  | 'nft__offer_count'
  | 'nft__top_domain'
  | 'nft__bottom_domain'
  | 'nft__suffix_domain'
  | 'nft__prefix_domain';

export type Nft_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  contract_id?: InputMaybe<Scalars['String']>;
  contract_id_not?: InputMaybe<Scalars['String']>;
  contract_id_gt?: InputMaybe<Scalars['String']>;
  contract_id_lt?: InputMaybe<Scalars['String']>;
  contract_id_gte?: InputMaybe<Scalars['String']>;
  contract_id_lte?: InputMaybe<Scalars['String']>;
  contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_contains?: InputMaybe<Scalars['String']>;
  contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_contains?: InputMaybe<Scalars['String']>;
  contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  token_id_not?: InputMaybe<Scalars['String']>;
  token_id_gt?: InputMaybe<Scalars['String']>;
  token_id_lt?: InputMaybe<Scalars['String']>;
  token_id_gte?: InputMaybe<Scalars['String']>;
  token_id_lte?: InputMaybe<Scalars['String']>;
  token_id_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_contains?: InputMaybe<Scalars['String']>;
  token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_contains?: InputMaybe<Scalars['String']>;
  token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_starts_with?: InputMaybe<Scalars['String']>;
  token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_ends_with?: InputMaybe<Scalars['String']>;
  token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['String']>;
  owner_id_not?: InputMaybe<Scalars['String']>;
  owner_id_gt?: InputMaybe<Scalars['String']>;
  owner_id_lt?: InputMaybe<Scalars['String']>;
  owner_id_gte?: InputMaybe<Scalars['String']>;
  owner_id_lte?: InputMaybe<Scalars['String']>;
  owner_id_in?: InputMaybe<Array<Scalars['String']>>;
  owner_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  owner_id_contains?: InputMaybe<Scalars['String']>;
  owner_id_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_id_not_contains?: InputMaybe<Scalars['String']>;
  owner_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  owner_id_starts_with?: InputMaybe<Scalars['String']>;
  owner_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_id_not_starts_with?: InputMaybe<Scalars['String']>;
  owner_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  owner_id_ends_with?: InputMaybe<Scalars['String']>;
  owner_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  owner_id_not_ends_with?: InputMaybe<Scalars['String']>;
  owner_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  is_burn?: InputMaybe<Scalars['Boolean']>;
  is_burn_not?: InputMaybe<Scalars['Boolean']>;
  is_burn_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_burn_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  metadata?: InputMaybe<Scalars['String']>;
  metadata_not?: InputMaybe<Scalars['String']>;
  metadata_gt?: InputMaybe<Scalars['String']>;
  metadata_lt?: InputMaybe<Scalars['String']>;
  metadata_gte?: InputMaybe<Scalars['String']>;
  metadata_lte?: InputMaybe<Scalars['String']>;
  metadata_in?: InputMaybe<Array<Scalars['String']>>;
  metadata_not_in?: InputMaybe<Array<Scalars['String']>>;
  metadata_contains?: InputMaybe<Scalars['String']>;
  metadata_contains_nocase?: InputMaybe<Scalars['String']>;
  metadata_not_contains?: InputMaybe<Scalars['String']>;
  metadata_not_contains_nocase?: InputMaybe<Scalars['String']>;
  metadata_starts_with?: InputMaybe<Scalars['String']>;
  metadata_starts_with_nocase?: InputMaybe<Scalars['String']>;
  metadata_not_starts_with?: InputMaybe<Scalars['String']>;
  metadata_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  metadata_ends_with?: InputMaybe<Scalars['String']>;
  metadata_ends_with_nocase?: InputMaybe<Scalars['String']>;
  metadata_not_ends_with?: InputMaybe<Scalars['String']>;
  metadata_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  metadata_?: InputMaybe<TokenMetadata_filter>;
  search_name?: InputMaybe<Scalars['String']>;
  search_name_not?: InputMaybe<Scalars['String']>;
  search_name_gt?: InputMaybe<Scalars['String']>;
  search_name_lt?: InputMaybe<Scalars['String']>;
  search_name_gte?: InputMaybe<Scalars['String']>;
  search_name_lte?: InputMaybe<Scalars['String']>;
  search_name_in?: InputMaybe<Array<Scalars['String']>>;
  search_name_not_in?: InputMaybe<Array<Scalars['String']>>;
  search_name_contains?: InputMaybe<Scalars['String']>;
  search_name_contains_nocase?: InputMaybe<Scalars['String']>;
  search_name_not_contains?: InputMaybe<Scalars['String']>;
  search_name_not_contains_nocase?: InputMaybe<Scalars['String']>;
  search_name_starts_with?: InputMaybe<Scalars['String']>;
  search_name_starts_with_nocase?: InputMaybe<Scalars['String']>;
  search_name_not_starts_with?: InputMaybe<Scalars['String']>;
  search_name_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  search_name_ends_with?: InputMaybe<Scalars['String']>;
  search_name_ends_with_nocase?: InputMaybe<Scalars['String']>;
  search_name_not_ends_with?: InputMaybe<Scalars['String']>;
  search_name_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  search_name_len?: InputMaybe<Scalars['Int']>;
  search_name_len_not?: InputMaybe<Scalars['Int']>;
  search_name_len_gt?: InputMaybe<Scalars['Int']>;
  search_name_len_lt?: InputMaybe<Scalars['Int']>;
  search_name_len_gte?: InputMaybe<Scalars['Int']>;
  search_name_len_lte?: InputMaybe<Scalars['Int']>;
  search_name_len_in?: InputMaybe<Array<Scalars['Int']>>;
  search_name_len_not_in?: InputMaybe<Array<Scalars['Int']>>;
  mint_time?: InputMaybe<Scalars['BigInt']>;
  mint_time_not?: InputMaybe<Scalars['BigInt']>;
  mint_time_gt?: InputMaybe<Scalars['BigInt']>;
  mint_time_lt?: InputMaybe<Scalars['BigInt']>;
  mint_time_gte?: InputMaybe<Scalars['BigInt']>;
  mint_time_lte?: InputMaybe<Scalars['BigInt']>;
  mint_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  mint_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  name_len?: InputMaybe<Scalars['Int']>;
  name_len_not?: InputMaybe<Scalars['Int']>;
  name_len_gt?: InputMaybe<Scalars['Int']>;
  name_len_lt?: InputMaybe<Scalars['Int']>;
  name_len_gte?: InputMaybe<Scalars['Int']>;
  name_len_lte?: InputMaybe<Scalars['Int']>;
  name_len_in?: InputMaybe<Array<Scalars['Int']>>;
  name_len_not_in?: InputMaybe<Array<Scalars['Int']>>;
  name_level?: InputMaybe<Scalars['Int']>;
  name_level_not?: InputMaybe<Scalars['Int']>;
  name_level_gt?: InputMaybe<Scalars['Int']>;
  name_level_lt?: InputMaybe<Scalars['Int']>;
  name_level_gte?: InputMaybe<Scalars['Int']>;
  name_level_lte?: InputMaybe<Scalars['Int']>;
  name_level_in?: InputMaybe<Array<Scalars['Int']>>;
  name_level_not_in?: InputMaybe<Array<Scalars['Int']>>;
  nft_approve_id_for_market_contract?: InputMaybe<Scalars['Int']>;
  nft_approve_id_for_market_contract_not?: InputMaybe<Scalars['Int']>;
  nft_approve_id_for_market_contract_gt?: InputMaybe<Scalars['Int']>;
  nft_approve_id_for_market_contract_lt?: InputMaybe<Scalars['Int']>;
  nft_approve_id_for_market_contract_gte?: InputMaybe<Scalars['Int']>;
  nft_approve_id_for_market_contract_lte?: InputMaybe<Scalars['Int']>;
  nft_approve_id_for_market_contract_in?: InputMaybe<Array<Scalars['Int']>>;
  nft_approve_id_for_market_contract_not_in?: InputMaybe<Array<Scalars['Int']>>;
  nft_approve_id_in_market_contract?: InputMaybe<Scalars['Int']>;
  nft_approve_id_in_market_contract_not?: InputMaybe<Scalars['Int']>;
  nft_approve_id_in_market_contract_gt?: InputMaybe<Scalars['Int']>;
  nft_approve_id_in_market_contract_lt?: InputMaybe<Scalars['Int']>;
  nft_approve_id_in_market_contract_gte?: InputMaybe<Scalars['Int']>;
  nft_approve_id_in_market_contract_lte?: InputMaybe<Scalars['Int']>;
  nft_approve_id_in_market_contract_in?: InputMaybe<Array<Scalars['Int']>>;
  nft_approve_id_in_market_contract_not_in?: InputMaybe<Array<Scalars['Int']>>;
  is_approve_id_effective_in_market_contract?: InputMaybe<Scalars['Boolean']>;
  is_approve_id_effective_in_market_contract_not?: InputMaybe<Scalars['Boolean']>;
  is_approve_id_effective_in_market_contract_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_approve_id_effective_in_market_contract_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  listing?: InputMaybe<Scalars['String']>;
  listing_not?: InputMaybe<Scalars['String']>;
  listing_gt?: InputMaybe<Scalars['String']>;
  listing_lt?: InputMaybe<Scalars['String']>;
  listing_gte?: InputMaybe<Scalars['String']>;
  listing_lte?: InputMaybe<Scalars['String']>;
  listing_in?: InputMaybe<Array<Scalars['String']>>;
  listing_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_contains?: InputMaybe<Scalars['String']>;
  listing_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_not_contains?: InputMaybe<Scalars['String']>;
  listing_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_starts_with?: InputMaybe<Scalars['String']>;
  listing_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_ends_with?: InputMaybe<Scalars['String']>;
  listing_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_?: InputMaybe<Listing_filter>;
  listing_time?: InputMaybe<Scalars['BigInt']>;
  listing_time_not?: InputMaybe<Scalars['BigInt']>;
  listing_time_gt?: InputMaybe<Scalars['BigInt']>;
  listing_time_lt?: InputMaybe<Scalars['BigInt']>;
  listing_time_gte?: InputMaybe<Scalars['BigInt']>;
  listing_time_lte?: InputMaybe<Scalars['BigInt']>;
  listing_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  listing_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  last_sale_price?: InputMaybe<Scalars['BigInt']>;
  last_sale_price_not?: InputMaybe<Scalars['BigInt']>;
  last_sale_price_gt?: InputMaybe<Scalars['BigInt']>;
  last_sale_price_lt?: InputMaybe<Scalars['BigInt']>;
  last_sale_price_gte?: InputMaybe<Scalars['BigInt']>;
  last_sale_price_lte?: InputMaybe<Scalars['BigInt']>;
  last_sale_price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  last_sale_price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  liked_sum?: InputMaybe<Scalars['Int']>;
  liked_sum_not?: InputMaybe<Scalars['Int']>;
  liked_sum_gt?: InputMaybe<Scalars['Int']>;
  liked_sum_lt?: InputMaybe<Scalars['Int']>;
  liked_sum_gte?: InputMaybe<Scalars['Int']>;
  liked_sum_lte?: InputMaybe<Scalars['Int']>;
  liked_sum_in?: InputMaybe<Array<Scalars['Int']>>;
  liked_sum_not_in?: InputMaybe<Array<Scalars['Int']>>;
  watched_sum?: InputMaybe<Scalars['Int']>;
  watched_sum_not?: InputMaybe<Scalars['Int']>;
  watched_sum_gt?: InputMaybe<Scalars['Int']>;
  watched_sum_lt?: InputMaybe<Scalars['Int']>;
  watched_sum_gte?: InputMaybe<Scalars['Int']>;
  watched_sum_lte?: InputMaybe<Scalars['Int']>;
  watched_sum_in?: InputMaybe<Array<Scalars['Int']>>;
  watched_sum_not_in?: InputMaybe<Array<Scalars['Int']>>;
  like_by_users_?: InputMaybe<UserLikeNft_filter>;
  watch_by_users_?: InputMaybe<UserWatchNft_filter>;
  character_set?: InputMaybe<CharacterSet>;
  character_set_not?: InputMaybe<CharacterSet>;
  character_set_in?: InputMaybe<Array<CharacterSet>>;
  character_set_not_in?: InputMaybe<Array<CharacterSet>>;
  contain_separator?: InputMaybe<Scalars['Boolean']>;
  contain_separator_not?: InputMaybe<Scalars['Boolean']>;
  contain_separator_in?: InputMaybe<Array<Scalars['Boolean']>>;
  contain_separator_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  club?: InputMaybe<NftClub>;
  club_not?: InputMaybe<NftClub>;
  club_in?: InputMaybe<Array<NftClub>>;
  club_not_in?: InputMaybe<Array<NftClub>>;
  offer_count?: InputMaybe<Scalars['Int']>;
  offer_count_not?: InputMaybe<Scalars['Int']>;
  offer_count_gt?: InputMaybe<Scalars['Int']>;
  offer_count_lt?: InputMaybe<Scalars['Int']>;
  offer_count_gte?: InputMaybe<Scalars['Int']>;
  offer_count_lte?: InputMaybe<Scalars['Int']>;
  offer_count_in?: InputMaybe<Array<Scalars['Int']>>;
  offer_count_not_in?: InputMaybe<Array<Scalars['Int']>>;
  offers_?: InputMaybe<Offering_filter>;
  top_domain?: InputMaybe<Scalars['String']>;
  top_domain_not?: InputMaybe<Scalars['String']>;
  top_domain_gt?: InputMaybe<Scalars['String']>;
  top_domain_lt?: InputMaybe<Scalars['String']>;
  top_domain_gte?: InputMaybe<Scalars['String']>;
  top_domain_lte?: InputMaybe<Scalars['String']>;
  top_domain_in?: InputMaybe<Array<Scalars['String']>>;
  top_domain_not_in?: InputMaybe<Array<Scalars['String']>>;
  top_domain_contains?: InputMaybe<Scalars['String']>;
  top_domain_contains_nocase?: InputMaybe<Scalars['String']>;
  top_domain_not_contains?: InputMaybe<Scalars['String']>;
  top_domain_not_contains_nocase?: InputMaybe<Scalars['String']>;
  top_domain_starts_with?: InputMaybe<Scalars['String']>;
  top_domain_starts_with_nocase?: InputMaybe<Scalars['String']>;
  top_domain_not_starts_with?: InputMaybe<Scalars['String']>;
  top_domain_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  top_domain_ends_with?: InputMaybe<Scalars['String']>;
  top_domain_ends_with_nocase?: InputMaybe<Scalars['String']>;
  top_domain_not_ends_with?: InputMaybe<Scalars['String']>;
  top_domain_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  bottom_domain?: InputMaybe<Scalars['String']>;
  bottom_domain_not?: InputMaybe<Scalars['String']>;
  bottom_domain_gt?: InputMaybe<Scalars['String']>;
  bottom_domain_lt?: InputMaybe<Scalars['String']>;
  bottom_domain_gte?: InputMaybe<Scalars['String']>;
  bottom_domain_lte?: InputMaybe<Scalars['String']>;
  bottom_domain_in?: InputMaybe<Array<Scalars['String']>>;
  bottom_domain_not_in?: InputMaybe<Array<Scalars['String']>>;
  bottom_domain_contains?: InputMaybe<Scalars['String']>;
  bottom_domain_contains_nocase?: InputMaybe<Scalars['String']>;
  bottom_domain_not_contains?: InputMaybe<Scalars['String']>;
  bottom_domain_not_contains_nocase?: InputMaybe<Scalars['String']>;
  bottom_domain_starts_with?: InputMaybe<Scalars['String']>;
  bottom_domain_starts_with_nocase?: InputMaybe<Scalars['String']>;
  bottom_domain_not_starts_with?: InputMaybe<Scalars['String']>;
  bottom_domain_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  bottom_domain_ends_with?: InputMaybe<Scalars['String']>;
  bottom_domain_ends_with_nocase?: InputMaybe<Scalars['String']>;
  bottom_domain_not_ends_with?: InputMaybe<Scalars['String']>;
  bottom_domain_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  suffix_domain?: InputMaybe<Scalars['String']>;
  suffix_domain_not?: InputMaybe<Scalars['String']>;
  suffix_domain_gt?: InputMaybe<Scalars['String']>;
  suffix_domain_lt?: InputMaybe<Scalars['String']>;
  suffix_domain_gte?: InputMaybe<Scalars['String']>;
  suffix_domain_lte?: InputMaybe<Scalars['String']>;
  suffix_domain_in?: InputMaybe<Array<Scalars['String']>>;
  suffix_domain_not_in?: InputMaybe<Array<Scalars['String']>>;
  suffix_domain_contains?: InputMaybe<Scalars['String']>;
  suffix_domain_contains_nocase?: InputMaybe<Scalars['String']>;
  suffix_domain_not_contains?: InputMaybe<Scalars['String']>;
  suffix_domain_not_contains_nocase?: InputMaybe<Scalars['String']>;
  suffix_domain_starts_with?: InputMaybe<Scalars['String']>;
  suffix_domain_starts_with_nocase?: InputMaybe<Scalars['String']>;
  suffix_domain_not_starts_with?: InputMaybe<Scalars['String']>;
  suffix_domain_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  suffix_domain_ends_with?: InputMaybe<Scalars['String']>;
  suffix_domain_ends_with_nocase?: InputMaybe<Scalars['String']>;
  suffix_domain_not_ends_with?: InputMaybe<Scalars['String']>;
  suffix_domain_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  prefix_domain?: InputMaybe<Scalars['String']>;
  prefix_domain_not?: InputMaybe<Scalars['String']>;
  prefix_domain_gt?: InputMaybe<Scalars['String']>;
  prefix_domain_lt?: InputMaybe<Scalars['String']>;
  prefix_domain_gte?: InputMaybe<Scalars['String']>;
  prefix_domain_lte?: InputMaybe<Scalars['String']>;
  prefix_domain_in?: InputMaybe<Array<Scalars['String']>>;
  prefix_domain_not_in?: InputMaybe<Array<Scalars['String']>>;
  prefix_domain_contains?: InputMaybe<Scalars['String']>;
  prefix_domain_contains_nocase?: InputMaybe<Scalars['String']>;
  prefix_domain_not_contains?: InputMaybe<Scalars['String']>;
  prefix_domain_not_contains_nocase?: InputMaybe<Scalars['String']>;
  prefix_domain_starts_with?: InputMaybe<Scalars['String']>;
  prefix_domain_starts_with_nocase?: InputMaybe<Scalars['String']>;
  prefix_domain_not_starts_with?: InputMaybe<Scalars['String']>;
  prefix_domain_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  prefix_domain_ends_with?: InputMaybe<Scalars['String']>;
  prefix_domain_ends_with_nocase?: InputMaybe<Scalars['String']>;
  prefix_domain_not_ends_with?: InputMaybe<Scalars['String']>;
  prefix_domain_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Nft_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Nft_filter>>>;
};

export type Nft_orderBy =
  | 'id'
  | 'contract_id'
  | 'token_id'
  | 'owner_id'
  | 'is_burn'
  | 'metadata'
  | 'metadata__id'
  | 'metadata__title'
  | 'metadata__description'
  | 'metadata__media'
  | 'metadata__media_hash'
  | 'metadata__copies'
  | 'metadata__issued_at'
  | 'metadata__expires_at'
  | 'metadata__starts_at'
  | 'metadata__updated_at'
  | 'metadata__extra'
  | 'metadata__reference'
  | 'metadata__reference_hash'
  | 'search_name'
  | 'search_name_len'
  | 'mint_time'
  | 'name_len'
  | 'name_level'
  | 'nft_approve_id_for_market_contract'
  | 'nft_approve_id_in_market_contract'
  | 'is_approve_id_effective_in_market_contract'
  | 'price'
  | 'listing'
  | 'listing__id'
  | 'listing__seller_id'
  | 'listing__contract_id'
  | 'listing__token_id'
  | 'listing__price'
  | 'listing__create_time'
  | 'listing__update_time'
  | 'listing__is_remove'
  | 'listing_time'
  | 'last_sale_price'
  | 'liked_sum'
  | 'watched_sum'
  | 'like_by_users'
  | 'watch_by_users'
  | 'character_set'
  | 'contain_separator'
  | 'club'
  | 'offer_count'
  | 'offers'
  | 'top_domain'
  | 'bottom_domain'
  | 'suffix_domain'
  | 'prefix_domain';

export type Notification = {
  id: Scalars['ID'];
  receiver?: Maybe<Scalars['String']>;
  receipt_id: Scalars['String'];
  contract_id: Scalars['String'];
  timestamp: Scalars['BigInt'];
  timestamp_plus_log_index: Scalars['BigInt'];
  notification_type: NotificationType;
  nft_liked_notification?: Maybe<NftLikedNotification>;
  nft_watched_notification?: Maybe<NftWatchedNotification>;
  offer_accepted_notification?: Maybe<OfferAcceptedNotification>;
  listing_sold_notification?: Maybe<ListingSoldNotification>;
  receive_offer_notification?: Maybe<ReceiveOfferNotification>;
  offer_update_notification?: Maybe<OfferUpdateNotification>;
  receive_nft_notification?: Maybe<ReceiveNftNotification>;
};

export type NotificationType =
  | 'nft_liked_notification'
  | 'nft_watched_notification'
  | 'offer_update_notification'
  | 'receive_offer_notification'
  | 'offer_accepted_notification'
  | 'listing_sold_notification'
  | 'receive_nft_notification';

export type Notification_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  receiver?: InputMaybe<Scalars['String']>;
  receiver_not?: InputMaybe<Scalars['String']>;
  receiver_gt?: InputMaybe<Scalars['String']>;
  receiver_lt?: InputMaybe<Scalars['String']>;
  receiver_gte?: InputMaybe<Scalars['String']>;
  receiver_lte?: InputMaybe<Scalars['String']>;
  receiver_in?: InputMaybe<Array<Scalars['String']>>;
  receiver_not_in?: InputMaybe<Array<Scalars['String']>>;
  receiver_contains?: InputMaybe<Scalars['String']>;
  receiver_contains_nocase?: InputMaybe<Scalars['String']>;
  receiver_not_contains?: InputMaybe<Scalars['String']>;
  receiver_not_contains_nocase?: InputMaybe<Scalars['String']>;
  receiver_starts_with?: InputMaybe<Scalars['String']>;
  receiver_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receiver_not_starts_with?: InputMaybe<Scalars['String']>;
  receiver_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receiver_ends_with?: InputMaybe<Scalars['String']>;
  receiver_ends_with_nocase?: InputMaybe<Scalars['String']>;
  receiver_not_ends_with?: InputMaybe<Scalars['String']>;
  receiver_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  receipt_id?: InputMaybe<Scalars['String']>;
  receipt_id_not?: InputMaybe<Scalars['String']>;
  receipt_id_gt?: InputMaybe<Scalars['String']>;
  receipt_id_lt?: InputMaybe<Scalars['String']>;
  receipt_id_gte?: InputMaybe<Scalars['String']>;
  receipt_id_lte?: InputMaybe<Scalars['String']>;
  receipt_id_in?: InputMaybe<Array<Scalars['String']>>;
  receipt_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  receipt_id_contains?: InputMaybe<Scalars['String']>;
  receipt_id_contains_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_not_contains?: InputMaybe<Scalars['String']>;
  receipt_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_starts_with?: InputMaybe<Scalars['String']>;
  receipt_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_not_starts_with?: InputMaybe<Scalars['String']>;
  receipt_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_ends_with?: InputMaybe<Scalars['String']>;
  receipt_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_not_ends_with?: InputMaybe<Scalars['String']>;
  receipt_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['String']>;
  contract_id_not?: InputMaybe<Scalars['String']>;
  contract_id_gt?: InputMaybe<Scalars['String']>;
  contract_id_lt?: InputMaybe<Scalars['String']>;
  contract_id_gte?: InputMaybe<Scalars['String']>;
  contract_id_lte?: InputMaybe<Scalars['String']>;
  contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_contains?: InputMaybe<Scalars['String']>;
  contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_contains?: InputMaybe<Scalars['String']>;
  contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_plus_log_index?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_plus_log_index_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  notification_type?: InputMaybe<NotificationType>;
  notification_type_not?: InputMaybe<NotificationType>;
  notification_type_in?: InputMaybe<Array<NotificationType>>;
  notification_type_not_in?: InputMaybe<Array<NotificationType>>;
  nft_liked_notification?: InputMaybe<Scalars['String']>;
  nft_liked_notification_not?: InputMaybe<Scalars['String']>;
  nft_liked_notification_gt?: InputMaybe<Scalars['String']>;
  nft_liked_notification_lt?: InputMaybe<Scalars['String']>;
  nft_liked_notification_gte?: InputMaybe<Scalars['String']>;
  nft_liked_notification_lte?: InputMaybe<Scalars['String']>;
  nft_liked_notification_in?: InputMaybe<Array<Scalars['String']>>;
  nft_liked_notification_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_liked_notification_contains?: InputMaybe<Scalars['String']>;
  nft_liked_notification_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_liked_notification_not_contains?: InputMaybe<Scalars['String']>;
  nft_liked_notification_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_liked_notification_starts_with?: InputMaybe<Scalars['String']>;
  nft_liked_notification_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_liked_notification_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_liked_notification_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_liked_notification_ends_with?: InputMaybe<Scalars['String']>;
  nft_liked_notification_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_liked_notification_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_liked_notification_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_liked_notification_?: InputMaybe<NftLikedNotification_filter>;
  nft_watched_notification?: InputMaybe<Scalars['String']>;
  nft_watched_notification_not?: InputMaybe<Scalars['String']>;
  nft_watched_notification_gt?: InputMaybe<Scalars['String']>;
  nft_watched_notification_lt?: InputMaybe<Scalars['String']>;
  nft_watched_notification_gte?: InputMaybe<Scalars['String']>;
  nft_watched_notification_lte?: InputMaybe<Scalars['String']>;
  nft_watched_notification_in?: InputMaybe<Array<Scalars['String']>>;
  nft_watched_notification_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_watched_notification_contains?: InputMaybe<Scalars['String']>;
  nft_watched_notification_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_watched_notification_not_contains?: InputMaybe<Scalars['String']>;
  nft_watched_notification_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_watched_notification_starts_with?: InputMaybe<Scalars['String']>;
  nft_watched_notification_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_watched_notification_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_watched_notification_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_watched_notification_ends_with?: InputMaybe<Scalars['String']>;
  nft_watched_notification_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_watched_notification_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_watched_notification_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_watched_notification_?: InputMaybe<NftWatchedNotification_filter>;
  offer_accepted_notification?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_not?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_gt?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_lt?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_gte?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_lte?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_in?: InputMaybe<Array<Scalars['String']>>;
  offer_accepted_notification_not_in?: InputMaybe<Array<Scalars['String']>>;
  offer_accepted_notification_contains?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_not_contains?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_starts_with?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_not_starts_with?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_ends_with?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_not_ends_with?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_accepted_notification_?: InputMaybe<OfferAcceptedNotification_filter>;
  listing_sold_notification?: InputMaybe<Scalars['String']>;
  listing_sold_notification_not?: InputMaybe<Scalars['String']>;
  listing_sold_notification_gt?: InputMaybe<Scalars['String']>;
  listing_sold_notification_lt?: InputMaybe<Scalars['String']>;
  listing_sold_notification_gte?: InputMaybe<Scalars['String']>;
  listing_sold_notification_lte?: InputMaybe<Scalars['String']>;
  listing_sold_notification_in?: InputMaybe<Array<Scalars['String']>>;
  listing_sold_notification_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_sold_notification_contains?: InputMaybe<Scalars['String']>;
  listing_sold_notification_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_sold_notification_not_contains?: InputMaybe<Scalars['String']>;
  listing_sold_notification_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_sold_notification_starts_with?: InputMaybe<Scalars['String']>;
  listing_sold_notification_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_sold_notification_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_sold_notification_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_sold_notification_ends_with?: InputMaybe<Scalars['String']>;
  listing_sold_notification_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_sold_notification_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_sold_notification_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_sold_notification_?: InputMaybe<ListingSoldNotification_filter>;
  receive_offer_notification?: InputMaybe<Scalars['String']>;
  receive_offer_notification_not?: InputMaybe<Scalars['String']>;
  receive_offer_notification_gt?: InputMaybe<Scalars['String']>;
  receive_offer_notification_lt?: InputMaybe<Scalars['String']>;
  receive_offer_notification_gte?: InputMaybe<Scalars['String']>;
  receive_offer_notification_lte?: InputMaybe<Scalars['String']>;
  receive_offer_notification_in?: InputMaybe<Array<Scalars['String']>>;
  receive_offer_notification_not_in?: InputMaybe<Array<Scalars['String']>>;
  receive_offer_notification_contains?: InputMaybe<Scalars['String']>;
  receive_offer_notification_contains_nocase?: InputMaybe<Scalars['String']>;
  receive_offer_notification_not_contains?: InputMaybe<Scalars['String']>;
  receive_offer_notification_not_contains_nocase?: InputMaybe<Scalars['String']>;
  receive_offer_notification_starts_with?: InputMaybe<Scalars['String']>;
  receive_offer_notification_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receive_offer_notification_not_starts_with?: InputMaybe<Scalars['String']>;
  receive_offer_notification_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receive_offer_notification_ends_with?: InputMaybe<Scalars['String']>;
  receive_offer_notification_ends_with_nocase?: InputMaybe<Scalars['String']>;
  receive_offer_notification_not_ends_with?: InputMaybe<Scalars['String']>;
  receive_offer_notification_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  receive_offer_notification_?: InputMaybe<ReceiveOfferNotification_filter>;
  offer_update_notification?: InputMaybe<Scalars['String']>;
  offer_update_notification_not?: InputMaybe<Scalars['String']>;
  offer_update_notification_gt?: InputMaybe<Scalars['String']>;
  offer_update_notification_lt?: InputMaybe<Scalars['String']>;
  offer_update_notification_gte?: InputMaybe<Scalars['String']>;
  offer_update_notification_lte?: InputMaybe<Scalars['String']>;
  offer_update_notification_in?: InputMaybe<Array<Scalars['String']>>;
  offer_update_notification_not_in?: InputMaybe<Array<Scalars['String']>>;
  offer_update_notification_contains?: InputMaybe<Scalars['String']>;
  offer_update_notification_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_update_notification_not_contains?: InputMaybe<Scalars['String']>;
  offer_update_notification_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_update_notification_starts_with?: InputMaybe<Scalars['String']>;
  offer_update_notification_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_update_notification_not_starts_with?: InputMaybe<Scalars['String']>;
  offer_update_notification_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_update_notification_ends_with?: InputMaybe<Scalars['String']>;
  offer_update_notification_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_update_notification_not_ends_with?: InputMaybe<Scalars['String']>;
  offer_update_notification_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_update_notification_?: InputMaybe<OfferUpdateNotification_filter>;
  receive_nft_notification?: InputMaybe<Scalars['String']>;
  receive_nft_notification_not?: InputMaybe<Scalars['String']>;
  receive_nft_notification_gt?: InputMaybe<Scalars['String']>;
  receive_nft_notification_lt?: InputMaybe<Scalars['String']>;
  receive_nft_notification_gte?: InputMaybe<Scalars['String']>;
  receive_nft_notification_lte?: InputMaybe<Scalars['String']>;
  receive_nft_notification_in?: InputMaybe<Array<Scalars['String']>>;
  receive_nft_notification_not_in?: InputMaybe<Array<Scalars['String']>>;
  receive_nft_notification_contains?: InputMaybe<Scalars['String']>;
  receive_nft_notification_contains_nocase?: InputMaybe<Scalars['String']>;
  receive_nft_notification_not_contains?: InputMaybe<Scalars['String']>;
  receive_nft_notification_not_contains_nocase?: InputMaybe<Scalars['String']>;
  receive_nft_notification_starts_with?: InputMaybe<Scalars['String']>;
  receive_nft_notification_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receive_nft_notification_not_starts_with?: InputMaybe<Scalars['String']>;
  receive_nft_notification_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receive_nft_notification_ends_with?: InputMaybe<Scalars['String']>;
  receive_nft_notification_ends_with_nocase?: InputMaybe<Scalars['String']>;
  receive_nft_notification_not_ends_with?: InputMaybe<Scalars['String']>;
  receive_nft_notification_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  receive_nft_notification_?: InputMaybe<ReceiveNftNotification_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Notification_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Notification_filter>>>;
};

export type Notification_orderBy =
  | 'id'
  | 'receiver'
  | 'receipt_id'
  | 'contract_id'
  | 'timestamp'
  | 'timestamp_plus_log_index'
  | 'notification_type'
  | 'nft_liked_notification'
  | 'nft_liked_notification__id'
  | 'nft_liked_notification__account_id'
  | 'nft_liked_notification__nft_contract_id'
  | 'nft_liked_notification__nft_token_id'
  | 'nft_watched_notification'
  | 'nft_watched_notification__id'
  | 'nft_watched_notification__account_id'
  | 'nft_watched_notification__nft_contract_id'
  | 'nft_watched_notification__nft_token_id'
  | 'offer_accepted_notification'
  | 'offer_accepted_notification__id'
  | 'listing_sold_notification'
  | 'listing_sold_notification__id'
  | 'receive_offer_notification'
  | 'receive_offer_notification__id'
  | 'offer_update_notification'
  | 'offer_update_notification__id'
  | 'receive_nft_notification'
  | 'receive_nft_notification__id';

export type OfferAcceptedNotification = {
  id: Scalars['ID'];
  accept_offering_action: AcceptOfferingAction;
};

export type OfferAcceptedNotification_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  accept_offering_action?: InputMaybe<Scalars['String']>;
  accept_offering_action_not?: InputMaybe<Scalars['String']>;
  accept_offering_action_gt?: InputMaybe<Scalars['String']>;
  accept_offering_action_lt?: InputMaybe<Scalars['String']>;
  accept_offering_action_gte?: InputMaybe<Scalars['String']>;
  accept_offering_action_lte?: InputMaybe<Scalars['String']>;
  accept_offering_action_in?: InputMaybe<Array<Scalars['String']>>;
  accept_offering_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  accept_offering_action_contains?: InputMaybe<Scalars['String']>;
  accept_offering_action_contains_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_contains?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_starts_with?: InputMaybe<Scalars['String']>;
  accept_offering_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_starts_with?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_ends_with?: InputMaybe<Scalars['String']>;
  accept_offering_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_ends_with?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_?: InputMaybe<AcceptOfferingAction_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OfferAcceptedNotification_filter>>>;
  or?: InputMaybe<Array<InputMaybe<OfferAcceptedNotification_filter>>>;
};

export type OfferAcceptedNotification_orderBy =
  | 'id'
  | 'accept_offering_action'
  | 'accept_offering_action__id'
  | 'accept_offering_action__buyer_id'
  | 'accept_offering_action__seller_id'
  | 'accept_offering_action__prepaid_balance'
  | 'accept_offering_action__payment_balance'
  | 'accept_offering_action__payout_balance'
  | 'accept_offering_action__nft_contract_id'
  | 'accept_offering_action__nft_token_id'
  | 'accept_offering_action__trading_id'
  | 'accept_offering_action__offering_id';

export type OfferUpdateNotification = {
  id: Scalars['ID'];
  update_offering_action: UpdateOfferingAction;
};

export type OfferUpdateNotification_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  update_offering_action?: InputMaybe<Scalars['String']>;
  update_offering_action_not?: InputMaybe<Scalars['String']>;
  update_offering_action_gt?: InputMaybe<Scalars['String']>;
  update_offering_action_lt?: InputMaybe<Scalars['String']>;
  update_offering_action_gte?: InputMaybe<Scalars['String']>;
  update_offering_action_lte?: InputMaybe<Scalars['String']>;
  update_offering_action_in?: InputMaybe<Array<Scalars['String']>>;
  update_offering_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  update_offering_action_contains?: InputMaybe<Scalars['String']>;
  update_offering_action_contains_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_not_contains?: InputMaybe<Scalars['String']>;
  update_offering_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_starts_with?: InputMaybe<Scalars['String']>;
  update_offering_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_not_starts_with?: InputMaybe<Scalars['String']>;
  update_offering_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_ends_with?: InputMaybe<Scalars['String']>;
  update_offering_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_not_ends_with?: InputMaybe<Scalars['String']>;
  update_offering_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_?: InputMaybe<UpdateOfferingAction_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<OfferUpdateNotification_filter>>>;
  or?: InputMaybe<Array<InputMaybe<OfferUpdateNotification_filter>>>;
};

export type OfferUpdateNotification_orderBy =
  | 'id'
  | 'update_offering_action'
  | 'update_offering_action__id'
  | 'update_offering_action__offer_creator'
  | 'update_offering_action__nft_contract_id'
  | 'update_offering_action__nft_token_id'
  | 'update_offering_action__old_price'
  | 'update_offering_action__new_price'
  | 'update_offering_action__offering_id';

export type Offering = {
  id: Scalars['ID'];
  buyer_id: Scalars['String'];
  contract_id: Scalars['String'];
  token_id: Scalars['String'];
  price: Scalars['BigInt'];
  create_time: Scalars['BigInt'];
  update_time: Scalars['BigInt'];
  is_simple_offering: Scalars['Boolean'];
  is_remove: Scalars['Boolean'];
  expire_time?: Maybe<Scalars['BigInt']>;
  nft_id: Nft;
};

export type Offering_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  buyer_id?: InputMaybe<Scalars['String']>;
  buyer_id_not?: InputMaybe<Scalars['String']>;
  buyer_id_gt?: InputMaybe<Scalars['String']>;
  buyer_id_lt?: InputMaybe<Scalars['String']>;
  buyer_id_gte?: InputMaybe<Scalars['String']>;
  buyer_id_lte?: InputMaybe<Scalars['String']>;
  buyer_id_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_id_contains?: InputMaybe<Scalars['String']>;
  buyer_id_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_contains?: InputMaybe<Scalars['String']>;
  buyer_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_starts_with?: InputMaybe<Scalars['String']>;
  buyer_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_starts_with?: InputMaybe<Scalars['String']>;
  buyer_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_ends_with?: InputMaybe<Scalars['String']>;
  buyer_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_ends_with?: InputMaybe<Scalars['String']>;
  buyer_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id?: InputMaybe<Scalars['String']>;
  contract_id_not?: InputMaybe<Scalars['String']>;
  contract_id_gt?: InputMaybe<Scalars['String']>;
  contract_id_lt?: InputMaybe<Scalars['String']>;
  contract_id_gte?: InputMaybe<Scalars['String']>;
  contract_id_lte?: InputMaybe<Scalars['String']>;
  contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_contains?: InputMaybe<Scalars['String']>;
  contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_contains?: InputMaybe<Scalars['String']>;
  contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  token_id_not?: InputMaybe<Scalars['String']>;
  token_id_gt?: InputMaybe<Scalars['String']>;
  token_id_lt?: InputMaybe<Scalars['String']>;
  token_id_gte?: InputMaybe<Scalars['String']>;
  token_id_lte?: InputMaybe<Scalars['String']>;
  token_id_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_contains?: InputMaybe<Scalars['String']>;
  token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_contains?: InputMaybe<Scalars['String']>;
  token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_starts_with?: InputMaybe<Scalars['String']>;
  token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_ends_with?: InputMaybe<Scalars['String']>;
  token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  create_time?: InputMaybe<Scalars['BigInt']>;
  create_time_not?: InputMaybe<Scalars['BigInt']>;
  create_time_gt?: InputMaybe<Scalars['BigInt']>;
  create_time_lt?: InputMaybe<Scalars['BigInt']>;
  create_time_gte?: InputMaybe<Scalars['BigInt']>;
  create_time_lte?: InputMaybe<Scalars['BigInt']>;
  create_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  create_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  update_time?: InputMaybe<Scalars['BigInt']>;
  update_time_not?: InputMaybe<Scalars['BigInt']>;
  update_time_gt?: InputMaybe<Scalars['BigInt']>;
  update_time_lt?: InputMaybe<Scalars['BigInt']>;
  update_time_gte?: InputMaybe<Scalars['BigInt']>;
  update_time_lte?: InputMaybe<Scalars['BigInt']>;
  update_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  update_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  is_simple_offering?: InputMaybe<Scalars['Boolean']>;
  is_simple_offering_not?: InputMaybe<Scalars['Boolean']>;
  is_simple_offering_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_simple_offering_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_remove?: InputMaybe<Scalars['Boolean']>;
  is_remove_not?: InputMaybe<Scalars['Boolean']>;
  is_remove_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_remove_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  expire_time?: InputMaybe<Scalars['BigInt']>;
  expire_time_not?: InputMaybe<Scalars['BigInt']>;
  expire_time_gt?: InputMaybe<Scalars['BigInt']>;
  expire_time_lt?: InputMaybe<Scalars['BigInt']>;
  expire_time_gte?: InputMaybe<Scalars['BigInt']>;
  expire_time_lte?: InputMaybe<Scalars['BigInt']>;
  expire_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  expire_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  nft_id?: InputMaybe<Scalars['String']>;
  nft_id_not?: InputMaybe<Scalars['String']>;
  nft_id_gt?: InputMaybe<Scalars['String']>;
  nft_id_lt?: InputMaybe<Scalars['String']>;
  nft_id_gte?: InputMaybe<Scalars['String']>;
  nft_id_lte?: InputMaybe<Scalars['String']>;
  nft_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_id_contains?: InputMaybe<Scalars['String']>;
  nft_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_id_?: InputMaybe<Nft_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Offering_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Offering_filter>>>;
};

export type Offering_orderBy =
  | 'id'
  | 'buyer_id'
  | 'contract_id'
  | 'token_id'
  | 'price'
  | 'create_time'
  | 'update_time'
  | 'is_simple_offering'
  | 'is_remove'
  | 'expire_time'
  | 'nft_id'
  | 'nft_id__id'
  | 'nft_id__contract_id'
  | 'nft_id__token_id'
  | 'nft_id__owner_id'
  | 'nft_id__is_burn'
  | 'nft_id__search_name'
  | 'nft_id__search_name_len'
  | 'nft_id__mint_time'
  | 'nft_id__name_len'
  | 'nft_id__name_level'
  | 'nft_id__nft_approve_id_for_market_contract'
  | 'nft_id__nft_approve_id_in_market_contract'
  | 'nft_id__is_approve_id_effective_in_market_contract'
  | 'nft_id__price'
  | 'nft_id__listing_time'
  | 'nft_id__last_sale_price'
  | 'nft_id__liked_sum'
  | 'nft_id__watched_sum'
  | 'nft_id__character_set'
  | 'nft_id__contain_separator'
  | 'nft_id__club'
  | 'nft_id__offer_count'
  | 'nft_id__top_domain'
  | 'nft_id__bottom_domain'
  | 'nft_id__suffix_domain'
  | 'nft_id__prefix_domain';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type Query = {
  listing?: Maybe<Listing>;
  listings: Array<Listing>;
  offering?: Maybe<Offering>;
  offerings: Array<Offering>;
  trading?: Maybe<Trading>;
  tradings: Array<Trading>;
  userAction?: Maybe<UserAction>;
  userActions: Array<UserAction>;
  createListingAction?: Maybe<CreateListingAction>;
  createListingActions: Array<CreateListingAction>;
  updateListingAction?: Maybe<UpdateListingAction>;
  updateListingActions: Array<UpdateListingAction>;
  removeListingAction?: Maybe<RemoveListingAction>;
  removeListingActions: Array<RemoveListingAction>;
  createOfferingAction?: Maybe<CreateOfferingAction>;
  createOfferingActions: Array<CreateOfferingAction>;
  updateOfferingAction?: Maybe<UpdateOfferingAction>;
  updateOfferingActions: Array<UpdateOfferingAction>;
  removeOfferingAction?: Maybe<RemoveOfferingAction>;
  removeOfferingActions: Array<RemoveOfferingAction>;
  buyListingAction?: Maybe<BuyListingAction>;
  buyListingActions: Array<BuyListingAction>;
  acceptOfferingAction?: Maybe<AcceptOfferingAction>;
  acceptOfferingActions: Array<AcceptOfferingAction>;
  nftMintAction?: Maybe<NftMintAction>;
  nftMintActions: Array<NftMintAction>;
  nftTransferAction?: Maybe<NftTransferAction>;
  nftTransferActions: Array<NftTransferAction>;
  nftBurnAction?: Maybe<NftBurnAction>;
  nftBurnActions: Array<NftBurnAction>;
  nftRegisterAction?: Maybe<NftRegisterAction>;
  nftRegisterActions: Array<NftRegisterAction>;
  nftUnregisterAction?: Maybe<NftUnregisterAction>;
  nftUnregisterActions: Array<NftUnregisterAction>;
  controlAccountAction?: Maybe<ControlAccountAction>;
  controlAccountActions: Array<ControlAccountAction>;
  likeNftAction?: Maybe<LikeNftAction>;
  likeNftActions: Array<LikeNftAction>;
  unlikeNftAction?: Maybe<UnlikeNftAction>;
  unlikeNftActions: Array<UnlikeNftAction>;
  watchNftAction?: Maybe<WatchNftAction>;
  watchNftActions: Array<WatchNftAction>;
  unwatchNftAction?: Maybe<UnwatchNftAction>;
  unwatchNftActions: Array<UnwatchNftAction>;
  readNotificationAction?: Maybe<ReadNotificationAction>;
  readNotificationActions: Array<ReadNotificationAction>;
  marketSummary?: Maybe<MarketSummary>;
  marketSummaries: Array<MarketSummary>;
  nftSummary?: Maybe<NftSummary>;
  nftSummaries: Array<NftSummary>;
  nft?: Maybe<Nft>;
  nfts: Array<Nft>;
  tokenMetadata?: Maybe<TokenMetadata>;
  tokenMetadata_collection: Array<TokenMetadata>;
  userLikeNft?: Maybe<UserLikeNft>;
  userLikeNfts: Array<UserLikeNft>;
  user?: Maybe<User>;
  users: Array<User>;
  userWatchNft?: Maybe<UserWatchNft>;
  userWatchNfts: Array<UserWatchNft>;
  notification?: Maybe<Notification>;
  notifications: Array<Notification>;
  nftLikedNotification?: Maybe<NftLikedNotification>;
  nftLikedNotifications: Array<NftLikedNotification>;
  nftWatchedNotification?: Maybe<NftWatchedNotification>;
  nftWatchedNotifications: Array<NftWatchedNotification>;
  offerAcceptedNotification?: Maybe<OfferAcceptedNotification>;
  offerAcceptedNotifications: Array<OfferAcceptedNotification>;
  listingSoldNotification?: Maybe<ListingSoldNotification>;
  listingSoldNotifications: Array<ListingSoldNotification>;
  receiveOfferNotification?: Maybe<ReceiveOfferNotification>;
  receiveOfferNotifications: Array<ReceiveOfferNotification>;
  offerUpdateNotification?: Maybe<OfferUpdateNotification>;
  offerUpdateNotifications: Array<OfferUpdateNotification>;
  receiveNftNotification?: Maybe<ReceiveNftNotification>;
  receiveNftNotifications: Array<ReceiveNftNotification>;
  nftSearch: Array<Nft>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type QuerylistingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerylistingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Listing_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Listing_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryofferingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryofferingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Offering_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Offering_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytradingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytradingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Trading_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Trading_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycreateListingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycreateListingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CreateListingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<CreateListingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryupdateListingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryupdateListingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UpdateListingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UpdateListingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryremoveListingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryremoveListingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RemoveListingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RemoveListingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycreateOfferingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycreateOfferingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CreateOfferingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<CreateOfferingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryupdateOfferingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryupdateOfferingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UpdateOfferingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UpdateOfferingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryremoveOfferingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryremoveOfferingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RemoveOfferingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RemoveOfferingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybuyListingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybuyListingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BuyListingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BuyListingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryacceptOfferingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryacceptOfferingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AcceptOfferingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AcceptOfferingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftMintActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftMintActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftMintAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftMintAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftTransferActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftTransferActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftTransferAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftTransferAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftBurnActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftBurnActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftBurnAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftBurnAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftRegisterActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftRegisterActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftRegisterAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftRegisterAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftUnregisterActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftUnregisterActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftUnregisterAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftUnregisterAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycontrolAccountActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycontrolAccountActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ControlAccountAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ControlAccountAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerylikeNftActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerylikeNftActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LikeNftAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<LikeNftAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryunlikeNftActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryunlikeNftActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnlikeNftAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UnlikeNftAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywatchNftActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerywatchNftActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WatchNftAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WatchNftAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryunwatchNftActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryunwatchNftActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnwatchNftAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UnwatchNftAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreadNotificationActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreadNotificationActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReadNotificationAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ReadNotificationAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketSummaryArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerymarketSummariesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketSummary_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketSummary_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftSummaryArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftSummariesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftSummary_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftSummary_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Nft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Nft_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenMetadataArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytokenMetadata_collectionArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenMetadata_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenMetadata_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserLikeNftArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserLikeNftsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserLikeNft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserLikeNft_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryusersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserWatchNftArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryuserWatchNftsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserWatchNft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserWatchNft_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Notification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Notification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftLikedNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftLikedNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftLikedNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftLikedNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftWatchedNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftWatchedNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftWatchedNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftWatchedNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryofferAcceptedNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryofferAcceptedNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferAcceptedNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<OfferAcceptedNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerylistingSoldNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerylistingSoldNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ListingSoldNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ListingSoldNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreceiveOfferNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreceiveOfferNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReceiveOfferNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ReceiveOfferNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryofferUpdateNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryofferUpdateNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferUpdateNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<OfferUpdateNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreceiveNftNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreceiveNftNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReceiveNftNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ReceiveNftNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftSearchArgs = {
  text: Scalars['String'];
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  block?: InputMaybe<Block_height>;
  where?: InputMaybe<Nft_filter>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type ReadNotificationAction = {
  id: Scalars['ID'];
  timestamp: Scalars['BigInt'];
};

export type ReadNotificationAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ReadNotificationAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ReadNotificationAction_filter>>>;
};

export type ReadNotificationAction_orderBy =
  | 'id'
  | 'timestamp';

export type ReceiveNftNotification = {
  id: Scalars['ID'];
  nft_transfer_action: NftTransferAction;
};

export type ReceiveNftNotification_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  nft_transfer_action?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not?: InputMaybe<Scalars['String']>;
  nft_transfer_action_gt?: InputMaybe<Scalars['String']>;
  nft_transfer_action_lt?: InputMaybe<Scalars['String']>;
  nft_transfer_action_gte?: InputMaybe<Scalars['String']>;
  nft_transfer_action_lte?: InputMaybe<Scalars['String']>;
  nft_transfer_action_in?: InputMaybe<Array<Scalars['String']>>;
  nft_transfer_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_transfer_action_contains?: InputMaybe<Scalars['String']>;
  nft_transfer_action_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_contains?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_starts_with?: InputMaybe<Scalars['String']>;
  nft_transfer_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_ends_with?: InputMaybe<Scalars['String']>;
  nft_transfer_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_?: InputMaybe<NftTransferAction_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ReceiveNftNotification_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ReceiveNftNotification_filter>>>;
};

export type ReceiveNftNotification_orderBy =
  | 'id'
  | 'nft_transfer_action'
  | 'nft_transfer_action__id'
  | 'nft_transfer_action__old_owner_id'
  | 'nft_transfer_action__new_owner_id'
  | 'nft_transfer_action__token_id'
  | 'nft_transfer_action__authorized_id'
  | 'nft_transfer_action__memo';

export type ReceiveOfferNotification = {
  id: Scalars['ID'];
  create_offering_action: CreateOfferingAction;
};

export type ReceiveOfferNotification_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  create_offering_action?: InputMaybe<Scalars['String']>;
  create_offering_action_not?: InputMaybe<Scalars['String']>;
  create_offering_action_gt?: InputMaybe<Scalars['String']>;
  create_offering_action_lt?: InputMaybe<Scalars['String']>;
  create_offering_action_gte?: InputMaybe<Scalars['String']>;
  create_offering_action_lte?: InputMaybe<Scalars['String']>;
  create_offering_action_in?: InputMaybe<Array<Scalars['String']>>;
  create_offering_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  create_offering_action_contains?: InputMaybe<Scalars['String']>;
  create_offering_action_contains_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_not_contains?: InputMaybe<Scalars['String']>;
  create_offering_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_starts_with?: InputMaybe<Scalars['String']>;
  create_offering_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_not_starts_with?: InputMaybe<Scalars['String']>;
  create_offering_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_ends_with?: InputMaybe<Scalars['String']>;
  create_offering_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_not_ends_with?: InputMaybe<Scalars['String']>;
  create_offering_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_?: InputMaybe<CreateOfferingAction_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ReceiveOfferNotification_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ReceiveOfferNotification_filter>>>;
};

export type ReceiveOfferNotification_orderBy =
  | 'id'
  | 'create_offering_action'
  | 'create_offering_action__id'
  | 'create_offering_action__offer_creator'
  | 'create_offering_action__nft_contract_id'
  | 'create_offering_action__nft_token_id'
  | 'create_offering_action__price'
  | 'create_offering_action__offering_id';

export type RemoveListingAction = {
  id: Scalars['ID'];
  price: Scalars['BigInt'];
  listing_id: Scalars['String'];
  listing: Listing;
};

export type RemoveListingAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  listing_id?: InputMaybe<Scalars['String']>;
  listing_id_not?: InputMaybe<Scalars['String']>;
  listing_id_gt?: InputMaybe<Scalars['String']>;
  listing_id_lt?: InputMaybe<Scalars['String']>;
  listing_id_gte?: InputMaybe<Scalars['String']>;
  listing_id_lte?: InputMaybe<Scalars['String']>;
  listing_id_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_contains?: InputMaybe<Scalars['String']>;
  listing_id_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_contains?: InputMaybe<Scalars['String']>;
  listing_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Scalars['String']>;
  listing_not?: InputMaybe<Scalars['String']>;
  listing_gt?: InputMaybe<Scalars['String']>;
  listing_lt?: InputMaybe<Scalars['String']>;
  listing_gte?: InputMaybe<Scalars['String']>;
  listing_lte?: InputMaybe<Scalars['String']>;
  listing_in?: InputMaybe<Array<Scalars['String']>>;
  listing_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_contains?: InputMaybe<Scalars['String']>;
  listing_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_not_contains?: InputMaybe<Scalars['String']>;
  listing_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_starts_with?: InputMaybe<Scalars['String']>;
  listing_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_ends_with?: InputMaybe<Scalars['String']>;
  listing_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_?: InputMaybe<Listing_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RemoveListingAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<RemoveListingAction_filter>>>;
};

export type RemoveListingAction_orderBy =
  | 'id'
  | 'price'
  | 'listing_id'
  | 'listing'
  | 'listing__id'
  | 'listing__seller_id'
  | 'listing__contract_id'
  | 'listing__token_id'
  | 'listing__price'
  | 'listing__create_time'
  | 'listing__update_time'
  | 'listing__is_remove';

export type RemoveOfferingAction = {
  id: Scalars['ID'];
  offer_creator: Scalars['String'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
  price: Scalars['BigInt'];
  offering_id: Scalars['String'];
  offering: Offering;
};

export type RemoveOfferingAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  offer_creator?: InputMaybe<Scalars['String']>;
  offer_creator_not?: InputMaybe<Scalars['String']>;
  offer_creator_gt?: InputMaybe<Scalars['String']>;
  offer_creator_lt?: InputMaybe<Scalars['String']>;
  offer_creator_gte?: InputMaybe<Scalars['String']>;
  offer_creator_lte?: InputMaybe<Scalars['String']>;
  offer_creator_in?: InputMaybe<Array<Scalars['String']>>;
  offer_creator_not_in?: InputMaybe<Array<Scalars['String']>>;
  offer_creator_contains?: InputMaybe<Scalars['String']>;
  offer_creator_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_not_contains?: InputMaybe<Scalars['String']>;
  offer_creator_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_starts_with?: InputMaybe<Scalars['String']>;
  offer_creator_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_not_starts_with?: InputMaybe<Scalars['String']>;
  offer_creator_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_ends_with?: InputMaybe<Scalars['String']>;
  offer_creator_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_not_ends_with?: InputMaybe<Scalars['String']>;
  offer_creator_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['BigInt']>;
  price_not?: InputMaybe<Scalars['BigInt']>;
  price_gt?: InputMaybe<Scalars['BigInt']>;
  price_lt?: InputMaybe<Scalars['BigInt']>;
  price_gte?: InputMaybe<Scalars['BigInt']>;
  price_lte?: InputMaybe<Scalars['BigInt']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  offering_id?: InputMaybe<Scalars['String']>;
  offering_id_not?: InputMaybe<Scalars['String']>;
  offering_id_gt?: InputMaybe<Scalars['String']>;
  offering_id_lt?: InputMaybe<Scalars['String']>;
  offering_id_gte?: InputMaybe<Scalars['String']>;
  offering_id_lte?: InputMaybe<Scalars['String']>;
  offering_id_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_contains?: InputMaybe<Scalars['String']>;
  offering_id_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_contains?: InputMaybe<Scalars['String']>;
  offering_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering?: InputMaybe<Scalars['String']>;
  offering_not?: InputMaybe<Scalars['String']>;
  offering_gt?: InputMaybe<Scalars['String']>;
  offering_lt?: InputMaybe<Scalars['String']>;
  offering_gte?: InputMaybe<Scalars['String']>;
  offering_lte?: InputMaybe<Scalars['String']>;
  offering_in?: InputMaybe<Array<Scalars['String']>>;
  offering_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_contains?: InputMaybe<Scalars['String']>;
  offering_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_not_contains?: InputMaybe<Scalars['String']>;
  offering_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_starts_with?: InputMaybe<Scalars['String']>;
  offering_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_ends_with?: InputMaybe<Scalars['String']>;
  offering_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_?: InputMaybe<Offering_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RemoveOfferingAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<RemoveOfferingAction_filter>>>;
};

export type RemoveOfferingAction_orderBy =
  | 'id'
  | 'offer_creator'
  | 'nft_contract_id'
  | 'nft_token_id'
  | 'price'
  | 'offering_id'
  | 'offering'
  | 'offering__id'
  | 'offering__buyer_id'
  | 'offering__contract_id'
  | 'offering__token_id'
  | 'offering__price'
  | 'offering__create_time'
  | 'offering__update_time'
  | 'offering__is_simple_offering'
  | 'offering__is_remove'
  | 'offering__expire_time';

export type Subscription = {
  listing?: Maybe<Listing>;
  listings: Array<Listing>;
  offering?: Maybe<Offering>;
  offerings: Array<Offering>;
  trading?: Maybe<Trading>;
  tradings: Array<Trading>;
  userAction?: Maybe<UserAction>;
  userActions: Array<UserAction>;
  createListingAction?: Maybe<CreateListingAction>;
  createListingActions: Array<CreateListingAction>;
  updateListingAction?: Maybe<UpdateListingAction>;
  updateListingActions: Array<UpdateListingAction>;
  removeListingAction?: Maybe<RemoveListingAction>;
  removeListingActions: Array<RemoveListingAction>;
  createOfferingAction?: Maybe<CreateOfferingAction>;
  createOfferingActions: Array<CreateOfferingAction>;
  updateOfferingAction?: Maybe<UpdateOfferingAction>;
  updateOfferingActions: Array<UpdateOfferingAction>;
  removeOfferingAction?: Maybe<RemoveOfferingAction>;
  removeOfferingActions: Array<RemoveOfferingAction>;
  buyListingAction?: Maybe<BuyListingAction>;
  buyListingActions: Array<BuyListingAction>;
  acceptOfferingAction?: Maybe<AcceptOfferingAction>;
  acceptOfferingActions: Array<AcceptOfferingAction>;
  nftMintAction?: Maybe<NftMintAction>;
  nftMintActions: Array<NftMintAction>;
  nftTransferAction?: Maybe<NftTransferAction>;
  nftTransferActions: Array<NftTransferAction>;
  nftBurnAction?: Maybe<NftBurnAction>;
  nftBurnActions: Array<NftBurnAction>;
  nftRegisterAction?: Maybe<NftRegisterAction>;
  nftRegisterActions: Array<NftRegisterAction>;
  nftUnregisterAction?: Maybe<NftUnregisterAction>;
  nftUnregisterActions: Array<NftUnregisterAction>;
  controlAccountAction?: Maybe<ControlAccountAction>;
  controlAccountActions: Array<ControlAccountAction>;
  likeNftAction?: Maybe<LikeNftAction>;
  likeNftActions: Array<LikeNftAction>;
  unlikeNftAction?: Maybe<UnlikeNftAction>;
  unlikeNftActions: Array<UnlikeNftAction>;
  watchNftAction?: Maybe<WatchNftAction>;
  watchNftActions: Array<WatchNftAction>;
  unwatchNftAction?: Maybe<UnwatchNftAction>;
  unwatchNftActions: Array<UnwatchNftAction>;
  readNotificationAction?: Maybe<ReadNotificationAction>;
  readNotificationActions: Array<ReadNotificationAction>;
  marketSummary?: Maybe<MarketSummary>;
  marketSummaries: Array<MarketSummary>;
  nftSummary?: Maybe<NftSummary>;
  nftSummaries: Array<NftSummary>;
  nft?: Maybe<Nft>;
  nfts: Array<Nft>;
  tokenMetadata?: Maybe<TokenMetadata>;
  tokenMetadata_collection: Array<TokenMetadata>;
  userLikeNft?: Maybe<UserLikeNft>;
  userLikeNfts: Array<UserLikeNft>;
  user?: Maybe<User>;
  users: Array<User>;
  userWatchNft?: Maybe<UserWatchNft>;
  userWatchNfts: Array<UserWatchNft>;
  notification?: Maybe<Notification>;
  notifications: Array<Notification>;
  nftLikedNotification?: Maybe<NftLikedNotification>;
  nftLikedNotifications: Array<NftLikedNotification>;
  nftWatchedNotification?: Maybe<NftWatchedNotification>;
  nftWatchedNotifications: Array<NftWatchedNotification>;
  offerAcceptedNotification?: Maybe<OfferAcceptedNotification>;
  offerAcceptedNotifications: Array<OfferAcceptedNotification>;
  listingSoldNotification?: Maybe<ListingSoldNotification>;
  listingSoldNotifications: Array<ListingSoldNotification>;
  receiveOfferNotification?: Maybe<ReceiveOfferNotification>;
  receiveOfferNotifications: Array<ReceiveOfferNotification>;
  offerUpdateNotification?: Maybe<OfferUpdateNotification>;
  offerUpdateNotifications: Array<OfferUpdateNotification>;
  receiveNftNotification?: Maybe<ReceiveNftNotification>;
  receiveNftNotifications: Array<ReceiveNftNotification>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
};


export type SubscriptionlistingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionlistingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Listing_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Listing_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionofferingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionofferingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Offering_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Offering_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontradingArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontradingsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Trading_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Trading_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncreateListingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncreateListingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CreateListingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<CreateListingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionupdateListingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionupdateListingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UpdateListingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UpdateListingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionremoveListingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionremoveListingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RemoveListingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RemoveListingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncreateOfferingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncreateOfferingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CreateOfferingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<CreateOfferingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionupdateOfferingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionupdateOfferingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UpdateOfferingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UpdateOfferingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionremoveOfferingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionremoveOfferingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<RemoveOfferingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RemoveOfferingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbuyListingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbuyListingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<BuyListingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<BuyListingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionacceptOfferingActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionacceptOfferingActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<AcceptOfferingAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<AcceptOfferingAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftMintActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftMintActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftMintAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftMintAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftTransferActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftTransferActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftTransferAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftTransferAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftBurnActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftBurnActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftBurnAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftBurnAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftRegisterActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftRegisterActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftRegisterAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftRegisterAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftUnregisterActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftUnregisterActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftUnregisterAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftUnregisterAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncontrolAccountActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncontrolAccountActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ControlAccountAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ControlAccountAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionlikeNftActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionlikeNftActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<LikeNftAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<LikeNftAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionunlikeNftActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionunlikeNftActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnlikeNftAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UnlikeNftAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwatchNftActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionwatchNftActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<WatchNftAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<WatchNftAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionunwatchNftActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionunwatchNftActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UnwatchNftAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UnwatchNftAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreadNotificationActionArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreadNotificationActionsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReadNotificationAction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ReadNotificationAction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketSummaryArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionmarketSummariesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<MarketSummary_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<MarketSummary_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftSummaryArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftSummariesArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftSummary_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftSummary_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Nft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Nft_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenMetadataArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontokenMetadata_collectionArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<TokenMetadata_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TokenMetadata_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserLikeNftArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserLikeNftsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserLikeNft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserLikeNft_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionusersArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserWatchNftArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionuserWatchNftsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserWatchNft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserWatchNft_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Notification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Notification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftLikedNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftLikedNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftLikedNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftLikedNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftWatchedNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftWatchedNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<NftWatchedNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<NftWatchedNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionofferAcceptedNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionofferAcceptedNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferAcceptedNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<OfferAcceptedNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionlistingSoldNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionlistingSoldNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ListingSoldNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ListingSoldNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreceiveOfferNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreceiveOfferNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReceiveOfferNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ReceiveOfferNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionofferUpdateNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionofferUpdateNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OfferUpdateNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<OfferUpdateNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreceiveNftNotificationArgs = {
  id: Scalars['ID'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreceiveNftNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<ReceiveNftNotification_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ReceiveNftNotification_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>;
};

export type TokenMetadata = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  media?: Maybe<Scalars['String']>;
  media_hash?: Maybe<Scalars['String']>;
  copies?: Maybe<Scalars['BigInt']>;
  issued_at?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['String']>;
  starts_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  reference_hash?: Maybe<Scalars['String']>;
};

export type TokenMetadata_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['String']>;
  title_not?: InputMaybe<Scalars['String']>;
  title_gt?: InputMaybe<Scalars['String']>;
  title_lt?: InputMaybe<Scalars['String']>;
  title_gte?: InputMaybe<Scalars['String']>;
  title_lte?: InputMaybe<Scalars['String']>;
  title_in?: InputMaybe<Array<Scalars['String']>>;
  title_not_in?: InputMaybe<Array<Scalars['String']>>;
  title_contains?: InputMaybe<Scalars['String']>;
  title_contains_nocase?: InputMaybe<Scalars['String']>;
  title_not_contains?: InputMaybe<Scalars['String']>;
  title_not_contains_nocase?: InputMaybe<Scalars['String']>;
  title_starts_with?: InputMaybe<Scalars['String']>;
  title_starts_with_nocase?: InputMaybe<Scalars['String']>;
  title_not_starts_with?: InputMaybe<Scalars['String']>;
  title_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  title_ends_with?: InputMaybe<Scalars['String']>;
  title_ends_with_nocase?: InputMaybe<Scalars['String']>;
  title_not_ends_with?: InputMaybe<Scalars['String']>;
  title_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  description_not?: InputMaybe<Scalars['String']>;
  description_gt?: InputMaybe<Scalars['String']>;
  description_lt?: InputMaybe<Scalars['String']>;
  description_gte?: InputMaybe<Scalars['String']>;
  description_lte?: InputMaybe<Scalars['String']>;
  description_in?: InputMaybe<Array<Scalars['String']>>;
  description_not_in?: InputMaybe<Array<Scalars['String']>>;
  description_contains?: InputMaybe<Scalars['String']>;
  description_contains_nocase?: InputMaybe<Scalars['String']>;
  description_not_contains?: InputMaybe<Scalars['String']>;
  description_not_contains_nocase?: InputMaybe<Scalars['String']>;
  description_starts_with?: InputMaybe<Scalars['String']>;
  description_starts_with_nocase?: InputMaybe<Scalars['String']>;
  description_not_starts_with?: InputMaybe<Scalars['String']>;
  description_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  description_ends_with?: InputMaybe<Scalars['String']>;
  description_ends_with_nocase?: InputMaybe<Scalars['String']>;
  description_not_ends_with?: InputMaybe<Scalars['String']>;
  description_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Scalars['String']>;
  media_not?: InputMaybe<Scalars['String']>;
  media_gt?: InputMaybe<Scalars['String']>;
  media_lt?: InputMaybe<Scalars['String']>;
  media_gte?: InputMaybe<Scalars['String']>;
  media_lte?: InputMaybe<Scalars['String']>;
  media_in?: InputMaybe<Array<Scalars['String']>>;
  media_not_in?: InputMaybe<Array<Scalars['String']>>;
  media_contains?: InputMaybe<Scalars['String']>;
  media_contains_nocase?: InputMaybe<Scalars['String']>;
  media_not_contains?: InputMaybe<Scalars['String']>;
  media_not_contains_nocase?: InputMaybe<Scalars['String']>;
  media_starts_with?: InputMaybe<Scalars['String']>;
  media_starts_with_nocase?: InputMaybe<Scalars['String']>;
  media_not_starts_with?: InputMaybe<Scalars['String']>;
  media_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  media_ends_with?: InputMaybe<Scalars['String']>;
  media_ends_with_nocase?: InputMaybe<Scalars['String']>;
  media_not_ends_with?: InputMaybe<Scalars['String']>;
  media_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  media_hash?: InputMaybe<Scalars['String']>;
  media_hash_not?: InputMaybe<Scalars['String']>;
  media_hash_gt?: InputMaybe<Scalars['String']>;
  media_hash_lt?: InputMaybe<Scalars['String']>;
  media_hash_gte?: InputMaybe<Scalars['String']>;
  media_hash_lte?: InputMaybe<Scalars['String']>;
  media_hash_in?: InputMaybe<Array<Scalars['String']>>;
  media_hash_not_in?: InputMaybe<Array<Scalars['String']>>;
  media_hash_contains?: InputMaybe<Scalars['String']>;
  media_hash_contains_nocase?: InputMaybe<Scalars['String']>;
  media_hash_not_contains?: InputMaybe<Scalars['String']>;
  media_hash_not_contains_nocase?: InputMaybe<Scalars['String']>;
  media_hash_starts_with?: InputMaybe<Scalars['String']>;
  media_hash_starts_with_nocase?: InputMaybe<Scalars['String']>;
  media_hash_not_starts_with?: InputMaybe<Scalars['String']>;
  media_hash_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  media_hash_ends_with?: InputMaybe<Scalars['String']>;
  media_hash_ends_with_nocase?: InputMaybe<Scalars['String']>;
  media_hash_not_ends_with?: InputMaybe<Scalars['String']>;
  media_hash_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  copies?: InputMaybe<Scalars['BigInt']>;
  copies_not?: InputMaybe<Scalars['BigInt']>;
  copies_gt?: InputMaybe<Scalars['BigInt']>;
  copies_lt?: InputMaybe<Scalars['BigInt']>;
  copies_gte?: InputMaybe<Scalars['BigInt']>;
  copies_lte?: InputMaybe<Scalars['BigInt']>;
  copies_in?: InputMaybe<Array<Scalars['BigInt']>>;
  copies_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  issued_at?: InputMaybe<Scalars['String']>;
  issued_at_not?: InputMaybe<Scalars['String']>;
  issued_at_gt?: InputMaybe<Scalars['String']>;
  issued_at_lt?: InputMaybe<Scalars['String']>;
  issued_at_gte?: InputMaybe<Scalars['String']>;
  issued_at_lte?: InputMaybe<Scalars['String']>;
  issued_at_in?: InputMaybe<Array<Scalars['String']>>;
  issued_at_not_in?: InputMaybe<Array<Scalars['String']>>;
  issued_at_contains?: InputMaybe<Scalars['String']>;
  issued_at_contains_nocase?: InputMaybe<Scalars['String']>;
  issued_at_not_contains?: InputMaybe<Scalars['String']>;
  issued_at_not_contains_nocase?: InputMaybe<Scalars['String']>;
  issued_at_starts_with?: InputMaybe<Scalars['String']>;
  issued_at_starts_with_nocase?: InputMaybe<Scalars['String']>;
  issued_at_not_starts_with?: InputMaybe<Scalars['String']>;
  issued_at_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  issued_at_ends_with?: InputMaybe<Scalars['String']>;
  issued_at_ends_with_nocase?: InputMaybe<Scalars['String']>;
  issued_at_not_ends_with?: InputMaybe<Scalars['String']>;
  issued_at_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['String']>;
  expires_at_not?: InputMaybe<Scalars['String']>;
  expires_at_gt?: InputMaybe<Scalars['String']>;
  expires_at_lt?: InputMaybe<Scalars['String']>;
  expires_at_gte?: InputMaybe<Scalars['String']>;
  expires_at_lte?: InputMaybe<Scalars['String']>;
  expires_at_in?: InputMaybe<Array<Scalars['String']>>;
  expires_at_not_in?: InputMaybe<Array<Scalars['String']>>;
  expires_at_contains?: InputMaybe<Scalars['String']>;
  expires_at_contains_nocase?: InputMaybe<Scalars['String']>;
  expires_at_not_contains?: InputMaybe<Scalars['String']>;
  expires_at_not_contains_nocase?: InputMaybe<Scalars['String']>;
  expires_at_starts_with?: InputMaybe<Scalars['String']>;
  expires_at_starts_with_nocase?: InputMaybe<Scalars['String']>;
  expires_at_not_starts_with?: InputMaybe<Scalars['String']>;
  expires_at_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  expires_at_ends_with?: InputMaybe<Scalars['String']>;
  expires_at_ends_with_nocase?: InputMaybe<Scalars['String']>;
  expires_at_not_ends_with?: InputMaybe<Scalars['String']>;
  expires_at_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  starts_at?: InputMaybe<Scalars['String']>;
  starts_at_not?: InputMaybe<Scalars['String']>;
  starts_at_gt?: InputMaybe<Scalars['String']>;
  starts_at_lt?: InputMaybe<Scalars['String']>;
  starts_at_gte?: InputMaybe<Scalars['String']>;
  starts_at_lte?: InputMaybe<Scalars['String']>;
  starts_at_in?: InputMaybe<Array<Scalars['String']>>;
  starts_at_not_in?: InputMaybe<Array<Scalars['String']>>;
  starts_at_contains?: InputMaybe<Scalars['String']>;
  starts_at_contains_nocase?: InputMaybe<Scalars['String']>;
  starts_at_not_contains?: InputMaybe<Scalars['String']>;
  starts_at_not_contains_nocase?: InputMaybe<Scalars['String']>;
  starts_at_starts_with?: InputMaybe<Scalars['String']>;
  starts_at_starts_with_nocase?: InputMaybe<Scalars['String']>;
  starts_at_not_starts_with?: InputMaybe<Scalars['String']>;
  starts_at_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  starts_at_ends_with?: InputMaybe<Scalars['String']>;
  starts_at_ends_with_nocase?: InputMaybe<Scalars['String']>;
  starts_at_not_ends_with?: InputMaybe<Scalars['String']>;
  starts_at_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['String']>;
  updated_at_not?: InputMaybe<Scalars['String']>;
  updated_at_gt?: InputMaybe<Scalars['String']>;
  updated_at_lt?: InputMaybe<Scalars['String']>;
  updated_at_gte?: InputMaybe<Scalars['String']>;
  updated_at_lte?: InputMaybe<Scalars['String']>;
  updated_at_in?: InputMaybe<Array<Scalars['String']>>;
  updated_at_not_in?: InputMaybe<Array<Scalars['String']>>;
  updated_at_contains?: InputMaybe<Scalars['String']>;
  updated_at_contains_nocase?: InputMaybe<Scalars['String']>;
  updated_at_not_contains?: InputMaybe<Scalars['String']>;
  updated_at_not_contains_nocase?: InputMaybe<Scalars['String']>;
  updated_at_starts_with?: InputMaybe<Scalars['String']>;
  updated_at_starts_with_nocase?: InputMaybe<Scalars['String']>;
  updated_at_not_starts_with?: InputMaybe<Scalars['String']>;
  updated_at_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  updated_at_ends_with?: InputMaybe<Scalars['String']>;
  updated_at_ends_with_nocase?: InputMaybe<Scalars['String']>;
  updated_at_not_ends_with?: InputMaybe<Scalars['String']>;
  updated_at_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  extra?: InputMaybe<Scalars['String']>;
  extra_not?: InputMaybe<Scalars['String']>;
  extra_gt?: InputMaybe<Scalars['String']>;
  extra_lt?: InputMaybe<Scalars['String']>;
  extra_gte?: InputMaybe<Scalars['String']>;
  extra_lte?: InputMaybe<Scalars['String']>;
  extra_in?: InputMaybe<Array<Scalars['String']>>;
  extra_not_in?: InputMaybe<Array<Scalars['String']>>;
  extra_contains?: InputMaybe<Scalars['String']>;
  extra_contains_nocase?: InputMaybe<Scalars['String']>;
  extra_not_contains?: InputMaybe<Scalars['String']>;
  extra_not_contains_nocase?: InputMaybe<Scalars['String']>;
  extra_starts_with?: InputMaybe<Scalars['String']>;
  extra_starts_with_nocase?: InputMaybe<Scalars['String']>;
  extra_not_starts_with?: InputMaybe<Scalars['String']>;
  extra_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  extra_ends_with?: InputMaybe<Scalars['String']>;
  extra_ends_with_nocase?: InputMaybe<Scalars['String']>;
  extra_not_ends_with?: InputMaybe<Scalars['String']>;
  extra_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  reference_not?: InputMaybe<Scalars['String']>;
  reference_gt?: InputMaybe<Scalars['String']>;
  reference_lt?: InputMaybe<Scalars['String']>;
  reference_gte?: InputMaybe<Scalars['String']>;
  reference_lte?: InputMaybe<Scalars['String']>;
  reference_in?: InputMaybe<Array<Scalars['String']>>;
  reference_not_in?: InputMaybe<Array<Scalars['String']>>;
  reference_contains?: InputMaybe<Scalars['String']>;
  reference_contains_nocase?: InputMaybe<Scalars['String']>;
  reference_not_contains?: InputMaybe<Scalars['String']>;
  reference_not_contains_nocase?: InputMaybe<Scalars['String']>;
  reference_starts_with?: InputMaybe<Scalars['String']>;
  reference_starts_with_nocase?: InputMaybe<Scalars['String']>;
  reference_not_starts_with?: InputMaybe<Scalars['String']>;
  reference_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  reference_ends_with?: InputMaybe<Scalars['String']>;
  reference_ends_with_nocase?: InputMaybe<Scalars['String']>;
  reference_not_ends_with?: InputMaybe<Scalars['String']>;
  reference_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  reference_hash?: InputMaybe<Scalars['String']>;
  reference_hash_not?: InputMaybe<Scalars['String']>;
  reference_hash_gt?: InputMaybe<Scalars['String']>;
  reference_hash_lt?: InputMaybe<Scalars['String']>;
  reference_hash_gte?: InputMaybe<Scalars['String']>;
  reference_hash_lte?: InputMaybe<Scalars['String']>;
  reference_hash_in?: InputMaybe<Array<Scalars['String']>>;
  reference_hash_not_in?: InputMaybe<Array<Scalars['String']>>;
  reference_hash_contains?: InputMaybe<Scalars['String']>;
  reference_hash_contains_nocase?: InputMaybe<Scalars['String']>;
  reference_hash_not_contains?: InputMaybe<Scalars['String']>;
  reference_hash_not_contains_nocase?: InputMaybe<Scalars['String']>;
  reference_hash_starts_with?: InputMaybe<Scalars['String']>;
  reference_hash_starts_with_nocase?: InputMaybe<Scalars['String']>;
  reference_hash_not_starts_with?: InputMaybe<Scalars['String']>;
  reference_hash_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  reference_hash_ends_with?: InputMaybe<Scalars['String']>;
  reference_hash_ends_with_nocase?: InputMaybe<Scalars['String']>;
  reference_hash_not_ends_with?: InputMaybe<Scalars['String']>;
  reference_hash_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TokenMetadata_filter>>>;
  or?: InputMaybe<Array<InputMaybe<TokenMetadata_filter>>>;
};

export type TokenMetadata_orderBy =
  | 'id'
  | 'title'
  | 'description'
  | 'media'
  | 'media_hash'
  | 'copies'
  | 'issued_at'
  | 'expires_at'
  | 'starts_at'
  | 'updated_at'
  | 'extra'
  | 'reference'
  | 'reference_hash';

export type Trading = {
  id: Scalars['ID'];
  trading_type: TradingType;
  offering_id?: Maybe<Scalars['String']>;
  offering?: Maybe<Offering>;
  is_simple_offering?: Maybe<Scalars['Boolean']>;
  listing_id?: Maybe<Scalars['String']>;
  listing?: Maybe<Listing>;
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
  nft_approval_id: Scalars['BigInt'];
  seller_id: Scalars['String'];
  buyer_id: Scalars['String'];
  prepaid_balance: Scalars['BigInt'];
  payment_balance: Scalars['BigInt'];
  payout_balance: Scalars['BigInt'];
  transfer_detail: Scalars['String'];
  tx_time: Scalars['BigInt'];
};

export type TradingType =
  | 'BuyListing'
  | 'AcceptOffering';

export type Trading_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  trading_type?: InputMaybe<TradingType>;
  trading_type_not?: InputMaybe<TradingType>;
  trading_type_in?: InputMaybe<Array<TradingType>>;
  trading_type_not_in?: InputMaybe<Array<TradingType>>;
  offering_id?: InputMaybe<Scalars['String']>;
  offering_id_not?: InputMaybe<Scalars['String']>;
  offering_id_gt?: InputMaybe<Scalars['String']>;
  offering_id_lt?: InputMaybe<Scalars['String']>;
  offering_id_gte?: InputMaybe<Scalars['String']>;
  offering_id_lte?: InputMaybe<Scalars['String']>;
  offering_id_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_contains?: InputMaybe<Scalars['String']>;
  offering_id_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_contains?: InputMaybe<Scalars['String']>;
  offering_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering?: InputMaybe<Scalars['String']>;
  offering_not?: InputMaybe<Scalars['String']>;
  offering_gt?: InputMaybe<Scalars['String']>;
  offering_lt?: InputMaybe<Scalars['String']>;
  offering_gte?: InputMaybe<Scalars['String']>;
  offering_lte?: InputMaybe<Scalars['String']>;
  offering_in?: InputMaybe<Array<Scalars['String']>>;
  offering_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_contains?: InputMaybe<Scalars['String']>;
  offering_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_not_contains?: InputMaybe<Scalars['String']>;
  offering_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_starts_with?: InputMaybe<Scalars['String']>;
  offering_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_ends_with?: InputMaybe<Scalars['String']>;
  offering_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_?: InputMaybe<Offering_filter>;
  is_simple_offering?: InputMaybe<Scalars['Boolean']>;
  is_simple_offering_not?: InputMaybe<Scalars['Boolean']>;
  is_simple_offering_in?: InputMaybe<Array<Scalars['Boolean']>>;
  is_simple_offering_not_in?: InputMaybe<Array<Scalars['Boolean']>>;
  listing_id?: InputMaybe<Scalars['String']>;
  listing_id_not?: InputMaybe<Scalars['String']>;
  listing_id_gt?: InputMaybe<Scalars['String']>;
  listing_id_lt?: InputMaybe<Scalars['String']>;
  listing_id_gte?: InputMaybe<Scalars['String']>;
  listing_id_lte?: InputMaybe<Scalars['String']>;
  listing_id_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_contains?: InputMaybe<Scalars['String']>;
  listing_id_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_contains?: InputMaybe<Scalars['String']>;
  listing_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Scalars['String']>;
  listing_not?: InputMaybe<Scalars['String']>;
  listing_gt?: InputMaybe<Scalars['String']>;
  listing_lt?: InputMaybe<Scalars['String']>;
  listing_gte?: InputMaybe<Scalars['String']>;
  listing_lte?: InputMaybe<Scalars['String']>;
  listing_in?: InputMaybe<Array<Scalars['String']>>;
  listing_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_contains?: InputMaybe<Scalars['String']>;
  listing_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_not_contains?: InputMaybe<Scalars['String']>;
  listing_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_starts_with?: InputMaybe<Scalars['String']>;
  listing_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_ends_with?: InputMaybe<Scalars['String']>;
  listing_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_?: InputMaybe<Listing_filter>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_approval_id?: InputMaybe<Scalars['BigInt']>;
  nft_approval_id_not?: InputMaybe<Scalars['BigInt']>;
  nft_approval_id_gt?: InputMaybe<Scalars['BigInt']>;
  nft_approval_id_lt?: InputMaybe<Scalars['BigInt']>;
  nft_approval_id_gte?: InputMaybe<Scalars['BigInt']>;
  nft_approval_id_lte?: InputMaybe<Scalars['BigInt']>;
  nft_approval_id_in?: InputMaybe<Array<Scalars['BigInt']>>;
  nft_approval_id_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  seller_id?: InputMaybe<Scalars['String']>;
  seller_id_not?: InputMaybe<Scalars['String']>;
  seller_id_gt?: InputMaybe<Scalars['String']>;
  seller_id_lt?: InputMaybe<Scalars['String']>;
  seller_id_gte?: InputMaybe<Scalars['String']>;
  seller_id_lte?: InputMaybe<Scalars['String']>;
  seller_id_in?: InputMaybe<Array<Scalars['String']>>;
  seller_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  seller_id_contains?: InputMaybe<Scalars['String']>;
  seller_id_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_contains?: InputMaybe<Scalars['String']>;
  seller_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  seller_id_starts_with?: InputMaybe<Scalars['String']>;
  seller_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_starts_with?: InputMaybe<Scalars['String']>;
  seller_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_ends_with?: InputMaybe<Scalars['String']>;
  seller_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  seller_id_not_ends_with?: InputMaybe<Scalars['String']>;
  seller_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id?: InputMaybe<Scalars['String']>;
  buyer_id_not?: InputMaybe<Scalars['String']>;
  buyer_id_gt?: InputMaybe<Scalars['String']>;
  buyer_id_lt?: InputMaybe<Scalars['String']>;
  buyer_id_gte?: InputMaybe<Scalars['String']>;
  buyer_id_lte?: InputMaybe<Scalars['String']>;
  buyer_id_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  buyer_id_contains?: InputMaybe<Scalars['String']>;
  buyer_id_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_contains?: InputMaybe<Scalars['String']>;
  buyer_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_starts_with?: InputMaybe<Scalars['String']>;
  buyer_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_starts_with?: InputMaybe<Scalars['String']>;
  buyer_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_ends_with?: InputMaybe<Scalars['String']>;
  buyer_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buyer_id_not_ends_with?: InputMaybe<Scalars['String']>;
  buyer_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  prepaid_balance?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_not?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_gt?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_lt?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_gte?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_lte?: InputMaybe<Scalars['BigInt']>;
  prepaid_balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  prepaid_balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payment_balance?: InputMaybe<Scalars['BigInt']>;
  payment_balance_not?: InputMaybe<Scalars['BigInt']>;
  payment_balance_gt?: InputMaybe<Scalars['BigInt']>;
  payment_balance_lt?: InputMaybe<Scalars['BigInt']>;
  payment_balance_gte?: InputMaybe<Scalars['BigInt']>;
  payment_balance_lte?: InputMaybe<Scalars['BigInt']>;
  payment_balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payment_balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payout_balance?: InputMaybe<Scalars['BigInt']>;
  payout_balance_not?: InputMaybe<Scalars['BigInt']>;
  payout_balance_gt?: InputMaybe<Scalars['BigInt']>;
  payout_balance_lt?: InputMaybe<Scalars['BigInt']>;
  payout_balance_gte?: InputMaybe<Scalars['BigInt']>;
  payout_balance_lte?: InputMaybe<Scalars['BigInt']>;
  payout_balance_in?: InputMaybe<Array<Scalars['BigInt']>>;
  payout_balance_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  transfer_detail?: InputMaybe<Scalars['String']>;
  transfer_detail_not?: InputMaybe<Scalars['String']>;
  transfer_detail_gt?: InputMaybe<Scalars['String']>;
  transfer_detail_lt?: InputMaybe<Scalars['String']>;
  transfer_detail_gte?: InputMaybe<Scalars['String']>;
  transfer_detail_lte?: InputMaybe<Scalars['String']>;
  transfer_detail_in?: InputMaybe<Array<Scalars['String']>>;
  transfer_detail_not_in?: InputMaybe<Array<Scalars['String']>>;
  transfer_detail_contains?: InputMaybe<Scalars['String']>;
  transfer_detail_contains_nocase?: InputMaybe<Scalars['String']>;
  transfer_detail_not_contains?: InputMaybe<Scalars['String']>;
  transfer_detail_not_contains_nocase?: InputMaybe<Scalars['String']>;
  transfer_detail_starts_with?: InputMaybe<Scalars['String']>;
  transfer_detail_starts_with_nocase?: InputMaybe<Scalars['String']>;
  transfer_detail_not_starts_with?: InputMaybe<Scalars['String']>;
  transfer_detail_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  transfer_detail_ends_with?: InputMaybe<Scalars['String']>;
  transfer_detail_ends_with_nocase?: InputMaybe<Scalars['String']>;
  transfer_detail_not_ends_with?: InputMaybe<Scalars['String']>;
  transfer_detail_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  tx_time?: InputMaybe<Scalars['BigInt']>;
  tx_time_not?: InputMaybe<Scalars['BigInt']>;
  tx_time_gt?: InputMaybe<Scalars['BigInt']>;
  tx_time_lt?: InputMaybe<Scalars['BigInt']>;
  tx_time_gte?: InputMaybe<Scalars['BigInt']>;
  tx_time_lte?: InputMaybe<Scalars['BigInt']>;
  tx_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  tx_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Trading_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Trading_filter>>>;
};

export type Trading_orderBy =
  | 'id'
  | 'trading_type'
  | 'offering_id'
  | 'offering'
  | 'offering__id'
  | 'offering__buyer_id'
  | 'offering__contract_id'
  | 'offering__token_id'
  | 'offering__price'
  | 'offering__create_time'
  | 'offering__update_time'
  | 'offering__is_simple_offering'
  | 'offering__is_remove'
  | 'offering__expire_time'
  | 'is_simple_offering'
  | 'listing_id'
  | 'listing'
  | 'listing__id'
  | 'listing__seller_id'
  | 'listing__contract_id'
  | 'listing__token_id'
  | 'listing__price'
  | 'listing__create_time'
  | 'listing__update_time'
  | 'listing__is_remove'
  | 'nft_contract_id'
  | 'nft_token_id'
  | 'nft_approval_id'
  | 'seller_id'
  | 'buyer_id'
  | 'prepaid_balance'
  | 'payment_balance'
  | 'payout_balance'
  | 'transfer_detail'
  | 'tx_time';

export type UnlikeNftAction = {
  id: Scalars['ID'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
};

export type UnlikeNftAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UnlikeNftAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UnlikeNftAction_filter>>>;
};

export type UnlikeNftAction_orderBy =
  | 'id'
  | 'nft_contract_id'
  | 'nft_token_id';

export type UnwatchNftAction = {
  id: Scalars['ID'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
};

export type UnwatchNftAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UnwatchNftAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UnwatchNftAction_filter>>>;
};

export type UnwatchNftAction_orderBy =
  | 'id'
  | 'nft_contract_id'
  | 'nft_token_id';

export type UpdateListingAction = {
  id: Scalars['ID'];
  old_price: Scalars['BigInt'];
  new_price: Scalars['BigInt'];
  listing_id: Scalars['String'];
  listing: Listing;
};

export type UpdateListingAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  old_price?: InputMaybe<Scalars['BigInt']>;
  old_price_not?: InputMaybe<Scalars['BigInt']>;
  old_price_gt?: InputMaybe<Scalars['BigInt']>;
  old_price_lt?: InputMaybe<Scalars['BigInt']>;
  old_price_gte?: InputMaybe<Scalars['BigInt']>;
  old_price_lte?: InputMaybe<Scalars['BigInt']>;
  old_price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  old_price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  new_price?: InputMaybe<Scalars['BigInt']>;
  new_price_not?: InputMaybe<Scalars['BigInt']>;
  new_price_gt?: InputMaybe<Scalars['BigInt']>;
  new_price_lt?: InputMaybe<Scalars['BigInt']>;
  new_price_gte?: InputMaybe<Scalars['BigInt']>;
  new_price_lte?: InputMaybe<Scalars['BigInt']>;
  new_price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  new_price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  listing_id?: InputMaybe<Scalars['String']>;
  listing_id_not?: InputMaybe<Scalars['String']>;
  listing_id_gt?: InputMaybe<Scalars['String']>;
  listing_id_lt?: InputMaybe<Scalars['String']>;
  listing_id_gte?: InputMaybe<Scalars['String']>;
  listing_id_lte?: InputMaybe<Scalars['String']>;
  listing_id_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_id_contains?: InputMaybe<Scalars['String']>;
  listing_id_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_contains?: InputMaybe<Scalars['String']>;
  listing_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_id_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing?: InputMaybe<Scalars['String']>;
  listing_not?: InputMaybe<Scalars['String']>;
  listing_gt?: InputMaybe<Scalars['String']>;
  listing_lt?: InputMaybe<Scalars['String']>;
  listing_gte?: InputMaybe<Scalars['String']>;
  listing_lte?: InputMaybe<Scalars['String']>;
  listing_in?: InputMaybe<Array<Scalars['String']>>;
  listing_not_in?: InputMaybe<Array<Scalars['String']>>;
  listing_contains?: InputMaybe<Scalars['String']>;
  listing_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_not_contains?: InputMaybe<Scalars['String']>;
  listing_not_contains_nocase?: InputMaybe<Scalars['String']>;
  listing_starts_with?: InputMaybe<Scalars['String']>;
  listing_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_starts_with?: InputMaybe<Scalars['String']>;
  listing_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  listing_ends_with?: InputMaybe<Scalars['String']>;
  listing_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_not_ends_with?: InputMaybe<Scalars['String']>;
  listing_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  listing_?: InputMaybe<Listing_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UpdateListingAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UpdateListingAction_filter>>>;
};

export type UpdateListingAction_orderBy =
  | 'id'
  | 'old_price'
  | 'new_price'
  | 'listing_id'
  | 'listing'
  | 'listing__id'
  | 'listing__seller_id'
  | 'listing__contract_id'
  | 'listing__token_id'
  | 'listing__price'
  | 'listing__create_time'
  | 'listing__update_time'
  | 'listing__is_remove';

export type UpdateOfferingAction = {
  id: Scalars['ID'];
  offer_creator: Scalars['String'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
  old_price: Scalars['BigInt'];
  new_price: Scalars['BigInt'];
  offering_id: Scalars['String'];
  offering: Offering;
};

export type UpdateOfferingAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  offer_creator?: InputMaybe<Scalars['String']>;
  offer_creator_not?: InputMaybe<Scalars['String']>;
  offer_creator_gt?: InputMaybe<Scalars['String']>;
  offer_creator_lt?: InputMaybe<Scalars['String']>;
  offer_creator_gte?: InputMaybe<Scalars['String']>;
  offer_creator_lte?: InputMaybe<Scalars['String']>;
  offer_creator_in?: InputMaybe<Array<Scalars['String']>>;
  offer_creator_not_in?: InputMaybe<Array<Scalars['String']>>;
  offer_creator_contains?: InputMaybe<Scalars['String']>;
  offer_creator_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_not_contains?: InputMaybe<Scalars['String']>;
  offer_creator_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_starts_with?: InputMaybe<Scalars['String']>;
  offer_creator_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_not_starts_with?: InputMaybe<Scalars['String']>;
  offer_creator_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_ends_with?: InputMaybe<Scalars['String']>;
  offer_creator_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offer_creator_not_ends_with?: InputMaybe<Scalars['String']>;
  offer_creator_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  old_price?: InputMaybe<Scalars['BigInt']>;
  old_price_not?: InputMaybe<Scalars['BigInt']>;
  old_price_gt?: InputMaybe<Scalars['BigInt']>;
  old_price_lt?: InputMaybe<Scalars['BigInt']>;
  old_price_gte?: InputMaybe<Scalars['BigInt']>;
  old_price_lte?: InputMaybe<Scalars['BigInt']>;
  old_price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  old_price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  new_price?: InputMaybe<Scalars['BigInt']>;
  new_price_not?: InputMaybe<Scalars['BigInt']>;
  new_price_gt?: InputMaybe<Scalars['BigInt']>;
  new_price_lt?: InputMaybe<Scalars['BigInt']>;
  new_price_gte?: InputMaybe<Scalars['BigInt']>;
  new_price_lte?: InputMaybe<Scalars['BigInt']>;
  new_price_in?: InputMaybe<Array<Scalars['BigInt']>>;
  new_price_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  offering_id?: InputMaybe<Scalars['String']>;
  offering_id_not?: InputMaybe<Scalars['String']>;
  offering_id_gt?: InputMaybe<Scalars['String']>;
  offering_id_lt?: InputMaybe<Scalars['String']>;
  offering_id_gte?: InputMaybe<Scalars['String']>;
  offering_id_lte?: InputMaybe<Scalars['String']>;
  offering_id_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_id_contains?: InputMaybe<Scalars['String']>;
  offering_id_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_contains?: InputMaybe<Scalars['String']>;
  offering_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_id_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering?: InputMaybe<Scalars['String']>;
  offering_not?: InputMaybe<Scalars['String']>;
  offering_gt?: InputMaybe<Scalars['String']>;
  offering_lt?: InputMaybe<Scalars['String']>;
  offering_gte?: InputMaybe<Scalars['String']>;
  offering_lte?: InputMaybe<Scalars['String']>;
  offering_in?: InputMaybe<Array<Scalars['String']>>;
  offering_not_in?: InputMaybe<Array<Scalars['String']>>;
  offering_contains?: InputMaybe<Scalars['String']>;
  offering_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_not_contains?: InputMaybe<Scalars['String']>;
  offering_not_contains_nocase?: InputMaybe<Scalars['String']>;
  offering_starts_with?: InputMaybe<Scalars['String']>;
  offering_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_starts_with?: InputMaybe<Scalars['String']>;
  offering_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  offering_ends_with?: InputMaybe<Scalars['String']>;
  offering_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_not_ends_with?: InputMaybe<Scalars['String']>;
  offering_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  offering_?: InputMaybe<Offering_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UpdateOfferingAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UpdateOfferingAction_filter>>>;
};

export type UpdateOfferingAction_orderBy =
  | 'id'
  | 'offer_creator'
  | 'nft_contract_id'
  | 'nft_token_id'
  | 'old_price'
  | 'new_price'
  | 'offering_id'
  | 'offering'
  | 'offering__id'
  | 'offering__buyer_id'
  | 'offering__contract_id'
  | 'offering__token_id'
  | 'offering__price'
  | 'offering__create_time'
  | 'offering__update_time'
  | 'offering__is_simple_offering'
  | 'offering__is_remove'
  | 'offering__expire_time';

export type User = {
  id: Scalars['ID'];
  account_id: Scalars['String'];
  mint_times: Scalars['Int'];
  burn_times: Scalars['Int'];
  listing_times: Scalars['Int'];
  buy_listing_times: Scalars['Int'];
  offer_times: Scalars['Int'];
  accept_offer_times: Scalars['Int'];
  transfer_times: Scalars['Int'];
  trading_times: Scalars['Int'];
  trading_volume: Scalars['BigInt'];
  first_interaction_time: Scalars['BigInt'];
  last_read_notification_time: Scalars['BigInt'];
  last_interaction_time: Scalars['BigInt'];
  like_sum: Scalars['Int'];
  watch_sum: Scalars['Int'];
  like_nfts: Array<UserLikeNft>;
  watch_nfts: Array<UserWatchNft>;
};


export type Userlike_nftsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserLikeNft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserLikeNft_filter>;
};


export type Userwatch_nftsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<UserWatchNft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<UserWatchNft_filter>;
};

export type UserAction = {
  id: Scalars['ID'];
  predecessor_id: Scalars['String'];
  signer_id: Scalars['String'];
  user_id: Scalars['String'];
  timestamp: Scalars['BigInt'];
  timestamp_plus_log_index: Scalars['BigInt'];
  receipt_id: Scalars['String'];
  nft?: Maybe<Nft>;
  contract_id?: Maybe<Scalars['String']>;
  token_id?: Maybe<Scalars['String']>;
  action_type: ActionType;
  create_listing_action?: Maybe<CreateListingAction>;
  update_listing_action?: Maybe<UpdateListingAction>;
  remove_listing_action?: Maybe<RemoveListingAction>;
  create_offering_action?: Maybe<CreateOfferingAction>;
  update_offering_action?: Maybe<UpdateOfferingAction>;
  remove_offering_action?: Maybe<RemoveOfferingAction>;
  buy_listing_action?: Maybe<BuyListingAction>;
  accept_offering_action?: Maybe<AcceptOfferingAction>;
  nft_mint_action?: Maybe<NftMintAction>;
  nft_transfer_action?: Maybe<NftTransferAction>;
  nft_burn_action?: Maybe<NftBurnAction>;
  nft_register_action?: Maybe<NftRegisterAction>;
  nft_unregister_action?: Maybe<NftUnregisterAction>;
  control_account_action?: Maybe<ControlAccountAction>;
  like_nft_action?: Maybe<LikeNftAction>;
  unlike_nft_action?: Maybe<UnlikeNftAction>;
  watch_nft_action?: Maybe<WatchNftAction>;
  unwatch_nft_action?: Maybe<UnwatchNftAction>;
  read_notification_action?: Maybe<ReadNotificationAction>;
};

export type UserAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  predecessor_id?: InputMaybe<Scalars['String']>;
  predecessor_id_not?: InputMaybe<Scalars['String']>;
  predecessor_id_gt?: InputMaybe<Scalars['String']>;
  predecessor_id_lt?: InputMaybe<Scalars['String']>;
  predecessor_id_gte?: InputMaybe<Scalars['String']>;
  predecessor_id_lte?: InputMaybe<Scalars['String']>;
  predecessor_id_in?: InputMaybe<Array<Scalars['String']>>;
  predecessor_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  predecessor_id_contains?: InputMaybe<Scalars['String']>;
  predecessor_id_contains_nocase?: InputMaybe<Scalars['String']>;
  predecessor_id_not_contains?: InputMaybe<Scalars['String']>;
  predecessor_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  predecessor_id_starts_with?: InputMaybe<Scalars['String']>;
  predecessor_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  predecessor_id_not_starts_with?: InputMaybe<Scalars['String']>;
  predecessor_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  predecessor_id_ends_with?: InputMaybe<Scalars['String']>;
  predecessor_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  predecessor_id_not_ends_with?: InputMaybe<Scalars['String']>;
  predecessor_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  signer_id?: InputMaybe<Scalars['String']>;
  signer_id_not?: InputMaybe<Scalars['String']>;
  signer_id_gt?: InputMaybe<Scalars['String']>;
  signer_id_lt?: InputMaybe<Scalars['String']>;
  signer_id_gte?: InputMaybe<Scalars['String']>;
  signer_id_lte?: InputMaybe<Scalars['String']>;
  signer_id_in?: InputMaybe<Array<Scalars['String']>>;
  signer_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  signer_id_contains?: InputMaybe<Scalars['String']>;
  signer_id_contains_nocase?: InputMaybe<Scalars['String']>;
  signer_id_not_contains?: InputMaybe<Scalars['String']>;
  signer_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  signer_id_starts_with?: InputMaybe<Scalars['String']>;
  signer_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  signer_id_not_starts_with?: InputMaybe<Scalars['String']>;
  signer_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  signer_id_ends_with?: InputMaybe<Scalars['String']>;
  signer_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  signer_id_not_ends_with?: InputMaybe<Scalars['String']>;
  signer_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
  user_id_not?: InputMaybe<Scalars['String']>;
  user_id_gt?: InputMaybe<Scalars['String']>;
  user_id_lt?: InputMaybe<Scalars['String']>;
  user_id_gte?: InputMaybe<Scalars['String']>;
  user_id_lte?: InputMaybe<Scalars['String']>;
  user_id_in?: InputMaybe<Array<Scalars['String']>>;
  user_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  user_id_contains?: InputMaybe<Scalars['String']>;
  user_id_contains_nocase?: InputMaybe<Scalars['String']>;
  user_id_not_contains?: InputMaybe<Scalars['String']>;
  user_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  user_id_starts_with?: InputMaybe<Scalars['String']>;
  user_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_id_not_starts_with?: InputMaybe<Scalars['String']>;
  user_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_id_ends_with?: InputMaybe<Scalars['String']>;
  user_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_id_not_ends_with?: InputMaybe<Scalars['String']>;
  user_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  timestamp?: InputMaybe<Scalars['BigInt']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_plus_log_index?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_not?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_gt?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_lt?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_gte?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_lte?: InputMaybe<Scalars['BigInt']>;
  timestamp_plus_log_index_in?: InputMaybe<Array<Scalars['BigInt']>>;
  timestamp_plus_log_index_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  receipt_id?: InputMaybe<Scalars['String']>;
  receipt_id_not?: InputMaybe<Scalars['String']>;
  receipt_id_gt?: InputMaybe<Scalars['String']>;
  receipt_id_lt?: InputMaybe<Scalars['String']>;
  receipt_id_gte?: InputMaybe<Scalars['String']>;
  receipt_id_lte?: InputMaybe<Scalars['String']>;
  receipt_id_in?: InputMaybe<Array<Scalars['String']>>;
  receipt_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  receipt_id_contains?: InputMaybe<Scalars['String']>;
  receipt_id_contains_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_not_contains?: InputMaybe<Scalars['String']>;
  receipt_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_starts_with?: InputMaybe<Scalars['String']>;
  receipt_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_not_starts_with?: InputMaybe<Scalars['String']>;
  receipt_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_ends_with?: InputMaybe<Scalars['String']>;
  receipt_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  receipt_id_not_ends_with?: InputMaybe<Scalars['String']>;
  receipt_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft?: InputMaybe<Scalars['String']>;
  nft_not?: InputMaybe<Scalars['String']>;
  nft_gt?: InputMaybe<Scalars['String']>;
  nft_lt?: InputMaybe<Scalars['String']>;
  nft_gte?: InputMaybe<Scalars['String']>;
  nft_lte?: InputMaybe<Scalars['String']>;
  nft_in?: InputMaybe<Array<Scalars['String']>>;
  nft_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contains?: InputMaybe<Scalars['String']>;
  nft_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_not_contains?: InputMaybe<Scalars['String']>;
  nft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_starts_with?: InputMaybe<Scalars['String']>;
  nft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_ends_with?: InputMaybe<Scalars['String']>;
  nft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_?: InputMaybe<Nft_filter>;
  contract_id?: InputMaybe<Scalars['String']>;
  contract_id_not?: InputMaybe<Scalars['String']>;
  contract_id_gt?: InputMaybe<Scalars['String']>;
  contract_id_lt?: InputMaybe<Scalars['String']>;
  contract_id_gte?: InputMaybe<Scalars['String']>;
  contract_id_lte?: InputMaybe<Scalars['String']>;
  contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  contract_id_contains?: InputMaybe<Scalars['String']>;
  contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_contains?: InputMaybe<Scalars['String']>;
  contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  contract_id_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id?: InputMaybe<Scalars['String']>;
  token_id_not?: InputMaybe<Scalars['String']>;
  token_id_gt?: InputMaybe<Scalars['String']>;
  token_id_lt?: InputMaybe<Scalars['String']>;
  token_id_gte?: InputMaybe<Scalars['String']>;
  token_id_lte?: InputMaybe<Scalars['String']>;
  token_id_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  token_id_contains?: InputMaybe<Scalars['String']>;
  token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_contains?: InputMaybe<Scalars['String']>;
  token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  token_id_starts_with?: InputMaybe<Scalars['String']>;
  token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_ends_with?: InputMaybe<Scalars['String']>;
  token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  action_type?: InputMaybe<ActionType>;
  action_type_not?: InputMaybe<ActionType>;
  action_type_in?: InputMaybe<Array<ActionType>>;
  action_type_not_in?: InputMaybe<Array<ActionType>>;
  create_listing_action?: InputMaybe<Scalars['String']>;
  create_listing_action_not?: InputMaybe<Scalars['String']>;
  create_listing_action_gt?: InputMaybe<Scalars['String']>;
  create_listing_action_lt?: InputMaybe<Scalars['String']>;
  create_listing_action_gte?: InputMaybe<Scalars['String']>;
  create_listing_action_lte?: InputMaybe<Scalars['String']>;
  create_listing_action_in?: InputMaybe<Array<Scalars['String']>>;
  create_listing_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  create_listing_action_contains?: InputMaybe<Scalars['String']>;
  create_listing_action_contains_nocase?: InputMaybe<Scalars['String']>;
  create_listing_action_not_contains?: InputMaybe<Scalars['String']>;
  create_listing_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  create_listing_action_starts_with?: InputMaybe<Scalars['String']>;
  create_listing_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  create_listing_action_not_starts_with?: InputMaybe<Scalars['String']>;
  create_listing_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  create_listing_action_ends_with?: InputMaybe<Scalars['String']>;
  create_listing_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  create_listing_action_not_ends_with?: InputMaybe<Scalars['String']>;
  create_listing_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  create_listing_action_?: InputMaybe<CreateListingAction_filter>;
  update_listing_action?: InputMaybe<Scalars['String']>;
  update_listing_action_not?: InputMaybe<Scalars['String']>;
  update_listing_action_gt?: InputMaybe<Scalars['String']>;
  update_listing_action_lt?: InputMaybe<Scalars['String']>;
  update_listing_action_gte?: InputMaybe<Scalars['String']>;
  update_listing_action_lte?: InputMaybe<Scalars['String']>;
  update_listing_action_in?: InputMaybe<Array<Scalars['String']>>;
  update_listing_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  update_listing_action_contains?: InputMaybe<Scalars['String']>;
  update_listing_action_contains_nocase?: InputMaybe<Scalars['String']>;
  update_listing_action_not_contains?: InputMaybe<Scalars['String']>;
  update_listing_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  update_listing_action_starts_with?: InputMaybe<Scalars['String']>;
  update_listing_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  update_listing_action_not_starts_with?: InputMaybe<Scalars['String']>;
  update_listing_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  update_listing_action_ends_with?: InputMaybe<Scalars['String']>;
  update_listing_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  update_listing_action_not_ends_with?: InputMaybe<Scalars['String']>;
  update_listing_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  update_listing_action_?: InputMaybe<UpdateListingAction_filter>;
  remove_listing_action?: InputMaybe<Scalars['String']>;
  remove_listing_action_not?: InputMaybe<Scalars['String']>;
  remove_listing_action_gt?: InputMaybe<Scalars['String']>;
  remove_listing_action_lt?: InputMaybe<Scalars['String']>;
  remove_listing_action_gte?: InputMaybe<Scalars['String']>;
  remove_listing_action_lte?: InputMaybe<Scalars['String']>;
  remove_listing_action_in?: InputMaybe<Array<Scalars['String']>>;
  remove_listing_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  remove_listing_action_contains?: InputMaybe<Scalars['String']>;
  remove_listing_action_contains_nocase?: InputMaybe<Scalars['String']>;
  remove_listing_action_not_contains?: InputMaybe<Scalars['String']>;
  remove_listing_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  remove_listing_action_starts_with?: InputMaybe<Scalars['String']>;
  remove_listing_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  remove_listing_action_not_starts_with?: InputMaybe<Scalars['String']>;
  remove_listing_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  remove_listing_action_ends_with?: InputMaybe<Scalars['String']>;
  remove_listing_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  remove_listing_action_not_ends_with?: InputMaybe<Scalars['String']>;
  remove_listing_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  remove_listing_action_?: InputMaybe<RemoveListingAction_filter>;
  create_offering_action?: InputMaybe<Scalars['String']>;
  create_offering_action_not?: InputMaybe<Scalars['String']>;
  create_offering_action_gt?: InputMaybe<Scalars['String']>;
  create_offering_action_lt?: InputMaybe<Scalars['String']>;
  create_offering_action_gte?: InputMaybe<Scalars['String']>;
  create_offering_action_lte?: InputMaybe<Scalars['String']>;
  create_offering_action_in?: InputMaybe<Array<Scalars['String']>>;
  create_offering_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  create_offering_action_contains?: InputMaybe<Scalars['String']>;
  create_offering_action_contains_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_not_contains?: InputMaybe<Scalars['String']>;
  create_offering_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_starts_with?: InputMaybe<Scalars['String']>;
  create_offering_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_not_starts_with?: InputMaybe<Scalars['String']>;
  create_offering_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_ends_with?: InputMaybe<Scalars['String']>;
  create_offering_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_not_ends_with?: InputMaybe<Scalars['String']>;
  create_offering_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  create_offering_action_?: InputMaybe<CreateOfferingAction_filter>;
  update_offering_action?: InputMaybe<Scalars['String']>;
  update_offering_action_not?: InputMaybe<Scalars['String']>;
  update_offering_action_gt?: InputMaybe<Scalars['String']>;
  update_offering_action_lt?: InputMaybe<Scalars['String']>;
  update_offering_action_gte?: InputMaybe<Scalars['String']>;
  update_offering_action_lte?: InputMaybe<Scalars['String']>;
  update_offering_action_in?: InputMaybe<Array<Scalars['String']>>;
  update_offering_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  update_offering_action_contains?: InputMaybe<Scalars['String']>;
  update_offering_action_contains_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_not_contains?: InputMaybe<Scalars['String']>;
  update_offering_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_starts_with?: InputMaybe<Scalars['String']>;
  update_offering_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_not_starts_with?: InputMaybe<Scalars['String']>;
  update_offering_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_ends_with?: InputMaybe<Scalars['String']>;
  update_offering_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_not_ends_with?: InputMaybe<Scalars['String']>;
  update_offering_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  update_offering_action_?: InputMaybe<UpdateOfferingAction_filter>;
  remove_offering_action?: InputMaybe<Scalars['String']>;
  remove_offering_action_not?: InputMaybe<Scalars['String']>;
  remove_offering_action_gt?: InputMaybe<Scalars['String']>;
  remove_offering_action_lt?: InputMaybe<Scalars['String']>;
  remove_offering_action_gte?: InputMaybe<Scalars['String']>;
  remove_offering_action_lte?: InputMaybe<Scalars['String']>;
  remove_offering_action_in?: InputMaybe<Array<Scalars['String']>>;
  remove_offering_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  remove_offering_action_contains?: InputMaybe<Scalars['String']>;
  remove_offering_action_contains_nocase?: InputMaybe<Scalars['String']>;
  remove_offering_action_not_contains?: InputMaybe<Scalars['String']>;
  remove_offering_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  remove_offering_action_starts_with?: InputMaybe<Scalars['String']>;
  remove_offering_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  remove_offering_action_not_starts_with?: InputMaybe<Scalars['String']>;
  remove_offering_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  remove_offering_action_ends_with?: InputMaybe<Scalars['String']>;
  remove_offering_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  remove_offering_action_not_ends_with?: InputMaybe<Scalars['String']>;
  remove_offering_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  remove_offering_action_?: InputMaybe<RemoveOfferingAction_filter>;
  buy_listing_action?: InputMaybe<Scalars['String']>;
  buy_listing_action_not?: InputMaybe<Scalars['String']>;
  buy_listing_action_gt?: InputMaybe<Scalars['String']>;
  buy_listing_action_lt?: InputMaybe<Scalars['String']>;
  buy_listing_action_gte?: InputMaybe<Scalars['String']>;
  buy_listing_action_lte?: InputMaybe<Scalars['String']>;
  buy_listing_action_in?: InputMaybe<Array<Scalars['String']>>;
  buy_listing_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  buy_listing_action_contains?: InputMaybe<Scalars['String']>;
  buy_listing_action_contains_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_contains?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_starts_with?: InputMaybe<Scalars['String']>;
  buy_listing_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_starts_with?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_ends_with?: InputMaybe<Scalars['String']>;
  buy_listing_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_ends_with?: InputMaybe<Scalars['String']>;
  buy_listing_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  buy_listing_action_?: InputMaybe<BuyListingAction_filter>;
  accept_offering_action?: InputMaybe<Scalars['String']>;
  accept_offering_action_not?: InputMaybe<Scalars['String']>;
  accept_offering_action_gt?: InputMaybe<Scalars['String']>;
  accept_offering_action_lt?: InputMaybe<Scalars['String']>;
  accept_offering_action_gte?: InputMaybe<Scalars['String']>;
  accept_offering_action_lte?: InputMaybe<Scalars['String']>;
  accept_offering_action_in?: InputMaybe<Array<Scalars['String']>>;
  accept_offering_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  accept_offering_action_contains?: InputMaybe<Scalars['String']>;
  accept_offering_action_contains_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_contains?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_starts_with?: InputMaybe<Scalars['String']>;
  accept_offering_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_starts_with?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_ends_with?: InputMaybe<Scalars['String']>;
  accept_offering_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_ends_with?: InputMaybe<Scalars['String']>;
  accept_offering_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  accept_offering_action_?: InputMaybe<AcceptOfferingAction_filter>;
  nft_mint_action?: InputMaybe<Scalars['String']>;
  nft_mint_action_not?: InputMaybe<Scalars['String']>;
  nft_mint_action_gt?: InputMaybe<Scalars['String']>;
  nft_mint_action_lt?: InputMaybe<Scalars['String']>;
  nft_mint_action_gte?: InputMaybe<Scalars['String']>;
  nft_mint_action_lte?: InputMaybe<Scalars['String']>;
  nft_mint_action_in?: InputMaybe<Array<Scalars['String']>>;
  nft_mint_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_mint_action_contains?: InputMaybe<Scalars['String']>;
  nft_mint_action_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_mint_action_not_contains?: InputMaybe<Scalars['String']>;
  nft_mint_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_mint_action_starts_with?: InputMaybe<Scalars['String']>;
  nft_mint_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_mint_action_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_mint_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_mint_action_ends_with?: InputMaybe<Scalars['String']>;
  nft_mint_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_mint_action_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_mint_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_mint_action_?: InputMaybe<NftMintAction_filter>;
  nft_transfer_action?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not?: InputMaybe<Scalars['String']>;
  nft_transfer_action_gt?: InputMaybe<Scalars['String']>;
  nft_transfer_action_lt?: InputMaybe<Scalars['String']>;
  nft_transfer_action_gte?: InputMaybe<Scalars['String']>;
  nft_transfer_action_lte?: InputMaybe<Scalars['String']>;
  nft_transfer_action_in?: InputMaybe<Array<Scalars['String']>>;
  nft_transfer_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_transfer_action_contains?: InputMaybe<Scalars['String']>;
  nft_transfer_action_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_contains?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_starts_with?: InputMaybe<Scalars['String']>;
  nft_transfer_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_ends_with?: InputMaybe<Scalars['String']>;
  nft_transfer_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_transfer_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_transfer_action_?: InputMaybe<NftTransferAction_filter>;
  nft_burn_action?: InputMaybe<Scalars['String']>;
  nft_burn_action_not?: InputMaybe<Scalars['String']>;
  nft_burn_action_gt?: InputMaybe<Scalars['String']>;
  nft_burn_action_lt?: InputMaybe<Scalars['String']>;
  nft_burn_action_gte?: InputMaybe<Scalars['String']>;
  nft_burn_action_lte?: InputMaybe<Scalars['String']>;
  nft_burn_action_in?: InputMaybe<Array<Scalars['String']>>;
  nft_burn_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_burn_action_contains?: InputMaybe<Scalars['String']>;
  nft_burn_action_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_burn_action_not_contains?: InputMaybe<Scalars['String']>;
  nft_burn_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_burn_action_starts_with?: InputMaybe<Scalars['String']>;
  nft_burn_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_burn_action_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_burn_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_burn_action_ends_with?: InputMaybe<Scalars['String']>;
  nft_burn_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_burn_action_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_burn_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_burn_action_?: InputMaybe<NftBurnAction_filter>;
  nft_register_action?: InputMaybe<Scalars['String']>;
  nft_register_action_not?: InputMaybe<Scalars['String']>;
  nft_register_action_gt?: InputMaybe<Scalars['String']>;
  nft_register_action_lt?: InputMaybe<Scalars['String']>;
  nft_register_action_gte?: InputMaybe<Scalars['String']>;
  nft_register_action_lte?: InputMaybe<Scalars['String']>;
  nft_register_action_in?: InputMaybe<Array<Scalars['String']>>;
  nft_register_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_register_action_contains?: InputMaybe<Scalars['String']>;
  nft_register_action_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_register_action_not_contains?: InputMaybe<Scalars['String']>;
  nft_register_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_register_action_starts_with?: InputMaybe<Scalars['String']>;
  nft_register_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_register_action_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_register_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_register_action_ends_with?: InputMaybe<Scalars['String']>;
  nft_register_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_register_action_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_register_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_register_action_?: InputMaybe<NftRegisterAction_filter>;
  nft_unregister_action?: InputMaybe<Scalars['String']>;
  nft_unregister_action_not?: InputMaybe<Scalars['String']>;
  nft_unregister_action_gt?: InputMaybe<Scalars['String']>;
  nft_unregister_action_lt?: InputMaybe<Scalars['String']>;
  nft_unregister_action_gte?: InputMaybe<Scalars['String']>;
  nft_unregister_action_lte?: InputMaybe<Scalars['String']>;
  nft_unregister_action_in?: InputMaybe<Array<Scalars['String']>>;
  nft_unregister_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_unregister_action_contains?: InputMaybe<Scalars['String']>;
  nft_unregister_action_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_unregister_action_not_contains?: InputMaybe<Scalars['String']>;
  nft_unregister_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_unregister_action_starts_with?: InputMaybe<Scalars['String']>;
  nft_unregister_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_unregister_action_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_unregister_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_unregister_action_ends_with?: InputMaybe<Scalars['String']>;
  nft_unregister_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_unregister_action_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_unregister_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_unregister_action_?: InputMaybe<NftUnregisterAction_filter>;
  control_account_action?: InputMaybe<Scalars['String']>;
  control_account_action_not?: InputMaybe<Scalars['String']>;
  control_account_action_gt?: InputMaybe<Scalars['String']>;
  control_account_action_lt?: InputMaybe<Scalars['String']>;
  control_account_action_gte?: InputMaybe<Scalars['String']>;
  control_account_action_lte?: InputMaybe<Scalars['String']>;
  control_account_action_in?: InputMaybe<Array<Scalars['String']>>;
  control_account_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  control_account_action_contains?: InputMaybe<Scalars['String']>;
  control_account_action_contains_nocase?: InputMaybe<Scalars['String']>;
  control_account_action_not_contains?: InputMaybe<Scalars['String']>;
  control_account_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  control_account_action_starts_with?: InputMaybe<Scalars['String']>;
  control_account_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  control_account_action_not_starts_with?: InputMaybe<Scalars['String']>;
  control_account_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  control_account_action_ends_with?: InputMaybe<Scalars['String']>;
  control_account_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  control_account_action_not_ends_with?: InputMaybe<Scalars['String']>;
  control_account_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  control_account_action_?: InputMaybe<ControlAccountAction_filter>;
  like_nft_action?: InputMaybe<Scalars['String']>;
  like_nft_action_not?: InputMaybe<Scalars['String']>;
  like_nft_action_gt?: InputMaybe<Scalars['String']>;
  like_nft_action_lt?: InputMaybe<Scalars['String']>;
  like_nft_action_gte?: InputMaybe<Scalars['String']>;
  like_nft_action_lte?: InputMaybe<Scalars['String']>;
  like_nft_action_in?: InputMaybe<Array<Scalars['String']>>;
  like_nft_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  like_nft_action_contains?: InputMaybe<Scalars['String']>;
  like_nft_action_contains_nocase?: InputMaybe<Scalars['String']>;
  like_nft_action_not_contains?: InputMaybe<Scalars['String']>;
  like_nft_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  like_nft_action_starts_with?: InputMaybe<Scalars['String']>;
  like_nft_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  like_nft_action_not_starts_with?: InputMaybe<Scalars['String']>;
  like_nft_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  like_nft_action_ends_with?: InputMaybe<Scalars['String']>;
  like_nft_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  like_nft_action_not_ends_with?: InputMaybe<Scalars['String']>;
  like_nft_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  like_nft_action_?: InputMaybe<LikeNftAction_filter>;
  unlike_nft_action?: InputMaybe<Scalars['String']>;
  unlike_nft_action_not?: InputMaybe<Scalars['String']>;
  unlike_nft_action_gt?: InputMaybe<Scalars['String']>;
  unlike_nft_action_lt?: InputMaybe<Scalars['String']>;
  unlike_nft_action_gte?: InputMaybe<Scalars['String']>;
  unlike_nft_action_lte?: InputMaybe<Scalars['String']>;
  unlike_nft_action_in?: InputMaybe<Array<Scalars['String']>>;
  unlike_nft_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  unlike_nft_action_contains?: InputMaybe<Scalars['String']>;
  unlike_nft_action_contains_nocase?: InputMaybe<Scalars['String']>;
  unlike_nft_action_not_contains?: InputMaybe<Scalars['String']>;
  unlike_nft_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  unlike_nft_action_starts_with?: InputMaybe<Scalars['String']>;
  unlike_nft_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  unlike_nft_action_not_starts_with?: InputMaybe<Scalars['String']>;
  unlike_nft_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  unlike_nft_action_ends_with?: InputMaybe<Scalars['String']>;
  unlike_nft_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  unlike_nft_action_not_ends_with?: InputMaybe<Scalars['String']>;
  unlike_nft_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  unlike_nft_action_?: InputMaybe<UnlikeNftAction_filter>;
  watch_nft_action?: InputMaybe<Scalars['String']>;
  watch_nft_action_not?: InputMaybe<Scalars['String']>;
  watch_nft_action_gt?: InputMaybe<Scalars['String']>;
  watch_nft_action_lt?: InputMaybe<Scalars['String']>;
  watch_nft_action_gte?: InputMaybe<Scalars['String']>;
  watch_nft_action_lte?: InputMaybe<Scalars['String']>;
  watch_nft_action_in?: InputMaybe<Array<Scalars['String']>>;
  watch_nft_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  watch_nft_action_contains?: InputMaybe<Scalars['String']>;
  watch_nft_action_contains_nocase?: InputMaybe<Scalars['String']>;
  watch_nft_action_not_contains?: InputMaybe<Scalars['String']>;
  watch_nft_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  watch_nft_action_starts_with?: InputMaybe<Scalars['String']>;
  watch_nft_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  watch_nft_action_not_starts_with?: InputMaybe<Scalars['String']>;
  watch_nft_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  watch_nft_action_ends_with?: InputMaybe<Scalars['String']>;
  watch_nft_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  watch_nft_action_not_ends_with?: InputMaybe<Scalars['String']>;
  watch_nft_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  watch_nft_action_?: InputMaybe<WatchNftAction_filter>;
  unwatch_nft_action?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_not?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_gt?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_lt?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_gte?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_lte?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_in?: InputMaybe<Array<Scalars['String']>>;
  unwatch_nft_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  unwatch_nft_action_contains?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_contains_nocase?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_not_contains?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_starts_with?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_not_starts_with?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_ends_with?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_not_ends_with?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  unwatch_nft_action_?: InputMaybe<UnwatchNftAction_filter>;
  read_notification_action?: InputMaybe<Scalars['String']>;
  read_notification_action_not?: InputMaybe<Scalars['String']>;
  read_notification_action_gt?: InputMaybe<Scalars['String']>;
  read_notification_action_lt?: InputMaybe<Scalars['String']>;
  read_notification_action_gte?: InputMaybe<Scalars['String']>;
  read_notification_action_lte?: InputMaybe<Scalars['String']>;
  read_notification_action_in?: InputMaybe<Array<Scalars['String']>>;
  read_notification_action_not_in?: InputMaybe<Array<Scalars['String']>>;
  read_notification_action_contains?: InputMaybe<Scalars['String']>;
  read_notification_action_contains_nocase?: InputMaybe<Scalars['String']>;
  read_notification_action_not_contains?: InputMaybe<Scalars['String']>;
  read_notification_action_not_contains_nocase?: InputMaybe<Scalars['String']>;
  read_notification_action_starts_with?: InputMaybe<Scalars['String']>;
  read_notification_action_starts_with_nocase?: InputMaybe<Scalars['String']>;
  read_notification_action_not_starts_with?: InputMaybe<Scalars['String']>;
  read_notification_action_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  read_notification_action_ends_with?: InputMaybe<Scalars['String']>;
  read_notification_action_ends_with_nocase?: InputMaybe<Scalars['String']>;
  read_notification_action_not_ends_with?: InputMaybe<Scalars['String']>;
  read_notification_action_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  read_notification_action_?: InputMaybe<ReadNotificationAction_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UserAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UserAction_filter>>>;
};

export type UserAction_orderBy =
  | 'id'
  | 'predecessor_id'
  | 'signer_id'
  | 'user_id'
  | 'timestamp'
  | 'timestamp_plus_log_index'
  | 'receipt_id'
  | 'nft'
  | 'nft__id'
  | 'nft__contract_id'
  | 'nft__token_id'
  | 'nft__owner_id'
  | 'nft__is_burn'
  | 'nft__search_name'
  | 'nft__search_name_len'
  | 'nft__mint_time'
  | 'nft__name_len'
  | 'nft__name_level'
  | 'nft__nft_approve_id_for_market_contract'
  | 'nft__nft_approve_id_in_market_contract'
  | 'nft__is_approve_id_effective_in_market_contract'
  | 'nft__price'
  | 'nft__listing_time'
  | 'nft__last_sale_price'
  | 'nft__liked_sum'
  | 'nft__watched_sum'
  | 'nft__character_set'
  | 'nft__contain_separator'
  | 'nft__club'
  | 'nft__offer_count'
  | 'nft__top_domain'
  | 'nft__bottom_domain'
  | 'nft__suffix_domain'
  | 'nft__prefix_domain'
  | 'contract_id'
  | 'token_id'
  | 'action_type'
  | 'create_listing_action'
  | 'create_listing_action__id'
  | 'create_listing_action__price'
  | 'create_listing_action__listing_id'
  | 'update_listing_action'
  | 'update_listing_action__id'
  | 'update_listing_action__old_price'
  | 'update_listing_action__new_price'
  | 'update_listing_action__listing_id'
  | 'remove_listing_action'
  | 'remove_listing_action__id'
  | 'remove_listing_action__price'
  | 'remove_listing_action__listing_id'
  | 'create_offering_action'
  | 'create_offering_action__id'
  | 'create_offering_action__offer_creator'
  | 'create_offering_action__nft_contract_id'
  | 'create_offering_action__nft_token_id'
  | 'create_offering_action__price'
  | 'create_offering_action__offering_id'
  | 'update_offering_action'
  | 'update_offering_action__id'
  | 'update_offering_action__offer_creator'
  | 'update_offering_action__nft_contract_id'
  | 'update_offering_action__nft_token_id'
  | 'update_offering_action__old_price'
  | 'update_offering_action__new_price'
  | 'update_offering_action__offering_id'
  | 'remove_offering_action'
  | 'remove_offering_action__id'
  | 'remove_offering_action__offer_creator'
  | 'remove_offering_action__nft_contract_id'
  | 'remove_offering_action__nft_token_id'
  | 'remove_offering_action__price'
  | 'remove_offering_action__offering_id'
  | 'buy_listing_action'
  | 'buy_listing_action__id'
  | 'buy_listing_action__buyer_id'
  | 'buy_listing_action__seller_id'
  | 'buy_listing_action__prepaid_balance'
  | 'buy_listing_action__payment_balance'
  | 'buy_listing_action__payout_balance'
  | 'buy_listing_action__nft_contract_id'
  | 'buy_listing_action__nft_token_id'
  | 'buy_listing_action__trading_id'
  | 'buy_listing_action__listing_id'
  | 'accept_offering_action'
  | 'accept_offering_action__id'
  | 'accept_offering_action__buyer_id'
  | 'accept_offering_action__seller_id'
  | 'accept_offering_action__prepaid_balance'
  | 'accept_offering_action__payment_balance'
  | 'accept_offering_action__payout_balance'
  | 'accept_offering_action__nft_contract_id'
  | 'accept_offering_action__nft_token_id'
  | 'accept_offering_action__trading_id'
  | 'accept_offering_action__offering_id'
  | 'nft_mint_action'
  | 'nft_mint_action__id'
  | 'nft_mint_action__owner_id'
  | 'nft_mint_action__contract_id'
  | 'nft_mint_action__token_id'
  | 'nft_transfer_action'
  | 'nft_transfer_action__id'
  | 'nft_transfer_action__old_owner_id'
  | 'nft_transfer_action__new_owner_id'
  | 'nft_transfer_action__token_id'
  | 'nft_transfer_action__authorized_id'
  | 'nft_transfer_action__memo'
  | 'nft_burn_action'
  | 'nft_burn_action__id'
  | 'nft_burn_action__token_id'
  | 'nft_burn_action__authorized_id'
  | 'nft_burn_action__memo'
  | 'nft_register_action'
  | 'nft_register_action__id'
  | 'nft_register_action__registrant_id'
  | 'nft_register_action__minter_id'
  | 'nft_unregister_action'
  | 'nft_unregister_action__id'
  | 'nft_unregister_action__registrant_id'
  | 'nft_unregister_action__public_key'
  | 'nft_unregister_action__is_force'
  | 'nft_unregister_action__is_control_success'
  | 'control_account_action'
  | 'control_account_action__id'
  | 'control_account_action__account_id'
  | 'control_account_action__public_key'
  | 'like_nft_action'
  | 'like_nft_action__id'
  | 'like_nft_action__nft_contract_id'
  | 'like_nft_action__nft_token_id'
  | 'unlike_nft_action'
  | 'unlike_nft_action__id'
  | 'unlike_nft_action__nft_contract_id'
  | 'unlike_nft_action__nft_token_id'
  | 'watch_nft_action'
  | 'watch_nft_action__id'
  | 'watch_nft_action__nft_contract_id'
  | 'watch_nft_action__nft_token_id'
  | 'unwatch_nft_action'
  | 'unwatch_nft_action__id'
  | 'unwatch_nft_action__nft_contract_id'
  | 'unwatch_nft_action__nft_token_id'
  | 'read_notification_action'
  | 'read_notification_action__id'
  | 'read_notification_action__timestamp';

export type UserLikeNft = {
  id: Scalars['ID'];
  user: User;
  nft: Nft;
};

export type UserLikeNft_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  user?: InputMaybe<Scalars['String']>;
  user_not?: InputMaybe<Scalars['String']>;
  user_gt?: InputMaybe<Scalars['String']>;
  user_lt?: InputMaybe<Scalars['String']>;
  user_gte?: InputMaybe<Scalars['String']>;
  user_lte?: InputMaybe<Scalars['String']>;
  user_in?: InputMaybe<Array<Scalars['String']>>;
  user_not_in?: InputMaybe<Array<Scalars['String']>>;
  user_contains?: InputMaybe<Scalars['String']>;
  user_contains_nocase?: InputMaybe<Scalars['String']>;
  user_not_contains?: InputMaybe<Scalars['String']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']>;
  user_starts_with?: InputMaybe<Scalars['String']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_starts_with?: InputMaybe<Scalars['String']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_ends_with?: InputMaybe<Scalars['String']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_ends_with?: InputMaybe<Scalars['String']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_?: InputMaybe<User_filter>;
  nft?: InputMaybe<Scalars['String']>;
  nft_not?: InputMaybe<Scalars['String']>;
  nft_gt?: InputMaybe<Scalars['String']>;
  nft_lt?: InputMaybe<Scalars['String']>;
  nft_gte?: InputMaybe<Scalars['String']>;
  nft_lte?: InputMaybe<Scalars['String']>;
  nft_in?: InputMaybe<Array<Scalars['String']>>;
  nft_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contains?: InputMaybe<Scalars['String']>;
  nft_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_not_contains?: InputMaybe<Scalars['String']>;
  nft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_starts_with?: InputMaybe<Scalars['String']>;
  nft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_ends_with?: InputMaybe<Scalars['String']>;
  nft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_?: InputMaybe<Nft_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UserLikeNft_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UserLikeNft_filter>>>;
};

export type UserLikeNft_orderBy =
  | 'id'
  | 'user'
  | 'user__id'
  | 'user__account_id'
  | 'user__mint_times'
  | 'user__burn_times'
  | 'user__listing_times'
  | 'user__buy_listing_times'
  | 'user__offer_times'
  | 'user__accept_offer_times'
  | 'user__transfer_times'
  | 'user__trading_times'
  | 'user__trading_volume'
  | 'user__first_interaction_time'
  | 'user__last_read_notification_time'
  | 'user__last_interaction_time'
  | 'user__like_sum'
  | 'user__watch_sum'
  | 'nft'
  | 'nft__id'
  | 'nft__contract_id'
  | 'nft__token_id'
  | 'nft__owner_id'
  | 'nft__is_burn'
  | 'nft__search_name'
  | 'nft__search_name_len'
  | 'nft__mint_time'
  | 'nft__name_len'
  | 'nft__name_level'
  | 'nft__nft_approve_id_for_market_contract'
  | 'nft__nft_approve_id_in_market_contract'
  | 'nft__is_approve_id_effective_in_market_contract'
  | 'nft__price'
  | 'nft__listing_time'
  | 'nft__last_sale_price'
  | 'nft__liked_sum'
  | 'nft__watched_sum'
  | 'nft__character_set'
  | 'nft__contain_separator'
  | 'nft__club'
  | 'nft__offer_count'
  | 'nft__top_domain'
  | 'nft__bottom_domain'
  | 'nft__suffix_domain'
  | 'nft__prefix_domain';

export type UserWatchNft = {
  id: Scalars['ID'];
  user: User;
  nft: Nft;
};

export type UserWatchNft_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  user?: InputMaybe<Scalars['String']>;
  user_not?: InputMaybe<Scalars['String']>;
  user_gt?: InputMaybe<Scalars['String']>;
  user_lt?: InputMaybe<Scalars['String']>;
  user_gte?: InputMaybe<Scalars['String']>;
  user_lte?: InputMaybe<Scalars['String']>;
  user_in?: InputMaybe<Array<Scalars['String']>>;
  user_not_in?: InputMaybe<Array<Scalars['String']>>;
  user_contains?: InputMaybe<Scalars['String']>;
  user_contains_nocase?: InputMaybe<Scalars['String']>;
  user_not_contains?: InputMaybe<Scalars['String']>;
  user_not_contains_nocase?: InputMaybe<Scalars['String']>;
  user_starts_with?: InputMaybe<Scalars['String']>;
  user_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_starts_with?: InputMaybe<Scalars['String']>;
  user_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  user_ends_with?: InputMaybe<Scalars['String']>;
  user_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_not_ends_with?: InputMaybe<Scalars['String']>;
  user_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  user_?: InputMaybe<User_filter>;
  nft?: InputMaybe<Scalars['String']>;
  nft_not?: InputMaybe<Scalars['String']>;
  nft_gt?: InputMaybe<Scalars['String']>;
  nft_lt?: InputMaybe<Scalars['String']>;
  nft_gte?: InputMaybe<Scalars['String']>;
  nft_lte?: InputMaybe<Scalars['String']>;
  nft_in?: InputMaybe<Array<Scalars['String']>>;
  nft_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contains?: InputMaybe<Scalars['String']>;
  nft_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_not_contains?: InputMaybe<Scalars['String']>;
  nft_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_starts_with?: InputMaybe<Scalars['String']>;
  nft_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_ends_with?: InputMaybe<Scalars['String']>;
  nft_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_?: InputMaybe<Nft_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<UserWatchNft_filter>>>;
  or?: InputMaybe<Array<InputMaybe<UserWatchNft_filter>>>;
};

export type UserWatchNft_orderBy =
  | 'id'
  | 'user'
  | 'user__id'
  | 'user__account_id'
  | 'user__mint_times'
  | 'user__burn_times'
  | 'user__listing_times'
  | 'user__buy_listing_times'
  | 'user__offer_times'
  | 'user__accept_offer_times'
  | 'user__transfer_times'
  | 'user__trading_times'
  | 'user__trading_volume'
  | 'user__first_interaction_time'
  | 'user__last_read_notification_time'
  | 'user__last_interaction_time'
  | 'user__like_sum'
  | 'user__watch_sum'
  | 'nft'
  | 'nft__id'
  | 'nft__contract_id'
  | 'nft__token_id'
  | 'nft__owner_id'
  | 'nft__is_burn'
  | 'nft__search_name'
  | 'nft__search_name_len'
  | 'nft__mint_time'
  | 'nft__name_len'
  | 'nft__name_level'
  | 'nft__nft_approve_id_for_market_contract'
  | 'nft__nft_approve_id_in_market_contract'
  | 'nft__is_approve_id_effective_in_market_contract'
  | 'nft__price'
  | 'nft__listing_time'
  | 'nft__last_sale_price'
  | 'nft__liked_sum'
  | 'nft__watched_sum'
  | 'nft__character_set'
  | 'nft__contain_separator'
  | 'nft__club'
  | 'nft__offer_count'
  | 'nft__top_domain'
  | 'nft__bottom_domain'
  | 'nft__suffix_domain'
  | 'nft__prefix_domain';

export type User_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  account_id?: InputMaybe<Scalars['String']>;
  account_id_not?: InputMaybe<Scalars['String']>;
  account_id_gt?: InputMaybe<Scalars['String']>;
  account_id_lt?: InputMaybe<Scalars['String']>;
  account_id_gte?: InputMaybe<Scalars['String']>;
  account_id_lte?: InputMaybe<Scalars['String']>;
  account_id_in?: InputMaybe<Array<Scalars['String']>>;
  account_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  account_id_contains?: InputMaybe<Scalars['String']>;
  account_id_contains_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_contains?: InputMaybe<Scalars['String']>;
  account_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  account_id_starts_with?: InputMaybe<Scalars['String']>;
  account_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_starts_with?: InputMaybe<Scalars['String']>;
  account_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_ends_with?: InputMaybe<Scalars['String']>;
  account_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  account_id_not_ends_with?: InputMaybe<Scalars['String']>;
  account_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  mint_times?: InputMaybe<Scalars['Int']>;
  mint_times_not?: InputMaybe<Scalars['Int']>;
  mint_times_gt?: InputMaybe<Scalars['Int']>;
  mint_times_lt?: InputMaybe<Scalars['Int']>;
  mint_times_gte?: InputMaybe<Scalars['Int']>;
  mint_times_lte?: InputMaybe<Scalars['Int']>;
  mint_times_in?: InputMaybe<Array<Scalars['Int']>>;
  mint_times_not_in?: InputMaybe<Array<Scalars['Int']>>;
  burn_times?: InputMaybe<Scalars['Int']>;
  burn_times_not?: InputMaybe<Scalars['Int']>;
  burn_times_gt?: InputMaybe<Scalars['Int']>;
  burn_times_lt?: InputMaybe<Scalars['Int']>;
  burn_times_gte?: InputMaybe<Scalars['Int']>;
  burn_times_lte?: InputMaybe<Scalars['Int']>;
  burn_times_in?: InputMaybe<Array<Scalars['Int']>>;
  burn_times_not_in?: InputMaybe<Array<Scalars['Int']>>;
  listing_times?: InputMaybe<Scalars['Int']>;
  listing_times_not?: InputMaybe<Scalars['Int']>;
  listing_times_gt?: InputMaybe<Scalars['Int']>;
  listing_times_lt?: InputMaybe<Scalars['Int']>;
  listing_times_gte?: InputMaybe<Scalars['Int']>;
  listing_times_lte?: InputMaybe<Scalars['Int']>;
  listing_times_in?: InputMaybe<Array<Scalars['Int']>>;
  listing_times_not_in?: InputMaybe<Array<Scalars['Int']>>;
  buy_listing_times?: InputMaybe<Scalars['Int']>;
  buy_listing_times_not?: InputMaybe<Scalars['Int']>;
  buy_listing_times_gt?: InputMaybe<Scalars['Int']>;
  buy_listing_times_lt?: InputMaybe<Scalars['Int']>;
  buy_listing_times_gte?: InputMaybe<Scalars['Int']>;
  buy_listing_times_lte?: InputMaybe<Scalars['Int']>;
  buy_listing_times_in?: InputMaybe<Array<Scalars['Int']>>;
  buy_listing_times_not_in?: InputMaybe<Array<Scalars['Int']>>;
  offer_times?: InputMaybe<Scalars['Int']>;
  offer_times_not?: InputMaybe<Scalars['Int']>;
  offer_times_gt?: InputMaybe<Scalars['Int']>;
  offer_times_lt?: InputMaybe<Scalars['Int']>;
  offer_times_gte?: InputMaybe<Scalars['Int']>;
  offer_times_lte?: InputMaybe<Scalars['Int']>;
  offer_times_in?: InputMaybe<Array<Scalars['Int']>>;
  offer_times_not_in?: InputMaybe<Array<Scalars['Int']>>;
  accept_offer_times?: InputMaybe<Scalars['Int']>;
  accept_offer_times_not?: InputMaybe<Scalars['Int']>;
  accept_offer_times_gt?: InputMaybe<Scalars['Int']>;
  accept_offer_times_lt?: InputMaybe<Scalars['Int']>;
  accept_offer_times_gte?: InputMaybe<Scalars['Int']>;
  accept_offer_times_lte?: InputMaybe<Scalars['Int']>;
  accept_offer_times_in?: InputMaybe<Array<Scalars['Int']>>;
  accept_offer_times_not_in?: InputMaybe<Array<Scalars['Int']>>;
  transfer_times?: InputMaybe<Scalars['Int']>;
  transfer_times_not?: InputMaybe<Scalars['Int']>;
  transfer_times_gt?: InputMaybe<Scalars['Int']>;
  transfer_times_lt?: InputMaybe<Scalars['Int']>;
  transfer_times_gte?: InputMaybe<Scalars['Int']>;
  transfer_times_lte?: InputMaybe<Scalars['Int']>;
  transfer_times_in?: InputMaybe<Array<Scalars['Int']>>;
  transfer_times_not_in?: InputMaybe<Array<Scalars['Int']>>;
  trading_times?: InputMaybe<Scalars['Int']>;
  trading_times_not?: InputMaybe<Scalars['Int']>;
  trading_times_gt?: InputMaybe<Scalars['Int']>;
  trading_times_lt?: InputMaybe<Scalars['Int']>;
  trading_times_gte?: InputMaybe<Scalars['Int']>;
  trading_times_lte?: InputMaybe<Scalars['Int']>;
  trading_times_in?: InputMaybe<Array<Scalars['Int']>>;
  trading_times_not_in?: InputMaybe<Array<Scalars['Int']>>;
  trading_volume?: InputMaybe<Scalars['BigInt']>;
  trading_volume_not?: InputMaybe<Scalars['BigInt']>;
  trading_volume_gt?: InputMaybe<Scalars['BigInt']>;
  trading_volume_lt?: InputMaybe<Scalars['BigInt']>;
  trading_volume_gte?: InputMaybe<Scalars['BigInt']>;
  trading_volume_lte?: InputMaybe<Scalars['BigInt']>;
  trading_volume_in?: InputMaybe<Array<Scalars['BigInt']>>;
  trading_volume_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  first_interaction_time?: InputMaybe<Scalars['BigInt']>;
  first_interaction_time_not?: InputMaybe<Scalars['BigInt']>;
  first_interaction_time_gt?: InputMaybe<Scalars['BigInt']>;
  first_interaction_time_lt?: InputMaybe<Scalars['BigInt']>;
  first_interaction_time_gte?: InputMaybe<Scalars['BigInt']>;
  first_interaction_time_lte?: InputMaybe<Scalars['BigInt']>;
  first_interaction_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  first_interaction_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  last_read_notification_time?: InputMaybe<Scalars['BigInt']>;
  last_read_notification_time_not?: InputMaybe<Scalars['BigInt']>;
  last_read_notification_time_gt?: InputMaybe<Scalars['BigInt']>;
  last_read_notification_time_lt?: InputMaybe<Scalars['BigInt']>;
  last_read_notification_time_gte?: InputMaybe<Scalars['BigInt']>;
  last_read_notification_time_lte?: InputMaybe<Scalars['BigInt']>;
  last_read_notification_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  last_read_notification_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  last_interaction_time?: InputMaybe<Scalars['BigInt']>;
  last_interaction_time_not?: InputMaybe<Scalars['BigInt']>;
  last_interaction_time_gt?: InputMaybe<Scalars['BigInt']>;
  last_interaction_time_lt?: InputMaybe<Scalars['BigInt']>;
  last_interaction_time_gte?: InputMaybe<Scalars['BigInt']>;
  last_interaction_time_lte?: InputMaybe<Scalars['BigInt']>;
  last_interaction_time_in?: InputMaybe<Array<Scalars['BigInt']>>;
  last_interaction_time_not_in?: InputMaybe<Array<Scalars['BigInt']>>;
  like_sum?: InputMaybe<Scalars['Int']>;
  like_sum_not?: InputMaybe<Scalars['Int']>;
  like_sum_gt?: InputMaybe<Scalars['Int']>;
  like_sum_lt?: InputMaybe<Scalars['Int']>;
  like_sum_gte?: InputMaybe<Scalars['Int']>;
  like_sum_lte?: InputMaybe<Scalars['Int']>;
  like_sum_in?: InputMaybe<Array<Scalars['Int']>>;
  like_sum_not_in?: InputMaybe<Array<Scalars['Int']>>;
  watch_sum?: InputMaybe<Scalars['Int']>;
  watch_sum_not?: InputMaybe<Scalars['Int']>;
  watch_sum_gt?: InputMaybe<Scalars['Int']>;
  watch_sum_lt?: InputMaybe<Scalars['Int']>;
  watch_sum_gte?: InputMaybe<Scalars['Int']>;
  watch_sum_lte?: InputMaybe<Scalars['Int']>;
  watch_sum_in?: InputMaybe<Array<Scalars['Int']>>;
  watch_sum_not_in?: InputMaybe<Array<Scalars['Int']>>;
  like_nfts_?: InputMaybe<UserLikeNft_filter>;
  watch_nfts_?: InputMaybe<UserWatchNft_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<User_filter>>>;
  or?: InputMaybe<Array<InputMaybe<User_filter>>>;
};

export type User_orderBy =
  | 'id'
  | 'account_id'
  | 'mint_times'
  | 'burn_times'
  | 'listing_times'
  | 'buy_listing_times'
  | 'offer_times'
  | 'accept_offer_times'
  | 'transfer_times'
  | 'trading_times'
  | 'trading_volume'
  | 'first_interaction_time'
  | 'last_read_notification_time'
  | 'last_interaction_time'
  | 'like_sum'
  | 'watch_sum'
  | 'like_nfts'
  | 'watch_nfts';

export type WatchNftAction = {
  id: Scalars['ID'];
  nft_contract_id: Scalars['String'];
  nft_token_id: Scalars['String'];
};

export type WatchNftAction_filter = {
  id?: InputMaybe<Scalars['ID']>;
  id_not?: InputMaybe<Scalars['ID']>;
  id_gt?: InputMaybe<Scalars['ID']>;
  id_lt?: InputMaybe<Scalars['ID']>;
  id_gte?: InputMaybe<Scalars['ID']>;
  id_lte?: InputMaybe<Scalars['ID']>;
  id_in?: InputMaybe<Array<Scalars['ID']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']>>;
  nft_contract_id?: InputMaybe<Scalars['String']>;
  nft_contract_id_not?: InputMaybe<Scalars['String']>;
  nft_contract_id_gt?: InputMaybe<Scalars['String']>;
  nft_contract_id_lt?: InputMaybe<Scalars['String']>;
  nft_contract_id_gte?: InputMaybe<Scalars['String']>;
  nft_contract_id_lte?: InputMaybe<Scalars['String']>;
  nft_contract_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_contract_id_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_contract_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id?: InputMaybe<Scalars['String']>;
  nft_token_id_not?: InputMaybe<Scalars['String']>;
  nft_token_id_gt?: InputMaybe<Scalars['String']>;
  nft_token_id_lt?: InputMaybe<Scalars['String']>;
  nft_token_id_gte?: InputMaybe<Scalars['String']>;
  nft_token_id_lte?: InputMaybe<Scalars['String']>;
  nft_token_id_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_not_in?: InputMaybe<Array<Scalars['String']>>;
  nft_token_id_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains?: InputMaybe<Scalars['String']>;
  nft_token_id_not_contains_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_starts_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_ends_with_nocase?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with?: InputMaybe<Scalars['String']>;
  nft_token_id_not_ends_with_nocase?: InputMaybe<Scalars['String']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<WatchNftAction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<WatchNftAction_filter>>>;
};

export type WatchNftAction_orderBy =
  | 'id'
  | 'nft_contract_id'
  | 'nft_token_id';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']>;
  /** The block number */
  number: Scalars['Int'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']>;
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AcceptOfferingAction: ResolverTypeWrapper<AcceptOfferingAction>;
  AcceptOfferingAction_filter: AcceptOfferingAction_filter;
  AcceptOfferingAction_orderBy: AcceptOfferingAction_orderBy;
  ActionType: ActionType;
  Aggregation_interval: Aggregation_interval;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  BuyListingAction: ResolverTypeWrapper<BuyListingAction>;
  BuyListingAction_filter: BuyListingAction_filter;
  BuyListingAction_orderBy: BuyListingAction_orderBy;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']>;
  CharacterSet: CharacterSet;
  ControlAccountAction: ResolverTypeWrapper<ControlAccountAction>;
  ControlAccountAction_filter: ControlAccountAction_filter;
  ControlAccountAction_orderBy: ControlAccountAction_orderBy;
  CreateListingAction: ResolverTypeWrapper<CreateListingAction>;
  CreateListingAction_filter: CreateListingAction_filter;
  CreateListingAction_orderBy: CreateListingAction_orderBy;
  CreateOfferingAction: ResolverTypeWrapper<CreateOfferingAction>;
  CreateOfferingAction_filter: CreateOfferingAction_filter;
  CreateOfferingAction_orderBy: CreateOfferingAction_orderBy;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int8: ResolverTypeWrapper<Scalars['Int8']>;
  LikeNftAction: ResolverTypeWrapper<LikeNftAction>;
  LikeNftAction_filter: LikeNftAction_filter;
  LikeNftAction_orderBy: LikeNftAction_orderBy;
  Listing: ResolverTypeWrapper<Listing>;
  ListingSoldNotification: ResolverTypeWrapper<ListingSoldNotification>;
  ListingSoldNotification_filter: ListingSoldNotification_filter;
  ListingSoldNotification_orderBy: ListingSoldNotification_orderBy;
  Listing_filter: Listing_filter;
  Listing_orderBy: Listing_orderBy;
  MarketSummary: ResolverTypeWrapper<MarketSummary>;
  MarketSummary_filter: MarketSummary_filter;
  MarketSummary_orderBy: MarketSummary_orderBy;
  Nft: ResolverTypeWrapper<Nft>;
  NftBurnAction: ResolverTypeWrapper<NftBurnAction>;
  NftBurnAction_filter: NftBurnAction_filter;
  NftBurnAction_orderBy: NftBurnAction_orderBy;
  NftClub: NftClub;
  NftLikedNotification: ResolverTypeWrapper<NftLikedNotification>;
  NftLikedNotification_filter: NftLikedNotification_filter;
  NftLikedNotification_orderBy: NftLikedNotification_orderBy;
  NftMintAction: ResolverTypeWrapper<NftMintAction>;
  NftMintAction_filter: NftMintAction_filter;
  NftMintAction_orderBy: NftMintAction_orderBy;
  NftRegisterAction: ResolverTypeWrapper<NftRegisterAction>;
  NftRegisterAction_filter: NftRegisterAction_filter;
  NftRegisterAction_orderBy: NftRegisterAction_orderBy;
  NftSummary: ResolverTypeWrapper<NftSummary>;
  NftSummary_filter: NftSummary_filter;
  NftSummary_orderBy: NftSummary_orderBy;
  NftTransferAction: ResolverTypeWrapper<NftTransferAction>;
  NftTransferAction_filter: NftTransferAction_filter;
  NftTransferAction_orderBy: NftTransferAction_orderBy;
  NftUnregisterAction: ResolverTypeWrapper<NftUnregisterAction>;
  NftUnregisterAction_filter: NftUnregisterAction_filter;
  NftUnregisterAction_orderBy: NftUnregisterAction_orderBy;
  NftWatchedNotification: ResolverTypeWrapper<NftWatchedNotification>;
  NftWatchedNotification_filter: NftWatchedNotification_filter;
  NftWatchedNotification_orderBy: NftWatchedNotification_orderBy;
  Nft_filter: Nft_filter;
  Nft_orderBy: Nft_orderBy;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationType: NotificationType;
  Notification_filter: Notification_filter;
  Notification_orderBy: Notification_orderBy;
  OfferAcceptedNotification: ResolverTypeWrapper<OfferAcceptedNotification>;
  OfferAcceptedNotification_filter: OfferAcceptedNotification_filter;
  OfferAcceptedNotification_orderBy: OfferAcceptedNotification_orderBy;
  OfferUpdateNotification: ResolverTypeWrapper<OfferUpdateNotification>;
  OfferUpdateNotification_filter: OfferUpdateNotification_filter;
  OfferUpdateNotification_orderBy: OfferUpdateNotification_orderBy;
  Offering: ResolverTypeWrapper<Offering>;
  Offering_filter: Offering_filter;
  Offering_orderBy: Offering_orderBy;
  OrderDirection: OrderDirection;
  Query: ResolverTypeWrapper<{}>;
  ReadNotificationAction: ResolverTypeWrapper<ReadNotificationAction>;
  ReadNotificationAction_filter: ReadNotificationAction_filter;
  ReadNotificationAction_orderBy: ReadNotificationAction_orderBy;
  ReceiveNftNotification: ResolverTypeWrapper<ReceiveNftNotification>;
  ReceiveNftNotification_filter: ReceiveNftNotification_filter;
  ReceiveNftNotification_orderBy: ReceiveNftNotification_orderBy;
  ReceiveOfferNotification: ResolverTypeWrapper<ReceiveOfferNotification>;
  ReceiveOfferNotification_filter: ReceiveOfferNotification_filter;
  ReceiveOfferNotification_orderBy: ReceiveOfferNotification_orderBy;
  RemoveListingAction: ResolverTypeWrapper<RemoveListingAction>;
  RemoveListingAction_filter: RemoveListingAction_filter;
  RemoveListingAction_orderBy: RemoveListingAction_orderBy;
  RemoveOfferingAction: ResolverTypeWrapper<RemoveOfferingAction>;
  RemoveOfferingAction_filter: RemoveOfferingAction_filter;
  RemoveOfferingAction_orderBy: RemoveOfferingAction_orderBy;
  String: ResolverTypeWrapper<Scalars['String']>;
  Subscription: ResolverTypeWrapper<{}>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']>;
  TokenMetadata: ResolverTypeWrapper<TokenMetadata>;
  TokenMetadata_filter: TokenMetadata_filter;
  TokenMetadata_orderBy: TokenMetadata_orderBy;
  Trading: ResolverTypeWrapper<Trading>;
  TradingType: TradingType;
  Trading_filter: Trading_filter;
  Trading_orderBy: Trading_orderBy;
  UnlikeNftAction: ResolverTypeWrapper<UnlikeNftAction>;
  UnlikeNftAction_filter: UnlikeNftAction_filter;
  UnlikeNftAction_orderBy: UnlikeNftAction_orderBy;
  UnwatchNftAction: ResolverTypeWrapper<UnwatchNftAction>;
  UnwatchNftAction_filter: UnwatchNftAction_filter;
  UnwatchNftAction_orderBy: UnwatchNftAction_orderBy;
  UpdateListingAction: ResolverTypeWrapper<UpdateListingAction>;
  UpdateListingAction_filter: UpdateListingAction_filter;
  UpdateListingAction_orderBy: UpdateListingAction_orderBy;
  UpdateOfferingAction: ResolverTypeWrapper<UpdateOfferingAction>;
  UpdateOfferingAction_filter: UpdateOfferingAction_filter;
  UpdateOfferingAction_orderBy: UpdateOfferingAction_orderBy;
  User: ResolverTypeWrapper<User>;
  UserAction: ResolverTypeWrapper<UserAction>;
  UserAction_filter: UserAction_filter;
  UserAction_orderBy: UserAction_orderBy;
  UserLikeNft: ResolverTypeWrapper<UserLikeNft>;
  UserLikeNft_filter: UserLikeNft_filter;
  UserLikeNft_orderBy: UserLikeNft_orderBy;
  UserWatchNft: ResolverTypeWrapper<UserWatchNft>;
  UserWatchNft_filter: UserWatchNft_filter;
  UserWatchNft_orderBy: UserWatchNft_orderBy;
  User_filter: User_filter;
  User_orderBy: User_orderBy;
  WatchNftAction: ResolverTypeWrapper<WatchNftAction>;
  WatchNftAction_filter: WatchNftAction_filter;
  WatchNftAction_orderBy: WatchNftAction_orderBy;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AcceptOfferingAction: AcceptOfferingAction;
  AcceptOfferingAction_filter: AcceptOfferingAction_filter;
  BigDecimal: Scalars['BigDecimal'];
  BigInt: Scalars['BigInt'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: Scalars['Boolean'];
  BuyListingAction: BuyListingAction;
  BuyListingAction_filter: BuyListingAction_filter;
  Bytes: Scalars['Bytes'];
  ControlAccountAction: ControlAccountAction;
  ControlAccountAction_filter: ControlAccountAction_filter;
  CreateListingAction: CreateListingAction;
  CreateListingAction_filter: CreateListingAction_filter;
  CreateOfferingAction: CreateOfferingAction;
  CreateOfferingAction_filter: CreateOfferingAction_filter;
  Float: Scalars['Float'];
  ID: Scalars['ID'];
  Int: Scalars['Int'];
  Int8: Scalars['Int8'];
  LikeNftAction: LikeNftAction;
  LikeNftAction_filter: LikeNftAction_filter;
  Listing: Listing;
  ListingSoldNotification: ListingSoldNotification;
  ListingSoldNotification_filter: ListingSoldNotification_filter;
  Listing_filter: Listing_filter;
  MarketSummary: MarketSummary;
  MarketSummary_filter: MarketSummary_filter;
  Nft: Nft;
  NftBurnAction: NftBurnAction;
  NftBurnAction_filter: NftBurnAction_filter;
  NftLikedNotification: NftLikedNotification;
  NftLikedNotification_filter: NftLikedNotification_filter;
  NftMintAction: NftMintAction;
  NftMintAction_filter: NftMintAction_filter;
  NftRegisterAction: NftRegisterAction;
  NftRegisterAction_filter: NftRegisterAction_filter;
  NftSummary: NftSummary;
  NftSummary_filter: NftSummary_filter;
  NftTransferAction: NftTransferAction;
  NftTransferAction_filter: NftTransferAction_filter;
  NftUnregisterAction: NftUnregisterAction;
  NftUnregisterAction_filter: NftUnregisterAction_filter;
  NftWatchedNotification: NftWatchedNotification;
  NftWatchedNotification_filter: NftWatchedNotification_filter;
  Nft_filter: Nft_filter;
  Notification: Notification;
  Notification_filter: Notification_filter;
  OfferAcceptedNotification: OfferAcceptedNotification;
  OfferAcceptedNotification_filter: OfferAcceptedNotification_filter;
  OfferUpdateNotification: OfferUpdateNotification;
  OfferUpdateNotification_filter: OfferUpdateNotification_filter;
  Offering: Offering;
  Offering_filter: Offering_filter;
  Query: {};
  ReadNotificationAction: ReadNotificationAction;
  ReadNotificationAction_filter: ReadNotificationAction_filter;
  ReceiveNftNotification: ReceiveNftNotification;
  ReceiveNftNotification_filter: ReceiveNftNotification_filter;
  ReceiveOfferNotification: ReceiveOfferNotification;
  ReceiveOfferNotification_filter: ReceiveOfferNotification_filter;
  RemoveListingAction: RemoveListingAction;
  RemoveListingAction_filter: RemoveListingAction_filter;
  RemoveOfferingAction: RemoveOfferingAction;
  RemoveOfferingAction_filter: RemoveOfferingAction_filter;
  String: Scalars['String'];
  Subscription: {};
  Timestamp: Scalars['Timestamp'];
  TokenMetadata: TokenMetadata;
  TokenMetadata_filter: TokenMetadata_filter;
  Trading: Trading;
  Trading_filter: Trading_filter;
  UnlikeNftAction: UnlikeNftAction;
  UnlikeNftAction_filter: UnlikeNftAction_filter;
  UnwatchNftAction: UnwatchNftAction;
  UnwatchNftAction_filter: UnwatchNftAction_filter;
  UpdateListingAction: UpdateListingAction;
  UpdateListingAction_filter: UpdateListingAction_filter;
  UpdateOfferingAction: UpdateOfferingAction;
  UpdateOfferingAction_filter: UpdateOfferingAction_filter;
  User: User;
  UserAction: UserAction;
  UserAction_filter: UserAction_filter;
  UserLikeNft: UserLikeNft;
  UserLikeNft_filter: UserLikeNft_filter;
  UserWatchNft: UserWatchNft;
  UserWatchNft_filter: UserWatchNft_filter;
  User_filter: User_filter;
  WatchNftAction: WatchNftAction;
  WatchNftAction_filter: WatchNftAction_filter;
  _Block_: _Block_;
  _Meta_: _Meta_;
}>;

export type entityDirectiveArgs = { };

export type entityDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = entityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type subgraphIdDirectiveArgs = {
  id: Scalars['String'];
};

export type subgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = subgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type derivedFromDirectiveArgs = {
  field: Scalars['String'];
};

export type derivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = derivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AcceptOfferingActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['AcceptOfferingAction'] = ResolversParentTypes['AcceptOfferingAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  buyer_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seller_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prepaid_balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  payment_balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  payout_balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trading_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trading?: Resolver<ResolversTypes['Trading'], ParentType, ContextType>;
  offering_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offering?: Resolver<ResolversTypes['Offering'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type BuyListingActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['BuyListingAction'] = ResolversParentTypes['BuyListingAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  buyer_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  seller_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prepaid_balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  payment_balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  payout_balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trading_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trading?: Resolver<ResolversTypes['Trading'], ParentType, ContextType>;
  listing_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  listing?: Resolver<ResolversTypes['Listing'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type ControlAccountActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ControlAccountAction'] = ResolversParentTypes['ControlAccountAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  account_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  public_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateListingActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['CreateListingAction'] = ResolversParentTypes['CreateListingAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  listing_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  listing?: Resolver<ResolversTypes['Listing'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CreateOfferingActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['CreateOfferingAction'] = ResolversParentTypes['CreateOfferingAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  offer_creator?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  offering_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offering?: Resolver<ResolversTypes['Offering'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Int8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int8'], any> {
  name: 'Int8';
}

export type LikeNftActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['LikeNftAction'] = ResolversParentTypes['LikeNftAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ListingResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Listing'] = ResolversParentTypes['Listing']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  seller_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  create_time?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  update_time?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  is_remove?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ListingSoldNotificationResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ListingSoldNotification'] = ResolversParentTypes['ListingSoldNotification']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  buy_listing_action?: Resolver<ResolversTypes['BuyListingAction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MarketSummaryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['MarketSummary'] = ResolversParentTypes['MarketSummary']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  volume?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  trading_sum?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  listing_sum?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  nft_minted_sum?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Nft'] = ResolversParentTypes['Nft']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  owner_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  is_burn?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  metadata?: Resolver<Maybe<ResolversTypes['TokenMetadata']>, ParentType, ContextType>;
  search_name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  search_name_len?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mint_time?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  name_len?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name_level?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nft_approve_id_for_market_contract?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nft_approve_id_in_market_contract?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  is_approve_id_effective_in_market_contract?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  listing?: Resolver<Maybe<ResolversTypes['Listing']>, ParentType, ContextType>;
  listing_time?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  last_sale_price?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  liked_sum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  watched_sum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  like_by_users?: Resolver<Array<ResolversTypes['UserLikeNft']>, ParentType, ContextType, RequireFields<Nftlike_by_usersArgs, 'skip' | 'first'>>;
  watch_by_users?: Resolver<Array<ResolversTypes['UserWatchNft']>, ParentType, ContextType, RequireFields<Nftwatch_by_usersArgs, 'skip' | 'first'>>;
  character_set?: Resolver<ResolversTypes['CharacterSet'], ParentType, ContextType>;
  contain_separator?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  club?: Resolver<Maybe<ResolversTypes['NftClub']>, ParentType, ContextType>;
  offer_count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offers?: Resolver<Maybe<Array<ResolversTypes['Offering']>>, ParentType, ContextType, RequireFields<NftoffersArgs, 'skip' | 'first'>>;
  top_domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  bottom_domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  suffix_domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prefix_domain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftBurnActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftBurnAction'] = ResolversParentTypes['NftBurnAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authorized_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  memo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftLikedNotificationResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftLikedNotification'] = ResolversParentTypes['NftLikedNotification']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  account_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft?: Resolver<ResolversTypes['Nft'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftMintActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftMintAction'] = ResolversParentTypes['NftMintAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  owner_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftRegisterActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftRegisterAction'] = ResolversParentTypes['NftRegisterAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  registrant_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  minter_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftSummaryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftSummary'] = ResolversParentTypes['NftSummary']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  mint_count?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  burn_count?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftTransferActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftTransferAction'] = ResolversParentTypes['NftTransferAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  old_owner_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  new_owner_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  authorized_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  memo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftUnregisterActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftUnregisterAction'] = ResolversParentTypes['NftUnregisterAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  registrant_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  public_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  is_force?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  is_control_success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftWatchedNotificationResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['NftWatchedNotification'] = ResolversParentTypes['NftWatchedNotification']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  account_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft?: Resolver<ResolversTypes['Nft'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NotificationResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  receiver?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receipt_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp_plus_log_index?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  notification_type?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  nft_liked_notification?: Resolver<Maybe<ResolversTypes['NftLikedNotification']>, ParentType, ContextType>;
  nft_watched_notification?: Resolver<Maybe<ResolversTypes['NftWatchedNotification']>, ParentType, ContextType>;
  offer_accepted_notification?: Resolver<Maybe<ResolversTypes['OfferAcceptedNotification']>, ParentType, ContextType>;
  listing_sold_notification?: Resolver<Maybe<ResolversTypes['ListingSoldNotification']>, ParentType, ContextType>;
  receive_offer_notification?: Resolver<Maybe<ResolversTypes['ReceiveOfferNotification']>, ParentType, ContextType>;
  offer_update_notification?: Resolver<Maybe<ResolversTypes['OfferUpdateNotification']>, ParentType, ContextType>;
  receive_nft_notification?: Resolver<Maybe<ResolversTypes['ReceiveNftNotification']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OfferAcceptedNotificationResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['OfferAcceptedNotification'] = ResolversParentTypes['OfferAcceptedNotification']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  accept_offering_action?: Resolver<ResolversTypes['AcceptOfferingAction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OfferUpdateNotificationResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['OfferUpdateNotification'] = ResolversParentTypes['OfferUpdateNotification']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  update_offering_action?: Resolver<ResolversTypes['UpdateOfferingAction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type OfferingResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Offering'] = ResolversParentTypes['Offering']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  buyer_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  create_time?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  update_time?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  is_simple_offering?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  is_remove?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  expire_time?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  nft_id?: Resolver<ResolversTypes['Nft'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  listing?: Resolver<Maybe<ResolversTypes['Listing']>, ParentType, ContextType, RequireFields<QuerylistingArgs, 'id' | 'subgraphError'>>;
  listings?: Resolver<Array<ResolversTypes['Listing']>, ParentType, ContextType, RequireFields<QuerylistingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  offering?: Resolver<Maybe<ResolversTypes['Offering']>, ParentType, ContextType, RequireFields<QueryofferingArgs, 'id' | 'subgraphError'>>;
  offerings?: Resolver<Array<ResolversTypes['Offering']>, ParentType, ContextType, RequireFields<QueryofferingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  trading?: Resolver<Maybe<ResolversTypes['Trading']>, ParentType, ContextType, RequireFields<QuerytradingArgs, 'id' | 'subgraphError'>>;
  tradings?: Resolver<Array<ResolversTypes['Trading']>, ParentType, ContextType, RequireFields<QuerytradingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  userAction?: Resolver<Maybe<ResolversTypes['UserAction']>, ParentType, ContextType, RequireFields<QueryuserActionArgs, 'id' | 'subgraphError'>>;
  userActions?: Resolver<Array<ResolversTypes['UserAction']>, ParentType, ContextType, RequireFields<QueryuserActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  createListingAction?: Resolver<Maybe<ResolversTypes['CreateListingAction']>, ParentType, ContextType, RequireFields<QuerycreateListingActionArgs, 'id' | 'subgraphError'>>;
  createListingActions?: Resolver<Array<ResolversTypes['CreateListingAction']>, ParentType, ContextType, RequireFields<QuerycreateListingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  updateListingAction?: Resolver<Maybe<ResolversTypes['UpdateListingAction']>, ParentType, ContextType, RequireFields<QueryupdateListingActionArgs, 'id' | 'subgraphError'>>;
  updateListingActions?: Resolver<Array<ResolversTypes['UpdateListingAction']>, ParentType, ContextType, RequireFields<QueryupdateListingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  removeListingAction?: Resolver<Maybe<ResolversTypes['RemoveListingAction']>, ParentType, ContextType, RequireFields<QueryremoveListingActionArgs, 'id' | 'subgraphError'>>;
  removeListingActions?: Resolver<Array<ResolversTypes['RemoveListingAction']>, ParentType, ContextType, RequireFields<QueryremoveListingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  createOfferingAction?: Resolver<Maybe<ResolversTypes['CreateOfferingAction']>, ParentType, ContextType, RequireFields<QuerycreateOfferingActionArgs, 'id' | 'subgraphError'>>;
  createOfferingActions?: Resolver<Array<ResolversTypes['CreateOfferingAction']>, ParentType, ContextType, RequireFields<QuerycreateOfferingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  updateOfferingAction?: Resolver<Maybe<ResolversTypes['UpdateOfferingAction']>, ParentType, ContextType, RequireFields<QueryupdateOfferingActionArgs, 'id' | 'subgraphError'>>;
  updateOfferingActions?: Resolver<Array<ResolversTypes['UpdateOfferingAction']>, ParentType, ContextType, RequireFields<QueryupdateOfferingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  removeOfferingAction?: Resolver<Maybe<ResolversTypes['RemoveOfferingAction']>, ParentType, ContextType, RequireFields<QueryremoveOfferingActionArgs, 'id' | 'subgraphError'>>;
  removeOfferingActions?: Resolver<Array<ResolversTypes['RemoveOfferingAction']>, ParentType, ContextType, RequireFields<QueryremoveOfferingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  buyListingAction?: Resolver<Maybe<ResolversTypes['BuyListingAction']>, ParentType, ContextType, RequireFields<QuerybuyListingActionArgs, 'id' | 'subgraphError'>>;
  buyListingActions?: Resolver<Array<ResolversTypes['BuyListingAction']>, ParentType, ContextType, RequireFields<QuerybuyListingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  acceptOfferingAction?: Resolver<Maybe<ResolversTypes['AcceptOfferingAction']>, ParentType, ContextType, RequireFields<QueryacceptOfferingActionArgs, 'id' | 'subgraphError'>>;
  acceptOfferingActions?: Resolver<Array<ResolversTypes['AcceptOfferingAction']>, ParentType, ContextType, RequireFields<QueryacceptOfferingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftMintAction?: Resolver<Maybe<ResolversTypes['NftMintAction']>, ParentType, ContextType, RequireFields<QuerynftMintActionArgs, 'id' | 'subgraphError'>>;
  nftMintActions?: Resolver<Array<ResolversTypes['NftMintAction']>, ParentType, ContextType, RequireFields<QuerynftMintActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftTransferAction?: Resolver<Maybe<ResolversTypes['NftTransferAction']>, ParentType, ContextType, RequireFields<QuerynftTransferActionArgs, 'id' | 'subgraphError'>>;
  nftTransferActions?: Resolver<Array<ResolversTypes['NftTransferAction']>, ParentType, ContextType, RequireFields<QuerynftTransferActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftBurnAction?: Resolver<Maybe<ResolversTypes['NftBurnAction']>, ParentType, ContextType, RequireFields<QuerynftBurnActionArgs, 'id' | 'subgraphError'>>;
  nftBurnActions?: Resolver<Array<ResolversTypes['NftBurnAction']>, ParentType, ContextType, RequireFields<QuerynftBurnActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftRegisterAction?: Resolver<Maybe<ResolversTypes['NftRegisterAction']>, ParentType, ContextType, RequireFields<QuerynftRegisterActionArgs, 'id' | 'subgraphError'>>;
  nftRegisterActions?: Resolver<Array<ResolversTypes['NftRegisterAction']>, ParentType, ContextType, RequireFields<QuerynftRegisterActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftUnregisterAction?: Resolver<Maybe<ResolversTypes['NftUnregisterAction']>, ParentType, ContextType, RequireFields<QuerynftUnregisterActionArgs, 'id' | 'subgraphError'>>;
  nftUnregisterActions?: Resolver<Array<ResolversTypes['NftUnregisterAction']>, ParentType, ContextType, RequireFields<QuerynftUnregisterActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  controlAccountAction?: Resolver<Maybe<ResolversTypes['ControlAccountAction']>, ParentType, ContextType, RequireFields<QuerycontrolAccountActionArgs, 'id' | 'subgraphError'>>;
  controlAccountActions?: Resolver<Array<ResolversTypes['ControlAccountAction']>, ParentType, ContextType, RequireFields<QuerycontrolAccountActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  likeNftAction?: Resolver<Maybe<ResolversTypes['LikeNftAction']>, ParentType, ContextType, RequireFields<QuerylikeNftActionArgs, 'id' | 'subgraphError'>>;
  likeNftActions?: Resolver<Array<ResolversTypes['LikeNftAction']>, ParentType, ContextType, RequireFields<QuerylikeNftActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  unlikeNftAction?: Resolver<Maybe<ResolversTypes['UnlikeNftAction']>, ParentType, ContextType, RequireFields<QueryunlikeNftActionArgs, 'id' | 'subgraphError'>>;
  unlikeNftActions?: Resolver<Array<ResolversTypes['UnlikeNftAction']>, ParentType, ContextType, RequireFields<QueryunlikeNftActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  watchNftAction?: Resolver<Maybe<ResolversTypes['WatchNftAction']>, ParentType, ContextType, RequireFields<QuerywatchNftActionArgs, 'id' | 'subgraphError'>>;
  watchNftActions?: Resolver<Array<ResolversTypes['WatchNftAction']>, ParentType, ContextType, RequireFields<QuerywatchNftActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  unwatchNftAction?: Resolver<Maybe<ResolversTypes['UnwatchNftAction']>, ParentType, ContextType, RequireFields<QueryunwatchNftActionArgs, 'id' | 'subgraphError'>>;
  unwatchNftActions?: Resolver<Array<ResolversTypes['UnwatchNftAction']>, ParentType, ContextType, RequireFields<QueryunwatchNftActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  readNotificationAction?: Resolver<Maybe<ResolversTypes['ReadNotificationAction']>, ParentType, ContextType, RequireFields<QueryreadNotificationActionArgs, 'id' | 'subgraphError'>>;
  readNotificationActions?: Resolver<Array<ResolversTypes['ReadNotificationAction']>, ParentType, ContextType, RequireFields<QueryreadNotificationActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketSummary?: Resolver<Maybe<ResolversTypes['MarketSummary']>, ParentType, ContextType, RequireFields<QuerymarketSummaryArgs, 'id' | 'subgraphError'>>;
  marketSummaries?: Resolver<Array<ResolversTypes['MarketSummary']>, ParentType, ContextType, RequireFields<QuerymarketSummariesArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftSummary?: Resolver<Maybe<ResolversTypes['NftSummary']>, ParentType, ContextType, RequireFields<QuerynftSummaryArgs, 'id' | 'subgraphError'>>;
  nftSummaries?: Resolver<Array<ResolversTypes['NftSummary']>, ParentType, ContextType, RequireFields<QuerynftSummariesArgs, 'skip' | 'first' | 'subgraphError'>>;
  nft?: Resolver<Maybe<ResolversTypes['Nft']>, ParentType, ContextType, RequireFields<QuerynftArgs, 'id' | 'subgraphError'>>;
  nfts?: Resolver<Array<ResolversTypes['Nft']>, ParentType, ContextType, RequireFields<QuerynftsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenMetadata?: Resolver<Maybe<ResolversTypes['TokenMetadata']>, ParentType, ContextType, RequireFields<QuerytokenMetadataArgs, 'id' | 'subgraphError'>>;
  tokenMetadata_collection?: Resolver<Array<ResolversTypes['TokenMetadata']>, ParentType, ContextType, RequireFields<QuerytokenMetadata_collectionArgs, 'skip' | 'first' | 'subgraphError'>>;
  userLikeNft?: Resolver<Maybe<ResolversTypes['UserLikeNft']>, ParentType, ContextType, RequireFields<QueryuserLikeNftArgs, 'id' | 'subgraphError'>>;
  userLikeNfts?: Resolver<Array<ResolversTypes['UserLikeNft']>, ParentType, ContextType, RequireFields<QueryuserLikeNftsArgs, 'skip' | 'first' | 'subgraphError'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryuserArgs, 'id' | 'subgraphError'>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  userWatchNft?: Resolver<Maybe<ResolversTypes['UserWatchNft']>, ParentType, ContextType, RequireFields<QueryuserWatchNftArgs, 'id' | 'subgraphError'>>;
  userWatchNfts?: Resolver<Array<ResolversTypes['UserWatchNft']>, ParentType, ContextType, RequireFields<QueryuserWatchNftsArgs, 'skip' | 'first' | 'subgraphError'>>;
  notification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<QuerynotificationArgs, 'id' | 'subgraphError'>>;
  notifications?: Resolver<Array<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<QuerynotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftLikedNotification?: Resolver<Maybe<ResolversTypes['NftLikedNotification']>, ParentType, ContextType, RequireFields<QuerynftLikedNotificationArgs, 'id' | 'subgraphError'>>;
  nftLikedNotifications?: Resolver<Array<ResolversTypes['NftLikedNotification']>, ParentType, ContextType, RequireFields<QuerynftLikedNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftWatchedNotification?: Resolver<Maybe<ResolversTypes['NftWatchedNotification']>, ParentType, ContextType, RequireFields<QuerynftWatchedNotificationArgs, 'id' | 'subgraphError'>>;
  nftWatchedNotifications?: Resolver<Array<ResolversTypes['NftWatchedNotification']>, ParentType, ContextType, RequireFields<QuerynftWatchedNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  offerAcceptedNotification?: Resolver<Maybe<ResolversTypes['OfferAcceptedNotification']>, ParentType, ContextType, RequireFields<QueryofferAcceptedNotificationArgs, 'id' | 'subgraphError'>>;
  offerAcceptedNotifications?: Resolver<Array<ResolversTypes['OfferAcceptedNotification']>, ParentType, ContextType, RequireFields<QueryofferAcceptedNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  listingSoldNotification?: Resolver<Maybe<ResolversTypes['ListingSoldNotification']>, ParentType, ContextType, RequireFields<QuerylistingSoldNotificationArgs, 'id' | 'subgraphError'>>;
  listingSoldNotifications?: Resolver<Array<ResolversTypes['ListingSoldNotification']>, ParentType, ContextType, RequireFields<QuerylistingSoldNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  receiveOfferNotification?: Resolver<Maybe<ResolversTypes['ReceiveOfferNotification']>, ParentType, ContextType, RequireFields<QueryreceiveOfferNotificationArgs, 'id' | 'subgraphError'>>;
  receiveOfferNotifications?: Resolver<Array<ResolversTypes['ReceiveOfferNotification']>, ParentType, ContextType, RequireFields<QueryreceiveOfferNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  offerUpdateNotification?: Resolver<Maybe<ResolversTypes['OfferUpdateNotification']>, ParentType, ContextType, RequireFields<QueryofferUpdateNotificationArgs, 'id' | 'subgraphError'>>;
  offerUpdateNotifications?: Resolver<Array<ResolversTypes['OfferUpdateNotification']>, ParentType, ContextType, RequireFields<QueryofferUpdateNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  receiveNftNotification?: Resolver<Maybe<ResolversTypes['ReceiveNftNotification']>, ParentType, ContextType, RequireFields<QueryreceiveNftNotificationArgs, 'id' | 'subgraphError'>>;
  receiveNftNotifications?: Resolver<Array<ResolversTypes['ReceiveNftNotification']>, ParentType, ContextType, RequireFields<QueryreceiveNftNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftSearch?: Resolver<Array<ResolversTypes['Nft']>, ParentType, ContextType, RequireFields<QuerynftSearchArgs, 'text' | 'first' | 'skip' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>;
}>;

export type ReadNotificationActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ReadNotificationAction'] = ResolversParentTypes['ReadNotificationAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReceiveNftNotificationResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ReceiveNftNotification'] = ResolversParentTypes['ReceiveNftNotification']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nft_transfer_action?: Resolver<ResolversTypes['NftTransferAction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReceiveOfferNotificationResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ReceiveOfferNotification'] = ResolversParentTypes['ReceiveOfferNotification']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  create_offering_action?: Resolver<ResolversTypes['CreateOfferingAction'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveListingActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['RemoveListingAction'] = ResolversParentTypes['RemoveListingAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  listing_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  listing?: Resolver<ResolversTypes['Listing'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RemoveOfferingActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['RemoveOfferingAction'] = ResolversParentTypes['RemoveOfferingAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  offer_creator?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  offering_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offering?: Resolver<ResolversTypes['Offering'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  listing?: SubscriptionResolver<Maybe<ResolversTypes['Listing']>, "listing", ParentType, ContextType, RequireFields<SubscriptionlistingArgs, 'id' | 'subgraphError'>>;
  listings?: SubscriptionResolver<Array<ResolversTypes['Listing']>, "listings", ParentType, ContextType, RequireFields<SubscriptionlistingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  offering?: SubscriptionResolver<Maybe<ResolversTypes['Offering']>, "offering", ParentType, ContextType, RequireFields<SubscriptionofferingArgs, 'id' | 'subgraphError'>>;
  offerings?: SubscriptionResolver<Array<ResolversTypes['Offering']>, "offerings", ParentType, ContextType, RequireFields<SubscriptionofferingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  trading?: SubscriptionResolver<Maybe<ResolversTypes['Trading']>, "trading", ParentType, ContextType, RequireFields<SubscriptiontradingArgs, 'id' | 'subgraphError'>>;
  tradings?: SubscriptionResolver<Array<ResolversTypes['Trading']>, "tradings", ParentType, ContextType, RequireFields<SubscriptiontradingsArgs, 'skip' | 'first' | 'subgraphError'>>;
  userAction?: SubscriptionResolver<Maybe<ResolversTypes['UserAction']>, "userAction", ParentType, ContextType, RequireFields<SubscriptionuserActionArgs, 'id' | 'subgraphError'>>;
  userActions?: SubscriptionResolver<Array<ResolversTypes['UserAction']>, "userActions", ParentType, ContextType, RequireFields<SubscriptionuserActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  createListingAction?: SubscriptionResolver<Maybe<ResolversTypes['CreateListingAction']>, "createListingAction", ParentType, ContextType, RequireFields<SubscriptioncreateListingActionArgs, 'id' | 'subgraphError'>>;
  createListingActions?: SubscriptionResolver<Array<ResolversTypes['CreateListingAction']>, "createListingActions", ParentType, ContextType, RequireFields<SubscriptioncreateListingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  updateListingAction?: SubscriptionResolver<Maybe<ResolversTypes['UpdateListingAction']>, "updateListingAction", ParentType, ContextType, RequireFields<SubscriptionupdateListingActionArgs, 'id' | 'subgraphError'>>;
  updateListingActions?: SubscriptionResolver<Array<ResolversTypes['UpdateListingAction']>, "updateListingActions", ParentType, ContextType, RequireFields<SubscriptionupdateListingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  removeListingAction?: SubscriptionResolver<Maybe<ResolversTypes['RemoveListingAction']>, "removeListingAction", ParentType, ContextType, RequireFields<SubscriptionremoveListingActionArgs, 'id' | 'subgraphError'>>;
  removeListingActions?: SubscriptionResolver<Array<ResolversTypes['RemoveListingAction']>, "removeListingActions", ParentType, ContextType, RequireFields<SubscriptionremoveListingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  createOfferingAction?: SubscriptionResolver<Maybe<ResolversTypes['CreateOfferingAction']>, "createOfferingAction", ParentType, ContextType, RequireFields<SubscriptioncreateOfferingActionArgs, 'id' | 'subgraphError'>>;
  createOfferingActions?: SubscriptionResolver<Array<ResolversTypes['CreateOfferingAction']>, "createOfferingActions", ParentType, ContextType, RequireFields<SubscriptioncreateOfferingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  updateOfferingAction?: SubscriptionResolver<Maybe<ResolversTypes['UpdateOfferingAction']>, "updateOfferingAction", ParentType, ContextType, RequireFields<SubscriptionupdateOfferingActionArgs, 'id' | 'subgraphError'>>;
  updateOfferingActions?: SubscriptionResolver<Array<ResolversTypes['UpdateOfferingAction']>, "updateOfferingActions", ParentType, ContextType, RequireFields<SubscriptionupdateOfferingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  removeOfferingAction?: SubscriptionResolver<Maybe<ResolversTypes['RemoveOfferingAction']>, "removeOfferingAction", ParentType, ContextType, RequireFields<SubscriptionremoveOfferingActionArgs, 'id' | 'subgraphError'>>;
  removeOfferingActions?: SubscriptionResolver<Array<ResolversTypes['RemoveOfferingAction']>, "removeOfferingActions", ParentType, ContextType, RequireFields<SubscriptionremoveOfferingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  buyListingAction?: SubscriptionResolver<Maybe<ResolversTypes['BuyListingAction']>, "buyListingAction", ParentType, ContextType, RequireFields<SubscriptionbuyListingActionArgs, 'id' | 'subgraphError'>>;
  buyListingActions?: SubscriptionResolver<Array<ResolversTypes['BuyListingAction']>, "buyListingActions", ParentType, ContextType, RequireFields<SubscriptionbuyListingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  acceptOfferingAction?: SubscriptionResolver<Maybe<ResolversTypes['AcceptOfferingAction']>, "acceptOfferingAction", ParentType, ContextType, RequireFields<SubscriptionacceptOfferingActionArgs, 'id' | 'subgraphError'>>;
  acceptOfferingActions?: SubscriptionResolver<Array<ResolversTypes['AcceptOfferingAction']>, "acceptOfferingActions", ParentType, ContextType, RequireFields<SubscriptionacceptOfferingActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftMintAction?: SubscriptionResolver<Maybe<ResolversTypes['NftMintAction']>, "nftMintAction", ParentType, ContextType, RequireFields<SubscriptionnftMintActionArgs, 'id' | 'subgraphError'>>;
  nftMintActions?: SubscriptionResolver<Array<ResolversTypes['NftMintAction']>, "nftMintActions", ParentType, ContextType, RequireFields<SubscriptionnftMintActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftTransferAction?: SubscriptionResolver<Maybe<ResolversTypes['NftTransferAction']>, "nftTransferAction", ParentType, ContextType, RequireFields<SubscriptionnftTransferActionArgs, 'id' | 'subgraphError'>>;
  nftTransferActions?: SubscriptionResolver<Array<ResolversTypes['NftTransferAction']>, "nftTransferActions", ParentType, ContextType, RequireFields<SubscriptionnftTransferActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftBurnAction?: SubscriptionResolver<Maybe<ResolversTypes['NftBurnAction']>, "nftBurnAction", ParentType, ContextType, RequireFields<SubscriptionnftBurnActionArgs, 'id' | 'subgraphError'>>;
  nftBurnActions?: SubscriptionResolver<Array<ResolversTypes['NftBurnAction']>, "nftBurnActions", ParentType, ContextType, RequireFields<SubscriptionnftBurnActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftRegisterAction?: SubscriptionResolver<Maybe<ResolversTypes['NftRegisterAction']>, "nftRegisterAction", ParentType, ContextType, RequireFields<SubscriptionnftRegisterActionArgs, 'id' | 'subgraphError'>>;
  nftRegisterActions?: SubscriptionResolver<Array<ResolversTypes['NftRegisterAction']>, "nftRegisterActions", ParentType, ContextType, RequireFields<SubscriptionnftRegisterActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftUnregisterAction?: SubscriptionResolver<Maybe<ResolversTypes['NftUnregisterAction']>, "nftUnregisterAction", ParentType, ContextType, RequireFields<SubscriptionnftUnregisterActionArgs, 'id' | 'subgraphError'>>;
  nftUnregisterActions?: SubscriptionResolver<Array<ResolversTypes['NftUnregisterAction']>, "nftUnregisterActions", ParentType, ContextType, RequireFields<SubscriptionnftUnregisterActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  controlAccountAction?: SubscriptionResolver<Maybe<ResolversTypes['ControlAccountAction']>, "controlAccountAction", ParentType, ContextType, RequireFields<SubscriptioncontrolAccountActionArgs, 'id' | 'subgraphError'>>;
  controlAccountActions?: SubscriptionResolver<Array<ResolversTypes['ControlAccountAction']>, "controlAccountActions", ParentType, ContextType, RequireFields<SubscriptioncontrolAccountActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  likeNftAction?: SubscriptionResolver<Maybe<ResolversTypes['LikeNftAction']>, "likeNftAction", ParentType, ContextType, RequireFields<SubscriptionlikeNftActionArgs, 'id' | 'subgraphError'>>;
  likeNftActions?: SubscriptionResolver<Array<ResolversTypes['LikeNftAction']>, "likeNftActions", ParentType, ContextType, RequireFields<SubscriptionlikeNftActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  unlikeNftAction?: SubscriptionResolver<Maybe<ResolversTypes['UnlikeNftAction']>, "unlikeNftAction", ParentType, ContextType, RequireFields<SubscriptionunlikeNftActionArgs, 'id' | 'subgraphError'>>;
  unlikeNftActions?: SubscriptionResolver<Array<ResolversTypes['UnlikeNftAction']>, "unlikeNftActions", ParentType, ContextType, RequireFields<SubscriptionunlikeNftActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  watchNftAction?: SubscriptionResolver<Maybe<ResolversTypes['WatchNftAction']>, "watchNftAction", ParentType, ContextType, RequireFields<SubscriptionwatchNftActionArgs, 'id' | 'subgraphError'>>;
  watchNftActions?: SubscriptionResolver<Array<ResolversTypes['WatchNftAction']>, "watchNftActions", ParentType, ContextType, RequireFields<SubscriptionwatchNftActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  unwatchNftAction?: SubscriptionResolver<Maybe<ResolversTypes['UnwatchNftAction']>, "unwatchNftAction", ParentType, ContextType, RequireFields<SubscriptionunwatchNftActionArgs, 'id' | 'subgraphError'>>;
  unwatchNftActions?: SubscriptionResolver<Array<ResolversTypes['UnwatchNftAction']>, "unwatchNftActions", ParentType, ContextType, RequireFields<SubscriptionunwatchNftActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  readNotificationAction?: SubscriptionResolver<Maybe<ResolversTypes['ReadNotificationAction']>, "readNotificationAction", ParentType, ContextType, RequireFields<SubscriptionreadNotificationActionArgs, 'id' | 'subgraphError'>>;
  readNotificationActions?: SubscriptionResolver<Array<ResolversTypes['ReadNotificationAction']>, "readNotificationActions", ParentType, ContextType, RequireFields<SubscriptionreadNotificationActionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  marketSummary?: SubscriptionResolver<Maybe<ResolversTypes['MarketSummary']>, "marketSummary", ParentType, ContextType, RequireFields<SubscriptionmarketSummaryArgs, 'id' | 'subgraphError'>>;
  marketSummaries?: SubscriptionResolver<Array<ResolversTypes['MarketSummary']>, "marketSummaries", ParentType, ContextType, RequireFields<SubscriptionmarketSummariesArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftSummary?: SubscriptionResolver<Maybe<ResolversTypes['NftSummary']>, "nftSummary", ParentType, ContextType, RequireFields<SubscriptionnftSummaryArgs, 'id' | 'subgraphError'>>;
  nftSummaries?: SubscriptionResolver<Array<ResolversTypes['NftSummary']>, "nftSummaries", ParentType, ContextType, RequireFields<SubscriptionnftSummariesArgs, 'skip' | 'first' | 'subgraphError'>>;
  nft?: SubscriptionResolver<Maybe<ResolversTypes['Nft']>, "nft", ParentType, ContextType, RequireFields<SubscriptionnftArgs, 'id' | 'subgraphError'>>;
  nfts?: SubscriptionResolver<Array<ResolversTypes['Nft']>, "nfts", ParentType, ContextType, RequireFields<SubscriptionnftsArgs, 'skip' | 'first' | 'subgraphError'>>;
  tokenMetadata?: SubscriptionResolver<Maybe<ResolversTypes['TokenMetadata']>, "tokenMetadata", ParentType, ContextType, RequireFields<SubscriptiontokenMetadataArgs, 'id' | 'subgraphError'>>;
  tokenMetadata_collection?: SubscriptionResolver<Array<ResolversTypes['TokenMetadata']>, "tokenMetadata_collection", ParentType, ContextType, RequireFields<SubscriptiontokenMetadata_collectionArgs, 'skip' | 'first' | 'subgraphError'>>;
  userLikeNft?: SubscriptionResolver<Maybe<ResolversTypes['UserLikeNft']>, "userLikeNft", ParentType, ContextType, RequireFields<SubscriptionuserLikeNftArgs, 'id' | 'subgraphError'>>;
  userLikeNfts?: SubscriptionResolver<Array<ResolversTypes['UserLikeNft']>, "userLikeNfts", ParentType, ContextType, RequireFields<SubscriptionuserLikeNftsArgs, 'skip' | 'first' | 'subgraphError'>>;
  user?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "user", ParentType, ContextType, RequireFields<SubscriptionuserArgs, 'id' | 'subgraphError'>>;
  users?: SubscriptionResolver<Array<ResolversTypes['User']>, "users", ParentType, ContextType, RequireFields<SubscriptionusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  userWatchNft?: SubscriptionResolver<Maybe<ResolversTypes['UserWatchNft']>, "userWatchNft", ParentType, ContextType, RequireFields<SubscriptionuserWatchNftArgs, 'id' | 'subgraphError'>>;
  userWatchNfts?: SubscriptionResolver<Array<ResolversTypes['UserWatchNft']>, "userWatchNfts", ParentType, ContextType, RequireFields<SubscriptionuserWatchNftsArgs, 'skip' | 'first' | 'subgraphError'>>;
  notification?: SubscriptionResolver<Maybe<ResolversTypes['Notification']>, "notification", ParentType, ContextType, RequireFields<SubscriptionnotificationArgs, 'id' | 'subgraphError'>>;
  notifications?: SubscriptionResolver<Array<ResolversTypes['Notification']>, "notifications", ParentType, ContextType, RequireFields<SubscriptionnotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftLikedNotification?: SubscriptionResolver<Maybe<ResolversTypes['NftLikedNotification']>, "nftLikedNotification", ParentType, ContextType, RequireFields<SubscriptionnftLikedNotificationArgs, 'id' | 'subgraphError'>>;
  nftLikedNotifications?: SubscriptionResolver<Array<ResolversTypes['NftLikedNotification']>, "nftLikedNotifications", ParentType, ContextType, RequireFields<SubscriptionnftLikedNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nftWatchedNotification?: SubscriptionResolver<Maybe<ResolversTypes['NftWatchedNotification']>, "nftWatchedNotification", ParentType, ContextType, RequireFields<SubscriptionnftWatchedNotificationArgs, 'id' | 'subgraphError'>>;
  nftWatchedNotifications?: SubscriptionResolver<Array<ResolversTypes['NftWatchedNotification']>, "nftWatchedNotifications", ParentType, ContextType, RequireFields<SubscriptionnftWatchedNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  offerAcceptedNotification?: SubscriptionResolver<Maybe<ResolversTypes['OfferAcceptedNotification']>, "offerAcceptedNotification", ParentType, ContextType, RequireFields<SubscriptionofferAcceptedNotificationArgs, 'id' | 'subgraphError'>>;
  offerAcceptedNotifications?: SubscriptionResolver<Array<ResolversTypes['OfferAcceptedNotification']>, "offerAcceptedNotifications", ParentType, ContextType, RequireFields<SubscriptionofferAcceptedNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  listingSoldNotification?: SubscriptionResolver<Maybe<ResolversTypes['ListingSoldNotification']>, "listingSoldNotification", ParentType, ContextType, RequireFields<SubscriptionlistingSoldNotificationArgs, 'id' | 'subgraphError'>>;
  listingSoldNotifications?: SubscriptionResolver<Array<ResolversTypes['ListingSoldNotification']>, "listingSoldNotifications", ParentType, ContextType, RequireFields<SubscriptionlistingSoldNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  receiveOfferNotification?: SubscriptionResolver<Maybe<ResolversTypes['ReceiveOfferNotification']>, "receiveOfferNotification", ParentType, ContextType, RequireFields<SubscriptionreceiveOfferNotificationArgs, 'id' | 'subgraphError'>>;
  receiveOfferNotifications?: SubscriptionResolver<Array<ResolversTypes['ReceiveOfferNotification']>, "receiveOfferNotifications", ParentType, ContextType, RequireFields<SubscriptionreceiveOfferNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  offerUpdateNotification?: SubscriptionResolver<Maybe<ResolversTypes['OfferUpdateNotification']>, "offerUpdateNotification", ParentType, ContextType, RequireFields<SubscriptionofferUpdateNotificationArgs, 'id' | 'subgraphError'>>;
  offerUpdateNotifications?: SubscriptionResolver<Array<ResolversTypes['OfferUpdateNotification']>, "offerUpdateNotifications", ParentType, ContextType, RequireFields<SubscriptionofferUpdateNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  receiveNftNotification?: SubscriptionResolver<Maybe<ResolversTypes['ReceiveNftNotification']>, "receiveNftNotification", ParentType, ContextType, RequireFields<SubscriptionreceiveNftNotificationArgs, 'id' | 'subgraphError'>>;
  receiveNftNotifications?: SubscriptionResolver<Array<ResolversTypes['ReceiveNftNotification']>, "receiveNftNotifications", ParentType, ContextType, RequireFields<SubscriptionreceiveNftNotificationsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_metaArgs>>;
}>;

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export type TokenMetadataResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TokenMetadata'] = ResolversParentTypes['TokenMetadata']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  media_hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  copies?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  issued_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expires_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  starts_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updated_at?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  extra?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference_hash?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TradingResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Trading'] = ResolversParentTypes['Trading']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  trading_type?: Resolver<ResolversTypes['TradingType'], ParentType, ContextType>;
  offering_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  offering?: Resolver<Maybe<ResolversTypes['Offering']>, ParentType, ContextType>;
  is_simple_offering?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  listing_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  listing?: Resolver<Maybe<ResolversTypes['Listing']>, ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_approval_id?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  seller_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  buyer_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  prepaid_balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  payment_balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  payout_balance?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transfer_detail?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tx_time?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnlikeNftActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UnlikeNftAction'] = ResolversParentTypes['UnlikeNftAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UnwatchNftActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UnwatchNftAction'] = ResolversParentTypes['UnwatchNftAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateListingActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UpdateListingAction'] = ResolversParentTypes['UpdateListingAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  old_price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  new_price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  listing_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  listing?: Resolver<ResolversTypes['Listing'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UpdateOfferingActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UpdateOfferingAction'] = ResolversParentTypes['UpdateOfferingAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  offer_creator?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  old_price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  new_price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  offering_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  offering?: Resolver<ResolversTypes['Offering'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  account_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mint_times?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  burn_times?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  listing_times?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  buy_listing_times?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  offer_times?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  accept_offer_times?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transfer_times?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  trading_times?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  trading_volume?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  first_interaction_time?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  last_read_notification_time?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  last_interaction_time?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  like_sum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  watch_sum?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  like_nfts?: Resolver<Array<ResolversTypes['UserLikeNft']>, ParentType, ContextType, RequireFields<Userlike_nftsArgs, 'skip' | 'first'>>;
  watch_nfts?: Resolver<Array<ResolversTypes['UserWatchNft']>, ParentType, ContextType, RequireFields<Userwatch_nftsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UserAction'] = ResolversParentTypes['UserAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  predecessor_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signer_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp_plus_log_index?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  receipt_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft?: Resolver<Maybe<ResolversTypes['Nft']>, ParentType, ContextType>;
  contract_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  token_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  action_type?: Resolver<ResolversTypes['ActionType'], ParentType, ContextType>;
  create_listing_action?: Resolver<Maybe<ResolversTypes['CreateListingAction']>, ParentType, ContextType>;
  update_listing_action?: Resolver<Maybe<ResolversTypes['UpdateListingAction']>, ParentType, ContextType>;
  remove_listing_action?: Resolver<Maybe<ResolversTypes['RemoveListingAction']>, ParentType, ContextType>;
  create_offering_action?: Resolver<Maybe<ResolversTypes['CreateOfferingAction']>, ParentType, ContextType>;
  update_offering_action?: Resolver<Maybe<ResolversTypes['UpdateOfferingAction']>, ParentType, ContextType>;
  remove_offering_action?: Resolver<Maybe<ResolversTypes['RemoveOfferingAction']>, ParentType, ContextType>;
  buy_listing_action?: Resolver<Maybe<ResolversTypes['BuyListingAction']>, ParentType, ContextType>;
  accept_offering_action?: Resolver<Maybe<ResolversTypes['AcceptOfferingAction']>, ParentType, ContextType>;
  nft_mint_action?: Resolver<Maybe<ResolversTypes['NftMintAction']>, ParentType, ContextType>;
  nft_transfer_action?: Resolver<Maybe<ResolversTypes['NftTransferAction']>, ParentType, ContextType>;
  nft_burn_action?: Resolver<Maybe<ResolversTypes['NftBurnAction']>, ParentType, ContextType>;
  nft_register_action?: Resolver<Maybe<ResolversTypes['NftRegisterAction']>, ParentType, ContextType>;
  nft_unregister_action?: Resolver<Maybe<ResolversTypes['NftUnregisterAction']>, ParentType, ContextType>;
  control_account_action?: Resolver<Maybe<ResolversTypes['ControlAccountAction']>, ParentType, ContextType>;
  like_nft_action?: Resolver<Maybe<ResolversTypes['LikeNftAction']>, ParentType, ContextType>;
  unlike_nft_action?: Resolver<Maybe<ResolversTypes['UnlikeNftAction']>, ParentType, ContextType>;
  watch_nft_action?: Resolver<Maybe<ResolversTypes['WatchNftAction']>, ParentType, ContextType>;
  unwatch_nft_action?: Resolver<Maybe<ResolversTypes['UnwatchNftAction']>, ParentType, ContextType>;
  read_notification_action?: Resolver<Maybe<ResolversTypes['ReadNotificationAction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserLikeNftResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UserLikeNft'] = ResolversParentTypes['UserLikeNft']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  nft?: Resolver<ResolversTypes['Nft'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWatchNftResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['UserWatchNft'] = ResolversParentTypes['UserWatchNft']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  nft?: Resolver<ResolversTypes['Nft'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type WatchNftActionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['WatchNftAction'] = ResolversParentTypes['WatchNftAction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  nft_contract_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nft_token_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Block_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentHash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  AcceptOfferingAction?: AcceptOfferingActionResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  BuyListingAction?: BuyListingActionResolvers<ContextType>;
  Bytes?: GraphQLScalarType;
  ControlAccountAction?: ControlAccountActionResolvers<ContextType>;
  CreateListingAction?: CreateListingActionResolvers<ContextType>;
  CreateOfferingAction?: CreateOfferingActionResolvers<ContextType>;
  Int8?: GraphQLScalarType;
  LikeNftAction?: LikeNftActionResolvers<ContextType>;
  Listing?: ListingResolvers<ContextType>;
  ListingSoldNotification?: ListingSoldNotificationResolvers<ContextType>;
  MarketSummary?: MarketSummaryResolvers<ContextType>;
  Nft?: NftResolvers<ContextType>;
  NftBurnAction?: NftBurnActionResolvers<ContextType>;
  NftLikedNotification?: NftLikedNotificationResolvers<ContextType>;
  NftMintAction?: NftMintActionResolvers<ContextType>;
  NftRegisterAction?: NftRegisterActionResolvers<ContextType>;
  NftSummary?: NftSummaryResolvers<ContextType>;
  NftTransferAction?: NftTransferActionResolvers<ContextType>;
  NftUnregisterAction?: NftUnregisterActionResolvers<ContextType>;
  NftWatchedNotification?: NftWatchedNotificationResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  OfferAcceptedNotification?: OfferAcceptedNotificationResolvers<ContextType>;
  OfferUpdateNotification?: OfferUpdateNotificationResolvers<ContextType>;
  Offering?: OfferingResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  ReadNotificationAction?: ReadNotificationActionResolvers<ContextType>;
  ReceiveNftNotification?: ReceiveNftNotificationResolvers<ContextType>;
  ReceiveOfferNotification?: ReceiveOfferNotificationResolvers<ContextType>;
  RemoveListingAction?: RemoveListingActionResolvers<ContextType>;
  RemoveOfferingAction?: RemoveOfferingActionResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Timestamp?: GraphQLScalarType;
  TokenMetadata?: TokenMetadataResolvers<ContextType>;
  Trading?: TradingResolvers<ContextType>;
  UnlikeNftAction?: UnlikeNftActionResolvers<ContextType>;
  UnwatchNftAction?: UnwatchNftActionResolvers<ContextType>;
  UpdateListingAction?: UpdateListingActionResolvers<ContextType>;
  UpdateOfferingAction?: UpdateOfferingActionResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserAction?: UserActionResolvers<ContextType>;
  UserLikeNft?: UserLikeNftResolvers<ContextType>;
  UserWatchNft?: UserWatchNftResolvers<ContextType>;
  WatchNftAction?: WatchNftActionResolvers<ContextType>;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>;
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = NameskyBetatestContext & BaseMeshContext;


const baseDir = pathModule.join(typeof __dirname === 'string' ? __dirname : '/', '..');

const importFn = (moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/namesky-betatest/introspectionSchema":
      return import("./sources/namesky-betatest/introspectionSchema");
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources = [];
const transforms = [];
const additionalEnvelopPlugins = [];
const nameskyBetatestTransforms = [];
const additionalTypeDefs = [] as any[];
const nameskyBetatestHandler = new GraphqlHandler({
              name: "namesky-betatest",
              config: {"endpoint":"https://gateway-arbitrum.network.thegraph.com/api/a72f540d5114d097f49f99d3d2cda08c/subgraphs/id/C5wLYxtXga6P4bnjRb92Nw4K7j4yUtmLZPHNGA7xUCtd"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("namesky-betatest"),
              logger: logger.child("namesky-betatest"),
              importFn,
            });
sources[0] = {
          name: 'namesky-betatest',
          handler: nameskyBetatestHandler,
          transforms: nameskyBetatestTransforms
        }
const additionalResolvers = [] as any[]
const merger = new(BareMerger as any)({
        cache,
        pubsub,
        logger: logger.child('bareMerger'),
        store: rootStore.child('bareMerger')
      })

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: GetNotificationsDocument,
        get rawSDL() {
          return printWithCache(GetNotificationsDocument);
        },
        location: 'GetNotificationsDocument.graphql'
      },{
        document: GetMarketSummariesDocument,
        get rawSDL() {
          return printWithCache(GetMarketSummariesDocument);
        },
        location: 'GetMarketSummariesDocument.graphql'
      },{
        document: GetMarketSummariesDocument,
        get rawSDL() {
          return printWithCache(GetMarketSummariesDocument);
        },
        location: 'GetMarketSummariesDocument.graphql'
      },{
        document: GetNftsDocument,
        get rawSDL() {
          return printWithCache(GetNftsDocument);
        },
        location: 'GetNftsDocument.graphql'
      },{
        document: GetNftsOrderByPriceDocument,
        get rawSDL() {
          return printWithCache(GetNftsOrderByPriceDocument);
        },
        location: 'GetNftsOrderByPriceDocument.graphql'
      },{
        document: GetNftsDocument,
        get rawSDL() {
          return printWithCache(GetNftsDocument);
        },
        location: 'GetNftsDocument.graphql'
      },{
        document: GetNftsOrderByPriceDocument,
        get rawSDL() {
          return printWithCache(GetNftsOrderByPriceDocument);
        },
        location: 'GetNftsOrderByPriceDocument.graphql'
      },{
        document: GetOffersDocument,
        get rawSDL() {
          return printWithCache(GetOffersDocument);
        },
        location: 'GetOffersDocument.graphql'
      },{
        document: GetOffersDocument,
        get rawSDL() {
          return printWithCache(GetOffersDocument);
        },
        location: 'GetOffersDocument.graphql'
      },{
        document: GetSalesDocument,
        get rawSDL() {
          return printWithCache(GetSalesDocument);
        },
        location: 'GetSalesDocument.graphql'
      },{
        document: GetSalesDocument,
        get rawSDL() {
          return printWithCache(GetSalesDocument);
        },
        location: 'GetSalesDocument.graphql'
      },{
        document: GetUserActionsByTokenIdDocument,
        get rawSDL() {
          return printWithCache(GetUserActionsByTokenIdDocument);
        },
        location: 'GetUserActionsByTokenIdDocument.graphql'
      },{
        document: GetUserActionsByTokenIdDocument,
        get rawSDL() {
          return printWithCache(GetUserActionsByTokenIdDocument);
        },
        location: 'GetUserActionsByTokenIdDocument.graphql'
      },{
        document: GetUserActionsByUserIdDocument,
        get rawSDL() {
          return printWithCache(GetUserActionsByUserIdDocument);
        },
        location: 'GetUserActionsByUserIdDocument.graphql'
      },{
        document: GetUserActionsByUserIdDocument,
        get rawSDL() {
          return printWithCache(GetUserActionsByUserIdDocument);
        },
        location: 'GetUserActionsByUserIdDocument.graphql'
      },{
        document: GetUsersDocument,
        get rawSDL() {
          return printWithCache(GetUsersDocument);
        },
        location: 'GetUsersDocument.graphql'
      },{
        document: GetUsersDocument,
        get rawSDL() {
          return printWithCache(GetUsersDocument);
        },
        location: 'GetUsersDocument.graphql'
      },{
        document: GetLikedNftsDocument,
        get rawSDL() {
          return printWithCache(GetLikedNftsDocument);
        },
        location: 'GetLikedNftsDocument.graphql'
      },{
        document: DetailPageQueryDocument,
        get rawSDL() {
          return printWithCache(DetailPageQueryDocument);
        },
        location: 'DetailPageQueryDocument.graphql'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler() {
  return createMeshHTTPHandler({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance<MeshContext>>;

export function getBuiltGraphClient(): Promise<MeshInstance<MeshContext>> {
  if (meshInstance$ == null) {
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh<MeshContext>(meshOptions)).then(mesh => {
      const id$ = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        id$.then(id => mesh.pubsub.unsubscribe(id)).catch(err => console.error(err));
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type GetNotificationsQueryVariables = Exact<{
  receiver: Scalars['String'];
  user_id: Scalars['ID'];
  last_read_timestamp: Scalars['BigInt'];
  limit: Scalars['Int'];
}>;


export type GetNotificationsQuery = { user?: Maybe<Pick<User, 'last_read_notification_time'>>, notifications: Array<(
    Pick<Notification, 'id' | 'receiver' | 'receipt_id' | 'contract_id' | 'timestamp' | 'notification_type'>
    & { nft_liked_notification?: Maybe<Pick<NftLikedNotification, 'id' | 'account_id' | 'nft_contract_id' | 'nft_token_id'>>, nft_watched_notification?: Maybe<Pick<NftWatchedNotification, 'id' | 'account_id' | 'nft_contract_id' | 'nft_token_id'>>, offer_accepted_notification?: Maybe<(
      Pick<OfferAcceptedNotification, 'id'>
      & { accept_offering_action: Pick<AcceptOfferingAction, 'id' | 'buyer_id' | 'seller_id' | 'prepaid_balance' | 'payment_balance' | 'payout_balance' | 'nft_contract_id' | 'nft_token_id' | 'trading_id'> }
    )>, listing_sold_notification?: Maybe<(
      Pick<ListingSoldNotification, 'id'>
      & { buy_listing_action: Pick<BuyListingAction, 'id' | 'buyer_id' | 'seller_id' | 'prepaid_balance' | 'payment_balance' | 'payout_balance' | 'nft_contract_id' | 'nft_token_id' | 'trading_id'> }
    )>, receive_offer_notification?: Maybe<(
      Pick<ReceiveOfferNotification, 'id'>
      & { create_offering_action: Pick<CreateOfferingAction, 'id' | 'nft_token_id' | 'price' | 'offering_id' | 'offer_creator'> }
    )>, offer_update_notification?: Maybe<(
      Pick<OfferUpdateNotification, 'id'>
      & { update_offering_action: Pick<UpdateOfferingAction, 'id' | 'nft_token_id' | 'old_price' | 'new_price' | 'offering_id' | 'offer_creator'> }
    )>, receive_nft_notification?: Maybe<(
      Pick<ReceiveNftNotification, 'id'>
      & { nft_transfer_action: Pick<NftTransferAction, 'id' | 'old_owner_id' | 'new_owner_id' | 'token_id' | 'authorized_id' | 'memo'> }
    )> }
  )> };

export type GetMarketSummariesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMarketSummariesQuery = { marketSummary?: Maybe<Pick<MarketSummary, 'listing_sum' | 'nft_minted_sum' | 'volume'>> };

export type GetNftsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Nft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  search_name_len?: InputMaybe<Scalars['Int']>;
  name_level?: InputMaybe<Scalars['Int']>;
}>;


export type GetNftsQuery = { nfts: Array<(
    Pick<Nft, 'id' | 'token_id' | 'owner_id' | 'price' | 'prefix_domain' | 'top_domain'>
    & { listing?: Maybe<Pick<Listing, 'id' | 'price'>> }
  )> };

export type GetNftsOrderByPriceQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<Nft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  search_name_len?: InputMaybe<Scalars['Int']>;
  name_level?: InputMaybe<Scalars['Int']>;
}>;


export type GetNftsOrderByPriceQuery = { nfts: Array<(
    Pick<Nft, 'id' | 'token_id' | 'owner_id' | 'price' | 'prefix_domain' | 'top_domain'>
    & { listing?: Maybe<Pick<Listing, 'id' | 'price'>> }
  )> };

export type GetOffersQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Offering_orderBy>;
  order_direction?: InputMaybe<OrderDirection>;
}>;


export type GetOffersQuery = { offerings: Array<Pick<Offering, 'id' | 'token_id' | 'buyer_id' | 'price'>> };

export type GetSalesQueryVariables = Exact<{
  order_by?: InputMaybe<Trading_orderBy>;
  order_direction?: InputMaybe<OrderDirection>;
}>;


export type GetSalesQuery = { tradings: Array<Pick<Trading, 'id' | 'nft_token_id' | 'payment_balance'>> };

export type GetUserActionsByTokenIdQueryVariables = Exact<{
  token_id?: InputMaybe<Scalars['String']>;
  last_timestamp?: InputMaybe<Scalars['BigInt']>;
}>;


export type GetUserActionsByTokenIdQuery = { userActions: Array<(
    Pick<UserAction, 'id' | 'timestamp' | 'action_type' | 'user_id' | 'token_id' | 'receipt_id'>
    & { create_listing_action?: Maybe<(
      Pick<CreateListingAction, 'price'>
      & { listing: Pick<Listing, 'seller_id' | 'price' | 'token_id'> }
    )>, update_listing_action?: Maybe<(
      Pick<UpdateListingAction, 'old_price' | 'new_price'>
      & { listing: Pick<Listing, 'seller_id' | 'token_id' | 'price'> }
    )>, remove_listing_action?: Maybe<{ listing: Pick<Listing, 'token_id' | 'seller_id'> }>, buy_listing_action?: Maybe<(
      Pick<BuyListingAction, 'buyer_id'>
      & { listing: Pick<Listing, 'token_id' | 'seller_id' | 'price'> }
    )>, create_offering_action?: Maybe<Pick<CreateOfferingAction, 'price'>>, update_offering_action?: Maybe<Pick<UpdateOfferingAction, 'old_price' | 'new_price'>>, accept_offering_action?: Maybe<Pick<AcceptOfferingAction, 'buyer_id' | 'seller_id' | 'payout_balance' | 'payment_balance'>>, nft_mint_action?: Maybe<Pick<NftMintAction, 'token_id' | 'owner_id'>>, nft_transfer_action?: Maybe<Pick<NftTransferAction, 'token_id' | 'old_owner_id' | 'new_owner_id'>>, nft_burn_action?: Maybe<Pick<NftBurnAction, 'token_id'>> }
  )> };

export type GetUserActionsByUserIdQueryVariables = Exact<{
  last_timestamp?: InputMaybe<Scalars['BigInt']>;
  user_id?: InputMaybe<Scalars['String']>;
}>;


export type GetUserActionsByUserIdQuery = { userActions: Array<(
    Pick<UserAction, 'id' | 'timestamp' | 'action_type' | 'user_id' | 'token_id' | 'receipt_id'>
    & { create_listing_action?: Maybe<(
      Pick<CreateListingAction, 'price'>
      & { listing: Pick<Listing, 'seller_id' | 'price' | 'token_id'> }
    )>, update_listing_action?: Maybe<(
      Pick<UpdateListingAction, 'old_price' | 'new_price'>
      & { listing: Pick<Listing, 'seller_id' | 'token_id' | 'price'> }
    )>, remove_listing_action?: Maybe<{ listing: Pick<Listing, 'token_id' | 'seller_id'> }>, buy_listing_action?: Maybe<(
      Pick<BuyListingAction, 'buyer_id'>
      & { listing: Pick<Listing, 'token_id' | 'seller_id' | 'price'> }
    )>, create_offering_action?: Maybe<Pick<CreateOfferingAction, 'price'>>, update_offering_action?: Maybe<Pick<UpdateOfferingAction, 'old_price' | 'new_price'>>, accept_offering_action?: Maybe<Pick<AcceptOfferingAction, 'buyer_id' | 'seller_id' | 'payout_balance' | 'payment_balance'>>, nft_mint_action?: Maybe<Pick<NftMintAction, 'token_id' | 'owner_id'>>, nft_transfer_action?: Maybe<Pick<NftTransferAction, 'token_id' | 'old_owner_id' | 'new_owner_id'>>, nft_burn_action?: Maybe<Pick<NftBurnAction, 'token_id'>> }
  )> };

export type GetUsersQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
}>;


export type GetUsersQuery = { users: Array<Pick<User, 'id' | 'account_id' | 'mint_times' | 'burn_times' | 'listing_times' | 'buy_listing_times' | 'offer_times' | 'accept_offer_times' | 'transfer_times' | 'trading_times' | 'trading_volume' | 'first_interaction_time' | 'last_read_notification_time' | 'last_interaction_time' | 'like_sum' | 'watch_sum'>> };

export type GetLikedNftsQueryVariables = Exact<{
  user_id: Scalars['ID'];
}>;


export type GetLikedNftsQuery = { user?: Maybe<(
    Pick<User, 'like_sum'>
    & { like_nfts: Array<{ nft: (
        Pick<Nft, 'id' | 'token_id' | 'prefix_domain' | 'top_domain' | 'owner_id' | 'price'>
        & { listing?: Maybe<Pick<Listing, 'id' | 'price'>> }
      ) }> }
  )> };

export type DetailPageQueryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DetailPageQueryQuery = { nft?: Maybe<(
    Pick<Nft, 'id' | 'token_id' | 'liked_sum'>
    & { like_by_users: Array<{ user: Pick<User, 'account_id'> }> }
  )> };


export const GetNotificationsDocument = gql`
    query GetNotifications($receiver: String!, $user_id: ID!, $last_read_timestamp: BigInt!, $limit: Int!) {
  user(id: $user_id) {
    last_read_notification_time
  }
  notifications(
    first: $limit
    orderBy: timestamp
    orderDirection: desc
    where: {timestamp_lt: $last_read_timestamp, receiver: $receiver}
  ) {
    id
    receiver
    receipt_id
    contract_id
    timestamp
    notification_type
    nft_liked_notification {
      id
      account_id
      nft_contract_id
      nft_token_id
    }
    nft_watched_notification {
      id
      account_id
      nft_contract_id
      nft_token_id
    }
    offer_accepted_notification {
      id
      accept_offering_action {
        id
        buyer_id
        seller_id
        prepaid_balance
        payment_balance
        payout_balance
        nft_contract_id
        nft_token_id
        trading_id
      }
    }
    listing_sold_notification {
      id
      buy_listing_action {
        id
        buyer_id
        seller_id
        prepaid_balance
        payment_balance
        payout_balance
        nft_contract_id
        nft_token_id
        trading_id
      }
    }
    receive_offer_notification {
      id
      create_offering_action {
        id
        nft_token_id
        price
        offering_id
        offer_creator
      }
    }
    offer_update_notification {
      id
      update_offering_action {
        id
        nft_token_id
        old_price
        new_price
        offering_id
        offer_creator
      }
    }
    receive_nft_notification {
      id
      nft_transfer_action {
        id
        old_owner_id
        new_owner_id
        token_id
        authorized_id
        memo
      }
    }
  }
}
    ` as unknown as DocumentNode<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetMarketSummariesDocument = gql`
    query GetMarketSummaries {
  marketSummary(id: "summary") {
    listing_sum
    nft_minted_sum
    volume
  }
}
    ` as unknown as DocumentNode<GetMarketSummariesQuery, GetMarketSummariesQueryVariables>;
export const GetNftsDocument = gql`
    query GetNfts($search: String, $orderBy: Nft_orderBy, $orderDirection: OrderDirection, $first: Int, $skip: Int, $search_name_len: Int, $name_level: Int) {
  nfts(
    orderBy: $orderBy
    orderDirection: $orderDirection
    first: $first
    skip: $skip
    where: {search_name_len_lte: $search_name_len, is_burn: false, search_name_contains_nocase: $search, name_level_lte: $name_level}
  ) {
    id
    token_id
    owner_id
    price
    prefix_domain
    top_domain
    listing {
      id
      price
    }
  }
}
    ` as unknown as DocumentNode<GetNftsQuery, GetNftsQueryVariables>;
export const GetNftsOrderByPriceDocument = gql`
    query GetNftsOrderByPrice($search: String, $orderBy: Nft_orderBy, $orderDirection: OrderDirection, $first: Int, $skip: Int, $search_name_len: Int, $name_level: Int) {
  nfts(
    orderBy: $orderBy
    orderDirection: $orderDirection
    first: $first
    skip: $skip
    where: {search_name_len_lte: $search_name_len, is_burn: false, token_id_contains: $search, price_not: null, is_approve_id_effective_in_market_contract: true, name_level_lte: $name_level}
  ) {
    id
    token_id
    owner_id
    price
    prefix_domain
    top_domain
    listing {
      id
      price
    }
  }
}
    ` as unknown as DocumentNode<GetNftsOrderByPriceQuery, GetNftsOrderByPriceQueryVariables>;
export const GetOffersDocument = gql`
    query GetOffers($first: Int = 200, $order_by: Offering_orderBy = price, $order_direction: OrderDirection = desc) {
  offerings(
    first: $first
    orderBy: $order_by
    orderDirection: $order_direction
    where: {is_remove: false}
  ) {
    id
    token_id
    buyer_id
    price
  }
}
    ` as unknown as DocumentNode<GetOffersQuery, GetOffersQueryVariables>;
export const GetSalesDocument = gql`
    query GetSales($order_by: Trading_orderBy = payment_balance, $order_direction: OrderDirection = desc) {
  tradings(first: 10, orderBy: $order_by, orderDirection: $order_direction) {
    id
    nft_token_id
    payment_balance
  }
}
    ` as unknown as DocumentNode<GetSalesQuery, GetSalesQueryVariables>;
export const GetUserActionsByTokenIdDocument = gql`
    query GetUserActionsByTokenId($token_id: String = "", $last_timestamp: BigInt = "3322572585300000000") {
  userActions(
    first: 50
    orderBy: timestamp_plus_log_index
    orderDirection: desc
    where: {timestamp_lt: $last_timestamp, token_id: $token_id}
  ) {
    id
    timestamp
    action_type
    user_id
    token_id
    receipt_id
    create_listing_action {
      price
      listing {
        seller_id
        price
        token_id
      }
    }
    update_listing_action {
      old_price
      new_price
      listing {
        seller_id
        token_id
        price
      }
    }
    remove_listing_action {
      listing {
        token_id
        seller_id
      }
    }
    buy_listing_action {
      buyer_id
      listing {
        token_id
        seller_id
        price
      }
    }
    create_offering_action {
      price
    }
    update_offering_action {
      old_price
      new_price
    }
    accept_offering_action {
      buyer_id
      seller_id
      payout_balance
      payment_balance
    }
    nft_mint_action {
      token_id
      owner_id
    }
    nft_transfer_action {
      token_id
      old_owner_id
      new_owner_id
    }
    nft_burn_action {
      token_id
    }
  }
}
    ` as unknown as DocumentNode<GetUserActionsByTokenIdQuery, GetUserActionsByTokenIdQueryVariables>;
export const GetUserActionsByUserIdDocument = gql`
    query GetUserActionsByUserId($last_timestamp: BigInt = "3322572585300000000", $user_id: String = "") {
  userActions(
    first: 50
    orderBy: timestamp_plus_log_index
    orderDirection: desc
    where: {timestamp_lt: $last_timestamp, user_id: $user_id, action_type_not_in: [read_notification_action]}
  ) {
    id
    timestamp
    action_type
    user_id
    token_id
    receipt_id
    create_listing_action {
      price
      listing {
        seller_id
        price
        token_id
      }
    }
    update_listing_action {
      old_price
      new_price
      listing {
        seller_id
        token_id
        price
      }
    }
    remove_listing_action {
      listing {
        token_id
        seller_id
      }
    }
    buy_listing_action {
      buyer_id
      listing {
        token_id
        seller_id
        price
      }
    }
    create_offering_action {
      price
    }
    update_offering_action {
      old_price
      new_price
    }
    accept_offering_action {
      buyer_id
      seller_id
      payout_balance
      payment_balance
    }
    nft_mint_action {
      token_id
      owner_id
    }
    nft_transfer_action {
      token_id
      old_owner_id
      new_owner_id
    }
    nft_burn_action {
      token_id
    }
  }
}
    ` as unknown as DocumentNode<GetUserActionsByUserIdQuery, GetUserActionsByUserIdQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($search: String = "", $first: Int, $skip: Int = 0, $orderBy: User_orderBy = mint_times, $orderDirection: OrderDirection = desc) {
  users(
    first: $first
    skip: $skip
    orderBy: $orderBy
    orderDirection: $orderDirection
    where: {account_id_contains: $search}
  ) {
    id
    account_id
    mint_times
    burn_times
    listing_times
    buy_listing_times
    offer_times
    accept_offer_times
    transfer_times
    trading_times
    trading_volume
    first_interaction_time
    last_read_notification_time
    last_interaction_time
    like_sum
    watch_sum
  }
}
    ` as unknown as DocumentNode<GetUsersQuery, GetUsersQueryVariables>;
export const GetLikedNftsDocument = gql`
    query GetLikedNfts($user_id: ID!) {
  user(id: $user_id) {
    like_sum
    like_nfts {
      nft {
        id
        token_id
        prefix_domain
        top_domain
        owner_id
        price
        listing {
          id
          price
        }
      }
    }
  }
}
    ` as unknown as DocumentNode<GetLikedNftsQuery, GetLikedNftsQueryVariables>;
export const DetailPageQueryDocument = gql`
    query DetailPageQuery($id: ID!) {
  nft(id: $id) {
    id
    token_id
    liked_sum
    like_by_users {
      user {
        account_id
      }
    }
  }
}
    ` as unknown as DocumentNode<DetailPageQueryQuery, DetailPageQueryQueryVariables>;












export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    GetNotifications(variables: GetNotificationsQueryVariables, options?: C): Promise<GetNotificationsQuery> {
      return requester<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, variables, options) as Promise<GetNotificationsQuery>;
    },
    GetMarketSummaries(variables?: GetMarketSummariesQueryVariables, options?: C): Promise<GetMarketSummariesQuery> {
      return requester<GetMarketSummariesQuery, GetMarketSummariesQueryVariables>(GetMarketSummariesDocument, variables, options) as Promise<GetMarketSummariesQuery>;
    },
    GetNfts(variables?: GetNftsQueryVariables, options?: C): Promise<GetNftsQuery> {
      return requester<GetNftsQuery, GetNftsQueryVariables>(GetNftsDocument, variables, options) as Promise<GetNftsQuery>;
    },
    GetNftsOrderByPrice(variables?: GetNftsOrderByPriceQueryVariables, options?: C): Promise<GetNftsOrderByPriceQuery> {
      return requester<GetNftsOrderByPriceQuery, GetNftsOrderByPriceQueryVariables>(GetNftsOrderByPriceDocument, variables, options) as Promise<GetNftsOrderByPriceQuery>;
    },
    GetOffers(variables?: GetOffersQueryVariables, options?: C): Promise<GetOffersQuery> {
      return requester<GetOffersQuery, GetOffersQueryVariables>(GetOffersDocument, variables, options) as Promise<GetOffersQuery>;
    },
    GetSales(variables?: GetSalesQueryVariables, options?: C): Promise<GetSalesQuery> {
      return requester<GetSalesQuery, GetSalesQueryVariables>(GetSalesDocument, variables, options) as Promise<GetSalesQuery>;
    },
    GetUserActionsByTokenId(variables?: GetUserActionsByTokenIdQueryVariables, options?: C): Promise<GetUserActionsByTokenIdQuery> {
      return requester<GetUserActionsByTokenIdQuery, GetUserActionsByTokenIdQueryVariables>(GetUserActionsByTokenIdDocument, variables, options) as Promise<GetUserActionsByTokenIdQuery>;
    },
    GetUserActionsByUserId(variables?: GetUserActionsByUserIdQueryVariables, options?: C): Promise<GetUserActionsByUserIdQuery> {
      return requester<GetUserActionsByUserIdQuery, GetUserActionsByUserIdQueryVariables>(GetUserActionsByUserIdDocument, variables, options) as Promise<GetUserActionsByUserIdQuery>;
    },
    GetUsers(variables?: GetUsersQueryVariables, options?: C): Promise<GetUsersQuery> {
      return requester<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, variables, options) as Promise<GetUsersQuery>;
    },
    GetLikedNfts(variables: GetLikedNftsQueryVariables, options?: C): Promise<GetLikedNftsQuery> {
      return requester<GetLikedNftsQuery, GetLikedNftsQueryVariables>(GetLikedNftsDocument, variables, options) as Promise<GetLikedNftsQuery>;
    },
    DetailPageQuery(variables: DetailPageQueryQueryVariables, options?: C): Promise<DetailPageQueryQuery> {
      return requester<DetailPageQueryQuery, DetailPageQueryQueryVariables>(DetailPageQueryDocument, variables, options) as Promise<DetailPageQueryQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;