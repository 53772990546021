import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import Collapse from "~components/collapse";
import { config } from "~domain/near/global";
import { ArrowUpRight, ExternalLink } from "react-feather";
import { useAccountBalanceQuery } from "~domain/nnn/queries";
import {
  getTokenMintbaseLink,
  getTokenParasLink,
} from "~domain/paras/services";
import { AccountLink } from "~pages/account/account-activities";
import { Token } from "graphql";
dayjs.extend(relativeTime);

interface Props {
  token_id: string;
  owner_id?: string;
  isOwner?: boolean;
  nft?: Token;
}

const AccountDetailBlock = ({ token_id, owner_id, isOwner, nft }: Props) => {
  const { data } = useAccountBalanceQuery(token_id);
  const parasLink = getTokenParasLink(token_id);
  const mintbaseLink = getTokenMintbaseLink(token_id);
  const walletLink = `${config.MYNEAR_WALLET_URL}/nft-detail/${config.NAMESKY_CORE_CONTRACT_ID}/${token_id}`;
  const explorerLink = `${config.explorerUrl}/address/${token_id}`;

  return (
    <Collapse title="Account details">
      <div className="bg-[#FBFDFF]">
        <div className="flex justify-between p-4 border-b border-gray-200 last:border-b-0">
          <div className="text-sm text-gray-500">Account ID</div>
          <a
            className="group flex items-center text-sm text-gray-500"
            href={explorerLink}
            target="_blank"
          >
            {token_id}
            <ExternalLink className="ml-1" size={16} />
          </a>
        </div>
        {nft && (
          <div className="flex justify-between p-4 border-b border-gray-200 last:border-b-0">
            <div className="text-sm text-gray-500">Current Owner</div>
            {isOwner ? (
              <div className="flex items-center text-sm text-gray-500">you</div>
            ) : (
              <AccountLink account_id={owner_id} className="text-sm" />
            )}
          </div>
        )}
        <div className="flex justify-between p-4 border-b border-gray-200 last:border-b-0">
          <div className="text-sm text-gray-500">Account Balance</div>
          <div className="flex items-center text-sm text-gray-500">
            {data && Number(data).toFixed(2)} NEAR
          </div>
        </div>
        {nft && (
          <>
            <div className="flex justify-between p-4 border-b border-gray-200 group last:border-b-0">
              <a
                target={"_blank"}
                className="flex items-center w-full text-sm font-semibold text-blue-500"
                href={walletLink}
              >
                View NFT on Wallet
                <ExternalLink
                  className="ml-1 group-hover:translate-x-0.5 transition-all duration-300 group-hover:-translate-y-0.5"
                  size={16}
                />
              </a>
            </div>
            <div className="flex justify-between p-4 border-b border-gray-200 group last:border-b-0">
              <a
                target={"_blank"}
                className="flex items-center w-full text-sm font-semibold text-blue-500"
                href={mintbaseLink}
              >
                View NFT on Mintbase
                <ExternalLink
                  className="ml-1 group-hover:translate-x-0.5 transition-all duration-300 group-hover:-translate-y-0.5"
                  size={16}
                />
              </a>
            </div>
          </>
        )}
      </div>
    </Collapse>
  );
};

export default AccountDetailBlock;
