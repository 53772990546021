import React from "react";

export const Flex = ({
  inline,
  column,
  children,
  className,
  justifyCenter,
  justifyEnd,
  justifyStart,
  itemsCenter,
}: {
  inline?: Boolean;
  column?: Boolean;
  children?: React.ReactNode;
  className?: string;
  justifyCenter?: Boolean;
  justifyEnd?: Boolean;
  justifyStart?: Boolean;
  itemsCenter?: Boolean;
}) => {
  const classNames = [
    ...(inline ? ["inline-flex"] : ["flex"]),
    ...(column ? ["flex-col"] : ["flex-row"]),
    ...(justifyCenter ? ["justify-center"] : []),
    ...(justifyEnd ? ["justify-end"] : []),
    ...(justifyStart ? ["justify-start"] : []),
    ...(itemsCenter ? ["items-center"] : []),
  ];
  return (
    <div className={`${classNames.join(" ")} ${className}`}>{children}</div>
  );
};
