import { Nft_orderBy, OrderDirection } from ".graphclient";
import {
  Checkbox,
  Col,
  Collapse,
  Flex,
  Input as AntdInput,
  InputNumber,
  Row,
  Radio,
  RadioChangeEvent,
} from "antd";
import _ from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import CollapsePanel from "antd/es/collapse/CollapsePanel";

const Input = styled(AntdInput)`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
`;

// const Radio = ({
//   label,
//   value,
//   checked,
//   onChange,
// }: {
//   label: string;
//   value: any;
//   checked: boolean;
//   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
// }) => {
//   return (
//     <label className="flex items-center font-medium text-gray-900 gap-x-1">
//       <input
//         type="radio"
//         className="w-6 h-6 text-gray-900 border-gray-300 rounded-full"
//         onChange={onChange}
//         value={value}
//         checked={checked}
//       />
//       {label}
//     </label>
//   );
// };

export type FiltersState = {
  club?: [];
  character_set?: [];
  contain_separator?: boolean;
  name_level?: string;
};

export default function Filters({
  filters,
  filtersState,
  updateFilter,
  onFilterChange,
}: {
  filters: Filter[];
  filtersState: FiltersState;
  updateFilter: (filter_item: FilterItem) => void;
  onFilterChange: (updates: any) => void;
}) {
  const getItems = (filters: Filter[]) =>
    filters.map((filter, i) => {
      return {
        key: i.toString(),
        label: (
          <div className="font-semibold text-gray-700 select-none">
            {filter.name}
          </div>
        ),
        children: (
          <Filter
            filter={filter}
            updateFilter={updateFilter}
            onFilterChange={onFilterChange}
            filtersState={filtersState}
          />
        ),
      };
    });

  return (
    <div className="bg-white flex flex-col mt-4 border border-gray-200 rounded-[16px] shadow overflow-hidden">
      <Collapse
        bordered={false}
        defaultActiveKey={["0", "1", "2"]}
        items={getItems(filters)}
        style={{ background: "white" }}
      />
    </div>
  );
}

function Filter({
  filter,
  filtersState,
  updateFilter,
  onFilterChange,
}: {
  filter: Filter;
  filtersState: FiltersState;
  updateFilter: (filter_item: FilterItem) => void;
  onFilterChange: (updates: any) => void;
}) {
  switch (filter.input_type) {
    case "between":
      return <FilterBetweenInput filter={filter} updateFilter={updateFilter} />;
    case "single":
      return (
        <FilterSingleInput
          filter={filter}
          updateFilter={updateFilter}
          filtersState={filtersState}
          onFilterChange={onFilterChange}
        />
      );
    case "multiple":
      return (
        <FilterMultipleInput
          filter={filter}
          updateFilter={updateFilter}
          filtersState={filtersState}
          onFilterChange={onFilterChange}
        />
      );
  }
}

function FilterSingleInput({
  filter,
  updateFilter,
  filtersState,
  onFilterChange,
}: {
  filter: Filter;
  updateFilter: (filter_item: FilterItem) => void;
  filtersState: FiltersState;
  onFilterChange: (updates: any) => void;
}) {
  const onChange = (e: RadioChangeEvent, filter_item: FilterItem) => {
    // selected_item.is_selected = false
    // updateFilter(selected_item)
    // let new_selected_item = filter.filter_items.find(item => item.value == e.target.value)
    // new_selected_item.is_selected = true
    // updateFilter(new_selected_item)
    const updates = filter_item.handleQueryParam(
      filtersState,
      e.target.checked
    );
    onFilterChange(updates);
  };

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {filter.filter_items.map((filter_item, i) => {
          const isChecked = filtersState[filter_item.key] === filter_item.value;
          return (
            <Radio
              key={i}
              checked={isChecked}
              value={filter_item.value}
              className="text-gray-700"
              onChange={(e) => onChange(e, filter_item)}
            >
              {filter_item.item_name}
            </Radio>
          );
        })}
      </div>
    </div>
  );
}

function FilterMultipleInput({
  filter,
  filtersState,
  updateFilter,
  onFilterChange,
}: {
  filter: Filter;
  updateFilter: (filter_item: FilterItem) => void;
  filtersState: FiltersState;
  onFilterChange: (updates: any) => void;
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {filter.filter_items.map((filter_item, i) => {
        let isChecked = false;
        if (filter_item.type === "Multiple")
          isChecked = filtersState[filter_item.key]?.includes(
            filter_item.value
          );
        if (filter_item.type === "Single")
          isChecked = filtersState[filter_item.key] === filter_item.value;

        return (
          <Checkbox
            key={i}
            id={i.toString()}
            checked={isChecked}
            onChange={(e) => {
              const updates = filter_item.handleQueryParam(
                filtersState,
                e.target.checked
              );
              onFilterChange(updates);
            }}
          >
            <span className="text-gray-700">{filter_item.item_name}</span>
          </Checkbox>
        );
      })}
    </div>
  );
}

function FilterBetweenInput({
  filter,
  updateFilter,
}: {
  filter: Filter;
  updateFilter: (filter_item: FilterItem) => void;
}) {
  const filter_item = filter.filter_items[0];
  const betweenValue = filter_item.value as BetweenValue;
  const [fromInput, setFromInput] = useState<string | undefined>(
    betweenValue.from
  );
  const [toInput, setToInput] = useState<string | undefined>(betweenValue.to);

  const [showError, setShowError] = useState<boolean>(false);

  return (
    <div>
      <Checkbox
        checked={filter_item.is_selected}
        onClick={(event) => {
          if (fromInput.length === 0 && toInput.length === 0) {
            setShowError(true);
            return;
          }
          try {
            if (fromInput.length === 0 && toInput.length === 0) {
              throw "At least input one";
            }

            if (fromInput.length !== 0 && toInput.length !== 0) {
              let fromInputNum = Number(fromInput);
              let toInputNum = Number(toInput);
              if (fromInputNum > toInputNum) {
                throw "Minimum must be less than maximum";
              }
            }
          } catch (e) {
            setShowError(true);
            return;
          }

          filter_item.is_selected = !filter_item.is_selected;
          betweenValue.from = fromInput;
          betweenValue.to = toInput;
          filter_item.value = betweenValue;
          updateFilter(filter_item);
        }}
      >
        <div className="flex items-center gap-2">
          <Input
            className="text-gray-700"
            size="middle"
            value={fromInput}
            min={betweenValue.min}
            onChange={(e) => {
              setFromInput(e.target.value);
            }}
          />
          to
          <Input
            className="text-gray-700"
            value={toInput}
            onChange={(e) => {
              setToInput(e.target.value);
            }}
          />
        </div>
      </Checkbox>
      {showError && <div>Error Input</div>}
    </div>
  );
}

export type Filter = {
  name: string;
  hint?: string;
  type: FilterType;
  input_type: InputType;
  tagText: (filter_item: FilterItem) => string;
  checkIsActive?: (
    filtersState: FiltersState,
    filterItem: FilterItem
  ) => boolean;
  filter_items: FilterItem[];
};

export type InputType = "between" | "single" | "multiple";

export interface FilterItem {
  filter_name: string;
  key: string;
  type: FilterType;
  item_name: string;
  value: number | string | BetweenValue | boolean;
  default_value: number | string | BetweenValue;
  is_selected?: boolean;
  color?: string;
  tagText?: string;
  checkIsActive?: (filtersState: FiltersState) => boolean;
  handleQueryParam: (
    param: any,
    is_selected: boolean,
    value?: number | string | BetweenValue
  ) => any;
}

export type BetweenValue = {
  from: string;
  to: string;
  min?: string;
  max?: string;
};

export type FilterType = "Single" | "Multiple";

export type FilterAction =
  | ChangeFilterAction
  | SelectFilterAction
  | UnselectFilterAction;
export type FilterActionStr =
  | "ChangeFilterAction"
  | "SelectFilterAction"
  | "UnselectFilterAction";

export type ChangeFilterAction = {
  filter_action: FilterActionStr;
  filter_name: string;
  from_item: FilterItem;
  to_item: FilterItem;
};

export type SelectFilterAction = {
  filter_action: FilterActionStr;
  filter_name: string;
  selected_item: FilterItem;
};

export type UnselectFilterAction = {
  filter_action: FilterActionStr;
  filter_name: string;
  unselected_item: FilterItem;
};
