import React from "react";
import { ArrowLeft } from "react-feather";
import "./NotificationHead.css";
import { getUnreadCountString } from "~utils/numbers";
import useUrlState from "@ahooksjs/use-url-state";

type NotificationHeadProps = {
  unreadCount: number;
  markAllAsRead: () => void;
};

const NotificationHead: React.FC<NotificationHeadProps> = ({
  unreadCount,
  markAllAsRead,
}) => {
  const [urlState, setUrlState] = useUrlState({ notifications: "false" });
  return (
    <div className="flex items-center justify-between px-4 py-4">
      <div className="flex items-center">
        <ArrowLeft
          className="p hover:text-blue-500 transition-colors w-10"
          onClick={() => setUrlState({ notifications: "false" })}
        />
        <p className="text-2xl font-semibold text-gray-900 ml-2 ">
          Notifications{" "}
          {unreadCount > 0 && (
            <span>({getUnreadCountString(unreadCount)})</span>
          )}
        </p>
      </div>
      <button
        disabled={unreadCount == 0}
        className="mark-all-read"
        onClick={markAllAsRead}
      >
        Mark all as Read
      </button>
    </div>
  );
};

export default NotificationHead;
