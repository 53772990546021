import { useBoolean } from "ahooks";
import React from "react";
import { ArrowUpRight, ChevronDown, Circle, ExternalLink } from "react-feather";
import { config } from "~domain/near/global";
import { Collapse } from "react-collapse";
import distribution from "~assets/distribution.png";

const FAQSection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const [isOpen, { toggle }] = useBoolean(false);
  return (
    <div
      className="group text-base text-[#353840] p-4 border rounded-[16px] border-gray-200 bg-white cursor-pointer"
      onClick={toggle}
    >
      <div className="flex items-start justify-between">
        <h3 className="mr-2 font-semibold text-gray-600">{title}</h3>
        <ChevronDown
          className={` transition-all group-hover:text-gray-600 shrink-0 ${
            isOpen ? "-rotate-180 text-gray-600" : "rotate-0 text-gray-400"
          }`}
        />
      </div>
      <Collapse isOpened={isOpen}>
        <div className="mt-3 mr-8">{children}</div>
      </Collapse>
    </div>
  );
};

export default () => {
  return (
    <div>
      <h2 className="text-3xl font-bold text-center">FAQ</h2>
      <div className="flex flex-col gap-y-3 mt-5 max-w-[800px] m-auto">
        <FAQSection title="What is the SKY Token?">
          <p id="what-is-sky-token">
            The SKY Token is NameSky's native governance token. The majority of
            the supply will be distributed to active NameSky users and community
            members.
          </p>
        </FAQSection>
        <FAQSection title="How about distribution of SKY Token?">
          <div>
            The total supply of SKY Token is 100,000,000 SKY.
            <ol>
              <li className="flex items-center">
                <Circle className="mr-2" size={"10px"} color="gray" />
                Community: 50%
              </li>
              <li className="flex items-center">
                <Circle className="mr-2" size={"10px"} color="gray" />
                Investors: 20%
              </li>
              <li className="flex items-center">
                <Circle className="mr-2" size={"10px"} color="gray" />
                Team: 15%
              </li>

              <li className="flex items-center">
                <Circle className="mr-2" size={"10px"} color="gray" />
                Marketing: 10%
              </li>

              <li className="flex items-center">
                <Circle className="mr-2" size={"10px"} color="gray" />
                Advisors: 5%
              </li>
            </ol>
            <img src={distribution} alt="distribution" className="mt-3" />
          </div>
        </FAQSection>
        <FAQSection title="How to get SKY Token?">
          <p>
            SKY Token can be obtained by Minting NFTs during the anniversary
            event, with each Minted NFT receiving 40 SKY rewards. The SKY Tokens
            acquired during the event can only be claimed after the event end.
          </p>
        </FAQSection>
        <FAQSection title="What is NameSky?">
          <div className="flex flex-col mt-2 text-gray-500 gap-y-3">
            <p>
              NameSky is an NFT project on the NEAR blockchain. It’s built with
              utilities to make buy & sell NEAR accounts easy and secure as
              NFTs.
            </p>
            <p>
              Every NFT represents the ownership of the attached NEAR account,
              i.e. buying an NFT with account id awesome.near means buying the{" "}
              <b>awesome.near</b> NEAR account.
            </p>
          </div>
        </FAQSection>
        <FAQSection title="How to turn a NEAR account into an NFT?">
          <div className="flex flex-col mt-2 text-gray-500 gap-y-3">
            <p>
              Upon minting an NFT, a lock contract will be deployed to the
              attached NEAR account and all access keys will be removed from
              that attached NEAR account. Thus, only the owner of the NFT can
              access the NEAR account through the lock contract.
            </p>

            {/* <a */}
            {/*   target={"_blank"} */}
            {/*   className="text-blue-500 " */}
            {/*   href={config.CORE_GITHUB_URL} */}
            {/* > */}
            {/*   Read the smart contracts on GitHub{" "} */}
            {/*   <ExternalLink size={18} className="inline -mt-[2px]" /> */}
            {/* </a> */}
          </div>
        </FAQSection>
        <FAQSection title="How to turn an NFT back into a NEAR account?">
          <div className="flex flex-col mt-2 text-gray-500 gap-y-3">
            <p>
              The owner of NameSky NFT can take back the attached NEAR account
              from this website.
            </p>
            <p>
              Only the NFT owner is able to bind a new access key to the
              attached NEAR account through the lock contract which is deployed
              during minting process. The NFT will be burned after binding a new
              access key is done.
            </p>
            <p>
              The recovery phase is generated locally from owner’s device and
              only be visible to the owner. Thus the owner and ony the owner can
              take back control of the attached NEAR account.
            </p>
          </div>
        </FAQSection>
        <FAQSection title=" Where do I get this NFT? ">
          <div className="flex flex-col text-gray-500 gap-y-2">
            <p>
              -{" "}
              <a className="text-blue-500" href="/mint">
                Mint
              </a>{" "}
              on our website
            </p>
            <p>
              -{" "}
              <a className="text-blue-500" href="/marketplace">
                Buy
              </a>{" "}
              on our marketplace
            </p>
          </div>
        </FAQSection>
        <FAQSection title="Why is full access necessary for selling a NEAR account?">
          <div className="flex flex-col text-gray-500 gap-y-2">
            <div>
              The full access is needed to:
              <ol>
                <li>
                  <span className="font-mono">1.</span> Deploy a lock contract
                  to the NEAR account
                </li>
                <li>
                  <span className="font-mono">2.</span> Clean up legacy states
                </li>
                <li>
                  <span className="font-mono">3.</span> Delete all access keys,
                  including the granted full access on NameSky
                </li>
              </ol>
            </div>
            <p>
              After minting, all access keys are deleted, including the full
              access granted earlier. No one can control the account with access
              keys, similar to selling a house without the previous owner having
              access via a hidden key.
            </p>
          </div>
        </FAQSection>
      </div>
    </div>
  );
};
