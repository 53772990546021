import React, { useMemo } from "react";
import nftBackgroundImg from "~assets/nft-background-with-near.png";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { generateNFTMediaSvg, transferToNumberWithCommas } from "~utils/util";
import { useNFTListingQuery } from "~domain/nnn/market/queries";
import getConfig from "~domain/near/config";

const HeroListing = () => {
  const config = getConfig();
  const nftTokenId = config.NAMESKY_HERO_NFT;

  return (
    <a href={`/${nftTokenId}`}>
      <div className="flex flex-col justify-end overflow-hidden bg-white shadow-md hover:-translate-y-1 hover:shadow-lg outline outline-0  transition-all outline-gray-200 box-content rounded-md">
        <img
          loading="lazy"
          src={`${generateNFTMediaSvg(nftTokenId)}`}
          className="inset-0 w-[240px] h-[240px] lg:w-[300px] lg:h-[300px] block"
        />
      </div>
    </a>
  );
};

export default HeroListing;
