import React from "react";
import Modal, { ModalProps } from "~components/modal/modal";
import NFTThumbnail from "~components/nft/thumbnail";
import Spacer from "~components/spacer";
import { Line } from "~pages/mint/mint-modal";
import { PrimaryButton } from "~components/button/Button";
import { useFormattedPriceText } from "~hooks/useNearFiatUSDText";
import { WebWalletIds, useWalletSelector } from "~context/namesky";
import { ListingView } from "namesky-sdk/dist/core/types/data";
import { Amount } from "namesky-sdk";

interface Props extends ModalProps {
  listing?: ListingView;
}

const PurchaseModal = (props: Props) => {
  const { listing } = props;
  if (!listing) return null;

  const [loading, setLoading] = React.useState(false);
  const { namesky, selector } = useWalletSelector();

  const handleClickConfirm = async () => {
    setLoading(true);
    namesky.marketplaceContract
      .buyListing({
        tokenId: listing.nft_token_id,
      })
      .then((res: any) => {
        if (WebWalletIds.includes(selector.store.getState().selectedWalletId))
          return;
        location.reload();
      })
      .catch((e) => {
        location.reload();
      });
  };

  const { fiatPriceFullText } = useFormattedPriceText(listing.price);

  return (
    <Modal
      {...props}
      title={<div className="text-center">Approve purchase</div>}
    >
      <div className="mx-[-16px] px-6">
        <div className="flex items-center mt-[40px]">
          <NFTThumbnail id={listing.nft_token_id} size={60} />
          <div className="flex flex-col items-start ml-[10px]">
            <span className="text-sm font-semibold text-gray-500">NameSky</span>
            <span className="font-semibold text-gray-900 ">
              {listing.nft_token_id}
            </span>
            <span className="text-sm font-semibold text-gray-500">
              Quantity: 1
            </span>
          </div>
        </div>
        <Line />
        <Spacer h="24px" />
        <div className="flex items-start justify-between">
          <span className="text-base font-semibold ">Total price</span>
          <div className="flex flex-col items-end">
            <span className="text-base font-semibold text-gray-900">
              {Amount.format(listing.price, "NEAR")} NEAR
            </span>
            <span className="font-normal text-gray-400">
              {fiatPriceFullText}
            </span>
          </div>
        </div>
        <Spacer h="24px" />
        <PrimaryButton
          size="large"
          isFull
          onClick={handleClickConfirm}
          loading={loading}
        >
          Complete purchase
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default PurchaseModal;
